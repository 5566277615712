<div class="main-page">
    <div class="page-title">
        <p class="title lh-1">Añadir o Modificar Restaurante</p>
        <a routerLink="/admin/ingredients" class="btn-red-ol sm m-auto mr-0">ingredientes</a>
    </div>
    <div class="d-flex js-center">
        <hr class="hr-decor">
        <button (click)="showForm()" type="button" class="btn-add"><span *ngIf="showForm()" class="m-auto">+</span><span *ngIf="showForm()" class="m-auto">-</span></button>
        <hr class="hr-decor">
    </div>
    <div class="my-1 grow-down d-none" id="showForm">
        <app-restaurant-form [admin]="true" (editEmit)="showForm()"></app-restaurant-form>
    </div>
    <div class="page-title">
        <p class="title lh-1">Restaurantes: <small>{{ total }}</small></p>
        <button (click)="get()" class="btn-red-ol sm m-auto mr-0">Refresh</button>
    </div>

    <div (scroll)="scroll( $event )" class="row admin-grid">
        <div *ngFor="let restaurant of restaurants, let i=index" class="col w-100 m-0 py-05 bd-b of-auto">
            <div class="d-flex">
                <img [src]="restaurant.img | img:'restaurants'" [alt]="restaurant.name" class="icon lg bd-bg-round">
                <div class="m-auto ml-05 lh-1">
                    <p class="subtitle">{{ restaurant.name | titlecase }}</p>
                    <a [href]="'https://www.google.es/maps/place?q=' + (restaurant.name + ', ' + (restaurant.address.addressplus ? (restaurant.address.addressplus + ', ') : '') + restaurant.address.town + ', ' + restaurant.address.country)" target="_blank" class="cl-black">
                        <p class="o-5">{{ restaurant.address.town + (restaurant.address.addressplus?', '+restaurant.address.addressplus:'') + ', ' + (restaurant.address.country | iso: 'name') }}</p>
                        <p *ngIf="restaurant.address.coord" class="o-5"><small>{{ restaurant.address?.coord?.coordinates[0] + ', ' + restaurant.address?.coord?.coordinates[1] }}</small></p>
                    </a>
                </div>
                <button (click)="scrollMove($event, i);" class="btn-red sm my-auto mx-05">Editar</button>
            </div>

            <div class="my-1 grow-down d-none" [id]="'showForm'+i">
                <div *ngIf="showFormCheck(i)">
                    <app-restaurant-form [restaurant]="restaurant" [id]="restaurant.id" [inRestaurant]="true" [admin]="true" (editEmit)="showForm(i)"></app-restaurant-form>
                </div>
            </div>
        </div>
        <div class="col w-100">
            <app-loading *ngIf="loading" [wait]="false"></app-loading>
        </div>
    </div>
</div>