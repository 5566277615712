import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../../services/backend/auth.service';
import { Restaurant } from '../../../models/restaurant.model';
import { Recipe } from '../../../models/recipe.model';
import { User } from '../../../models/user.model';
import { ModalService } from '../../../services/modal.service';
import { RecipeReview } from '../../../models/recipe-review.model';
import { ReviewsListComponent } from '../reviews-list/reviews-list.component';

@Component({
  selector: 'app-reviews-concat',
  templateUrl: './reviews-concat.component.html',
  styles: [
  ]
})
export class ReviewsConcatComponent implements OnInit {

  @ViewChild( ReviewsListComponent ) reviewsComponent: ReviewsListComponent;
  
  @Input() content: Recipe | Restaurant | any;
  @Input() model: 'Recipe' | 'Restaurant';
  @Input() modal: boolean = true;
  @Output() reviewsCountEmit: EventEmitter< number > = new EventEmitter();

  public mainUser: User;
  public uid: string;
  public id: string;

  public reviewsCount: number = 0;
  public reviewed: boolean = true;
  public owner: boolean = false;
  public admin: boolean = false;
  public addReview: boolean = false;

  constructor(  private auth: AuthService,
                private modalService: ModalService  ) { 
                this.uid = auth.uid
                this.mainUser = auth.user
  }

  ngOnInit(): void {
    this.id = this.content?.id || this.content?._id || '';
    if ( this.uid === (this.content.user?._id || this.content.user?.id) ) this.owner = true;
    if ( this.mainUser?.role === 'ADMIN_ROLE' ) this.admin = true;
  }

  // scoreChange( event: any ) {    
  //   let options = [];
  //   if ( this.model === 'Restaurant' ) {
  //     options = ['qualityprice', 'atention', 'decoration', 'price'];
  //   } else {
  //     options = ['main'];
  //   }
  //   for ( let option of options ) {
  //     if ( this.content?.score ) {
  //       let avgSum = ( (event.old?event.old:0) * this.reviewsCount  - (event.old?event.old:0) );
  //       this.content.score[option] = ( avgSum + event.main[option] ) / ( this.reviewsCount + (event.old?0:1) );
  //     } else {
  //       this.content.score = { ...this.content.score, [option]: event.main[option] };
  //     }
  //   }
  // }
  
  reloadReviews( event: { form: RecipeReview, edit: boolean } ) {
    // this.scoreChange( event.form.score );  
    this.reviewsComponent.from = 0;
    this.reviewsComponent.reviews = [];
    this.reviewsComponent.getReviews();
  }

}
