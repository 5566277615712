import { Injectable } from '@angular/core';
import { Cache } from '../interfaces/cache.interface';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  public cacheChanges: boolean = false;

  constructor() { }

  getCacheContent( type: string ) {
    return JSON.parse( localStorage.getItem( type ) || '{}' );
  }

  getCacheOptions( type: string ) {
    return JSON.parse( localStorage.getItem( type + 'Opt' ) || '{}' );
  }

  setCacheContent( arr: any[], pos: number, from: number, max: number, type: string, empty: boolean ) {
    const obj: Cache = { array: arr, position: pos, from: from, max: max, empty: empty, date: new Date() };
    localStorage.setItem( type,  JSON.stringify( obj ) );
  }

  setCacheOptions( type: string, opt: any ) {
    
    const old = JSON.parse( localStorage.getItem( type + 'Opt' ) || "{}" );

    if ( type != 'profile' ) {
      localStorage.setItem( type + 'Opt', JSON.stringify( opt || { contentType: old.contentType || 'recipes', friends: old.friends || false } ));
    } else {
      localStorage.setItem( type + 'Opt', JSON.stringify( opt || { type: old.type || 'recipes', contentType: old.contentType || 'recipes' } ));
      if ( opt.changes ) {
        localStorage.removeItem( 'recipesByIdFavsOpt' );
      }
    }
    
  }

  cacheReset( type: string, all: boolean = false ) {

    if ( !all ) {
      localStorage.removeItem( type );
      return;
    }

    let toRemove = '';
    for ( let add1 of ['', 'reviews'] ) {
      for ( let add2 of ['', 'favs', 'Friends'] ) {
        for ( let add3 of ['', 'ById'] ) {
          toRemove = type + add1 + add2 + add3;
          localStorage.removeItem( toRemove );
        }
      }
    }

  }

  cacheClear() {

    const keeper = [ 'profileOpt', 'recipesOpt', 'restaurantsOpt', 'token', 'friends', 'type', 'recipeCache' ];
    let keepArr = [];
    for ( let keep of keeper ) keepArr.push( localStorage.getItem( keep ) );

    localStorage.clear();
    
    let i = 0;
    for ( let keep of keepArr ) {
      localStorage.setItem( keeper[i], keep || '' );
      i++;
    }

  }
  
}
