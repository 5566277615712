import { Injectable } from '@angular/core';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  private _deleting: boolean = false;
  private _helperChanges: boolean = false;
  private _foodFamily: string[] = ['unknown'];
  private _optionsSelection: any = {
    tools: [],
    ingredients: []
  };
  private _tempImg: string;
  private _tempImgElab: string;
  private _imgType: 'users' | 'recipes' | 'elaborations' | 'restaurants' | 'none' = 'none';
  private _shareImg: string;
  private _shareName: string;
  private _shareType: 'users' | 'recipes' | 'restaurants';
  private _coin: string = 'EUR';
  private _array: any[] = [];
  private _selection: any;
  private _locAccepted: boolean = false;
  private _lat: number = 0;
  private _lon: number = 0;
  private _type: 'recipes' | 'restaurants' = 'restaurants';
  private _friends: boolean = false;
  
  constructor( private cs: CacheService  ) { }
  
  get deleting() {
    return this._deleting;
  }

  get helperChanges() {
    return this._helperChanges;
  }

  get foodFamily() {
    return this._foodFamily;
  }

  get optionsSelection() {
    return this._optionsSelection;
  }
  
  get tempImg() {
    return this._tempImg;
  }
  
  get tempImgElab() {
    return this._tempImgElab;
  }

  get imgType() {
    return this._imgType;
  }

  get share() {
    return { img: this._shareImg, name: this._shareName, type: this._shareType };
  }

  get coin() {
    return this._coin;
  }

  get array() {
    return this._array;
  }

  get selection() {
    return this._selection;
  }

  get locAccepted() {
    return this._locAccepted;
  }

  get type() {
    return this._type;
  }

  get friends() {
    return this._friends;
  }

  get coords() {
    return { lat: this._lat, lon: this._lon };
  }

  deletingChange( bool: boolean ) {
    this._deleting = bool;
  }

  noChanges() {
    this._helperChanges = false;
  }

  setFoodFamily( ingredientType: string ) {
    
    if ( !ingredientType ) {
      return this._foodFamily = ['unknown'];
    }
    this._foodFamily = [ ingredientType ];
    this._helperChanges = true;
    
  }

  setOptionSelection( options: any ) {
    this._optionsSelection = options;
    this._helperChanges = true;
  }

  setImg( temp: any ) {
    this._tempImg = temp;
  }

  setImgElab( temp: any ) {
    this._tempImgElab = temp;
  }

  setImgType( type: 'users' | 'recipes' | 'elaborations' | 'restaurants' | 'none' ) {
    this._imgType = type;
  }

  setCoin( coin: string = 'EUR' ) {
    this._coin = coin;
  }

  setArray( array: any[] = [] ) {
    this._array = array;
  }

  setSelection( selection: any = undefined ) {
    this._selection = selection;
  }
  
  setLocAccepted( ok: boolean, lat: number = 0, lon: number = 0 ) {
    if ( ok !== this._locAccepted ) {
      this._locAccepted = ok;
      this._lat = lat;
      this._lon = lon;
      this.cs.cacheReset( 'restaurants', true );
    }
  }

  setType( type: 'recipes' | 'restaurants' ) {   
    this._type = type;
    localStorage.setItem('type', type);
  }

  setFriends( friends: boolean ) {
    this._friends = friends;
    localStorage.setItem('friends', friends?'1':'0');
  }

  setShare( img: string, name: string, type:  'users' | 'recipes' | 'restaurants' ) {
    this._shareImg = img;
    this._shareName = name;
    this._shareType = type;
  }

}
