<div (click)="scrollable()" class="carousel-box">
    <div (scroll)="carouselScroll()" class="carousel" [id]="name + 'Carousel'">
        <div *ngFor="let img of imagesTemp, let i=index" class="card-m d-flex">
            <button (click)="posChange(i, -1)" type="button" class="btn-car-ch left" [class.d-none]="elabMove(i, -1)">
                <img src="/assets/img/intern/icons/menus/arrow.svg" alt="arrow left">
            </button>
            <div class="pst-relative">
                <img [src]="img | img:type" [alt]="'image'+i" class="carousel-img">
                <img (click)="quitElaboration(i)" src="/assets/img/intern/icons/menus/delete.svg" alt="delete" class="carousel-del icon sm">
            </div>
            <button (click)="posChange(i, 1)" type="button" class="btn-car-ch right" [class.d-none]="elabMove(i, 1)">
                <img src="/assets/img/intern/icons/menus/arrow.svg" alt="arrow right">
            </button>
        </div>
    </div>
    <div (click)="scrolling(-1)" class="carousel-s left" [class.d-none]="!scrollBtn.left">
        <img src="/assets/img/intern/icons/menus/arrow-car.svg" alt="arrow left">
    </div>
    <div (click)="scrolling(1)" class="carousel-s right" [class.d-none]="!itsScrollable || !scrollBtn.right">
        <img src="/assets/img/intern/icons/menus/arrow-car.svg" alt="arrow right">
    </div>
</div>