<form (ngSubmit)="register()" [formGroup]="registerForm">
    <input type="text" formControlName="name" placeholder="Nombre de usuario, ej: Mickael Smith" [class.error]="invalidField('name')">
    <input type="text" formControlName="alias" placeholder="Alias, ej: mickael_smith" [class.error]="invalidField('alias')">
    <input type="email" formControlName="email" placeholder="Email" [class.error]="invalidField('email')">
    <input type="password" formControlName="password" placeholder="Contraseña" [class.error]="invalidField('password') || invalidPassword()">
    <input type="password" formControlName="passwordCheck" placeholder="Repetir Contraseña" [class.error]="invalidField('passwordCheck') || invalidPassword()">
    <p *ngIf="!registerForm.valid && formSubmitted" class="error-text">Revisa los campos marcados en rojo</p>
    <p *ngIf="existing" class="error-text">{{ existing }}</p>
    <div class="check-label mb-05">
        <label (click)="checkOpt()" class="label" [class.checked]="registerForm.controls['terms'].value"></label>
        <label> Acepto los <a routerLink="terms" target="_blank" class="alt text-label">Terminos</a></label>
    </div>
    <button type="submit" class="btn-red">Login</button>
</form>