import { Component, OnInit, ViewChild, ElementRef, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { HelperService } from '../../../services/helper.service';
import { SearchService } from '../../../services/backend/search.service';
import { RestaurantFiltersComponent } from '../../filters/restaurant-filters/restaurant-filters.component';
import { RecipeFiltersComponent } from '../../filters/recipe-filters/recipe-filters.component';
import { TranslateService } from '../../../services/translate.service';
import { VariablesService } from '../../../services/variables.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styles: [
  ]
})
export class SearchComponent implements OnInit {

  @ViewChild( 'filter' ) public filter: ElementRef;
  @ViewChild( RestaurantFiltersComponent ) public restaurantFiltersComponent: RestaurantFiltersComponent;
  @ViewChild( RecipeFiltersComponent ) public recipeFiltersComponent: RecipeFiltersComponent;

  @Input() id: string;
  @Input() limit: number = 12;
  @Input() type: 'recipes' | 'restaurants' = 'restaurants';
  @Input() friends: boolean = false;
  @Input() contentType: '' | 'reviews' | 'favs' | 'later' | 'lists' = '';
  @Output() searchEmit: EventEmitter< any > = new EventEmitter();
  @Output() loadingEmit: EventEmitter< boolean > = new EventEmitter();
  @Output() lastMaxReset: EventEmitter< number > = new EventEmitter();
  
  public collapsed: boolean = true;
  public scrollDown: boolean = true;

  public titleElement: any;
  public titleBoxElement: any;
  public searchElement: any;
  public menuElement: any;
  private searchContents: any = [];
  public filters: any = {};
  public termFilters: any = {};
  private filterOk: boolean = false;
  public searchTerm: string;
  public from: number = 0;
  public fromDiacritics: number = 0;
  public fromSplit: number = 0;
  public timeOut: any;

  constructor(  private helperService: HelperService,
                private searchService: SearchService,
                private vs: VariablesService,
                private translateService: TranslateService  ) { }

  ngOnInit(): void {
    this.titleElement = document.getElementsByClassName('page-title');
    this.titleBoxElement = document.getElementsByClassName('title-box');
    this.searchElement = document.getElementsByClassName('p-search');
    this.menuElement = document.getElementById('profile-menu');
  }

  search( push: boolean = false, filtered: boolean = false ) {
    
    clearTimeout( this.timeOut );
    
    if ( !push ) {
      this.from = 0;
      this.fromDiacritics = 0;
      this.fromSplit = 0;
      this.searchContents = [];
      this.lastMaxReset.emit(0);
    };
    
    if ( !filtered && this.searchTerm?.length <= 0 ) {
      if ( this.type === 'recipes' ) this.recipeFiltersComponent.resetTemp();
      if ( this.type === 'restaurants' ) this.restaurantFiltersComponent.resetTemp();
    }
    
    if ( this.searchTerm?.length >= 1 || this.filterOk ) {
      
      if ( !push) {
        this.townsVS();
        // this.addressVS();

        this.timeOut = setTimeout( ()=> {
          
          this.loadingEmit.emit( true );
          
          if ( this.searchTerm?.length >= 1 && !filtered) {
            if ( this.type === 'recipes' ) {
              this.recipeFiltersComponent.filterSearch( this.searchTerm );
            } else if ( this.type === 'restaurants' ) {
              this.restaurantFiltersComponent.filterSearch( this.searchTerm );
            }
          }
          
          if ( filtered ) return this.searchServ( false );

        }, 500 );
      } else {
        return this.searchServ( push );
      }
      
    } else {
      
      this.from = 0;
      this.fromDiacritics = 0;
      this.fromSplit = 0;
      this.searchContents = [];
      this.searchEmit.emit( { ok: false } );
    
    }

  }

  searchServ( push: boolean ) {

    const term = this.searchTerm ? this.searchTerm.split( ',' )[0] : undefined;

    return this.searchService.search( this.from, this.limit, term, this.id, this.filters, (this.contentType===''?this.type:this.contentType), this.friends, this.type, this.termFilters ).subscribe( resp => {
      if ( resp.ok ) {
        if ( push ) {
          this.searchContents.push( ...resp.searchResults );
        } else {
          this.searchContents = resp.searchResults;
        }
        
        this.from += this.limit;
        const more = resp.searchResults.length >= this.limit; //0
        
        this.searchEmit.emit( { ok: true, contents: this.searchContents, more: more } );
      }
      
      this.loadingEmit.emit( false );
    } );
    
  }
  
  filterGrid( event: { filters: any, termFilters: any } ) {
    
    this.loadingEmit.emit( true );
    this.filterOk = false;
    this.filters = event.filters;
    this.termFilters = event.termFilters;
    const filterIter = ['categories', 'subcategories', 'conditions', 'ingredientsFamilies', 'commodities', 'restaurantType', 'ocassions', 'town', 'recommended'];
    
    if ( (this.filters['time'] < 999999) || this.filters['score'] || (this.filters['maxPrice'] < 99999999) ) {
      this.filterOk = true;
    } else {
      for ( let filter of filterIter ) {
        if ( this.filters[filter]?.length > 0 ) {
          this.filterOk = true;
          break;
        }
      }
    }
    
    this.search( false, true );

  }

  townsVS() {

    if ( this.type !== 'restaurants') return;
    if (!this.vs.towns && !this.vs.working) {
      this.vs.getTowns().subscribe( (resp: any) => {
        this.restaurantFiltersComponent.towns = resp;
      } );
    } else {
      this.restaurantFiltersComponent.towns = this.vs.towns;
    }
    
  }

  // addressVS() {
  //   if ( this.type === 'restaurants' ) {
  //     if (!this.vs.address && !this.vs.working) {
  //       this.vs.towns = [];
  //       this.vs.getAddress().subscribe( (resp: any) => {
  //         this.restaurantFiltersComponent.address = resp;
  //         for ( let address of resp ) this.vs.towns.push( address.town );
  //         this.restaurantFiltersComponent.towns = this.vs.towns;
  //       } );
  //     } else {
  //       this.restaurantFiltersComponent.address = this.vs.address;
  //       this.restaurantFiltersComponent.towns = this.vs.towns;
  //     }
  //   }
  // }

  @HostListener( 'document: click', ['$event'] )
  clickOut( event: { target: any; } ) {
    if ( !this.collapsed ) {
      if ( this.filter.nativeElement.contains( event.target ) || this.helperService.deleting ) return this.helperService.deletingChange( false );
      this.collapsed = true;
    }
  }
  
}
