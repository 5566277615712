import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TypeChangeService {

  public _toolsArr: string[] = ['pot', 'cocktail-shaker'];

  constructor() { }

  get toolsArr() {
    return this._toolsArr;
  }

  strToNumArr( arr: string[], arrCont: string[] ) {
    
    let toNum = [];

    if ( arr ) {
      for ( let item of arr ) {
        toNum.push( arrCont.indexOf( item ) );
      }
    }
    return toNum;

  }

  numToStrArr( arr: number[], arrCont: string[] ) {

    let toStr = [];
    
    if ( arr ) {
      for ( let item of arr ) {
        toStr.push( arrCont[item] );
      }
    }
    return toStr;

  }

}
