import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/backend/auth.service';
import { tap } from 'rxjs/operators';
import { ModalService } from '../services/modal.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(  private authService: AuthService,
                private modalService: ModalService,
                private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

      return this.authService.validateToken()
        .pipe(
          tap( isAuth =>  {
            if ( !isAuth ) {
              this.router.navigateByUrl( '/recipes' );
              this.modalService.openModal( 'login' );
            }
          })
        );
  }

}
