import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { HelperService } from '../helper.service';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private headers: object;

  constructor(  private http: HttpClient,
                private hs: HelperService,
                private authService: AuthService  ) {
                  this.headers = authService.headers;
                }

  search( from: number, limit: number, term?: string, id?: string, filters?: any, contentType?: string, friends: boolean = false, type: string = 'recipes', termFilters?: any  ) {

    let location;
    if ( this.hs?.coords?.lat ) location = [this.hs.coords.lat, this.hs.coords.lon];
    
    return this.http.get( `${ base_url }/search?from=${from}&limit=${limit}&term=${ term || undefined }&id=${ id || undefined }&location=${ location || undefined }&time=${filters?.time}&category=${filters?.categories}&subcategory=${filters?.subcategories}&conditions=${filters?.conditions}&ingredientsFamilies=${filters?.ingredientsFamilies}&score=${filters?.score}&commodities=${filters?.commodities}&town=${filters?.town}&country=${filters?.country}&restaurantType=${filters?.restaurantType}&maxprice=${filters?.maxPrice}&ocassions=${filters?.ocassions}&recommended=${filters?.recommended}&categoryterm=${termFilters?.categories}&subcategoryterm=${termFilters?.subcategories}&conditionsterm=${termFilters?.conditions}&commoditiesterm=${termFilters?.commodities}&countryterm=${termFilters?.country}&restaurantTypeterm=${termFilters?.restaurantType}&ocassionsterm=${termFilters?.ocassions}&contenttype=${contentType}&uid=${this.authService.uid}${friends?(`&friends=${this.authService.user.follow?.users?.array || ''}`):''}&type=${type}` )
    .pipe(
      tap( (resp: any) => {
        return resp;
      }),
      catchError( error => {
        console.log(error);
        return of(false)
      } )
    );

  }

  searchAll( from: number, limit: number, term: string  ) {

    return this.http.get( `${ base_url }/search/${term}?from=${from}&limit=${limit}` )
    .pipe(
      tap( (resp: any) => {
        return resp;
      }),
      catchError( error => {
        console.log(error);
        return of(false)
      } )
    );

  }

  searchAutComp( from: number, limit: number, term: string, town: string, country: string, type: 'restaurants' | 'recipes'  ) {

    return this.http.get( `${ base_url }/search/auto/${type}?from=${from}&limit=${limit}&term=${term}&town=${town}&country=${country}` )
    .pipe(
      tap( (resp: any) => {
        return resp;
      }),
      catchError( error => {
        console.log(error);
        return of(false)
      } )
    );

  }

}
