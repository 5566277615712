<div [id]="name+content.id" class="d-flex h-100 dropdown-menu">
    <div class="opt pst-relative">
        <img *ngIf="edit" (click)="editChange(false)" src="/assets/img/intern/icons/menus/close.svg" alt="options" class="icon md bd-rd-circle cr-pointer">
        <img *ngIf="!edit" (click)="collapsed=!collapsed" src="/assets/img/intern/icons/menus/options.svg" alt="options" class="icon md cr-pointer">

        <div *ngIf="!edit" class="dropdown grow-down text-center" [class.d-block]="!collapsed">
            <ul>
                <li (click)="share()" class="cr-pointer">
                    <span class="m-auto">Compartir</span>
                    <img src="/assets/img/intern/icons/menus/share.svg" alt="share" class="icon sm cr-pointer">
                </li>
                <div *ngIf="!owner">
                    <li (click)="report()" class="cr-pointer">
                        <span class="m-auto">Reportar</span>
                        <img src="/assets/img/intern/icons/menus/report.svg" alt="report" class="icon sm">
                    </li>
                </div>
                <div *ngIf="owner">
                    <li (click)="editChange(true)" class="cr-pointer">
                        <div class="d-flex m-auto">
                            <p class="m-auto">Modificar</p>
                            <img src="/assets/img/intern/icons/menus/edit.svg" alt="modify" class="icon sm">
                        </div>
                    </li>
                    <li (click)="delete()" class="error-text bd-t cr-pointer">
                        <div class="d-flex m-auto">
                            <p class="m-auto">Eliminar</p>
                            <img src="/assets/img/intern/icons/menus/delete-r.svg" alt="delete" class="icon sm">
                        </div>
                    </li>
                </div>
            </ul>
        </div>
    </div>
</div>