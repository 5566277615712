<app-search [id]="id" [limit]="limit" [type]="type" [contentType]="contentType" [friends]="friends" (searchEmit)="search($event)" (loadingEmit)="searchingLoad=$event" (lastMaxReset)="lastMax=$event"></app-search>
<div (scroll)="onScroll($event)" id="grid" class="grid">
    <div *ngIf="contentsCheck() && !searchingLoad" class="row scroll-hide" [class.g-4321]="!double && contentType!=='lists'" [class.g-1]="double || contentType==='lists'">
        <!-- <div *ngIf="contentsCheck()" class="row scroll-hide" [class.g-4321]="!double && contentType!=='lists'" [class.g-1]="double || contentType==='lists'"> -->
        <!-- <div *ngIf="contentsCheck()" class="row scroll-hide" [class.g-4321]="!double && contentType!=='lists'" [class.g-21]="double" [class.g-1]="contentType==='lists'"> -->
        <div *ngIf="contents.length <= 0 && !loading" class="empty wait">
            <p class="title">¡Parece que has vaciado la nevera!</p>
            <p class="subtitle">No hay elementos en esta selección</p>
            <img src="assets/img/intern/icons/menus/empty.svg" alt="empty">
        </div>
        <div *ngFor="let content of contents, let i=index" class="col" [class.d-none]="contentType==='reviews' && !content.restaurant?.name && !content.recipe?.name">
            <app-card *ngIf="contentType!=='lists'" [content]="content" [idx]="i" [type]="type" [contentType]="contentType" [double]="double"></app-card>
            <app-list *ngIf="contentType==='lists'" [content]="content" [type]="type"></app-list>
        </div>
    </div>
    <app-loading *ngIf="loading || searchingLoad || (searching && more)" [wait]="false"></app-loading>
</div>
<app-btn-scroll-top></app-btn-scroll-top>