import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalService } from '../../services/modal.service';
import { UploadService } from '../../services/backend/upload.service';
import { HelperService } from '../../services/helper.service';
import { AuthService } from '../../services/backend/auth.service';
import { User } from '../../models/user.model';
import { Router } from '@angular/router';
import { ValidationService } from '../../services/validation.service';
import { UserService } from '../../services/backend/user.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styles: [
  ]
})
export class SettingsComponent implements OnInit {

  public settingsForm: FormGroup;
  public passwordForm: FormGroup;
  public settingsSubmitted: boolean;
  public passwordSubmitted: boolean;

  public user: User;
  public imgToUpdate: File | Blob;

  private max: number = 100;

  constructor(  private fb: FormBuilder,
                public modalService: ModalService,
                private uploadService: UploadService,
                private helperService: HelperService,
                private authService: AuthService,
                private userService: UserService,
                private router: Router,
                private validationService: ValidationService  ) {
                  this.user = authService.user;
                }

  ngOnInit(): void {

    this.settingsForm = this.fb.group({
      name: [ this.user.name, [Validators.required]],
      alias: [ this.user.alias, [Validators.required]],
      email: [ this.user.email, [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')] ],
      description: [ this.user.description, Validators.max(this.max) ]
    });

    this.passwordForm = this.fb.group({
      password: ['',  [Validators.required, Validators.minLength(2)] ],
      passwordNew: ['',  [Validators.required, Validators.minLength(2)] ],
      passwordCheck: ['',  [Validators.required, Validators.minLength(2)] ]
    }, {
      validators: [ this.validationService.passwordMatch('passwordNew', 'passwordCheck')]
    });

  }

  checkOpt() {

    const oldValue = this.settingsForm.controls['terms'].value;
    this.settingsForm.controls['terms'].setValue(!oldValue);

  }

  openModal( type: 'login' | 'register' | 'image' | 'crop' | 'tools' | 'ingredients' | 'close' | 'question' ) {
    this.modalService.openModal( type );
  }

  updateImg() {
    this.wait( 'img' );
  }

  updateFields() {
    this.settingsSubmitted = true;
    if ( this.settingsForm.valid ) {
      this.wait( 'fields' );
    }
  }

  updatePassword() {
    this.passwordSubmitted = true;
    if ( this.passwordForm.valid ) {
      this.wait( 'password' );
    }
  }

  wait( updateOpt: string ) {

    this.openModal( 'question' );
    const interval = setInterval( () => {
      
      if ( !this.modalService.showModal ) {

        if ( this.modalService.ok ) {
          this.modalService.setOk( false );
          this.updateService( updateOpt );
        }
        clearInterval(interval);

      }

    }, 100);

  }

  updateService( updateOpt: string ) {

    switch ( updateOpt ) {
      case 'fields' :
          this.userService.updateUser( this.settingsForm.value ).subscribe( resp => {   
            this.status( resp );
            if ( resp.ok ) {
              this.passwordSubmitted = false;
              this.passwordForm.reset();
            }
          });
          break;
      case 'password' :
          this.authService.changePassword( this.passwordForm.value ).subscribe( resp => {
            this.status( resp );
            if ( resp.ok ) {
              this.passwordSubmitted = false;
              this.passwordForm.reset();
            }
          });
          break;
      case 'img' :
        this.modalService.setStatus( 'loading' );
        this.uploadService.updateImg( this.imgToUpdate, 'users', this.authService.uid )
          .then( resp => {
            this.status( resp );
          }).catch( error => {
            console.log(error);
            this.modalService.setStatus( 'error', error.error.msg );
          });
          
        this.authService.validateToken();
        break;
      default:
        break;
    }

  }

  status( resp: any ) {

    this.modalService.setStatus( resp.ok ? 'ok' : 'error', resp.msg );
    this.openModal( 'close' );

    const interval = setInterval( () => {

      if ( !this.modalService.showModal ) {
        clearInterval( interval );
        this.router.navigate( [ '/reload' ] )
        setTimeout( () => { 
          this.router.navigate( [ `/profile/settings` ] ); 
        }, 100);
      }
      
    }, 100 );

  }

  invalidField( field: string, form: FormGroup, submitted: boolean ): boolean {
    return this.validationService.invalidField( form, field, submitted);
  }

  invalidPassword() {
    return this.validationService.invalidPassword( this.passwordForm, this.passwordSubmitted );
  }

}
