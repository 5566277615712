import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-recipe',
  templateUrl: './new-recipe.component.html',
  styles: [
  ]
})
export class NewRecipeComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {
  }

}
