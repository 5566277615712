import { Component, Input, OnInit, ViewChild, HostListener } from '@angular/core';
import { RecipeReview } from '../../models/recipe-review.model';
import { GridComponent } from '../grid/grid.component';
import { AuthService } from '../../services/backend/auth.service';
import { CacheService } from '../../services/cache.service';
import { HelperService } from '../../services/helper.service';
import { User } from '../../models/user.model';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-page-grid',
  templateUrl: './page-grid.component.html',
  styles: [
  ]
})
export class PageGridComponent implements OnInit {

  @ViewChild( GridComponent ) gridComponent: GridComponent;

  @Input() title: string;
  @Input() type: 'recipes' | 'restaurants';
  
  public friends: boolean = false;
  public contentType: '' | 'reviews' | 'favs' | 'lists' = 'reviews';
  public guard: boolean = false;
  private location: string = '';
  public windowWidth: number;
  public uid: string;
  public notChange: boolean = false;
  
  private touchStartX: number;
  private touchStartY: number;

  constructor(  private authService: AuthService,
                private modalService: ModalService,
                public hs: HelperService,
                private cs: CacheService ) {
                this.guard = authService.guard;
                this.windowWidth = window.innerWidth;
                this.uid = authService.uid;
              }

  ngOnInit(): void {
    this.location = window.location.href.split('/').pop() || '';
    const cache = this.cs.getCacheOptions( this.location );
    this.contentType = cache?.contentType || 'reviews';
    this.friends = this.hs.friends;    
  }

  friendsChange( friends: boolean ) {
    this.friends = friends;
    // this.friends = !this.friends;
    this.gridComponent.friends = this.friends;
    this.gridComponent.searchComponent.friends = this.friends;
    
    this.change();
  }

  contentTypeChange( type?: 'cards' | 'reviews' | 'lists' ) {
    if ( type && this.contentType === (type==='cards' ? '' : type) ) return;
    // if ( type && this.contentType === (type==='cards' ? '' : 'reviews') ) return;
    this.contentType = type ? (type==='cards' ? '' : type) : (this.contentType==='' ? 'reviews' : '');
    // this.contentType = type ? (type==='cards' ? '' : 'reviews') : (this.contentType==='' ? 'reviews' : '');
    this.gridComponent.contentType = this.contentType;
    this.gridComponent.searchComponent.contentType = this.contentType;

    this.change();
  }

  change() {
    this.gridComponent.fullContents = [];
    this.gridComponent.contents = [];
    this.gridComponent.from = 0;
    this.gridComponent.ngOnInit();
    this.gridComponent.searchComponent.search();
    this.cs.setCacheOptions( this.location, { contentType: this.contentType, friends: this.friends } );
  }

  openModal( type: 'login' ) {
    this.modalService.openModal( 'login' );
  }

  @HostListener( 'document: click', ['$event'] )
  clickFriends( event: { target: any; } ) {
    const friendsChangeFriends: any = document.getElementById( 'friendsChangeFriends' );
    const friendsChangeWorld: any = document.getElementById( 'friendsChangeWorld' );
    const friendsChangeTopFriends: any = document.getElementById( 'friendsChangeTopFriends' );
    const friendsChangeTopWorld: any = document.getElementById( 'friendsChangeTopWorld' );
    
    if ( friendsChangeFriends.contains( event.target ) || friendsChangeWorld.contains( event.target ) || friendsChangeTopFriends.contains( event.target ) || friendsChangeTopWorld.contains( event.target ) ) {
      const friends: boolean = (friendsChangeFriends.contains( event.target ) || friendsChangeTopFriends.contains( event.target )) ? true : false;
      if ( this.uid ) this.friendsChange( friends );
      return;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowWidth = window.innerWidth;
  }

  @HostListener( 'document: touchstart', ['$event'] )
  touchStart( event: TouchEvent | any ) {
    this.touchStartX = event.touches[0].pageX;
    this.touchStartY = event.touches[0].pageY;
    if ( event.target.classList.contains('card-car') || event.target.classList.contains('card-car-img') || this.modalService.showModal ) this.notChange = true;
  }

  @HostListener( 'document: touchend', ['$event'] )
  touchEnd( event: TouchEvent ) {

    const changeTypes: any[] = [ 'reviews', '', 'lists' ];
    const idx = changeTypes.indexOf( this.contentType || '' );
    
    if ( idx >= 0 && !this.notChange) {
      const difY = this.touchStartY - event.changedTouches[0].pageY;
      const difX = this.touchStartX - event.changedTouches[0].pageX;
      if ( Math.abs( difY ) < 25 ) if ( difX > 50 || difX < -50 ) this.contentTypeChange()
    }
    
    this.notChange = false;

  }

}
