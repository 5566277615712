<div class="review-box">
    <a [routerLink]="['/profile/user/'+review.user?._id]">
        <img [src]="review.user?.img | img: 'users'" [alt]="review.user?.name" class="review-user bd-bg-round" onerror="this.onerror=null;this.src='/assets/img/intern/no-img.svg'">
    </a>

    <div class="w-100 lh-1">
        <div class="d-flex">
            <div class="score-w d-flex w-100">
                <a [routerLink]="['/profile/user/'+review.user?._id]" class="m-auto ml-0 cl-black">
                    <!-- <p>{{ (owner?'Tú':review.user?.name) | titlecase }}</p> -->
                    <p>{{ owner?'Tú':(review.user?.alias | lowercase) }}</p>
                </a>
                <p class="my-auto"><small>{{ review.updatedAt | date:'dd/MM/yy' }}</small></p>
            </div>

            <app-dropdown-menu [name]="'reviewMenu'" [content]="review" [model]="model" (editEmit)="edit=$event"></app-dropdown-menu>
        </div>

        <p [class]="'cl-'+recommendationColor[review.recommended - 1]">{{ recommendation[review.recommended - 1] | uppercase }}</p>
    </div>
</div>

<div class="w-100">
    <div class="d-flex">
        <div class="score-text pst-relative">
            <app-review-content [type]="model==='RestaurantReview'?'restaurants':'recipes'" [contentName]="content.name" [reviewContent]="review"></app-review-content>

            <div>
                <div class="d-flex mt-05">
                    <div (click)="replies=!replies" class="icon sm cr-pointer">
                        <img src="/assets/img/intern/icons/menus/replies.svg" alt="options" class="icon xsm p-01">
                    </div>
                    <p (click)="replies=!replies" class="mr-1 o-5 cr-pointer">{{ repliesCount }}</p>
                    <div (click)="reply=!reply" class="icon sm cr-pointer">
                        <img src="/assets/img/intern/icons/menus/reply.svg" alt="options" class="icon xsm p-01">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="edit" class="edit-review">
    <div class="modal">
        <div class="modal-box">
            <img (click)="edit=false;editChange();" src="assets/img/intern/icons/menus/close.svg" alt="close" class="modal-close">
            <div class="modal-title bd-b">
                <p class="title">Modificar Reseña</p>
            </div>
            <div class="m-auto">
                <app-restaurant-review-form *ngIf="model==='Restaurant' || model==='RestaurantReview'" [oldReview]="review" [restaurant]="content" [inRestaurant]="true" (reviewEmit)="reviewUpdate($event)" (editEmit)="edit=$event;"></app-restaurant-review-form>
                <app-recipe-review-form *ngIf="model==='Recipe' || model==='RecipeReview'" [oldReview]="review" (reviewEmit)="reviewUpdate($event)"></app-recipe-review-form>
            </div>
        </div>
    </div>
</div>

<div *ngIf="reply" class="score-text-box">
    <app-reply-form [ref]="review.id " [model]="model" (replyEmit)="repliesReload()"></app-reply-form>
</div>

<div class="score-text-box mt-05 bd-t rd-02 grow-down" [class.d-none]="!replies">
    <app-replies [ref]="review.id" (repliesCount)="repliesCount=$event"></app-replies>
</div>