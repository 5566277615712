<div class="main-page">

    <div class="title-box">
        <div class="page-title">
            <h2 class="title">Market Place</h2>
        </div>
    </div>

    <app-grid></app-grid>

</div>