import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { ModalComponent } from '../../components/modals/modal/modal.component';
import { AuthService } from '../../services/backend/auth.service';
import { User } from '../../models/user.model';
import { AuthGuard } from '../../guards/auth.guard';
import { SearchService } from '../../services/backend/search.service';
import { SearchGlobalComponent } from '../../components/search-folder/search-global/search-global.component';
import { HelperService } from '../../services/helper.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

const base_url = environment.base_url;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styles: [
  ]
})
export class NavbarComponent implements OnInit {

  @ViewChild( SearchGlobalComponent ) SGB: SearchGlobalComponent

  public user: User;
  public guard: boolean;
  public type: 'recipes' | 'restaurants' = 'restaurants';
  public friends: boolean = false;
  public menu: boolean = false;
  public anim: boolean = false;
  public profile: boolean = false;

  constructor(  public modalService: ModalService,
                public hs: HelperService,
                private router: Router,
                public authService: AuthService  ) {
                  this.user = authService.user;
                }

  ngOnInit(): void {
    const href = window.location.href;
    this.type = href.includes('restaurant') ? 'restaurants' : (href.includes('recipes') ? 'recipes' : this.hs.type);
    this.hs.setType( this.type );
    this.friends = this.hs.friends;
  }

  openModal( type: 'login' | 'register' | 'image' | 'tools' | 'ingredients' | 'close' | 'new' ) {
    this.modalService.openModal( type );
  }

  typeSel( sel: 'recipes' | 'restaurants', event?: any ) {

    if ( event?.ctrlKey ) return;
    
    if ( this.type !== sel) {
      this.anim = true;
      this.type = sel;
      this.hs.setType( sel );
    }
    
    this.menu = false;
    if ( !window.location.href.includes( 'profile' ) ) {
      this.profile = false;
      return this.router.navigateByUrl(sel);
    }

  }

  friendsSel( friends: boolean ) {
    if ( this.authService.uid ) {
      this.friends = friends;
      return this.hs.setFriends( friends );
    }
    this.modalService.setStatus( 'no-log', 'Inicia sesión o registrate para poder seguir las recomendaciones de personas afines a tí.' );
  }

  profileCheck() {
    this.profile = window.location.href.includes('profile');
  }

  @HostListener( 'document: click', ['$event'] )
  clickOut( event: { target: any; } ) {
    if ( this.menu ) {
      const mainMenu: any = document.getElementById( 'mainMenu' );
      const mainCentral: any = document.getElementById( 'mainCentral' );
      if ( mainMenu.contains( event.target ) || mainCentral.contains( event.target ) ) {
        return;
      }
      this.menu = false;
    }
  }

}
