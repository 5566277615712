import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-advise-banner',
  templateUrl: './advise-banner.component.html',
  styles: [
  ]
})
export class AdviseBannerComponent implements OnInit {

  constructor( public ms: ModalService  ) { }

  ngOnInit(): void {
  }

}
