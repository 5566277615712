import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TextFinderService {

  constructor() { }

  find( toFind: string[], toSearch: string ) {
    
    const searchArray = toSearch.split( ' ' );
    let returnText = '';    
    for ( let i = 0; i < toFind.length; i++ ) toFind[i] = toFind[i].toLowerCase();

    for ( let i = 0; i < searchArray.length; i++ ) {

      const word = searchArray[i].replace( /[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '' )
      .normalize('NFD')
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\S])/g, '').toLowerCase();
    
      if ( !['el','él','ella','ellas','ellos','la','lá','lo','las','los','le','les','en','de','un','con','para', 'a', 'y', 'o', 'al', 'es', 'ya', 'sin', 'si', 'no'].includes( word ) ) {

        if (
          toFind.includes( word ) ||
          toFind.includes( word + 'es' ) ||
          toFind.includes( word + 's' ) ||
          word.endsWith( 'es' ) && toFind.includes( word.slice( 0, ( word.length - 2 ) ) ) ||
          word.endsWith( 's' ) && toFind.includes( word.slice( 0, ( word.length - 1 ) ) )
        ) {
          returnText = `${returnText} <b>${searchArray[i]}</b>`
        } else {
          returnText = `${returnText} ${searchArray[i]}`
        }

      } else {
        returnText = `${returnText} ${searchArray[i]}`
      }

    }

    return returnText;

  }

}
