import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { RestaurantReview } from '../../models/restaurant-review.model';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class RestaurantReviewsService {
  
  private headers: object;
  private uid: string;

  constructor(  private http: HttpClient,
    private authService: AuthService  ) {
      this.headers = authService.headers;
      this.uid = authService.uid;
    }

  get( from: number, limit: number, friends: boolean = false, location?: any ) {
    
    return this.http.get( `${ base_url }/review/restaurants?from=${from}&limit=${limit}&location=${location}${friends?(`&friends=${this.authService.user.follow?.users?.array}`):''}` )
    .pipe(
      tap( (resp: any) => {
        return resp;
      }),
      catchError( error => {
        return of(false)
      } )
    );

  }

  getFilterId( id: string, from: number, limit: number, location?: any ) {

    return this.http.get( `${ base_url }/review/restaurants/${ id }?from=${from}&limit=${limit}&location=${location}` )
    .pipe(
      tap( (resp: any) => {
        return resp;
      }),
      catchError( error => {
        return of(false)
      } )
    );

  }
  getById( id: string, from: number, limit: number, friends: string[] | undefined = undefined ) {

    return this.http.get( `${ base_url }/review/restaurants/restaurant/${ id }?from=${ from }&limit=${ limit }${this.uid?(`&uid=${ this.uid }`):''}${friends?`&friends=${friends}`:''}` )
    .pipe(
      tap( (resp: any) => {
        return resp;
      }),
      catchError( error => {
        return of(false)
      } )
    );

  }
  
  getRestaurantReviewsAvg( id: string ) {

    return this.http.get( `${ base_url }/review/restaurants/avg/${ id }` )
    .pipe(
      tap( (resp: any) => {
        return resp;
      }),
      catchError( error => {
        return of(false)
      } )
    );

  }

  getRestaurantReviewsAvgFriends( id: string, friends: string[] | undefined = undefined ) {

    return this.http.get( `${ base_url }/review/restaurants/avg/friends/${ id }${friends?`?friends=${friends}`:''}` )
    .pipe(
      tap( (resp: any) => {
        return resp;
      }),
      catchError( error => {
        return of(false)
      } )
    );

  }

  createRestaurantReview( review: RestaurantReview ) {
    
    return this.http.post( `${ base_url }/review/restaurants`, review, this.headers )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  return of(false)
                } )
              );
    
  }


  updateRestaurantReview( id: string | undefined, review: RestaurantReview ) {

    return this.http.put( `${ base_url }/review/restaurants/${ id }`, review, this.headers )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  return of(false)
                } )
              );

  }

  delete( id: string | undefined ) {

    return this.http.delete( `${ base_url }/review/restaurants/${ id }`, this.headers )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  return of(false)
                } )
              );

  }


}
