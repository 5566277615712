import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { Recipe } from '../../models/recipe.model';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class RecipesService {

  private headers: object;

  constructor(  private http: HttpClient,
                private authService: AuthService  ) {
                  this.headers = authService.headers;
                }

  get( from: number, limit: number, friends: boolean = false, location?: any ) {

    return this.http.get( `${ base_url }/recipes?from=${from}&limit=${limit}&location=${location}${friends?(`&friends=${this.authService.user.follow?.users?.array}`):''}` )
    .pipe(
      tap( (resp: any) => {
        return resp;
      }),
      catchError( error => {
        console.log(error);
        return of(false)
      } )
    );

  }

  getFilterId( id: string, from: number, limit: number, location?: any ) {

    return this.http.get( `${ base_url }/recipes/filter/${ id }?from=${from}&limit=${limit}&location=${location}` )
    .pipe(
      tap( (resp: any) => {
        return resp;
      }),
      catchError( error => {
        return of(false)
      } )
    );

  }

  getRecipesById( id: string ) {

    return this.http.get( `${ base_url }/recipes/recipe/${ id }`, this.headers )
    .pipe(
      tap( (resp: any) => {
        return resp;
      }),
      catchError( error => {
        console.log(error);
        return of(false)
      } )
    );

  }

  getFavs( id: string, from: number, limit: number, location: any ) {

    return this.http.get( `${ base_url }/recipes/favs/${ id }?from=${ from }&limit=${ limit }`, this.headers )
    .pipe(
      tap( (resp: any) => {
        return resp;
      }),
      catchError( error => {
        return of(false)
      } )
    );

  }

  getLists( from: number, limit: number, friends: boolean = false, location?: any ) {

    return this.http.get( `${ base_url }/recipes/users/lists?from=${from}&limit=${limit}&location=${location}${friends?(`&friends=${this.authService.user.follow?.users?.array}`):''}` )
    .pipe(
      tap( (resp: any) => {
        return resp;
      }),
      catchError( error => {
        console.log(error);
        return of(false)
      } )
    );

  }

  createRecipe( recipe: Recipe, pos: number | null = 0 ) {
    
    return this.http.post( `${ base_url }/recipes?position=${ pos }`, recipe, this.headers )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  console.log(error);
                  return of(false)
                } )
              );

  }

  updateRecipe( recipe: Recipe, id: string | undefined, pos: number | null = null  ) {
    return this.http.put( `${ base_url }/recipes/${ id }?position=${ pos }`, recipe, this.headers )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  console.log(error);
                  return of(false)
                } )
              );

  }

  addFavs( id: string ) {

    const body = { user: this.authService.uid, date: new Date() };
    return this.http.put( `${ base_url }/recipes/favs/${ id }`, body, this.headers )
              .pipe(
                tap( (resp: any) => {                  
                  return resp;
                }),
                catchError( error => {
                  console.log(error);
                  return of(false)
                } )
              );

  }

  //Change Id
  delete( id: string | undefined ) {
    
    return this.http.put( `${ base_url }/recipes/change/${ id }`, {}, this.headers )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  console.log(error);
                  return of(false)
                } )
              );

  }

}
