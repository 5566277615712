import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { List } from 'app/models/list.model';
import { environment } from 'environments/environment';
import { catchError, of, tap } from 'rxjs';
import { AuthService } from './auth.service';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class ListsService {
  private headers: object;

  constructor(  private http: HttpClient,
    private authService: AuthService  ) {
      this.headers = authService.headers;
    }

  getListsById( id: string | undefined ) {

    return this.http.get( `${ base_url }/list/lists/${ id }` )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  return of(false)
                } )
              );

  }
  
  getListsFilterById( id: string | undefined, from: number, limit: number ) {

    return this.http.get( `${ base_url }/list/lists/${ id }?from=${from}&limit=${limit}` )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  return of(false)
                } )
              );

  }

  createList( list: List ) {
    
    return this.http.post( `${ base_url }/list`, list, this.headers )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  return of(false)
                } )
              );

  }

  updateList( list: List ) {
    
    return this.http.put( `${ base_url }/list/${ list.id }`, list, this.headers )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  return of(false)
                } )
              );

  }

  delete( id: string | undefined ) {
    
    return this.http.delete( `${ base_url }/list/${ id }`, this.headers )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  return of(false)
                } )
              );

  }

}
