import { Component, OnInit, HostListener } from '@angular/core';
import { VariablesService } from '../../services/variables.service';
import { IngredientsService } from '../../services/backend/ingredients.service';
import { ModalService } from '../../services/modal.service';
import { UserService } from '../../services/backend/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ingredients',
  templateUrl: './ingredients-admin.component.html',
  styles: [
  ]
})
export class IngredientsAdminComponent implements OnInit {

  public foodFamilies: string[];
  public ingredients: any[];
  public total: number;
  public loading: boolean = false;

  constructor(  private vs: VariablesService,
                private ingServ: IngredientsService,
                private userService: UserService,
                private router: Router,
                private modalService: ModalService  ) { 
    this.foodFamilies = vs.foodFamilies;
  }

  ngOnInit(): void {
    if ( this.userService?.role !== 'ADMIN_ROLE' ) { 
      this.router.navigateByUrl( '/recipes' );
      return;
    }
    this.get();
  }

  get() {
    this.loading = true;
    this.ingServ.getIngredients().subscribe( resp => {
      if ( resp.ok ) {
        this.ingredients = resp?.ingredients;
        this.total = resp.total;
        for ( let ingredient of this.ingredients ) {
          if ( ingredient.foodFamily[0] === 'unknown' ) ingredient.foodFamily = [];
        }
      }
      this.loading = false;
    } );
  }

  create() {

    const ingredient:any = document.getElementById( 'ingredient' );
    const foodFamily:any = document.getElementById( 'foodFamily' );

    const ingredientForm = {
      ingredient: ingredient.value,
      foodFamily: [foodFamily.value]
    }    

    this.ingServ.createIngredient( ingredientForm ).subscribe( resp => {
      if ( resp.ok ) {
       this.modalService.setStatus( 'ok', 'Ingrediente añadido correctamente' );
       this.get();
      } else {        
        this.modalService.setStatus( 'error', resp.msg );
      }
    } );

  }

  update( id: string, idx: number ) {

    if ( !id ) return;
    
    const ingredient:any = document.getElementById( 'ingredient' + idx );

    const ingredientForm = {
      ingredient: ingredient.value,
      foodFamily: this.ingredients[ idx ].foodFamily
    }    

    this.ingServ.updateIngredient( ingredientForm, id ).subscribe( resp => {
      if ( resp.ok ) {
        this.ingredients.splice( idx ,1 );
        this.total--;
        if ( this.ingredients.length <= 3 ) {
          this.get();
        }
        setTimeout( () => {
          document.getElementById( 'ingredient' + idx )?.focus();
        }, 100 )
      } else {
        this.modalService.setStatus( 'error', resp.msg );
      }
    } );

  }

  delete( id: string, idx: number ) {

    if ( !id ) return;
    
    this.modalService.openModal( 'question' );
    const interval = setInterval( () => {
      if ( !this.modalService.showModal ) {
        clearInterval(interval);
        if ( this.modalService.ok ) {

          this.ingServ.deleteIngredient( id ).subscribe( resp => {
            if ( resp.ok ) {
              this.ingredients.splice( idx ,1 );
              this.total--;
              if ( this.ingredients.length <= 3 ) {
                this.get();
              }
              setTimeout( () => {
                document.getElementById( 'ingredient' + idx )?.focus();
              }, 100 )
            }
          } );

        }
      }
    }, 100);

  }

  familyChange( idx: any = '', del: boolean = false, fIdx: any = '' ) {
    
    const foodFamily:any = document.getElementById( 'foodFamily' + idx );
    
    if ( del ) {
      foodFamily.value = 'unknown';
      return this.ingredients[ idx ].foodFamily.splice( fIdx, 1 );
    }
    if ( !this.ingredients[ idx ].foodFamily.includes( foodFamily.value ) && foodFamily.value !== 'unknown' ) {
      this.ingredients[ idx ].foodFamily.push( foodFamily.value );
      foodFamily.value = 'unknown';
    }
    
  }

}
