import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../services/backend/user.service';
import { AuthService } from '../../services/backend/auth.service';
import { User } from '../../models/user.model';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-follow-btn',
  templateUrl: './follow-btn.component.html',
  styles: [
  ]
})
export class FollowBtnComponent implements OnInit {

  @Input() id: string;
  public user: User;
  public following: boolean = false;

  constructor(  private userService: UserService,
                private modalService: ModalService,
                private authService: AuthService  ) { 
                  this.user = authService.user;
                }

  ngOnInit(): void {
    if ( this.user && this.user.follow && this.user.follow.users && this.user.follow.users.array.indexOf( this.id ) >= 0 ) {
      this.following = true;
    }
  }

  follow() {
    
    if ( this.user ) {
      this.following = !this.following;
      const followArray = this.user.follow?.users?.array || [];
      const idx = followArray.indexOf( this.id );
      if ( idx >= 0 ) {
        this.user.follow?.users?.array.splice( idx, 1 );
      } else {
        this.user.follow?.users?.array.push( this.id );
      }
      return this.userService.follow( this.id ).subscribe();
    }
    
    this.modalService.setStatus( 'no-log' );
      
  }

}
