import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reload',
  templateUrl: './reload.component.html',
  styles: [
  ]
})
export class ReloadComponent implements OnInit {

  public timeOut: any;

  constructor(  private router: Router  ) { }

  ngOnInit(): void {
    this.timeOut = setTimeout( () => {
      this.router.navigateByUrl( '/restaurants' );
    }, 1000 );
  }

  ngOnDestroy() {
    clearTimeout( this.timeOut );
  }

}
