import { AbstractControl } from "@angular/forms";

export class MyValidations {

    static link( control: AbstractControl ) {
        const value = control.value;
        if ( value && value.length > 0 ) {
          if ( value.indexOf('https://www.youtube.com/watch?') >= 0 ||  value.indexOf('https://youtu.be/') >= 0 ) {
            return true;
          }
          return { link: false };
        }
        return true;
    }

}