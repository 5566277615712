<!-- <div> -->
<!-- <div *ngIf="ok"> -->
<div *ngIf="authService.init">
    <router-outlet></router-outlet>
</div>
<div *ngIf="!authService.init" class="d-flex js-center h-100vh">
    <!-- You must also activate the bg-cl-main style -->
    <!-- <div *ngIf="!authService.init" class="d-flex js-center h-100vh bg-cl-main"> -->
    <!-- <div class="m-auto cl-white"> -->
    <div class="m-auto">
        <div class="d-flex h-50vh">
            <img src="../../assets/img/intern/logo.svg" class="logo big" alt="FoodiesHome Logo">
            <!-- <img src="../../assets/img/intern/logo-w.svg" class="logo" alt="FoodiesHome Logo"> -->
            <h1 class="text-logo big">FoodiesHome</h1>
        </div>
        <app-loading></app-loading>
    </div>
</div>