import { Component, OnInit, ViewChild, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-img-carousel',
  templateUrl: './img-carousel.component.html',
  styles: [
  ]
})
export class ImgCarouselComponent implements OnInit {

  @Input() imagesTemp: any[];
  @Input() images: File[] | Blob[] | string[];
  @Input() name: string = '';
  @Input() type: 'users' | 'recipes' | 'restaurants' = 'restaurants';
  @Output() imagesEmitter: EventEmitter< object > = new EventEmitter();
  
  public carousel: any;
  private slide: boolean = false;
  private scrollCheck: any;
  public itsScrollable: boolean = false;
  public scrollBtn: any = {
    left: false,
    right: true
  };

  constructor(  private modalService: ModalService  ) { }

  ngOnInit(): void {
    this.checkCarousel();
  }

  @HostListener('document:mousedown', ['$event'])
  onMouseDown( event: MouseEvent) {
    
    if ( this.carousel?.contains( event.target ) ) {
      event.preventDefault();
      this.slide = true;
      this.carousel.style.cursor = 'grabbing';
      this.carousel.classList.add( 'grab' );
    }

  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp( event: MouseEvent) {
    
    this.checkCarousel();
    if ( this.slide ) {
      this.carousel.eventPre
      this.slide = false;
      this.carousel.style.cursor = 'grab';
      this.carousel.classList.remove( 'grab' );
      this.carouselScroll();
    }

  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove( event: MouseEvent) {

    if ( this.carousel?.contains( event.target ) && this.slide ) {
      event.preventDefault();
      let scrollX: any = this.carousel.scrollLeft - event.movementX;;
      
      this.carousel.scroll( { left: scrollX } );
    }

  }

  carouselScroll() {
    clearTimeout( this.scrollCheck );
    this.scrollCheck = setTimeout( () => {
      this.scrollBtnDisp( this.carousel.scrollLeft );
    }, 100 );
  }

  scrollBtnDisp( scrollX: number ) {
    
    this.scrollBtn = { 
      left: ( scrollX > 0 ), 
      right: ( (scrollX + 1) < (this.carousel.scrollWidth - this.carousel.clientWidth) )
    }
    this.carousel.scroll( { left: scrollX, behavior: "smooth" } )

  }

  scrolling( dir: number ) {
    
    const scrollWidth: any = this.carousel.scrollWidth;
    let scrollX: any = this.carousel.scrollLeft;
    scrollX = scrollX + ( Math.floor( this.carousel.clientWidth - 120 ) * dir );
    
    if ( scrollX <= 50 ) scrollX = 0;
    if ( scrollX >= (scrollWidth - this.carousel.clientWidth) ) scrollX = scrollWidth;

    this.scrollBtnDisp( scrollX );

  }

  scrollable() {
    if ( !this.itsScrollable && this.carousel ) this.itsScrollable = (this.carousel.scrollWidth > this.carousel.clientWidth);
  }

  posChange( idx: number, dir: number ) {
    
    let copy;
    const changer = idx + dir;
  
    if ( changer < 0 || changer > this.imagesTemp?.length ) {
      return;
    }
    for ( let arr of [ this.images, this.imagesTemp ] ) {
      copy = arr[ idx ];
      arr.splice( idx, 1 );
      arr.splice( changer, 0, copy );
    }
    
    const lastSel: any = document.getElementsByClassName('selected')[0];
    if ( lastSel ) {
      lastSel.classList.remove('selected');
    }
    const changeSel: any = document.getElementsByClassName('changed')[0];
    if ( changeSel ) {
      changeSel.classList.remove('changed');
    }
    
    const newSel: any = document.getElementsByClassName('card-m')[idx];
    const newChangeSel: any = document.getElementsByClassName('card-m')[idx + dir];
    if ( newSel && newChangeSel ) {
      newSel.classList.add('selected');
      newChangeSel.classList.add('changed');
      setTimeout( () => {
        newSel.classList.remove('selected');
        newChangeSel.classList.remove('changed');
      }, 500 );
    }

    this.imagesEmitter.emit( { images: this.images, imagesTemp: this.imagesTemp } );

  }

  elabMove( i: number, dir: number ) {
    return (dir < 0) ? (i <= 0) : (i >= ( this.imagesTemp?.length - 1 ));
  }

  quitElaboration( idx: number ) {

    this.modalService.openModal( 'question' );
    const interval = setInterval( () => {
      if ( !this.modalService.showModal ) {
        clearInterval(interval);
        if ( this.modalService.ok ) {
          this.imagesTemp.splice( idx, 1 );
          this.images.splice( idx, 1 );
          this.imagesEmitter.emit( { images: this.images, imagesTemp: this.imagesTemp } );
        }
      }
    }, 100);

  }

  checkCarousel() {
    const interval = setInterval( () => {
      if ( !this.carousel ) this.carousel = document.getElementById( this.name + 'Carousel' );
      if ( this.carousel ) {
        clearInterval( interval );
        this.scrollable();
      }
    }, 100 );
  }

}
