import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-icon-selector',
  templateUrl: './icon-selector.component.html',
  styles: [
  ]
})
export class IconSelectorComponent implements OnInit {

  @Input() options: string[];
  @Input() type: string;
  @Input() grid: string;
  @Input() oldOptSelected: string[];
  @Output() selectedEmit: EventEmitter< string[] > = new EventEmitter();
  
  public optionsSelected: string[] = [];

  constructor() { }

  ngOnInit(): void {
    if ( this.oldOptSelected ) {
      this.optionsSelected = this.oldOptSelected;
    }  
  }

  checkOpt( option: string ) {

    return this.selection( this.optionsSelected, option );
    
  }

  selection( selection: any, option: string ) {

    const include = selection.indexOf( option );
    if ( include >= 0 ) {
      return selection.splice( include, 1 );
    }

    selection.push( option );
    this.selectedEmit.emit( selection );

  }

  selected( event: any ) {    
    this.optionsSelected = event;
    this.selectedEmit.emit( event );
  }

}
