<app-loading *ngIf="loading">
</app-loading>
<div *ngIf="content && !loading">

    <div class="in-head bg-restaurant">
        <div class="main-page row g-21">
            <div class="col w-fc d-flex">
                <img (click)="openModal('image');setImg(content.img)" [src]="(content.img | img:'restaurants')" [alt]="'image ' + content.name" class="in-img">
            </div>
            <div class="col w-md-60 d-flex">
                <div class="in-titlebox">
                    <h2 class="in-title">{{ content.name | titlecase }}</h2>
                    <h3 *ngIf="!content.category && content.category!==0" class="in-category xl">restaurante</h3>
                    <h3 *ngIf="content.category || content.category===0" class="in-category xl">{{ (content.category!==24?'Cocina ':'') + (((content.category[0] | toStr:'restCategories' | Esp) || '-') | titlecase) }}</h3>
                    <h3 *ngIf="content.specialities.length>0" class="in-category mb-025">Especialista en <span *ngFor="let speciality of content.specialities, let i=index"><span *ngIf="i>0"> {{ (i===(content.specialities.length-1))?'y':',' }} </span>{{ speciality | toStr: 'specialities' | Esp | titlecase}}</span>
                    </h3>
                    <h3 class="in-category d-flex">
                        <a [href]="'https://www.google.es/maps/place?q=' + (content.name + ', ' + (content.address.addressplus ? (content.address.addressplus + ', ') : '') + content.address.town + ', ' + content.address.country)" target="_blank" class="d-flex">
                            <img src="/assets/img/intern/icons/menus/geo.svg" alt="geo icon" class="icon sm geo ml-0"> {{ ((content.address.addressplus ? (content.address.addressplus + ', ') : '') + content.address.town + ', ' + (content.address.country
                            | iso)) | titlecase }}
                        </a>
                    </h3>
                </div>
            </div>
        </div>

        <app-btn-back></app-btn-back>
        <div class="edit">
            <img (click)="addLater()" [src]="'/assets/img/intern/icons/menus/later'+(isLater?'-fill':'')+'.svg'" alt="later" class="my-auto later icon md">
            <img (click)="addFavs()" [src]="'/assets/img/intern/icons/menus/heart'+(isFav?'-fill':'')+'.svg'" alt="heart" class="icon md cr-pointer">
            <app-dropdown-menu [name]=" 'restaurantMenu'" [content]="content" [model]="model" (editEmit)="edit=$event"></app-dropdown-menu>
        </div>
    </div>

    <div *ngIf="!uid" class="py-2 bg-aux-075 cl-main text-center">
        <p class="w-90 m-auto"><b><span class="subtitle">Únete a FoodiesHome</span><br>Guarda, crea y comparte restaurantes y recetas con tus amigos</b></p>
        <button (click)="openModal( 'login')" class="btn-red-ol sm mx-0 mt-1">Login</button>
    </div>

    <div class="main-page mt-2">

        <div *ngIf="!edit">

            <div *ngIf="content?.score" (click)="scrollRev()" class="text-center">
                <img src="/assets/img/intern/icons/menus/score.svg" alt="score" class="icon slg">
                <div class="d-flex js-center">
                    <div *ngIf="avgFriendsScore || avgFriendsScore===0" class="px-1 bd-r">
                        <div class="text-center cr-pointer" id="friendActiv">
                            <p><small class="o-5">Amigos</small></p>
                        </div>
                        <div class="score-avg cr-pointer" id="friendActivScr">
                            <p class="mr-025 lh-1"><b>{{ avgFriendsScore | number:'1.1-1' }}<small>/10</small></b></p>
                        </div>
                    </div>
                    <div class="px-1">
                        <div class="text-center cr-pointer">
                            <p><small class="o-5">Mundo</small></p>
                        </div>
                        <div class="score-avg cr-pointer">
                            <p class="mr-025 lh-1"><b>{{ (avgScore | number:'1.1-1') || '-' }}<small>/10</small></b></p>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="content?.contact?.phone || content?.contact?.booking || content?.contact?.menu || content?.web">
                <div class="row g-4 text-center mb-1 mt-2 profile-info">

                    <div *ngIf="content?.contact?.phone" class="col p-05">
                        <div class="w-100 of-auto">
                            <a (click)="contactChange(content.contact.phone, 'tel:')" [href]="'tel:'+content.contact.phone">
                                <img (click)="contactChange(content.contact.phone, 'tel:')" src="/assets/img/intern/icons/menus/phone.svg" class="icon xmd cr-pointer">
                            </a>
                        </div>
                        <p (click)="contactChange(content.contact.phone, 'tel:')"><small class="o-5">Teléfono</small></p>
                    </div>
                    <div *ngIf="content?.contact?.booking" class="col p-05">
                        <div class="w-100 of-auto">
                            <a (click)="contactChange(content.contact.booking, (content.contact.booking.includes('@')?'mailto:':''))" [href]="content.contact.booking.includes('@')?'mailto:':''+content.contact.booking" target="_blank">
                                <img (click)="contactChange(content.contact.booking, (content.contact.booking.includes('@')?'mailto:':''))" src="/assets/img/intern/icons/menus/booking-s.svg" class="icon xmd w-fc cr-pointer">
                            </a>
                        </div>
                        <p (click)="contactChange(content.contact.booking, (content.contact.booking.includes('@')?'mailto:':''))"><small class="o-5">Reserva</small></p>
                    </div>
                    <div *ngIf="content?.contact?.menu" class="col p-05">
                        <div class="w-100 of-auto">
                            <a (click)="contactChange(content.contact.menu, '')" [href]="content.contact.menu" target="_blank">
                                <img (click)="contactChange(content.contact.menu, '')" src="/assets/img/intern/icons/menus/menu-s.svg" class="icon xmd cr-pointer">
                            </a>
                        </div>
                        <p (click)="contactChange(content.contact.menu, '')"><small class="o-5">Carta</small></p>
                    </div>
                    <div *ngIf="content?.contact?.web" class="col p-05">
                        <div class="w-100 of-auto">
                            <a (click)="contactChange(content.contact.web, '')" [href]="content.contact.web" target="_blank" class="cl-black">
                                <img (click)="contactChange(content.contact.web, '')" src="/assets/img/intern/icons/menus/web.svg" class="icon xmd cr-pointer">
                            </a>
                        </div>
                        <p (click)="contactChange(content.contact.web, '')"><small class="o-5">Web</small></p>
                    </div>
                </div>
                <a *ngIf="contactType==='tel:'" [href]="contactType+contact" target="_blank" class="cl-black">
                    <p class="title text-center of-auto px-05" [class.d-none]="!contact">{{ format(contact) }}</p>
                </a>
            </div>

            <div *ngIf="content.score">
                <hr class="my-2-auto">
                <div *ngIf="content.price || content.score?.qualityprice || content.score?.atention || content.score?.decoration">
                    <div class="row g-4 md of-auto text-center">
                        <div *ngIf="content.price" (click)="advise()" class="col">
                            <p class="o-5 lh-1 mb-05"><small>Precio:</small></p>
                            <p class="title">{{ (content.price?.price * priceAux) >= 60 ? '€€€+' : (content.price?.price * priceAux) >= 35 ? '€€€' : (content.price?.price * priceAux) >= 15 ? '€€' : '€'}}</p>
                            <p class="advise" id="price-advise">
                                {{ (content.price?.price * priceAux) >= 60 ? 'más de 60 €' : (content.price?.price * priceAux) >= 35 ? '35-60 €' : (content.price?.price * priceAux) >= 15 ? '15-35 €' : 'menos de 15 €'}}
                            </p>
                        </div>
                        <div *ngIf="content.score?.qualityprice" class="col odr-md-1">
                            <p class="o-5 lh-1 mb-05"><small>Calidad/Precio:</small></p>
                            <img [src]="'/assets/img/intern/icons/menus/'+mood[content.score.qualityprice - 1]+'.svg'" [alt]="mood[content.score.qualityprice]" class="icon">
                        </div>
                        <div *ngIf="content.score?.atention" class="col odr-md-1">
                            <p class="o-5 lh-1 mb-05"><small>Atención:</small></p>
                            <img [src]="'/assets/img/intern/icons/menus/'+mood[content.score.atention - 1]+'.svg'" [alt]="mood[content.score.atention]" class="icon">
                        </div>
                        <div *ngIf="content.score?.decoration" class="col odr-md-1">
                            <p class="o-5 lh-1 mb-05"><small>Decoración:</small></p>
                            <img [src]="'/assets/img/intern/icons/menus/'+mood[content.score.decoration - 1]+'.svg'" [alt]="mood[content.score.decoration]" class="icon">
                        </div>
                    </div>
                </div>

                <div *ngIf="content?.score?.foodsize || content?.score?.bookingscore || content?.score?.parkingscore" class="mt-2 container">
                    <div class="text-center">
                        <img src="/assets/img/intern/icons/status/warning.svg" alt="warning" class="icon">
                        <p class="o-5">Atención</p>
                    </div>
                    <div class="row g-4 md of-auto text-center">
                        <div *ngIf="content.score?.foodsize" class="col odr-md-1">
                            <p class="o-5 lh-1 mb-05"><small>Raciones</small>:</p>
                            <p class="subtitle">{{ size[content.score.foodsize - 1] }}</p>
                        </div>
                        <div *ngIf="content.score?.parkingscore" class="col">
                            <p class="o-5 lh-1 mb-05"><small>Aparcamiento Difícil:</small></p>
                            <p class="subtitle">{{ ((content.score.parkingscore - 1) > 0) ? 'No' : 'Sí' }}</p>
                        </div>
                        <div *ngIf="content.score?.bookingscore" class="col">
                            <p class="o-5 lh-1 mb-05"><small>Reserva recomendada:</small></p>
                            <p class="subtitle">{{ bookingTime[content.score.bookingscore - 1] }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="my-2-auto">

            <div *ngIf="contentcommodities.length>0" class="mt-2">
                <div class="row g-4 commodities text-center">
                    <div *ngFor="let commodity of contentcommodities" class="col mx-05">
                        <div [class]="'commodity bg-'+commodity">
                            <img [src]="'assets/img/intern/icons/commodities/'+commodity+'.svg'" alt="nobooking" class="icon lg m-auto">
                        </div>
                        <div class="commodity-text">
                            <p><b>{{ commodity | Esp | titlecase }}</b></p>
                        </div>
                    </div>
                </div>
                <hr class="my-2-auto">
            </div>

            <div *ngIf="content.observations">
                <div class="d-flex">
                    <p class="enf mr-05"><b>observaciones:</b></p>
                    <button (click)="obs('Main')" type="button" class="btn-add card-category"><span class="m-auto">
                        <span id="plusMain">+</span>
                        <span class="d-none" id="minusMain">-</span></span>
                    </button>
                </div>
                <p class="d-none" id="checkMain">{{ content.observations }}</p>
                <hr class="my-2-auto">
            </div>

            <div *ngIf="(plates?.length||0)>0">
                <div class="row mxh-100px of-auto">
                    <div *ngFor="let plate of plates" class="col d-flex js-center">
                        <p class="mr-05">{{ plate.name }}</p>
                        <img [src]=" '/assets/img/intern/icons/menus/'+(moodStatus[(plate.score || 1) - 1] || 'excellent')+'.svg'" [alt]="mood[(plate.score || 1) - 1] + '.svg'" class="icon sm">
                    </div>
                </div>
                <hr class="my-2-auto">
            </div>

            <div class="pst-relative">
                <iframe src="https://www.google.com/maps/embed/v1/place?key=AIzaSyApxDl_UlYmLAPCm27f4ahKC2bJC155Wp8&q=españa&zoom=4" width="100%" height="250px" frameborder="0" class="map" [class.d-none]="mapHide" allowfullscreen id="mapLoc">
                </iframe>
                <div *ngIf="!mapLoadingHide" class="map-cover" [class.fade-out]="!mapLoading">
                    <app-loading></app-loading>
                </div>
            </div>

            <div id="reviews">
                <app-reviews-concat [content]="content" [model]="'Restaurant'" [modal]="false" (reviewsCountEmit)="reviewsCount=$event"></app-reviews-concat>
            </div>
        </div>

        <app-restaurant-form *ngIf="edit" [restaurant]="content" [id]="content.id" [inRestaurant]="true"></app-restaurant-form>

    </div>
</div>