import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoServiceService {

  constructor(  private title: Title,
                private meta: Meta  ) { }

  updateTitle( title: string ) {
    this.title.setTitle(title);
  }

  updateMetaTags( metaTags: MetaDefinition[] | any[] ) {   
    metaTags.forEach( m => this.meta.updateTag(m) );
  }

}
