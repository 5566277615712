import { Component, OnInit, Input,  } from '@angular/core';
import { AuthService } from '../../services/backend/auth.service';
import { ModalService } from '../../services/modal.service';
import { RecipesService } from '../../services/backend/recipes.service';
import { Router } from '@angular/router';
import { CacheService } from '../../services/cache.service';
import { Recipe } from '../../models/recipe.model';


@Component({
  selector: 'app-recipe-form',
  templateUrl: './recipe-form.component.html',
  styles: [
  ]
})
export class RecipeFormComponent implements OnInit {

  @Input() content: Recipe;

  public elaborations: Recipe[] = [];
  private elaborationsIds: string[] = [];

  private uid: string;
  private role: string = 'NONE_ROLE';

  constructor(  private modalService: ModalService,
                private recipesService: RecipesService,
                private authService: AuthService,
                private cs: CacheService,
                private router: Router  ) {
                  this.uid = authService.uid;
                  this.role = authService?.user?.role || 'NONE_ROLE';
                }

  ngOnInit(): void {
    
    this.modalService.setImgEdit( false, '' );

    if ( this.content?.elaborations && (this.content?.elaborations?.length > 0) ) {
      for ( let elaboration of this.content?.elaborations ) {
        let i = 0;
        if (elaboration?.ingredients && elaboration?.ingredients.length > 0) {
          for (let ing of elaboration?.ingredients) {
            elaboration.ingredients[i].quantity = Math.round((ing.quantity * this.content.portions) * 100 ) / 100;
            i++;
          }
        }
      }
      
    }
    let j = 0;
    if (this.content?.ingredients && this.content?.ingredients.length > 0) {
      for (let ing of this.content?.ingredients) {
        this.content.ingredients[j].quantity = Math.round((ing.quantity * this.content.portions) * 100) / 100;
        j++;
      }
    }
  }

  ngOnDestroy() {
    if ( this.uid === (this.content?.user?._id || this.content?.user?.id) || this.role === 'ADMIN_ROLE' ) this.modalService.setImgEdit( true, (this.content?.id || this.content?._id) );
  }

  async newRecipe( event: any ) {
    
    this.elaborations = event?.elaborations || [];

    const tTime = event.time;
    let ingredientFamilies: any[] = [];
    for ( let ing of event?.ingredients ) {
      ingredientFamilies.push( ing.foodFamily );
    }
    let difficulty: number = event.difficulty;
    let tags: any[] = [ event.tags ];
    
    this.elaborations.forEach( elab => {
      
      if ( elab.difficulty > difficulty ) {
        difficulty = elab.difficulty
      };
      if ( elab.time > tTime && tTime && tTime > 0 ) {
        event.time = elab.time;
      }
      
      elab?.ingredients?.forEach( ing => {
        for ( let family of ing.foodFamily ) {
          if ( ingredientFamilies.indexOf( family ) < 0 ) {
            ingredientFamilies = [ ...ingredientFamilies, ...ing.foodFamily ];
          }
        }
      });

      elab.portions = event.portions;
      elab?.ingredients?.forEach( ing => {
        for ( let family of ing.foodFamily ) {
          if ( elab.ingredientsFamilies && elab.ingredientsFamilies.indexOf( family ) < 0 ) {
            elab.ingredientsFamilies = [ ...ingredientFamilies, ...ing.foodFamily ];
          }
        }
      } );
    } );

    this.elaborationsIds = await this.createElaborations();
  
    for ( let ing of (event?.ingredients || []) ) {
      ing.quantity = ing.quantity / event.portions;
    }
            
    this.createRecipe( event );
    
  }

  async createElaborations() {
    
    let elaborations = new Array(this.elaborations.length).fill( 'empty' );
    let pos: number = 0;
    let i: number = 0;

    for await ( let elaboration of this.elaborations ) {
      
      i = 0;
      for ( let ing of (elaboration?.ingredients || []) ) {
        if ( elaboration && elaboration?.ingredients) {
          elaboration.ingredients[i].quantity = ing.quantity / elaboration.portions;
        }
        i++;
      }
      
      if ( !elaboration._id && !elaboration.foreign ) {
        this.recipesService.createRecipe( elaboration, pos ).subscribe( async resp => {
          elaborations[resp.position] = resp.recipe.id;
        });
      } else if( !elaboration.foreign ) {

        if ( elaboration.user?._id?elaboration.user?._id:elaboration.user === this.uid ) {
          this.recipesService.updateRecipe( elaboration, elaboration._id, pos ).subscribe( async resp => {
            elaborations[resp.position] = resp.recipe.id;
          });
        } else {
          elaborations[pos] = elaboration._id;
          this.modalService.setStatus( 'warning', 'Las elaboraciones de otros usuarios no podrán ser modificadas, el resto se procesará de forma normal');
        }

      } else {
        elaborations[pos] = (elaboration._id || elaboration.id);
      }
      
      pos++;
    }

    return elaborations;
    
  }

  createRecipe( event: any ) {
    
    this.modalService.setStatus( 'loading' );

    for ( let control of [ 'conditions', 'tags', 'observations', 'link', 'elaborations', 'tools' ] ) {
      if ( !event[control] || event[control]?.length <= 0 ) {
        delete event[control];
      }
    }

    let iter = 0;
    const interval = setInterval( () => {
      
      if ( this.elaborationsIds.length === this.elaborations.length && this.elaborationsIds.indexOf( 'empty' ) < 0 ) {
        
        clearInterval(interval);
        event.elaborations = this.elaborationsIds;

        if ( !this.content ) {
          this.recipesService.createRecipe( event ).subscribe( async resp => {
            this.response( resp.ok, `/recipe/${resp.recipe.id}` );
          });
        } else {
          this.recipesService.updateRecipe( event, this.content.id ).subscribe( async resp => {
            this.response( resp.ok, `/recipe/${ this.content.id }`, false );
          });
        }
        
        this.cs.cacheReset( 'recipes', true );
        //Revisar en un futuro, no me convence
        this.modalService.setEditing( false );

      } else if ( iter > 100 ) {

        this.modalService.setStatus( 'error', 'Error en el proceso, compruebe su conexión e intentelo de nuevo' );
        clearInterval(interval);

      } else {
        iter++;
      }

    }, 100 );

  }

  response( ok: boolean, nav: string, share: boolean = true ) {

    if ( ok ) {

      this.cs.cacheReset( 'recipeCache', true );
      this.modalService.closeModal();
      this.modalService.setStatus( 'ok', 'Receta procesada con éxito' );

      const interval = setInterval( () => {
        if ( !this.modalService.showModal ) {
          clearInterval( interval );
          if ( share ) {

            this.modalService.openModal( 'q-share' );
            
            const shareInterval = setInterval( () => {
              if ( !this.modalService.showModal ) {
                clearInterval( shareInterval );
                if ( this.modalService.ok ) setTimeout ( () => this.modalService.openModal( 'share' ), 200 );
                this.reload( nav );
              }
            }, 100);

          } else {
            this.reload( nav );
          }
        }
      }, 100);

    } else {
      this.modalService.setStatus( 'error', 'Error al procesar la receta, compruebe que los campos y si no recargue la página o contacte con Foodieshome');
    }

  }

  reload( nav: any ) {
    this.router.navigate( [ '/reload' ] );
    setTimeout( () => { 
      this.router.navigate( [ nav ] );
    }, 100);
  }

}
