import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/backend/user.service';
import { VariablesService } from '../../services/variables.service';
import { Router } from '@angular/router';
import { RestaurantsService } from '../../services/backend/restaurants.service';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-restaurants-admin',
  templateUrl: './restaurants-admin.component.html',
  styles: [
  ]
})
export class RestaurantsAdminComponent implements OnInit {

  public restaurants: any[];
  public total: number;
  public loading: boolean = false;
  private from: number = 0;
  private limit: number = 10;
  public empty: boolean = false;

  constructor(  private vs: VariablesService,
                private userService: UserService,
                private restaurantsService: RestaurantsService,
                private modalService: ModalService,
                private router: Router  ) { }

  ngOnInit(): void {
    if (!this.vs.towns && !this.vs.working) this.vs.getTowns().subscribe();
    if ( this.userService?.role !== 'ADMIN_ROLE' ) { 
      this.router.navigateByUrl( '/recipes' );
      return;
    }
    this.get();
  }

  get( push: boolean = false ) {

    this.loading = true;
    if ( push ) this.from += this.limit;

    this.restaurantsService.get( this.from, this.limit ).subscribe( resp => {

      if ( resp.ok ) {
        if ( push ) {
          if ( resp.restaurants.length > 0 ) {
            this.empty = false;
            this.restaurants = [ ...this.restaurants, ...resp.restaurants ];
            return;
          }
          this.empty = true;
        } else {
          this.empty = false;
          this.restaurants = resp.restaurants;
        }
        this.total = resp.total;
      }
      this.loading = false;

    } );

  }

  delete( id: string, idx: number ) {

    if ( !id ) return;
    
    this.modalService.openModal( 'question' );
    const interval = setInterval( () => {
      if ( !this.modalService.showModal ) {
        clearInterval(interval);
        if ( this.modalService.ok ) {

          this.restaurantsService.delete( id ).subscribe( resp => {
            if ( resp.ok ) {
              this.restaurants.splice( idx ,1 );
              this.total--;
              if ( this.restaurants.length <= 3 ) {
                this.get();
              }
              setTimeout( () => {
                document.getElementById( 'ingredient' + idx )?.focus();
              }, 100 )
            }
          } );

        }
      }
    }, 100);

  }

  showForm( idx: any = '' ) {

    const show = document.getElementById( 'showForm' + idx )?.classList;
    
    if ( show?.contains( 'd-flex' ) ) {
      document.getElementById( 'showForm' + idx )?.classList.add( 'd-none' );
      document.getElementById( 'showForm' + idx )?.classList.remove( 'd-flex' );
      return false;
    }
    document.getElementById( 'showForm' + idx )?.classList.add( 'd-flex' );
    document.getElementById( 'showForm' + idx )?.classList.remove( 'd-none' );
    return true;

  }

  showFormCheck( idx: any ) {
    const show = document.getElementById( 'showForm' + idx )?.classList;
    
    if ( show?.contains( 'd-none' ) ) return false;
    return true;
  }

  scroll( event: any ) {

    const height = event.target.clientHeight || 0;
    const max = event.target.scrollHeight || 0;
    const pos = event.target.scrollTop || 0;
    
    if ( ((pos + height) * 1.5) > max && !this.empty) {
      this.get( true );
    }

  }

  scrollMove( event: any, i: number ) {
    
    window.scroll( { top: 93, behavior: 'smooth' });

    setTimeout( () => {
      const grid = document.getElementsByClassName('admin-grid');
      const scrollIn = ( (grid.length > 0) ? grid[0] : window );
      const initY = ( (grid.length > 0) ? grid[0].scrollTop : window.scrollY );
      
      const targetTop: number = event.target.getBoundingClientRect().top;
      if ( targetTop < 100 ) return;
      const scrollY = ( initY + (targetTop - 150) );
      setTimeout( () => {
        this.showForm(i);
        scrollIn.scrollTo({ top: scrollY, behavior: 'smooth' });
      }, 100 );
    }, 300 );

  }

}
