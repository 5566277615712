import { Component, Input, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalService } from '../../../services/modal.service';
import { SearchIconSelectorComponent } from '../../search-folder/search-icon-selector/search-icon-selector.component';
import { HelperService } from '../../../services/helper.service';
import { VariablesService } from '../../../services/variables.service';

@Component({
  selector: 'app-opt-select',
  templateUrl: './opt-select.component.html',
  styles: [
  ]
})
export class OptSelectComponent implements OnInit {

  @ViewChild( SearchIconSelectorComponent) searchIconSelectorComponent: SearchIconSelectorComponent;

  @Output() closeEmit: EventEmitter< boolean > = new EventEmitter();

  public type: 'tools' | 'ingredients' = 'tools';
  public options: any = {
    tools: [],
    ingredients: []
  }

  public optionsSelection: any = {
    tools: [],
    ingredients: []
  };

  public optForm: FormGroup;
  public modalSearch: any;
  public ok: boolean;
  public more: boolean;

  constructor(  private fb: FormBuilder,
                public modalService: ModalService,
                private helperService: HelperService,
                private vs: VariablesService  ) {
                  this.options.ingredients = vs.foodFamilies.slice(1, vs.foodFamilies.length);
                }

  ngOnInit(): void {
   
    this.options.tools= this.vs.tools.tools;
    
    if ( this.modalService.showModal ) {
      this.optionsSelection = this.helperService.optionsSelection;
    }

    const modalIf:any = document.getElementsByClassName('modal-if');
    modalIf[0].style.width= "90%"

    this.modalSearch = document.getElementById('search');

  }

  ngDoCheck() {
    
    if ( this.modalService.type !== 'tools' && this.modalService.type !== 'ingredients' ) {
      this.modalService.setStatus('error', `No es posible procesar la opción ${ this.modalService.type }`);
      return
    }

    this.type = this.modalService.type;

    if ( this.modalService.showModal && this.ok ) {
      this.helperService.setFoodFamily( this.optionsSelection?.ingredients[0] );
      this.ok = false;
    } else if ( !this.modalService.showModal ) {
      this.modalSearch.value = '';
      if ( this.searchIconSelectorComponent ) {
        this.searchIconSelectorComponent.searchOptions = [];
      }
    }

  }

  saveOpt() {
    this.ok = true;
    this.helperService.setOptionSelection( this.optionsSelection );
    this.closeEmit.emit( true );
  }

}
