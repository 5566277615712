import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplyService } from '../../services/backend/reply.service';
import { ReportsService } from '../../services/backend/reports.service';
import { AuthService } from '../../services/backend/auth.service';
import { ModalService } from '../../services/modal.service';
import { RecipeReviewsService } from '../../services/backend/recipe-reviews.service';
import { RecipesService } from '../../services/backend/recipes.service';
import { UserService } from '../../services/backend/user.service';
import { RestaurantsService } from '../../services/backend/restaurants.service';
import { RestaurantReviewsService } from '../../services/backend/restaurant-reviews.service';

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styles: [
  ]
})
export class DropdownMenuComponent implements OnInit {

  @Input() name: string;
  @Input() content: any;
  @Input() model: string;
  @Output() editEmit: EventEmitter< boolean > = new EventEmitter();
  
  private uid: string;
  public owner: boolean = false;
  public edit: boolean = false;
  public collapsed: boolean = true;
  
  constructor(  private recipesService: RecipesService,
                private replyService: ReplyService,
                private userService: UserService,
                private recipeReviewsService: RecipeReviewsService,
                private restaurantsService: RestaurantsService,
                private restaurantReviewsService: RestaurantReviewsService,
                private activatedRoute: ActivatedRoute,
                private authService: AuthService,
                private modalService: ModalService,
                private router: Router  ) {  
                                this.uid = authService.uid; 
                              }

  ngOnInit(): void {
    if ( this.model !== 'Restaurant' && this.content?.user?._id === this.uid || this.userService?.role === 'ADMIN_ROLE' ) this.owner = true;
  }

  report() {
    this.modalService.setReport( this.content.id, this.model );
    this.modalService.openModal( 'report' );
  }

  delete() {
    
    this.modalService.openModal( 'question' );
    const interval = setInterval( () => {
      
      if ( !this.modalService.showModal ) {

        if ( this.modalService.ok ) {
          let service;
          switch (this.model) {
            // case 'User':
            //   service = this.userService;
            //   break;
            case 'Recipe':
              service = this.recipesService;
              break;
            case 'Elaboration':
              service = this.recipesService;
              break;
            case 'RecipeReview':
              service = this.recipeReviewsService;
              break;
            case 'Restaurant':
              service = this.restaurantsService;
              break;
            case 'RestaurantReview':
              service = this.restaurantReviewsService;
              break;
            // case 'Review':
            //     reference = await Review.findById(ref);
            //     break;
            case 'Reply':
              service = this.replyService;
              break;
            default:
              this.modalService.setStatus('error', 'Tipo de elemento no registrado, o disponible no apto para ser borrado');
              break;
          }
          service?.delete( this.content.id ).subscribe( resp => {
            this.reload( resp );
          });

        }
        clearInterval(interval);

      }

    }, 100);

  }

  share() {
    this.modalService.openModal( 'share' );
  }

  editChange( val: boolean ) {
    this.edit = val;
    this.modalService.setEditing( val );
    this.editEmit.emit( val );
  }

  reload( resp: any, reload: boolean = true ) {

    this.modalService.setStatus( resp.ok ? 'ok' : (resp.nolog ? 'no-log' : 'error'), resp.msg );
    
    if ( resp.ok && reload ) {
      const actual = window.location.pathname;
      this.router.navigate( [ '/reload' ] )
      setTimeout( () => { 
        this.router.navigate( [ actual ] );
      }, 100);
    }
  }
  
  @HostListener( 'document: click', ['$event'] )
  clickOut( event: { target: any; } ) {

    if ( !this.collapsed ) {
      const reviewMenu: any = document.querySelector(`#${ this.name + this.content.id } .dropdown`);
      const btnReviewMenu: any = document.querySelector( `#${ this.name + this.content.id } img` );          
      if ( reviewMenu?.contains( event.target ) || btnReviewMenu?.contains( event.target ) ) {
        return;
      }
      this.collapsed = true;
    }

  }

}
