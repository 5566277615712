import { Component, EventEmitter, Input, OnInit, Output, ViewChild, HostListener } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ModalService } from '../../../services/modal.service';
import { ValidationService } from '../../../services/validation.service';
import { UploadService } from '../../../services/backend/upload.service';
import { TypeChangeService } from '../../../services/type-change.service';
import { VariablesService } from '../../../services/variables.service';
import { AuthService } from '../../../services/backend/auth.service';
import { MyValidations } from '../../../validations/my-validations.validation';
import { Recipe } from '../../../models/recipe.model';
import { SearchService } from '../../../services/backend/search.service';
import { AdderContainerComponent } from '../adder-container/adder-container.component';
import { HelperService } from '../../../services/helper.service';
import { UserService } from '../../../services/backend/user.service';

@Component({
  selector: 'app-elaboration-form',
  templateUrl: './elaboration-form.component.html',
  styles: [
  ]
})
export class ElaborationFormComponent implements OnInit {

  @ViewChild( AdderContainerComponent ) adderContainerComponent: AdderContainerComponent;

  @Input() portions: number | any = 0;
  @Input() elaboration: any;
  @Input() main: boolean = false;
  @Output() recipeFormEmit: EventEmitter< Recipe > = new EventEmitter();
  @Output() elaborationsEmit: EventEmitter< boolean > = new EventEmitter();

  public elaborations: Recipe[] = [];

  public newRecipeForm: FormGroup;
  public formSubmitted: boolean = false;
  
  public categories: string[];
  public fullSubcategories: any[];
  // public conditions: string[];
  public difficulties: string[];
  public subcategories: any[];
  public drinkSubcategories: any[];
  public auxSubcategories: any[];
  public tools: string[] = [];
  private id: string;

  public toolsSelected: string[] = [];
  // public selectedConditions: string[];

  public img: File | Blob;
  public newImg: boolean;
  public fileName: string | any;
  public tags: string[] = [];

  private uid: string;
  private role: string;

  public searchTerm: string;
  public searchContents: Recipe[];
  public timeOut: any;
  public timeOutCache: any;
  private from: number = 0;
  private limit: number = 5;
  private empty: boolean = false;
  private loading: boolean = false;
  public foreign: boolean = false;
  public searchOpen: boolean = false;
  public showObs: boolean = false;
  public more: boolean = false;
  public cache: boolean = false;

  constructor(  private fb: FormBuilder,
                private modalService: ModalService,
                public helperService: HelperService,
                private validationService: ValidationService,
                private uploadService: UploadService,
                private typeChangeService: TypeChangeService,
                private searchService: SearchService,
                private authService: AuthService,
                private userService: UserService,
                private vs: VariablesService,  ) {
                  this.categories = vs.categories;
                  this.fullSubcategories = vs.fullSubcategories;
                  // this.conditions = vs.conditions;
                  this.difficulties = vs.difficulties;
                  this.subcategories = vs.subcategories;
                  this.drinkSubcategories = vs.drinkSubcategories;
                  this.auxSubcategories = vs.subcategories;
                  this.tools= vs.tools.tools;
                  this.uid = authService.uid;
                  this.role = userService.role;
                }

  ngOnInit(): void {

    if ( !this.elaboration && this.main ) {
      this.cache = true;
      const cache = localStorage.getItem( 'recipeCache' ) || false;
      if (  cache ) {
        this.elaboration = JSON.parse( cache );
        if ( this.elaboration.category === 4 ) this.auxSubcategories = this.drinkSubcategories;
      } 
    }

    this.newRecipeForm = this.fb.group({
      category: [ this.categories[0], Validators.required ],
      subcategory: [ this.fullSubcategories[1], Validators.required ],
      name: [ '', Validators.required ],
      difficulty: [ this.difficulties[0], Validators.required ],
      time: ['', Validators.required],
      portions: [ this.portions || '', [ Validators.min(1), Validators.required ] ],
      ingredients: [ [] ],
      steps: [ [] ],
      elaborations: [ [] ],
      img: [ 'no-img', Validators.required ],
      // conditions: [],
      tools: [],
      tags: [ [] ],
      observations: [ '' ],
      link: [ '', MyValidations.link ],
    });

    this.setElaboration();

  }

  setElaboration() {

    if ( this.elaboration ) { 

      const elab = this.elaboration;
      //Maybe let the user change the recipe if is the owner in a future

      if ( this.role !== 'ADMIN_ROLE' ) {
        if ( (elab?.user && ((elab?.user?._id || elab?.user) !== this.uid)) || elab.foreign ) {
          this.foreign = true;
        }
      }
      
      this.fileName = elab.img;
      this.tags = elab.tags;
      // this.selectedConditions = this.typeChangeService.numToStrArr( elab.conditions, this.conditions );
      this.toolsSelected = this.typeChangeService.numToStrArr( elab.tools, this.tools );
      // 'conditions', 
      for ( let field of [ 'name', 'time', 'portions', 'ingredients', 'steps', 'img', 'tools', 'tags', 'observations', 'link' ] ) {
        if ( elab[ field ] ) this.newRecipeForm.controls[ field ].setValue( elab[ field ] );        
      }
      this.newRecipeForm.controls['category'].setValue( this.categories[ elab.category ] );
      this.newRecipeForm.controls['subcategory'].setValue( this.fullSubcategories[ elab.subcategory ] );
      this.newRecipeForm.controls['difficulty'].setValue( this.difficulties[ elab.difficulty ] );
      
      this.id = elab.id || elab._id;
      
      if ( this.id ) {
        this.newRecipeForm.addControl( 'user', this.fb.control( elab?.user._id || elab?.user ));
        this.newRecipeForm.addControl( '_id', this.fb.control( this.id ));
      }

      this.elaborations = elab?.elaborations || [];

    }

  }

  ngDoCheck() {
    if ( this.helperService.helperChanges ) {
      this.toolsSelected = this.helperService.optionsSelection['tools'];
      this.newRecipeForm.controls['tools'].setValue( this.typeChangeService.strToNumArr( this.toolsSelected, this.tools ) );
    }
  }

  async saveRecipe() {
    
    this.formSubmitted = true;
    const NRF = this.newRecipeForm;
    NRF.controls['elaborations'].setValue( this.elaborations );
    
    // Upload the img
    if ( this.img && this.newImg ) {

      let upImg;
      if ( !this.id ) {
        upImg = await this.uploadService.uploadImg( this.img, 'recipes' );
      } else {
        upImg = await this.uploadService.updateImg( this.img, 'recipes', NRF.controls[ '_id' ].value );
      }
      this.fileName = upImg.fileName || 'no-img';

      this.newImg = false;
      NRF.controls['img'].setValue( this.fileName );
      
    }
    
    if ( (NRF.valid && NRF.controls[ 'elaborations' ]?.value?.length > 1) || (NRF.valid && NRF.controls[ 'ingredients' ]?.value?.length > 0 && NRF.controls[ 'steps' ]?.value?.length > 0) ) {
      
      NRF.controls['tags'].setValue( this.tags );

      for ( let control of [ 'tools', 'tags', 'observations', 'link' ] ) {
        if ( !NRF.controls[control].value || NRF.controls[control].value?.length <= 0 ) {
          NRF.removeControl( control );
        }
      }

      const sendRecipeForm: Recipe = NRF.value;
      sendRecipeForm.name = sendRecipeForm.name.toLocaleLowerCase();
      sendRecipeForm.category = this.categories.indexOf(NRF.controls['category'].value);
      sendRecipeForm.subcategory = this.fullSubcategories.indexOf(NRF.controls['subcategory'].value);
      sendRecipeForm.difficulty = this.difficulties.indexOf(NRF.controls['difficulty'].value);
      
      this.recipeFormEmit.emit( sendRecipeForm );

    }
    
  }

  // Form Helpers

  advise( id: string ) {

    const advise = document.getElementById( id );
    
    if ( advise && advise.style.opacity !== '1' ) {
      advise.style.opacity = '1';
      advise.style.visibility = 'visible';
      setTimeout( () => {
        advise.style.opacity = '0';
        advise.style.visibility = 'hidden';
      }, 2000 )
    }

  }
  
  openModal( type: 'login' | 'register' | 'image' | 'tools' | 'ingredients' ) {
    this.helperService.setOptionSelection( { tools: this.toolsSelected, ingredients: [] } );
    this.modalService.openModal( type );
  }
  
  setImg( img: string ) {
    this.modalService.setImg( img, 'recipes', this.elaboration.updatedAt || '', this.elaboration.name );
  }

  contentValidation() {
    return {  ingredients: this.invalidField('ingredients'),
              steps: this.invalidField('steps')  }
  }

  content( event: any ) {
    this.newRecipeForm.controls['ingredients']?.setValue( event?.ingredients );
    this.newRecipeForm.controls['steps']?.setValue( event?.steps );
    this.recipeCache();
  }

  contentSend() {
    if ( this.elaboration ) {
      return {  ingredients: this.elaboration.ingredients,
                steps: this.elaboration.steps  }
    }
  }

  categorySelect( option: string ){
    return this.vs.categorySelect( option );
  }

  search( push: boolean = false) {
    
    this.searchOpen = true;
    this.loading = true;
    clearTimeout( this.timeOut );

    if ( !push ) {
      this.from = 0;
    };
    if ( this.searchTerm?.length >= 1 ) {
      return this.searchServ( push );      
    }

    this.from = 0;
    this.searchContents = [];

  }

  searchServ( push: boolean ) {

    return this.searchService.search( this.from, this.limit, this.searchTerm ).subscribe( resp => {

      if ( resp.ok && resp.searchResults.length > 0 ) {

        if ( push ) {
          this.searchContents.push( ...resp.searchResults );
        } else {
          this.searchContents = resp.searchResults;
        }
        this.empty = false;
        this.from += this.limit;

      } else {
        this.empty = true;
      }

      this.loading = false;

    } );

  }

  externRecipe( content: Recipe ) {

    this.searchOpen = false;
    if ( content?.elaborations && content?.elaborations.length > 0 ) {
        for ( let elab of content?.elaborations ) {
          this.recipeFormEmit.emit( { ...elab, foreign: true } );
        }
        return;
    }

    this.recipeFormEmit.emit( { ...content, foreign: true } );
    
  }

  setDifficulty( event: any ) {
    
    switch( event ) {
      case 1:
        event = 'low';
        break;
      case 2:
        event = 'medium';
        break;
      case 3:
        event = 'high';
        break;
      default:
        event = 'low';
        break;
    }
      
    this.fVal( 'difficulty', event );
    
  }

  recipeCache() {
    if ( !this.cache ) return;
    clearTimeout( this.timeOutCache );
    this.timeOutCache = setTimeout( () => {
      const NRF = this.newRecipeForm;
      if ( this.elaborations ) NRF.controls['elaborations'].setValue( this.elaborations );
      NRF.controls['img'].setValue( this.fileName );
      NRF.controls['tags'].setValue( this.tags );
      const sendRecipeForm: Recipe = NRF.value;
      sendRecipeForm.name = sendRecipeForm.name.toLocaleLowerCase();
      sendRecipeForm.category = this.categories.indexOf(NRF.controls['category'].value);
      sendRecipeForm.subcategory = this.fullSubcategories.indexOf(NRF.controls['subcategory'].value);
      sendRecipeForm.difficulty = this.difficulties.indexOf(NRF.controls['difficulty'].value);
  
      const json = JSON.stringify(sendRecipeForm);
      localStorage.setItem( 'recipeCache', json );
    }, 200 );
  }

  scroll( searchResult: any ) {

    if ( !this.empty && !this.loading ) {
      const pos = searchResult.scrollTop;
      const max = searchResult.scrollHeight || 0;

      if ( (pos + (searchResult.clientHeight * 1.5)) >= max ) {
          this.search( true );
      }
    }
    
  }

  @HostListener( 'document: click', ['$event'] )
  clickOut( event: { target: any; } ) {
    if ( this.searchOpen ) {
      const searchElaboration: any = document.getElementById( 'search-elaboration' );
      const searchResult: any = document.getElementById( 'search-result' );
      if ( searchElaboration.contains( event.target ) || searchResult.contains( event.target ) ) {
        return;
      }
      this.searchOpen = false;
    }
  }


  invalidField( field: string ) {
    return this.validationService.invalidField( this.newRecipeForm, field, this.formSubmitted );
  }

  fVal( formControlName: string, setVal?: any ) {
    if ( setVal || setVal === 0 ) {
      return this.newRecipeForm.controls[ formControlName ].setValue( setVal );
    }
    return this.newRecipeForm.controls[ formControlName ]?.value;
  }

  enter( event: any ) {
    const adder =  document.querySelectorAll( '.adder' );
    if ( !adder[0]?.contains(event.target) && !adder[1]?.contains(event.target) ) {
      event.preventDefault();
    }
  }

}
