import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-btn-back',
  templateUrl: './btn-back.component.html',
  styles: [
  ]
})
export class BtnBackComponent {

  @Input() abs: boolean = true;

  constructor() { }

  back() {
    history.back()
  }

}
