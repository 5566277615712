import { Component, Input, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { RestaurantReviewsService } from '../../../services/backend/restaurant-reviews.service';
import { RestaurantReview } from '../../../models/restaurant-review.model';
import { User } from '../../../models/user.model';
import { AuthService } from '../../../services/backend/auth.service';
import { Restaurant } from '../../../models/restaurant.model';
import { HelperService } from '../../../services/helper.service';
import { RecipeReviewsService } from '../../../services/backend/recipe-reviews.service';
import { RecipeReview } from '../../../models/recipe-review.model';


@Component({
  selector: 'app-reviews-list',
  templateUrl: './reviews-list.component.html',
  styles: [
  ]
})
export class ReviewsListComponent implements OnInit {

  @Input() id: string;
  @Input() model: string;
  @Input() content: Restaurant | any;
  @Output() reviewsCount: EventEmitter< number > = new EventEmitter();
  // @Output() scoreEmit: EventEmitter< object > = new EventEmitter();
  @Output() userReviewEmit: EventEmitter< boolean > = new EventEmitter();
  @Output() reviewEmit: EventEmitter< any > = new EventEmitter();
  @Output() editEmit: EventEmitter< boolean > = new EventEmitter();

  public from: number = 0;
  private limit: number = 6;
  public loading: boolean = false;
  public friends: boolean = false;
  public friendIds: string[] = [];
  private maxPos: number = 0;
  public guard: boolean = false;
  
  public userReview: RestaurantReview[] | RecipeReview[] | any[] = [];
  public reviews: RestaurantReview[] | RecipeReview[] | any[] = [];
  public reviewsCache: any = {
    world: {
      reviews: [],
      from: 0
    },
    friends: {
      reviews: [],
      from: 0
    }
  };
  private user: User;
  public count: number;
  private service: any;

  constructor(  private restaurantReviewsService: RestaurantReviewsService,
                private recipeReviewsService: RecipeReviewsService,
                private hs: HelperService,
                private authService: AuthService  ) { 
                  this.user = authService.user;
                  this.guard = authService.guard;
                }
            
  ngOnInit(): void {
    if ( this.model === 'RestaurantReview' ) {
      this.service = this.restaurantReviewsService;
    } else {
      this.service = this.recipeReviewsService;
    }
    this.friendIds = this.user?.follow?.users?.array || [];
    this.friends = this.hs.friends;
    this.getReviews();
  }

  getReviews() {
    
    this.loading = true;

    this.service.getById( this.id, this.from, this.limit, (this.friends?this.friendIds:undefined) ).subscribe(  (resp: any) => {
      
      if ( resp.ok ) {
        if (  resp.reviews.length > 0 || resp.userReview.length > 0 ) {
          this.userReview = resp.userReview;
          this.reviews.push( ...resp.reviews );
        }
        this.count = resp.total;
        this.reviewsCount.emit( this.count );
        this.userReviewEmit.emit( resp.userReview?.length > 0 );
      }
      this.loading = false;
      this.from += this.limit;

    } );

  }

  onScroll( event: any ) {

    const element = event.target;
    let pos = 0;
    if (element) {
      pos = element.scrollTop;
    }
    const max = element?.scrollHeight || 0;
    
    if ( (pos + 450) >= max && !this.loading && pos > this.maxPos ) {
      this.maxPos = pos;
      this.getReviews();
    }
    
  }

  friendsChange() {
    this.friends = !this.friends;
    
    let sel1 = this.friends ? 'world' : 'friends';
    let sel2 = !this.friends ? 'world' : 'friends';
    this.reviewsCache[sel1] = { reviews: this.reviews, from: this.from };
    this.reviews = this.reviewsCache[sel2].reviews;
    this.from = this.reviewsCache[sel2].from;

    this.getReviews();
  }

  @HostListener( 'document: click', ['$event'] )
  clickFriends( event: { target: any; } ) {
    const friendsChange: any = document.getElementById( 'friendsChange' );
    const friendsChangeTop: any = document.getElementById( 'friendsChangeTop' );
    
    if ( friendsChange.contains( event.target ) || friendsChangeTop.contains( event.target ) ) {
      if ( this.guard ) this.friendsChange();
      return;
    }
  }
  
}
