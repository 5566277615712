import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/services/backend/auth.service';
import { ListsService } from '../../services/backend/lists.service';
import { User } from '../../models/user.model';
import { List } from 'app/models/list.model';
import { ModalService } from '../../services/modal.service';
import { UserService } from '../../services/backend/user.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-to-do-list',
  templateUrl: './to-do-list.component.html',
  styles: [
  ]
})
export class ToDoListComponent implements OnInit {

  public myList: List;
  public list: List;
  public toMyShop: string[] = [];
  public toMyShopChecked: string[] = [];
  public toShop: string[] = [];
  public toShopChecked: string[] = [];
  public undo: any[][] = [];
  public edit: boolean = false;
  public myListBool: boolean = true;
  public mainUser: User;
  private uid: string;
  public sharedLists: List[];

  constructor(  private authService: AuthService,
                private listsService: ListsService,
                private modalService: ModalService,
                private helperService: HelperService,
                private userService: UserService  ) {  
                  this.mainUser = authService.user;
                  this.uid = authService.uid;
                }

  ngOnInit(): void {
    this.listsService.getListsFilterById(this.uid,0,5).subscribe( resp => {
      if ( resp.ok ) {        
        if ( resp.myLists.length > 0 ) {
          console.log(resp.myLists[0]);
          this.list = resp.myLists[0];
          this.toShop = resp.myLists[0].items;
          this.toShopChecked = resp.myLists[0].checkeditems;
          // This logic could be improved
          this.myList = this.list;
          this.toMyShop = this.toShop;
          this.toMyShopChecked = this.toShopChecked;
          // this.myList = resp.myLists[0];
          // this.toMyShop = resp.myLists[0].items;
        } else { 
          this.listsService.createList( { items: [], checkeditems: [] } ).subscribe( resp => {
            if (resp.ok ) {
              this.list = resp.list;              
            } else {
              this.modalService.setStatus( 'warning', 'Conexión a internet dudosa<br>Los cambios pudieron no ser guardados' );
            }
          }) 
        }
        
        this.sharedLists = resp.sharedLists || [];
      }
      
    });

  }

  addItem( item: any ) {
    if ( item.value.length <= 0 ) return
    this.toShop.unshift( item.value );
    item.value = '';
    item.select();

    this.saveList()
  }

  changeItem( idx: number, lIdx: number ) {
    const itemAux: any = document.getElementById( lIdx + 'itemVal' + idx ) || '';
    
    if ( itemAux.value.length <= 0 || !itemAux ) {
      this.modalService.openAdviseBanner('No se guardarán campos vacíos', false);
      return; //this.toShop.splice(idx, 1);
    }

    let aux = [ this.toShop, this.toShopChecked ][lIdx];
    aux[idx] =  itemAux.value;
  
    this.saveList()
  }
  
  rmItem( idx: number, lIdx: number ) {
    
    let aux = [ this.toShop, this.toShopChecked ][lIdx];

    this.undo.unshift([aux[idx], lIdx])
    aux.splice(idx, 1);

    this.saveList()
  }

  rmUndo() {
    if ( this.undo.length <= 0 ) return
    
    let aux = [ this.toShop, this.toShopChecked ][this.undo[0][1] || 0];
    aux.unshift(this.undo[0][0]);
    this.undo.splice(0, 1);

    this.saveList()
  }

  checked( idx: number, lIdx: number ) {
    
    if ( lIdx === 0 ) {
      this.toShopChecked.unshift( this.toShop[idx] );
      this.toShop.splice(idx, 1);
    } else {
      this.toShop.unshift( this.toShopChecked[idx] );
      this.toShopChecked.splice(idx, 1);
    }

    // const check = document.querySelector( '#item' + idx );
    // let auxExt: number;

    // if ( check?.classList.contains( 'checked' ) ) {
    //   if ( !check ) return
    //   check.classList.remove( 'checked' );
    //   auxExt = 0;
    // } else {
    //   if ( !check ) return
    //   check.classList.add( 'checked' );
    //   auxExt = this.toShop.length - 1;
    // }
    // this.aux = this.toShop[auxExt];
    // this.toShop[auxExt] = this.toShop[idx];
    // this.toShop[idx] = this.aux;

    this.saveList()
  }

  posChange(idx: number, lIdx: number, dir: number) {
    
    let aux = [ this.toShop, this.toShopChecked ][lIdx];
    let auxVal;
    
    if ( (idx === 0 && dir < 0) || (idx === aux.length && dir > 0) ) return
    
    auxVal = aux[idx];
    aux[idx] = aux[idx + dir];
    aux[idx + dir] = auxVal;
    
    // Change style when position change
    const lastSel: any = document.querySelectorAll('.selected')[0];
    if ( lastSel ) {
      lastSel.classList.remove('selected');
    }
    const changeSel: any = document.querySelectorAll('.changed')[0];
    if ( changeSel ) {
      changeSel.classList.remove('changed');
    }
    
    const newSel: any = document.getElementById(lIdx+'item'+idx);
    const newChangeSel: any = document.getElementById(lIdx+'item'+(idx+dir));
    
    if ( newSel && newChangeSel ) {
      newSel.classList.add('selected');
      newChangeSel.classList.add('changed');
      setTimeout( () => {
        newSel.classList.remove('selected');
        newChangeSel.classList.remove('changed');
      }, 500 );
    }

    this.saveList()
  }

  share() {
    this.helperService.setArray( this.list.permissions );
    this.modalService.openModal( 'share-follow' );
    this.wait()
  }

  wait() {
    
    const interval = setInterval( () => {

      if ( !this.modalService.showModal ) {        
        if ( this.modalService.ok ) {
          this.modalService.setOk( false );
          this.list.permissions = this.helperService.array || [];
          
          this.saveList();
        }
        clearInterval(interval);
      }

    }, 100);
  }

  saveList() {
    this.list.items = this.toShop;
    this.list.checkeditems = this.toShopChecked;
    this.listsService.updateList( this.list ).subscribe();
    if ( this.myListBool ) { 
      this.toMyShop = this.toShop;
      this.toMyShopChecked = this.toShopChecked;
      this.myList = this.list; 
    }
  }

  listChange( cList: List) {    
    this.list = cList    
    this.toShop = cList.items;
    this.toShopChecked = cList.checkeditems;
    this.undo = [];
    this.saveList()
  }

}
