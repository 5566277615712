<form (ngSubmit)="newReview()" [formGroup]="reviewForm" class="text-center">
    <div class="m-auto">
        <div class="recipe-review-scroll">
            <p class="mt-1">¿Recomiendas esta receta?</p>
            <div class="mt-1 mb-2">
                <app-mood-score [score]="recommended" [moods]="['bad', 'normal', 'good']" [buttons]="['no', 'sin más', 'sí']" (scoreEmitter)="recommended=$event"></app-mood-score>
                <!-- <app-button-group [active]="recommended" [buttons]="['no', 'sin más', 'si']" [mandatory]="true" (emitter)="fval('recommended', $event);initScroll();"></app-button-group> -->
                <!-- <app-button-group [active]="recommended" [buttons]="['no', 'sin más', 'si']" (emitter)="recommended=$event"></app-button-group> -->
            </div>

            <div class="my-1">
                <p>¿Qué te ha parecido?</p>
                <app-mood-score [score]="oldReview?oldReview.main:-1" [buttons]="['mala', 'sin más', 'rica', 'excelente']" (scoreEmitter)="scoreSel=$event;scoreCheck=true"></app-mood-score>
            </div>

            <p *ngIf="invalidField('score')" class="error-text">Es necesario asignar una puntuación</p>
            <textarea formControlName="observations" placeholder="Escribe un comentario..." cols="30" rows="10"></textarea>
        </div>
        <div class="pt-05 bd-t">
            <button type="submit" class="btn-red sm">Guardar</button>
        </div>
    </div>
</form>