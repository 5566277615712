import { Component, OnInit } from '@angular/core';
import { VariablesService } from '../../../services/variables.service';
import { HelperService } from '../../../services/helper.service';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'app-coins',
  templateUrl: './coins.component.html',
  styles: [
  ]
})
export class CoinsComponent implements OnInit {

  public coins: any[];
  public coinsSearch: any[] = [];
  public coinSelected: string;
  
  constructor(  private vs: VariablesService,
                private helperService: HelperService,
                private modalService: ModalService  ) { }

  ngOnInit(): void {
    this.coins = this.vs.coins;
    this.coinsSearch = this.coins;
  }

  filter( term: string ) {    
    this.coinsSearch = this.coins.filter( coin => {
      return (coin.coin.includes( term ) || coin.iso.includes( term ));
    } );
  }

  coinSel() {
    this.helperService.setCoin( this.coinSelected );
    this.modalService.closeModal();
  }
}
