import { Component, Input, OnInit, ViewChild, HostListener } from '@angular/core';
import { Recipe } from '../../models/recipe.model';
import { TypeChangeService } from '../../services/type-change.service';
import { VariablesService } from '../../services/variables.service';
import { RecipesService } from '../../services/backend/recipes.service';
import { AuthService } from '../../services/backend/auth.service';
import { RecipeReview } from '../../models/recipe-review.model';
import { User } from '../../models/user.model';
import { ModalService } from '../../services/modal.service';
import { CacheService } from '../../services/cache.service';
import { RestaurantsService } from '../../services/backend/restaurants.service';
import { RestaurantReview } from '../../models/restaurant-review.model';
import { HelperService } from '../../services/helper.service';
import { UserService } from '../../services/backend/user.service';
import { Restaurant } from '../../models/restaurant.model';
import { RepliesComponent } from '../reply-folder/replies/replies.component';
import { RestaurantReviewsService } from '../../services/backend/restaurant-reviews.service';
import { RecipeReviewsService } from '../../services/backend/recipe-reviews.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styles: [
  ]
})
export class CardComponent implements OnInit {
  
  @ViewChild( RepliesComponent ) repliesComponent: RepliesComponent;
  
  @Input() content: Recipe | RecipeReview | Restaurant | RestaurantReview | any;
  @Input() idx: number;
  @Input() type: 'recipes' | 'restaurants';
  @Input() contentType: '' | 'reviews' | 'favs' | 'later' = '';
  @Input() double: boolean;

  public user: User;

  public uid: string;
  public mainUser: User;
  public id: string;
  public cardId: string;
  public loading: boolean = false;
  public isFav: boolean = false;
  public isLater: boolean = false;
  public reviewContent: RecipeReview | RestaurantReview | any;
  public len: number[] = [1, 2, 3, 4, 5];
  public extend: boolean = false;
  public coords: { lat: number, lon: number };
  public dist: boolean = false;
  // public dist: number | undefined;

  public reviews: boolean = false;

  public recommendation: string[] = ['no recomendado', 'no está mal...', 'recomendado'];
  public recommendationColor: string[] = ['error', 'warning', 'ok'];
  public mood = ['angry', 'neutral', 'happy', 'excellent'];
  public moodStatus = ['bad', 'normal', 'good'];

  public countReviews: number;
  public friendReview: User;
  public ownReview: boolean = false;
  
  public priceAux: number = 1;
  public avgScore: number | undefined;
  // public avgScoreFriends: number;

  // Recipe
  public category: string;
  public subcategory: string;
  public conditionsSel: string[] = [];
  public categories: string[];
  public subcategories: any[];
  public conditions: string[];
  public condLength: number = 0;
  public pannel: boolean = false;
  public pannelScr: boolean = true;
  public bgEmpty: number[];


  constructor(  private vs: VariablesService,
                private typeChangeService: TypeChangeService,
                private modalService: ModalService,
                private recipesService: RecipesService,
                private restaurantsService: RestaurantsService,
                private userService: UserService,
                private cs: CacheService,
                private hs: HelperService,
                private restaurantReviewsService: RestaurantReviewsService,
                private recipeReviewsService: RecipeReviewsService,
                private authService: AuthService  ) {
                  this.mainUser = authService.user;
                  this.coords = hs.coords;
                  this.categories = vs.categories;
                  this.subcategories = vs.fullSubcategories;
                  this.conditions = vs.conditions;
                }
            
  ngOnInit(): void {

    this.loading = true;
    this.user = this.content.user;
    if ( this.contentType !== 'reviews' ) {
      this.countReviews = this.content?.reviews?.length;

      const mainId = this.mainUser?._id || this.mainUser?.id;      
      const ownRev = this.content?.reviews.filter( (rev: any) => {
        return mainId === ( rev.user?.id || rev.user?._id );
      } );
      
      if ( ownRev.length > 0 ) {
        this.friendReview = this.mainUser;
        this.friendReview.alias = 'tú';
        this.ownReview = true;
      } else {
        for ( let review of this.content?.reviews ) {
          if ( this.mainUser?.follow?.users?.array.includes( review.user?.id || review.user?._id ) ) {
            this.friendReview = review.user;
            break;
          }
        }
      }

    }

    if ( this.type === 'restaurants' && this.contentType !== 'reviews' ) this.distance();

    if ( this.contentType === 'reviews' ) {
      // this.recipeReviewsService.updateRecipeReview( (this.content?._id ||  this.content?.id), this.content ).subscribe( resp => {
      //   console.log(resp);
      // });
      // this.restaurantReviewsService.updateRestaurantReview( (this.content?._id ||  this.content?.id), this.content ).subscribe( resp => {
      //   console.log(resp);
      // });

      this.reviewContent = this.content;
      this.content = this.content[this.type.slice(0,-1)];
      if ( !this.content ) {
        this.loading = false;
        return;
      };
    }
    
    this.id = this.content.id || this.content._id;
    this.cardId = (this.contentType === 'reviews' ? (this.reviewContent.id || this.reviewContent._id) : this.id);

    this.uid = this.authService.uid;
    this.fav();
    this.later();

    this.avgScore = this.scoreAlg( this.content?.score?.friends || this.content?.score );
    // this.avgScoreFriends = this.scoreAlg( this.content.score.friends );
    if ( this.content.score) for ( let score of ['main', 'recommended', 'qualityprice', 'atention', 'decoration', 'foodsize', 'bookingscore', 'parkingscore'] ) this.content.score[score] = this.rounder(score);

    if ( this.type === 'recipes' ) {
      this.category = this.categories[this.content.category]
      this.subcategory = this.subcategories[this.content.subcategory]
      if ( this.content.conditions ) {
        this.conditionsSel = this.typeChangeService.numToStrArr( this.content.conditions, this.conditions );
        this.condLength = this.content.conditions.length;
      }
  
      this.bgEmpty = new Array( ( 5 - this.conditionsSel?.length ) );
    } else if ( this.type === 'restaurants' ) {
      if ( this.content.restaurant?.restaurantType[0] === 1 ) this.priceAux = 3;
    }

    this.loading = false;

  }

  scoreAlg( score: any ) {
    if ( this.type === 'recipes' ) {
      if ( !score?.recommended || !score?.main) return;
      return ( (score.recommended - 1) * 0.6 * 3/2 + (score.main - 1) * 0.4 ) * 10/3;
    } else {
      if ( !score?.recommended || !score?.qualityprice || !score?.atention ||  !score?.decoration ) return;
      return ( (score.recommended - 1) * 0.4 * 3/2 + (score.qualityprice - 1) * 0.3 + (score.atention - 1) * 0.15 + (score.decoration - 1) * 0.15 ) * 10/3;
    }
  }
  
  rounder( type: string ) {
    if ( this.content.score[ type ] ) return (Math.max( Math.floor(this.content.score[type] - 0.5), 0 ) + 1);
    return null;
  }

  addFavs() {

    if ( !this.isFav && this.uid ) this.modalService.openAdviseBanner( 'en favoritos' );

    let service;
    if ( this.type === 'recipes' ) {
      service = this.recipesService;
    } else {
      service = this.restaurantsService;
    }
    service.addFavs( this.id?this.id:'' ).subscribe( resp => {
      if ( resp.ok) {
        let favsArr: any = false,
            favPos: any = -1
        if ( this.mainUser?.favs && this.mainUser.favs[this.type]?.array ) {
          favPos = this.mainUser?.favs[this.type]?.array.indexOf( this.id );
          favPos = (favPos===0) ? 0 : (favPos || -1);
          favsArr = this.mainUser.favs[this.type]?.array || false;
        }
        if ( this.isFav && this.mainUser.favs && favsArr && (favPos >= 0) ) {
          this.mainUser.favs[this.type]?.array.splice( favPos, 1);
        } else if( this.mainUser.favs  && favsArr ) {
          this.mainUser.favs[this.type]?.array?.push( this.id );
        } else if ( this.mainUser ) {
          this.mainUser = { ...this.mainUser, favs: { [this.type]: { array:  [ this.id ] } } };
        }
        this.cs.cacheReset( this.type + 'favsById' );
        this.authService.user = { ...this.authService.user, favs: { [this.type]: { array: ( favsArr  || [] ) } } };
        return this.isFav = !this.isFav;
      }
      this.modalService.setStatus( resp.nolog ? 'no-log' : 'error', resp.msg );
    });

  }
  
  fav() {
    if ( this.mainUser?.favs && this.mainUser.favs[this.type]?.array ) {
      let favPos = this.mainUser?.favs[this.type]?.array.indexOf( this.id );
      favPos = (favPos===0) ? 0 : (favPos || -1)
      const fav = favPos >= 0;
      this.isFav = fav;
    }
  }
  
  addLater() {

    if ( !this.isLater && this.uid ) this.modalService.openAdviseBanner( 'para más tarde' );

    this.userService.addLater( this.id, this.type ).subscribe( resp => {
      if ( resp.ok) {
        let laterArr: any = false,
            laterPos: any = -1
        if ( this.mainUser?.later && this.mainUser.later[this.type] ) {
          laterPos = this.mainUser?.later[this.type]?.indexOf( this.id );
          laterPos = (laterPos===0) ? 0 : (laterPos || -1);
          laterArr = this.mainUser.later[this.type] || false;
        }
        if ( this.isLater && this.mainUser.later && laterArr && (laterPos >= 0) ) {
          this.mainUser.later[this.type]?.splice( laterPos, 1);
        } else if( this.mainUser.later  && laterArr ) {
          this.mainUser.later[this.type]?.push( this.id );
        } else if ( this.mainUser ) {
          this.mainUser = { ...this.mainUser, later: { [this.type]: [ this.id ] } };
        }
        this.cs.cacheReset( this.type + 'laterById' );
        this.authService.user = { ...this.authService.user, later: { [this.type]: ( laterArr  || [] ) } };
        return this.isLater = !this.isLater;
      }
      this.modalService.setStatus( resp.nolog ? 'no-log' : 'error', resp.msg );
    });

  }

  later() {
    const userLater: any = this.mainUser?.later || false;
    if ( userLater ) {
      const userLaterType = userLater[this.type] || false;      
      if ( userLaterType ){
        let laterIdx = userLaterType.indexOf( this.id );
        this.isLater = (laterIdx >= 0);
      }
    }
  }

  distance() {
    if ( !this.content?.address?.coord?.coordinates || !this.coords.lat ) return this.dist = false;
    this.dist = true;
  }

  repliesReload() {
    this.repliesComponent.from = 0;
    this.repliesComponent.replies = [];
    this.repliesComponent.getReplies();
  }

  pannelChange( scr: boolean = true, show: boolean = true ) {
    if ( this.pannel && (scr === this.pannelScr) ) {
      this.fadeOut();
    } else {
      this.pannel = show;
    }
    this.pannelScr = scr;
  }

  fadeOut() {
    const panElem: any = document.getElementById( 'pannel' + this.cardId );
    panElem.classList.add("fade-out", "fast");
    
    setTimeout( () => {
      this.pannel = false;
    }, 190 );
  }

  @HostListener( 'document: click', ['$event'] )
  clickOut( event: any ) {

    if ( this.pannel ) {
      const btn: any = document.getElementById( 'pannelBtn' + this.cardId );
      const scr: any = document.getElementById( 'card-score' + this.cardId );
      if ( btn?.contains( event.target ) || scr?.contains( event.target ) ) return;

      this.fadeOut();
    }

    if ( this.reviews ) {
      const modalBox: any = document.getElementById( 'modal-box' + this.cardId );
      const modalBoxReply: any = document.getElementById( 'modal-box-reply' + this.cardId );
      const revBtn: any = document.getElementById( 'revBtn' + this.cardId );
      const drop: any = document.querySelector( '.dropdown.d-block' );
      
      for ( let path of event.path ) if ( (path?.classList && path?.classList[0] === 'dropdown') || path.id === 'modal' ) return;
      if ( modalBox?.contains( event.target ) || modalBoxReply?.contains( event.target ) || revBtn?.contains( event.target ) || drop || this.modalService.showModal ) return;

      this.reviews = false;
    }

  }

}
