import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numOpt'
})
export class NumOptPipe implements PipeTransform {

  transform( num: number ): number {
    return Math.round((num) * 100 ) / 100;
  }

}
