import { Pipe, PipeTransform } from '@angular/core';
import { VariablesService } from '../services/variables.service';

@Pipe({
  name: 'ingFind'
})
export class IngFindPipe implements PipeTransform {

  private verbs: string[] | any;
  
  constructor(  private vs: VariablesService ) { 
                  this.verbs = vs.verbs
                }

  transform( toSearch: string, toFind: string | string[]  ): unknown {
        
    const searchArray = toSearch.split( /[,.:;\-_(){}\[\]\s]/ );
    let returnText = toSearch;
    
    toFind = toFind
    .toString()
    .split( /[,.:;\-_(){}\[\]\s]/ ) //Important starting secuence
    .toString()
    .toLowerCase()
    .normalize('NFD')
    .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\S])/g, '')
    .replace(/a|á|à|ä/g, 'a')
    .replace(/e|é|è|ë/g, 'e')
    .replace(/i|í|ì|ï/g, 'i')
    .replace(/o|ó|ò|ö/g, 'o')
    .replace(/u|ú|ù|ü/g, 'u')
    .split(',');
    
    for ( let i = 0; i < searchArray.length; i++ ) {
      
      const word = searchArray[i]
      .toLowerCase()
      .normalize('NFD')
      .replace( /[.,\/#!$%\^&\*;:{}=\-_`~()\º]/g, '' )
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\S])/g, '')
      .replace(/a|á|à|ä/g, 'a')
      .replace(/e|é|è|ë/g, 'e')
      .replace(/i|í|ì|ï/g, 'i')
      .replace(/o|ó|ò|ö/g, 'o')
      .replace(/u|ú|ù|ü/g, 'u')
      .replace(/n|ñ/g, 'n');
      
      if ( word && !returnText.includes( `<b>${searchArray[i]}</b>` ) && !returnText.includes( '<b>'+searchArray[i]+'</b>' ) && !['el','él','ella','ellas','ellos','la','lá','lo','las','los','le','les','en','de','un','con','para', 'a', 'y', 'o', 'al', 'es', 'ya', 'sin', 'si', 'no'].includes( word ) ) {
        
        if (
          toFind.includes( word ) ||
          toFind.includes( word + 'es' ) ||
          toFind.includes( word + 's' ) ||
          word === 'masa' || word ==="masas" || word==="mezcla" ||
          word.endsWith( 'es' ) && toFind.includes( word.slice( 0, ( word.length - 2 ) ) ) ||
          word.endsWith( 's' ) && toFind.includes( word.slice( 0, ( word.length - 1 ) ) ) ||
          /\d/.test(word) ||
          /(hora|minuto|segundo)(s)?/g.test( word ) ||
          this.verbs.test( word )
          ) {
            let aux = '\\b';
            if ( /\d/.test(word) ) aux = '';
            returnText = returnText.replace( new RegExp("\\b" + searchArray[i] + aux, 'gi'), `<b>${searchArray[i]}</b>`);
          }

      }

    }
    
    returnText = returnText.replace(new RegExp(/\.\.\./, 'g'), '___')
    .replace( new RegExp(/\./, 'g'), '.<br>' )
    .replace( new RegExp(/___/, 'g'), '...' );
    return returnText;

  }

}
