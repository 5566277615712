import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { VariablesService } from '../../../services/variables.service';
import { ForkScoreComponent } from '../../reviews-folder/score/fork-score/fork-score.component';
import { TranslateService } from '../../../services/translate.service';
import { LevenshteinService } from '../../../services/levenshtein.service';
import { TypeChangeService } from '../../../services/type-change.service';
import { HelperService } from '../../../services/helper.service';

@Component({
  selector: 'app-restaurant-filters',
  templateUrl: './restaurant-filters.component.html',
  styles: [
  ]
})
export class RestaurantFiltersComponent implements OnInit {

  @ViewChild( 'categoriesIcons' ) public categoriesIcons: ElementRef | any;
  @ViewChild( 'ocassionsIcons' ) public ocassionsIcons: ElementRef | any;
  @ViewChild( 'restaurantTypesIcons' ) public restaurantTypesIcons: ElementRef | any;
  @ViewChild( ForkScoreComponent ) public forkScoreComponent: ForkScoreComponent;
  
  @Input() collapsed: boolean;
  @Input() contentType:  '' | 'reviews' | 'favs' | 'later' | 'lists' = '';
  @Output() filtersEmit: EventEmitter< { filters: any, termFilters: any } > = new EventEmitter();
  @Output() collapsedEmit: EventEmitter< boolean > = new EventEmitter();
  
  public recommended: number[] = [];
  public commodities: string[];
  // public address: any[] = [];
  public towns: string[];
  public townsFil: string[] = [];
  public restaurantTypes: string[] = [];
  public categories: string[] = [];
  public ocassions: string[] = [];

  public commoditiesSel: string[] = [];
  public townsSel: string[] = [];
  public restaurantTypesSel: string[] = [];
  public categoriesSel: string[] = [];
  public ocassionsSel: string[] = [];

  public commoditiesSelTemp: string[] = [];
  public townsSelTemp: string[] = [];
  public restaurantTypesSelTemp: string[] = [];
  public categoriesSelTemp: string[] = [];
  public ocassionsSelTemp: string[] = [];

  public max: any;
  // public max: any = 99999999;

  constructor(  private vs: VariablesService,
                private translateService: TranslateService,
                private tcs: TypeChangeService,
                private helperService: HelperService,
                private levenshteinService: LevenshteinService  ) { 
                  this.commodities = vs.commodities;
                  this.towns = vs.towns;
                  // this.address = vs.address;
                  this.restaurantTypes = vs.restaurantTypes;
                  this.categories = vs.restCategories;
                  this.ocassions = vs.ocassions;
                }

  async ngOnInit() {
  }

  filterChange() {
    this.filtersEmit.emit( { filters: {
      recommended: this.recommended,
      commodities: this.commoditiesSel,
      town: [ ...this.townsSel, ...this.townsSelTemp ],
      categories: this.tcs.strToNumArr( this.categoriesSel, this.categories ),
      restaurantType: this.tcs.strToNumArr( this.restaurantTypesSel, this.restaurantTypes ),
      maxPrice: Number(this.max),
      ocassions: this.tcs.strToNumArr( this.ocassionsSel, this.ocassions )
    }, termFilters: {
      commodities: this.commoditiesSelTemp,
      categories: this.tcs.strToNumArr( this.categoriesSelTemp, this.categories ),
      restaurantType: this.tcs.strToNumArr( this.restaurantTypesSelTemp, this.restaurantTypes ),
      ocassions: this.tcs.strToNumArr( this.ocassionsSelTemp, this.ocassions )
    }} );
    this.collapsedEmit.emit( true );
  }

  reset() {
    this.commoditiesSel = [];
    this.townsSel = [];
    this.commoditiesSel = [];
    this.townsSel = [];
    this.restaurantTypesSel = [];
    this.restaurantTypesIcons.optionsSelected = [];
    this.categoriesSel = [];
    this.categoriesIcons.optionsSelected = [];
    this.ocassionsSel = [];
    this.ocassionsIcons.optionsSelected = [];
    const town: any = document.getElementById('townF');  
    if ( town ) town.value = '';
    const cat: any = document.getElementById('catSel');  
    if ( cat ) cat.value = 'todas';
  }

  filterSearch( searchTerm: string ) {

    if ( !this.towns ) {
      let i = 0;
      const interval = setInterval( () => {
        if ( this.vs.towns ) {
          clearInterval( interval );
          return this.filterMatch( searchTerm );
        } else if ( i > 300 ) {
          clearInterval( interval );
          return searchTerm;
        } else {
          i++;
        }
      }, 100 );
    } else {
      return this.filterMatch( searchTerm );
    }
  
  }

  filterMatch( searchTerm: string ) {

    let searchOptions: any[] = [],
        filterSearchOptions: any[] = [],
        finalSearchOptions: any[] = [],
        i: number = 0,
        searchDiv: String[] = [];

    this.resetTemp();
        
    searchDiv = searchTerm?.split(',') || [];
    
    for ( let k = 0; k < 2; k++ ) {

      for ( let term of searchDiv[k]?.split( ' ' ) || [] ) {
  
        i = 0;
        
        if ( term.length > 2 && !['el','él','ella','ellas','ellos','la','lá','lo','las','los','le','les','en','de','un','con','para', 'a', 'y', 'o', 'al', 'es', 'ya', 'sin', 'si', 'no'].includes( term ) ) {        
          for ( let options of (k === 0 ? [this.commodities, this.categories, this.restaurantTypes, this.ocassions] : [this.towns]) ) {

            filterSearchOptions = [];
            finalSearchOptions = [];
            for ( let j = 0; j < 3; j++ ) {

              filterSearchOptions = [ ...filterSearchOptions, ...searchOptions.concat(
                options.filter(
                  option => {
                    return this.textMatcher( option, term, j, searchOptions );
                  }
                )
              ) ];
              
            }
  
            for ( let filter of filterSearchOptions ) {
              if ( !finalSearchOptions.includes( filter ) ) finalSearchOptions.push( filter );
            }
            
            if ( k === 0 ) {
              if ( i === 0 ) {
                this.commoditiesSelTemp = [ ...this.commoditiesSelTemp, ...finalSearchOptions ];
              } else if ( i === 1 ) {
                this.categoriesSelTemp = [ ...this.categoriesSelTemp, ...finalSearchOptions ];
              } else if ( i === 2 ) {                
                this.restaurantTypesSelTemp = [ ...this.restaurantTypesSelTemp, ...finalSearchOptions ];                
              } else if ( i === 3 ) {
                this.ocassionsSelTemp = [ ...this.ocassionsSelTemp, ...finalSearchOptions ];
              }
            } else if ( k === 1 ) {
              this.townsSelTemp = [ ...this.townsSelTemp, ...finalSearchOptions ];
            }
              
            i++;
  
          }
        }
        
      }

    }

    this.filterChange();

  }
  
  townsFilter( text: string ) {
    if ( text.length > 0 ) return this.townsFil = this.towns.filter( town => town.includes( text ) );
    return this.townsFil = [];
  }
  
  categoryChange( val: string,  idx: any = '', del: boolean = false ) {
    this.helperService.deletingChange( true );
    if ( !this.categoriesSel.includes( val ) && val.length > 0 ) return this.categoriesSel.push( val );
    if ( del ) return this.categoriesSel.splice( idx, 1 );
    this.helperService.deletingChange( false );
  }

  resetTemp() {
    this.commoditiesSelTemp = [];
    this.townsSelTemp = [];
    this.restaurantTypesSelTemp = [];
    this.categoriesSelTemp = [];
    this.ocassionsSelTemp = [];
  }

  pushRecom( event: number ) {
    const idx = this.recommended.indexOf( event );
    if ( idx >= 0 ) return this.recommended.splice( idx, 1 );
    this.recommended.push( event );
  }

  textMatcher( option: string, term: string, j: number, searchOptions: string[] ) {

    let must;

    const regTerm = new RegExp( term.toLowerCase()
    .normalize('NFC')
    .normalize('NFKC')
    .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\S\s])/g, '')
    .replace(/a/g, '[a,á,à,ä]+')
    .replace(/e/g, '[e,é,ë]+')
    .replace(/i/g, '[i,í,ï,y]+')
    .replace(/o/g, '[o,ó,ö,ò]+')
    .replace(/u/g, '[u,ü,ú,ù]+')
    .replace(/n/g, '[n,ñ]+')
    .replace(/b|v/g, '[b,v]+')
    .replace(/h/g, '([h,y]+)?')
    .replace(/c|ç|z/g, '[c,ç,z]+')
    .replace(/y|ll/g, '[i,y,j,ll,h]+')
    .replace(/\,\[i,y,j,ll,h\]\+\]/g, ',y]')
    .replace(/f|ph/g, '[f,ph]+')
    .replace(/g|j|x/g, '[g,j,y,x]+')
    .replace(/\,\[g,j,y,x\]\+\,/g, ',j,')
    .replace(/(s|x|(\[c,ç,z]\+\(\[h,y\]\+\)\?))/g, '[x,ch,s,z]+')
    .replace(/\,\[x,ch,s,z\]\+/g, ',x')
    .replace(/(\[e,é,ë\]\+\[x,ch,s,z\]\+$|\[e,é,ë\]\+\[x,ch,s,z\]\+ |\[x,ch,s,z\]\+$|\[x,ch,s,z\]\+ )/g, '([e,é,ë]|s|es)?')
    .replace(/(k|q\[u,ü,ú,ù]\+|q)/g, '[q,qu,k]+'), 'i');

    switch ( j ) {
      case 0:
        must = (this.translateService.esp( option ).includes( term ) || regTerm.test( this.translateService.esp( option ) ));
        break;
      case 1:
        must = (option.includes( term ) || regTerm.test( option ));
        break;
      case 2:
        must = this.levenshteinService.searchSplit( option, term, 1 ) || 
                this.levenshteinService.searchSplit( this.translateService.esp( option ), term, 1 ); //Could be changed to catch the levenshtein value                      
        break;
    }

    return !searchOptions.includes( option ) && must

  }

}
