import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-pills',
  templateUrl: './pills.component.html',
  styles: [
  ]
})
export class PillsComponent implements OnInit {

  @Output() pillsEmit: EventEmitter< string[] | any > = new EventEmitter();
  @Input() pills: string[] = [];
  @Input() placesHolder: string = 'etiquetas';

  constructor() { }

  ngOnInit(): void {
  }


  addPill( keyCode: number, pill: string ) {

    const pillsInput: any = document.activeElement;

    pill = pill.replace(/ /g, '');

    if ( pill.trim().length <= 0 ) {
      if ( pillsInput ) {
        pillsInput.value = null;
      }
      return;
    }

    if ( keyCode === 13 || keyCode === 32 ) {
      if ( this.pills.indexOf( pill ) < 0 ) {
        this.pills.push( pill );
        this.pillsEmit.emit( this.pills );
      }

      if ( pillsInput ) {
        pillsInput.value = null;
      }
    }

  }

  deletePill( i: number ) {
    
    this.pills.splice( i, 1 );
    this.pillsEmit.emit( this.pills );

  }
  
}
