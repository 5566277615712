import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RecipeReviewsService } from '../../../services/backend/recipe-reviews.service';
import { RecipeReview } from '../../../models/recipe-review.model';
import { ModalService } from '../../../services/modal.service';
import { ValidationService } from '../../../services/validation.service';
import { CacheService } from '../../../services/cache.service';

@Component({
  selector: 'app-recipe-review-form',
  templateUrl: './recipe-review-form.component.html',
  styles: [
  ]
})
export class RecipeReviewFormComponent implements OnInit {

  @Input() id: string;
  @Input() oldReview: RecipeReview;
  @Input() model: string;
  @Output() reviewEmit: EventEmitter< any > = new EventEmitter();

  public reviewForm: FormGroup;
  public formSubmitted: boolean;
  public scoreSel: number;
  public scoreCheck: boolean = false;
  public recommended: number;

  constructor(  private fb: FormBuilder,
                private recipeReviewsService: RecipeReviewsService,
                private modalService: ModalService,
                private cs: CacheService,
                private router: Router,
                private validationService: ValidationService ) { }

  ngOnInit(): void {
    this.formInit();
  }

  formInit() {

    if ( this.oldReview && this.oldReview.recipe ) {

      this.scoreSel = this.oldReview.main;
      this.scoreCheck = true;
      this.id = this.oldReview.recipe;
      this.recommended = this.oldReview?.recommended || 0;
      
      this.reviewForm = this.fb.group({
        main: [ '', Validators.required ],
        recommended: [ this.oldReview.recommended ],
        observations: [ this.oldReview.observations ],
        recipe: [ this.oldReview.recipe, Validators.required ]
      });

    } else {

      this.reviewForm = this.fb.group({
        main: [ '', Validators.required ],
        recommended: [ '' ],
        observations: [ '' ],
        recipe: [ this.id, Validators.required ],
        pathModel: [ this.model, Validators.required ]
      });

    }

  }

  newReview() {

    const RF = this.reviewForm;
    this.formSubmitted = true;
    
    if ( this.scoreCheck ) {
      RF.controls['main'].setValue( this.scoreSel );
      RF.controls['recommended'].setValue( this.recommended );
      
      if ( this.oldReview ) {
        
        this.recipeReviewsService.updateRecipeReview( this.oldReview.id, RF.value ).subscribe( resp => {
          this.adder( resp );
        });

      } else {

        this.recipeReviewsService.createRecipeReview( RF.value ).subscribe( resp => {
          this.adder( resp );
        });

      }

    }

  }

  adder( resp: any ) {
    if ( resp.ok ) {
      this.reviewEmit.emit( {form: this.reviewForm.value, edit: false} );
      //Revisar en un futuro, no me convence
      this.modalService.setEditing( false );

      this.formSubmitted = false;
      this.formInit();
      const scrollElem = document.getElementsByClassName('reviews')[0];
      scrollElem?.scroll( { top: 0, behavior: 'smooth' } );
      this.modalService.setStatus( 'ok', (resp.msg ? resp.msg : (resp.ok ? 'Reseña guardada con éxito.<br>Recarga la página si quieres ver el efecto sobre la puntuación global.' : 'No ha sido posible guardar la reseña')) );
      this.cs.cacheReset( 'recipes', true );
    } else {
      this.modalService.setStatus( resp.nolog ? 'no-log' : 'error', resp.msg );
    }
  }

  invalidField( field: string ) {
    return this.validationService.invalidField( this.reviewForm, field, this.formSubmitted );
  }

}
