<div class="row g-2 modal-bg">
    <div class="col bd-r new">
        <a (click)="close()" routerLink="/profile/new-review">
            <p class="title mb-1">Restaurante</p>
            <img src="assets/img/intern/icons/menus/restaurants.svg" alt="restaurant icon" class="d-block m-auto">
        </a>
    </div>
    <div class="col bd-l new">
        <a (click)="close()" routerLink="/profile/new-recipe">
            <p class="title mb-1">Receta</p>
            <img src="assets/img/intern/icons/menus/recipes.svg" alt="recipe icon" class="d-block m-auto">
        </a>
    </div>
</div>