import { NgModule } from '@angular/core';
import { EspPipe } from './esp.pipe';
import { ImgPipe } from './img.pipe';
import { ToStrPipe } from './to-str.pipe';
import { UnitsPipe } from './units.pipe';
import { FirstUppercasePipe } from './first-uppercase.pipe';
import { LevelPipe } from './level.pipe';
import { AutoFitPipe } from './auto-fit.pipe';
import { IngFindPipe } from './ing-find.pipe';
import { IsoPipe } from './iso.pipe';
import { HourMinPipe } from './hour-min.pipe';
import { NumOptPipe } from './num-opt.pipe';


@NgModule({
  declarations: [
    EspPipe,
    ImgPipe,
    ToStrPipe,
    UnitsPipe,
    FirstUppercasePipe,
    LevelPipe,
    AutoFitPipe,
    IngFindPipe,
    IsoPipe,
    HourMinPipe,
    NumOptPipe
  ],
  exports: [
    EspPipe,
    ImgPipe,
    ToStrPipe,
    UnitsPipe,
    FirstUppercasePipe,
    LevelPipe,
    AutoFitPipe,
    IngFindPipe,
    IsoPipe,
    HourMinPipe,
    NumOptPipe
  ]
})
export class PipesModule { }