<img [src]="'assets/img/intern/icons/status/'+(share?'share':'question')+'.svg'" alt="question" class="icon xl">
<!-- <div *ngIf="!share"> -->
<p class="subtitle"><small>{{ modalService.opt.title || '¿Está seguro?' }}</small></p>
<p>{{ modalService.status.msg || modalService.opt.msg || 'Si hace click en "Ok" se aplicarán los cambios' }}</p>
<!-- </div> -->
<!-- <div *ngIf="share">
    <p class="subtitle"><small>¿Desea compartir la publicación?</small></p>
    <p>Haga click en ok para compartirla con tus contactos o cancela para volver al perfil</p>
</div> -->

<button (click)="ok(false)" class="btn-red sm mt-1 mr-05">{{  modalService.opt.buttons[0] || 'Cancelar' }}</button>
<button (click)="ok(true)" class="btn-red sm mt-1 ml-05">{{  modalService.opt.buttons[1] || 'Ok' }}</button>