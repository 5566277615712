import { Component, Input, OnInit, ViewChild, Output, EventEmitter, HostListener } from '@angular/core';
import { RepliesComponent } from '../../reply-folder/replies/replies.component';
import { RecipeReview } from '../../../models/recipe-review.model';
import { DropdownMenuComponent } from '../../dropdown-menu/dropdown-menu.component';
import { Restaurant } from '../../../models/restaurant.model';
import { RestaurantReview } from '../../../models/restaurant-review.model';
import { Recipe } from '../../../models/recipe.model';

@Component({
  selector: 'app-review-element',
  templateUrl: './review-element.component.html',
  styles: [
  ]
})
export class ReviewElementComponent implements OnInit {

  @ViewChild( RepliesComponent ) repliesComponent: RepliesComponent;
  @ViewChild( DropdownMenuComponent ) dropdownMenuComponent: DropdownMenuComponent;

  @Input() content: Restaurant | Recipe | any;
  @Input() review: any;
  @Input() model: string;
  @Input() owner: boolean = false;
  // @Output() scoreEmit: EventEmitter< object > = new EventEmitter();

  public len: number[] = [1,2,3,4,5];
  public extendReview: boolean = false;
  public bigReview: boolean = false;
  
  public collapsed: boolean = true;
  public edit: boolean = false;
  public reply: boolean = false;
  public replies: boolean = false;
  public repliesCount: number = 0;
  // public mood: string[] = ['less', 'happy', 'excellent'];
  public mood = ['angry', 'neutral', 'happy', 'excellent']
  public recommendation: string[] = ['no recomendado', 'no está mal...', 'recomendado'];
  public recommendationColor: string[] = ['error', 'warning', 'ok'];
  
  constructor() { }

  ngOnInit(): void {
    
    let h = 66,
        i = 0,
        bigger: any; 
    
    const interval = setInterval( () => {
      bigger = document.getElementById( 'revObs' + (this.review.id || this.review._id) );
      if ( bigger ) {
        clearInterval( interval );        
        if ( bigger.scrollHeight > h ) {
          this.bigReview = true;
        }
      } else if ( i > 200 ) {
        clearInterval( interval );
        this.bigReview = true;
      } else {
        i++;
      }
    }, 10);

  }

  repliesReload() {
    this.repliesComponent.from = 0;
    this.repliesComponent.replies = [];
    this.repliesComponent.getReplies();
    this.replies = true;
  }

  reviewUpdate( event: { form: RecipeReview | RestaurantReview | any, edit: boolean } ) {

    this.review.observations = event.form.observations;
    event.form = { id: this.review.id, active: true, user: this.review.user, updatedAt: this.review.updatedAt, ...event.form };
    if ( this.model.includes('Restaurant') ) {
      this.review = event.form;
    } else {
      this.review.main = event.form.main;
    }
    this.review.recommended = event.form.recommended;
    this.edit = event.edit;
    this.dropdownMenuComponent.edit = event.edit;
    
  }

  editChange() {
    this.dropdownMenuComponent.edit = this.edit;
  }
  
  @HostListener( 'document: click', ['$event'] )
  clickOut( event: { target: any; } ) {

    if ( this.extendReview ) {
      const container: any = document.getElementById( 'review' + (this.review.id || this.review._id) );
      
      if ( container.contains( event.target ) ) {
        return;
      }
      this.extendReview = false;
    }

  }

}
