import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-img-select',
  templateUrl: './img-select.component.html',
  styles: [
  ]
})
export class ImgSelectComponent implements OnInit {

  @Input() type: 'users' | 'recipes' | 'elaborations' | 'restaurants' | 'none' = 'none';
  @Input() img: string = 'no-img';
  @Input() error: boolean = false;
  @Input() multiple: boolean = false;
  @Output() imgEmit: EventEmitter< File | Blob > = new EventEmitter();
  public uploadFile: File | Blob;
  public tempImg: any = null;

  constructor(  private modalService: ModalService,
                private helperService: HelperService  ) { }
    
  ngOnInit(): void {
    this.tempImg = undefined;
    this.helperService.setImgElab( undefined );
  }

  ngOnDestroy() {
    this.reset();
  }

  newImg( event: Event ) {
    
    const target = event.target as HTMLInputElement;
    const files: any = (target.files as FileList);

    if ( files.length <= 1 ) return this.fileUpload( files[0] );

    for ( let file of files ) {

      const interval = setInterval( () => {
        
        if ( !this.modalService.showModal) {
          clearInterval( interval );
          this.fileUpload( file );
        }
        
      }, 100 );
      
    }

  }

  fileUpload( file: any ) {

    this.helperService.setImgType( this.type );
        
    if ( !file ) return this.tempImg = undefined;
    
    this.uploadFile = file;

    const reader = new FileReader();
    reader.readAsDataURL( file );
    
    reader.onloadend = () => {
      this.tempImg = reader.result;

      if ( this.type === 'elaborations' ) {
        this.helperService.setImgElab( this.tempImg );
      } else {        
        this.helperService.setImg( this.tempImg );
      }
    }

    this.modalService.openModal( 'crop' );

  }

  ngDoCheck() {
    
    if ( this.helperService.tempImg && this.tempImg !== this.helperService.tempImg && this.type !== 'elaborations' ||
    this.helperService.tempImgElab && this.tempImg !== this.helperService.tempImgElab && this.type === 'elaborations' ) {
      if ( this.type === 'elaborations' ) {
        this.tempImg = this.helperService.tempImgElab;
      } else {
        this.tempImg = this.helperService.tempImg;
      }
      this.uploadFile = this.base64ToFile( this.tempImg );
      this.imgEmit.emit( this.uploadFile );
    }
    
  }

  base64ToFile( base64Image: string ): Blob {
    const split = base64Image.split( ',' );
    const type = split[0].replace( 'data:', '' ).replace( ';base64', '' );
    const byteString = atob( split[1] );
    const ab = new ArrayBuffer( byteString.length );
    const ia = new Uint8Array( ab );
    for ( let i = 0; i < byteString.length; i += 1 ) {
        ia[i] = byteString.charCodeAt( i );
    }
    return new Blob( [ab], {type} );
  }

  reset() {
    this.tempImg = undefined;
    this.helperService.setImg( undefined );
    this.helperService.setImgElab( undefined );
  }
}
