<div class="d-flex flex-wrap js-center">
    <div class="mx-1 my-05">
        <a [href]="'https://api.whatsapp.com/send?text=' + text + url" target="_blank">
            <img src="/assets/img/intern/icons/menus/whatsapp.svg" alt="whatsapp" class="icon lg">
            <p><small class="cl-black o-5">whatsapp</small></p>
        </a>
    </div>
    <div class="mx-1 my-05">
        <a [href]="'https://www.facebook.com/sharer/sharer.php?u=' + url" target="_blank">
            <img src="/assets/img/intern/icons/menus/facebook.svg" alt="facebook" class="icon lg">
            <p><small class="cl-black o-5">facebook</small></p>
        </a>
    </div>
    <div class="mx-1 my-05">
        <a [href]="'https://twitter.com/intent/tweet?text= ' + text + '&url=' + url" target="_blank">
            <img src="/assets/img/intern/icons/menus/twitter.svg" alt="twitter" class="icon lg">
            <p><small class="cl-black o-5">twitter</small></p>
        </a>
    </div>
    <div class="mx-1 my-05">
        <a [href]="'https://www.linkedin.com/sharing/share-offsite/?url=' + url" target="_blank">
            <img src="/assets/img/intern/icons/menus/linkedin.svg" alt="linkedin" class="icon lg">
            <p><small class="cl-black o-5">linkedin</small></p>
        </a>
    </div>
    <div class="mx-1 my-05">
        <a [href]="'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=FoodiesHome&body='+ text + url + '&ui=2&tf=1&pli=1'" target="_blank">
            <img src="/assets/img/intern/icons/menus/gmail.svg" alt="gmail" class="icon lg">
            <p><small class="cl-black o-5">gmail</small></p>
        </a>
    </div>
    <div class="mx-1 my-05">
        <a [href]="'mailto:?subject=FoodiesHome&amp;body=' + text + url" target="_blank">
            <img src="/assets/img/intern/icons/menus/mail.svg" alt="mail" class="icon lg">
            <p><small class="cl-black o-5">mail</small></p>
        </a>
    </div>
</div>