import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { User } from '../../models/user.model';
import { Notification } from '../../interfaces/notification.interface';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HelperService } from '../helper.service';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public user: User;

  constructor(  private http: HttpClient,
                private hs: HelperService,
                private authService: AuthService  ) {
                  this.user = this.authService.user;                  
                }

  get id(): string {
    return this.authService.user.id || '';
  }

  get role(): 'ADMIN_ROLE' | 'OFFERER_ROLE' | 'USER_ROLE' {
    return this.authService.user?.role;
  }

  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get headers() {
    return {
      headers: {
        'x-token': this.token
      }
    }
  }

  getUser( id: string ) {

    return this.http.get(`${ base_url }/users/${ id }`)
                  .pipe(
                    tap( (resp: any) => {
                      return resp;
                    } ),
                    catchError( error => {
                      return of(false)
                    } )
                  );

  }

  getStats( id: string ) {

    return this.http.get(`${ base_url }/users/stats/${ id }`)
                  .pipe(
                    tap( (resp: any) => {
                      return resp;
                    } ),
                    catchError( error => {
                      return of(false)
                    } )
                  );

  }
  
  updateUser( data: { email: string, nonamembre: string, role: string } ) {

    data = {
      ...data,
      role: this.authService?.user?.role
    };

    return this.http.put(`${ base_url }/users/${ this.id }`, data, this.headers )
                  .pipe(
                    tap( (resp: any) => {
                      return resp;
                    } ),
                    catchError( error => {
                      return of(false)
                    } )
                  );

  }

  updateUsersNotifications( notifications: Notification[] ) {
    return this.http.put(`${ base_url }/users/notifications/${ this.id }`, { notifications: notifications }, this.headers )
    .pipe(
      tap( (resp: any) => {
        return resp;
      } ),
      catchError( error => {
        return of(false)
      } )
    );
  }

  follow( id: string, type: string = 'array' ) {
    
    return this.http.put(`${ base_url }/users/follow/${ id }`, { type }, this.headers )
                  .pipe(
                    tap( (resp: any) => {
                      return resp;
                    } ),
                    catchError( error => {
                      return of(false)
                    } )
                  );

  }

  getFollow( id: string ) {
    
    return this.http.get(`${ base_url }/users/follow/${ id }?uid=${this.user?.id}`, this.headers )
                  .pipe(
                    tap( (resp: any) => {
                      return resp;
                    } ),
                    catchError( error => {
                      return of(false)
                    } )
                  );

  }

  getLater( type: 'restaurants' | 'recipes', from: number, limit: number ) {

    let location;
    if ( this.hs?.coords?.lat ) location = [this.hs.coords.lat, this.hs.coords.lon];

    return this.http.get(`${ base_url }/users/later/${ type }?from=${from}&limit=${limit}&location=${location || undefined}`, this.headers )
                  .pipe(
                    tap( (resp: any) => {
                      return resp;
                    } ),
                    catchError( error => {
                      return of(false)
                    } )
                  );
                  
  }
  
  addLater( id: string, type: 'restaurants' | 'recipes' ) {

    return this.http.put(`${ base_url }/users/later/${ id }`, { type: type }, this.headers )
                  .pipe(
                    tap( (resp: any) => {
                      return resp;
                    } ),
                    catchError( error => {
                      return of(false)
                    } )
                  );

  }

}
