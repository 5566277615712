import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { LoginForm } from '../../interfaces/login-form.interface';
import { User } from '../../models/user.model';
import { ModalService } from '../modal.service';
import { HelperService } from '../helper.service';

const base_url = environment.base_url;


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public auth2: any;
  public user: User;
  public guard: boolean = false;
  public init: boolean = false;
  public alert: boolean = false;

  constructor(  private http: HttpClient,
                private router: Router,
                private ngZone: NgZone,
                private hs: HelperService,
                private modalService: ModalService ) {}

  get uid(): string {
    return this.user?.id || '';
  }

  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get headers() {
    return {
      headers: {
        'x-token': this.token
      }
    }
  }

  validateToken(): Observable<boolean> {

    if ( this.token ) {

      return this.http.get( `${ base_url }/auth/renew`,{
        headers: { 
          'x-token': this.token
        }
      }).pipe(
        map( (resp: {ok: boolean, token: string, user: User } | any) => {

          if ( resp.ok ) {
            this.user = resp.user;
            localStorage.setItem( 'token', resp.token );
            this.guard = true;
            this.init = true;
          }
          return resp.ok;
          
        } ),
        catchError( error => {
          this.init = true;
          return of(false)
        } )
      );

    } else {
      this.init = true;
      return of(false);
    }

  }

  createUser( formData: any ) {

    return this.http.post( `${ base_url }/users`, formData )
              .pipe(
                tap( (resp: any) => {
                  if ( resp.ok ) {
                    this.user = resp.user;
                    this.guard = true;
                    localStorage.setItem( 'token', resp.token );
                    this.router.navigateByUrl('/profile');
                    setTimeout( () => {
                      location.reload();      
                    } );
                  }
                }),
                catchError( error => {
                  return of(false)
                } )
              );

  }

  login( formData: LoginForm ) {

    return this.http.post( `${ base_url }/auth`, formData )
                .pipe(
                  tap( (resp: any) => {
                    this.user = resp.user;
                    this.guard = true;
                    localStorage.setItem( 'token', resp.token )
                      location.reload();
                  } ),
                  catchError( error => {
                    return of(false)
                  } )
                );

  }

  loginGoogle( token: any ) {
    
    return this.http.post( `${ base_url }/auth/google`, { token: token.credential } )
                .pipe(
                  tap( (resp: any) => {
                    
                    if ( !resp.ok ) {
                      this.modalService.closeModal();
                      this.modalService.openModal( 'close' );
                      return this.modalService.setStatus( resp.ok?'ok':'error', resp.msg );
                    }
                    this.user = resp.user;
                    this.guard = true;
                    localStorage.setItem('token', resp.token );
                    location.reload();
                    
                  }),
                  catchError( error => {
                    return of(false)
                  } )
                );

  }

  // loginFacebook( token: any ) {
    
  //   return this.http.post( `${ base_url }/auth/facebook`, { token: token.credential } )
  //               .pipe(
  //                 tap( (resp: any) => {
  //                   this.user = resp.user;
  //                   this.guard = true;
  //                   localStorage.setItem('token', resp.token );
  //                   this.router.navigateByUrl('/profile');
  //                   setTimeout( () => {
  //                     location.reload();
  //                   } );
  //                 }),
  //                 catchError( error => {
  //                   // this.modalService.setStatus( 'error', error.error.msg );
  //                   return of(false)
  //                 } )
  //               );

  // }

  logout() {

    this.guard = false;
    this.user = {
      id: '',
      name: '',
      alias: '',
      email: '',
      img: '',
      role: 'USER_ROLE',
    };
    const localSave = localStorage.getItem('access');
    localStorage.clear();
    if ( localSave ) localStorage.setItem('access', localSave);

    this.router.navigateByUrl('/'+ this.hs.type);
    setTimeout( () => {
      location.reload();
    } );
    
    this.auth2.signOut().then(() => {
      this.ngZone.run(() => {
        this.router.navigateByUrl('/login');
      });
    });

  }

  changePassword( formData: object ) {

    return this.http.put( `${ base_url }/auth/change/${ this.user?.id }`, formData, this.headers )
                  .pipe(
                    tap( (resp: any) => {
                      return resp;
                    } ),
                    catchError( error => {
                      return of(false);
                    } )
                  );

  }

  forgotten( email: object ) {
    
    return this.http.put(`${ base_url }/auth/forgotten`, email );
  
  }

}
