<div class="main-page">
    <div class="title-box">
        <div class="page-tilte d-flex">
            <div class="my-auto">
                <app-btn-back [abs]="false"></app-btn-back>
            </div>
            <p class="title">Notificaciones</p>
        </div>
    </div>
    <table class="w-100 bd-t bd-b m-auto follow">
        <tr *ngFor="let notification of user?.notifications, let i=index" [class.d-none]="!notification.user">
            <td class="d-flex">
                <a [routerLink]="'/profile/user/'+(notification.user?._id || notification.user?.id || '')" class="m-auto">
                    <img [src]="notification.user?.img | img: 'users'" [alt]="notification.user?.alias+' Img'" class="bd-bg-round" onerror="this.onerror=null;this.src='/assets/img/intern/no-img.svg'">
                </a>
            </td>
            <td class="lh-1">
                <!-- <a [routerLink]="'/profile/user/'+(notification.user?._id || notification.user?.id || '')" class="c-bk">
                    <p><b>{{ notification.user?.alias }}</b></p>
                </a> -->
                <a [routerLink]="(notification.action>0)?('/'+((notification.type==='Restaurant')?'restaurant':'recipe')+'/'+(notification.ref?._id || notification.ref?.id)):('/profile/user/'+(notification.user?._id || notification.user?.id || ''))" [fragment]="(notification.action>0)?'reviews':''"
                    class="c-bk">
                    <p><b>{{ notification.user?.alias }}</b></p>
                    <p><small [innerText]="">{{ action(notification.action) }} <b class="cl-main">{{ (notification.action>0?notification.ref.name:'') | titlecase }}</b></small></p>
                </a>
            </td>
            <td *ngIf="notification.action>0" class="d-flex">
                <a [routerLink]="'/'+((notification.type==='Restaurant')?'restaurant':'recipe')+'/'+(notification.ref?._id || notification.ref?.id)" [fragment]="(notification.action>0)?'reviews':''" class="c-bk m-auto">
                    <img [src]="notification.ref?.img | img:((notification.type==='Restaurant')?'restaurants':'recipes')" [alt]="notification.user?.name+' Imagen de perfil'" class="bd-rds-20">
                </a>
            </td>
            <td *ngIf="notification.action<=0" class="text-center">
                <app-follow-btn [id]="(notification.user?._id || notification.user?.id || '')"></app-follow-btn>
            </td>
        </tr>
        <tr *ngIf="empty" class="wait">
            <td>No hay notificaciones</td>
        </tr>
    </table>
</div>