import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { Recipe } from '../../models/recipe.model';

@Component({
  selector: 'app-elaborations-table',
  templateUrl: './elaborations-table.component.html',
  styles: [
  ]
})
export class ElaborationsTableComponent implements OnInit {

  @Input() content: Recipe;
  @Input() idx: number = 0;
  @Input() noImg: boolean;
  @Input() portions: number;

  public ingShow: boolean = false;
  public ing: any;
  public ingTimeOut: any;

  constructor(  private modalService: ModalService  ) { }

  ngOnInit(): void {
  }

  advise( ing: any ) {
    this.ingShow = false;
    setTimeout( () => {
      clearTimeout( this.ingTimeOut );
      this.ing = ing;
      this.ingShow = true;
      this.ingTimeOut = setTimeout( () => {
        if ( this.ingShow ) this.ingShow = false;
      }, 1500 );
    }, 100 );
  }
  
  openModal( type: 'login' | 'register' | 'image' | 'crop' | 'tools' | 'ingredients' ) {
    this.modalService.openModal( type );
  }
  
  setImg( img: string ) {
    this.modalService.setImg( img, 'recipes', this.content.updatedAt || '', this.content.name );
  }

  ingArr() {
    let ingArr: string[] = [];
    for ( let ing of (this.content?.ingredients || []) ) ingArr.push( ing.ingredient );
    return ingArr;
  }

  checked( idx: number, type: string = 'ing' ) {
    const check = document.querySelector( '#' + type + (this.content._id || this.content.id) + idx );
    
    if ( check?.classList.contains( 'checked' ) ) {
      check.classList.remove( 'checked' );
    } else {
      if ( check ) check.classList.add( 'checked' );
    }
  }

}
