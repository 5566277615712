<input (keyup)="search(text.value)" type="search" placeholder="Buscar..." id="search" #text>

<app-icon-selected [optionsSelected]="optionsSelected" [type]="type" (selectedEmit)="selectedRes($event)"></app-icon-selected>

<div *ngIf="text.value.length > 0 && searchOptions.length > 0 || text.value.length <= 0 && searchOptions.length <= 0">
    <div class="flex-box flex-nowrap js-left scroll-show h-80px">
        <div *ngFor="let option of searchOptions.length>0?searchOptions:ingredientsPreview() | slice:0:6" class="cat-select m-025">
            <div [class]="'btn-ingredient bg-'+categorySelect(option)">
                <img (click)="checkOpt(option)" [src]="'assets/img/intern/icons/'+type+'/'+categorySelect(option)+'.svg'" [alt]="categorySelect(option)" class="icon">
            </div>
            <p>{{ option | Esp | titlecase }}</p>
        </div>
    </div>
</div>
<div *ngIf="text.value.length>0 && searchOptions.length<=0">
    <p>No se encuentran resultados</p>
</div>