import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styles: [
  ]
})
export class StatusComponent implements OnInit {
  
  public status: string;

  constructor(  public ms: ModalService  ) { }

  ngOnInit(): void {
    this.status = this.ms.status.status;
  }

  auth( type: 'login' | 'register' | 'close' = 'close') {
    this.ms.setStatus('none');
    this.ms._type = type;
    if ( this.ms.type === 'close' ) {
      this.ms.closeModal();
    }
  }

  cancel() {
    this.ms.closeModal();
  }

}
