import { Component, OnInit, Output, EventEmitter, Input, HostListener } from '@angular/core';

@Component({
  selector: 'app-fork-score',
  templateUrl: './fork-score.component.html',
  styles: [
  ]
})
export class ForkScoreComponent implements OnInit {

  @Output() scoreEmit: EventEmitter< number > = new EventEmitter();
  @Input() scoreSel: number = 4;
  @Input() scoreCheck: boolean = false;
  @Input() error: boolean = false;
  
  public scoreTemp: number;
  public scoreOver: boolean = false;
  public scoreArray: number[] = [ 1, 2, 3, 4, 5 ];
  public windowWidth: number;

  constructor() { }

  ngOnInit(): void {
    this.windowWidth = window.innerWidth;
  }

  score( i: number ) {
    this.scoreCheck = true;
    if ( this.windowWidth < 768 && this.scoreSel === (i + 1) ) {
      this.scoreSel = i
    } else {
      this.scoreSel = i + 1;
    }
    this.scoreEmit.emit( this.scoreSel );
  }

  overScore( i: number ) {
    if ( this.windowWidth > 768 ) {
      this.scoreTemp = ( i + 1 );
      this.scoreOver = true;
    }
  }

  fill( i: number ) {
    if ( !this.scoreOver ) {
      if ( this.scoreSel > i ) {
        return true;
      }
    } else {
      if ( this.scoreTemp > i ) {
        return true;
      }
    }
    return false;
  }  
  
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowWidth = window.innerWidth;
  }


}
