<div class="main-page">

    <div class="title-box">

        <!-- <div *ngIf="!uid" class="py-2 cl-main text-center">
            <p class="w-90 m-auto"><b><span class="subtitle">Únete a FoodiesHome</span><br>Guarda, crea y comparte restaurantes y recetas con tus amigos</b></p>
            <button (click)="openModal('login')" class="btn-red-ol sm mx-0 mt-1">Login</button>
        </div> -->

        <div id="profile-menu" class="profile-menu mb-0">
            <div (click)="contentTypeChange('reviews')" class="animated" [class.category-selected]="contentType==='reviews'">
                <img [src]="'assets/img/intern/icons/menus/'+(hs.friends?'profile':'world')+'.svg'" [alt]="(friends?'profile':'world')" class="icon">
                <!-- <img src="assets/img/intern/icons/menus/reviews.svg" alt="reviews" class="icon"> -->
            </div>
            <div (click)="contentTypeChange('cards')" class="animated" [class.category-selected]="contentType===''">
                <img [src]="'assets/img/intern/icons/menus/'+type+'.svg'" alt="type" class="icon">
            </div>
            <!-- <div (click)="contentTypeChange('lists')" class="animated" [class.category-selected]="contentType==='lists'">
                <img src="assets/img/intern/icons/menus/lists.svg" alt="lists" class="icon">
            </div> -->
        </div>
    </div>

    <app-grid [type]="type" [contentType]="contentType" [friends]="friends"></app-grid>

</div>