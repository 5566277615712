import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HelperService } from '../../../services/helper.service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styles: [
  ]
})
export class ShareComponent implements OnInit {

  public text: string = 'Mira esta publicación: ';
  public url: string = 'https://foodieshome.com';

  constructor(  private activatedRoute: ActivatedRoute,
                private hs: HelperService  ) { }

  ngOnInit(): void {

    this.url = window.location.href;
    
    // if ( img ) img.setAttribute( 'content', 'https://storage.googleapis.com/foodieshome-uploads/uploads/' + this.hs.share.type + '/' + this.hs.share.img );
    // if ( title ) title.setAttribute( 'content', 'FoodiesHome: ' + this.hs.share.name );

    if ( this.url.includes( 'recipe' ) ) {
      this.text = 'Mira esta receta: ';
      // this.text = 'Mira esta receta: ' + this.hs.share.name + ' ';
    } else if ( this.url.includes( 'restaurant' ) ) {
      this.text = 'Mira este restaurante: ';
      // this.text = 'Mira este restaurante: ' + this.hs.share.name + ' ';
    } else if ( this.url.includes( 'product' ) ) {
      this.text = 'Mira este producto: ';
    }

  }

}
