import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  public original: string[] = [
                                // Categories
                                'starters', 'main', 'desserts', 'drinks', 'sauces', 'garnish', 'others',
                                // Subcategories
                                'none', 'legumes', 'vegetables', 'fruits', 'mushrooms', 'soups','rices', 'pastas', 'meats', 'fishes', 'eggs', 'desserts', 'bakes',
                                // DrinkCategories
                                'juice', 'cocktail', 'infusion',
                                // Conditions
                                'gluten-free', 'vegan', 'no-meat', 'no-milk', 'no-alcohol',
                                // Ingredients
                                'unknown', 'milk', 'nuts', 'fish', 'blue-fish', 'white-fish', 'seafood', 'crustacean', 'mollusk', 'echinoderm', 'meat', 'red-meat', 'white-meat', 'sausages', 'egg', 'tuber', 'legume', 'vegetable', 'mushroom', 'fruit', 'cereal', 'pasta', 'flour', 'sugar',  'fat', 'oil', 'butter', 'spice', 'seed', 'dressing', 'alcohol', 'processed', 'drink', 'infusion', 'water', 'condiment',
                                // Types
                                'ingredients', 'ingredient', 'steps', 'step',
                                // Difficulties
                                'low', 'medium', 'high',
                                // Tools
                                'pot','cocktail-shaker',
                                // Status
                                'none','ok', 'error', 'question', 'warning', 'no-log', 'loading',
                                // Restaurant categories
                                'spanish', 'italian', 'french', 'greek', 'german', 'english', 'chinese', 'japanese', 'thai', 'indian', 'mexican', 'peruvian', 'arabic','north american', 'latin american', 'european', 'african', 'asian', 'oceania', 'american', 'fusion', 'mediterranean', 'seasonal', 'market', 'bake-pies-cakes', 'vegetarian', 'cafeteria', 'teahouse', 'varied', 'product', 'fast-food', 'food-truck', 'vanguard', 'others', 'galician', 'asturian', 'basque', 'castilian', 'andalusian', 'levantine', 'healthy', 'traditional',
                                // Specialities
                                'meets', 'fishes-s', 'seafoods', 'vegan', 'rices-s', 'stews', 'desserts', 'cocktails', 'alcohols', 'beers', 'wines', 'bushmeat', 'roast', 'grill',
                                // Commodities
                                'menu', 'parking', 'takeaway', 'valet', 'nobooking', 'terrace',
                                // Restaurant Types
                                'lunch-dinner', 'breakfast-snack', 'brunch', 'snack-food', 'drinks-cocktails',
                                // Ocassions
                                'lunch-dinner', 'breakfast-snack', 'brunch', 'snack-food', 'drinks-cocktails', 'little-time', 'big-events', 'special-ocassions', 'romantic', 'business', 'family', 'friends',
                              ];
  public translatedEsp: string[] = [
                                    // Categories
                                    'entrantes', 'principales', 'postres', 'bebidas', 'salsas', 'guarniciones', 'otros',
                                    // Subcategories
                                    'ninguna', 'legumbres', 'verduras y hortalizas', 'frutas', 'setas y hongos', 'sopas y pucheros', 'arroces y cereales', 'pasta y pizzas', 'carnes', 'pescados y mariscos', 'huevos', 'tartas y pasteles', 'panadería y bollería',
                                    // DrinkCategories
                                    'zumos y sin alcohol', 'cócteles y alcohólicas', 'té, café e infusiones',
                                    // Conditions
                                    'sin gluten', 'vegano', 'sin carne', 'sin lactosa', 'sin alcohol',
                                    // Ingredients
                                    'desconocido', 'lácteos', 'frutos secos', 'pescados', 'pescados azules', 'pescados blancos', 'mariscos', 'crustaceos', 'moluscos', 'equinodermos', 'carnes', 'carnes rojas', 'carnes blancas', 'embutidos', 'huevos', 'tubérculos', 'legumbres', 'verduras u hortalizas', 'setas u hongos', 'frutas', 'cereales', 'pastas', 'harinas', 'azúcares', 'grasas', 'aceites', 'mantequillas', 'especias', 'semillas', 'aliños', 'alcoholes', 'procesados', 'bebidas', 'infusiones', 'agua', 'condimentos', 
                                    // Types
                                    'ingredientes', 'ingrediente', 'pasos', 'paso',
                                    // Difficulties
                                    'baja', 'media', 'alta',
                                    // Tools
                                    'olla','coctelera',
                                    // Status
                                    'ninguno','correcto', 'error', 'seguro', 'cuidado', 'sesión no iniciada', 'cargando',
                                    // Restaurant categories
                                    'española', 'italiana', 'francesa', 'griega', 'alemana', 'inglesa', 'china', 'japonesa', 'tailandesa', 'india', 'mexicana', 'peruana', 'árabe','norteamericana', 'latinoamericana', 'europea', 'africana', 'asiática', 'oceánica', 'americana', 'fusión', 'mediterranea', 'de temporada', 'de mercado', 'pastelería', 'vegetariana', 'cafetería', 'tetería', 'variada', 'de producto', 'fast-food', 'food-truck', 'de vanguardia', 'otras', 'gallega', 'asturiana', 'vasco navarra', 'castiza', 'andaluza', 'levantina', 'saludable', 'tradicional',
                                    // Specialities
                                    'carnes', 'pescados', 'mariscos', 'vegano', 'arroces', 'guisos', 'postres', 'cócteles', 'alcoholes', 'cervezas', 'vinos', 'carne de caza', 'asado', 'parrilla',
                                    // Commodities
                                    'menú', 'parking gratuito', 'para llevar', 'aparcacoches', 'sin reserva', 'terraza',
                                    // Restaurant Types
                                    'comida/cena', 'merienda/desayuno', 'brunch', 'picoteo', 'copas/cócteles',
                                    // Ocassions
                                    'comidas y cenas', 'desayunos y meriendas', 'brunch', 'picoteo', 'bebidas y cócteles', 'poco tiempo', 'grandes eventos', 'ocasiones especiales', 'romántico', 'negocios', 'familiar', 'amigos',
                                  ];

  constructor() { }

  esp( option: string ) {
    const i = this.original.indexOf(option);
    return this.translatedEsp[i] || option;
  }

}
