import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LevenshteinService {

  constructor() { }

  searchSplit(s1: string, s2: string, dist: number = 2) {
    
    let valid: number;

    const arr1: any[] = s1.split( ' ' ).filter(
      option => option.length > 0 && 
      option !== 'y' && 
      option !== 'and' 
    );

    const arr2: any[] = s2.split( ' ' ).filter(
      option => option.length > 0 && 
      option !== 'y' && 
      option !== 'and' 
    );
    
    for ( let w1 of arr1 ) {
      for ( let w2 of arr2 ) {
          
        w1 = w1.slice(0, w2.length);
        valid = this.levenshtein( w1, w2 );
        if ( valid <= dist ) {
          return true;
        }

      }
    }

    return false;

  }

  // From Wikipedia
  levenshtein(s1: string, s2: string) {
    
    let l1 = s1.length,
    l2 = s2.length,
    d = [],
    c = 0,
    a = 0;
     
    if(l1 == 0) {
      return l2;
    }
    
    if(l2 == 0) {
      return l1;
    }
    
    a = l1 + 1;

    for(let i = 0; i <= l1; d[i] = i++);
    for(let j = 0; j <= l2; d[j * a] = j++);

    for(let i = 1; i <= l1; i++) {
      for(let j = 1; j <= l2; j++) {
        if(s1[i - 1] == s2[j - 1]) {
          c = 0;
        } else {
          c = 1;
        }
        let r: number = d[j * a + i - 1] + 1;
        let s: number = d[(j - 1) * a + i] + 1;
        let t: number = d[(j - 1) * a + i - 1] + c;

        d[j * a + i] = Math.min(Math.min(r, s), t);
      }
    }
  
    return d[l2 * a + l1];

  }

}
