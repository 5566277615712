<app-loading *ngIf="loading"></app-loading>
<div *ngIf="content && !loading">
    <div [class]="'in-head bg-'+(content.category | toStr:'category')+' bg-'+(content.subcategory | toStr:'subcategory')">
        <div class="main-page row g-21">
            <div class="col w-fc d-flex">
                <img (click)="openModal('image');setImg(content.img)" [src]="(content.img | img: 'recipes')" alt="recipe image" class="in-img">
            </div>
            <div class="col w-md-60 d-flex">
                <div class="in-titlebox">
                    <h2 class="in-title">{{ content.name | titlecase }}</h2>
                    <h3 class="in-category xl">{{ (content.category | toStr:'category') | Esp }}</h3>
                    <h3 *ngIf="content.subcategory" class="in-category">{{ (content.subcategory | toStr:'subcategory') | Esp }}</h3>
                    <div class="in-category d-flex js-md-center">
                        <p class="my-auto"><small class="my-auto">by</small></p>
                        <a [routerLink]="['/profile/user/'+content.user._id]" class="d-flex">
                            <span class="mx-025 my-auto">{{ content.user.alias | titlecase }}</span>
                            <img [src]="content.user.img | img: 'users'" class="card-category bd-bg-round m-auto" alt="profile img" onerror="this.onerror=null;this.src='/assets/img/intern/no-img.svg'">
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <app-btn-back></app-btn-back>
        <div class="edit">
            <img (click)="addLater()" [src]="'/assets/img/intern/icons/menus/later'+(isLater?'-fill':'')+'.svg'" alt="later" class="my-auto later icon md">
            <img (click)="addFavs()" [src]="'/assets/img/intern/icons/menus/heart'+(isFav?'-fill':'')+'.svg'" alt="heart" class="icon md cr-pointer">
            <app-dropdown-menu [name]="'recipeMenu'" [content]="content" [model]="model" (editEmit)="edit=$event;scroll($event);"></app-dropdown-menu>
        </div>
    </div>

    <div *ngIf="!uid" class="py-2 bg-aux-075 cl-main text-center">
        <p class="w-90 m-auto"><b><span class="subtitle">Únete a FoodiesHome</span><br>Guarda, crea y comparte recetas y restaurantes con tus amigos</b></p>
        <button (click)="openModal('login')" class="btn-red-ol sm mx-0 mt-1">Login</button>
    </div>

    <div class="main-page mt-2">

        <div *ngIf="!edit">
            <div *ngIf="content.score" class="text-center">
                <img src="/assets/img/intern/icons/menus/score.svg" alt="score" class="icon slg">
                <div class="d-flex js-center">
                    <div *ngIf="avgFriendsScore || avgFriendsScore===0" (click)="scrollRev()" class="px-1 bd-r">
                        <div class="text-center cr-pointer" id="friendActiv">
                            <p><small class="o-5">Amigos</small></p>
                        </div>
                        <div class="score-avg cr-pointer" id="friendActivScr">
                            <p class="mr-025 lh-1"><b>{{ avgFriendsScore | number:'1.1-1' }}<small>/10</small></b></p>
                        </div>
                    </div>
                    <div (click)="scrollRev()" class="px-1">
                        <div class="text-center">
                            <p><small class="o-5">Mundo</small></p>
                        </div>
                        <div class="score-avg">
                            <p class="mr-025 lh-1"><b>{{ avgScore?(avgScore | number:'1.1-1'):'-' }}<small>/10</small></b></p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row g-3 text-center mb-1 mt-2 profile-info">
                <div class="col">
                    <p><b>Raciones:</b></p>
                    <!-- <p>{{ content.portions }}</p> -->
                    <div class="d-flex js-center">
                        <button (click)="portionsChange(portionsVal.value, -1)" type=" button" class="btn-add">-</button>
                        <input (change)="portionsChange(portionsVal.value)" type="number" [value]="portions" class="sm-input mx-05" #portionsVal>
                        <button (click)="portionsChange(portionsVal.value, 1)" type="button" class="btn-add">+</button>
                    </div>
                </div>
                <div class="col">
                    <p><b>Tiempo:</b></p>
                    <p>{{ content.time | hourMin }}</p>
                </div>
                <div class="col">
                    <p><b>Dificultad:</b></p>
                    <p>{{ content.difficulty | toStr:'difficulty' | Esp }}</p>
                </div>
            </div>
            <hr class="my-2-auto">

            <div class="px-1">
                <div class="mb-1">
                    <div *ngIf="condLength>0" class="row g-63 my-1">
                        <div *ngFor="let condition of conditionsSel" class="col search-opt">
                            <img [src]="'assets/img/intern/icons/conditions/'+condition+'.svg'" [alt]="condition" [title]="condition | Esp" class="icon m-auto">
                            <p>{{ condition | Esp }}</p>
                        </div>
                    </div>
                    <p class="need-text mb-1"><small>Familias alimenticias:</small></p>
                    <div class="d-flex flex-wrap js-center">
                        <div *ngFor="let family of (content?.ingredientsFamilies || [])" class="cat-select">
                            <div [class]="'cr-unset btn-ingredient bg-'+family">
                                <img [src]="'/assets/img/intern/icons/ingredients/'+family+'.svg'" [alt]="family" class="icon">
                            </div>
                            <p>{{ family | Esp }}</p>
                        </div>
                    </div>
                </div>

                <div *ngIf="toolsLength>0" class="mb-1">
                    <p class="need-text"><small>Utensilios Recomendados:</small></p>
                    <div class="d-flex js-center">
                        <div *ngFor="let tool of toolsSel" class="cat-select m-05">
                            <div [class]="'text-center cr-unset btn-ingredient bg-'+categorySelect(tool)">
                                <img [src]="'assets/img/intern/icons/tools/'+categorySelect(tool)+'.svg'" [alt]="categorySelect(tool)" class="icon m-auto">
                            </div>
                            <p>{{ tool | Esp }}</p>
                        </div>
                    </div>
                </div>
                <div *ngIf="content.observations">
                    <div class="d-flex">
                        <p class="enf mr-05"><b>observaciones:</b></p>
                        <button (click)="obs('Main')" type="button" class="btn-add card-category"><span class="m-auto">
                            <span id="plusMain">+</span>
                            <span class="d-none" id="minusMain">-</span></span>
                        </button>
                    </div>
                    <p class="d-none" id="checkMain" [innerHtml]="content.observations | ingFind:allIngredients"></p>
                </div>

                <div *ngIf="content?.ingredients.length > 0" class="mt-3">
                    <div class="profile-info">
                        <p><b>Elaboración:</b></p>
                    </div>
                    <div class="elaboration bd-b-none">
                        <app-elaborations-table [content]="content" [noImg]="true" [portions]="portions"></app-elaborations-table>
                        <!-- <app-elaborations-table [content]="content" [noImg]="true" [portions]="content.portions"></app-elaborations-table> -->
                    </div>
                </div>

                <div *ngIf="content?.elaborations && (content?.elaborations?.length>0)" class="mt-1">
                    <div class="profile-info">
                        <p><b>Elaboraciones Secundarias:</b> {{ content?.elaborations.length }}</p>
                    </div>
                    <div *ngFor="let elaboration of content?.elaborations, let i=index" class="row elaboration">
                        <p class="m-auto mt-1"><small>elaboración {{ i + 1 }}:</small></p>
                        <h2 class="m-0 px-05">{{ elaboration.name }}</h2>

                        <div *ngIf='elaboration.user._id!==content.user._id'>
                            <div class="in-category d-flex">
                                <p class="my-auto"><small class="my-auto">by</small></p>
                                <a [routerLink]="['/profile/user/'+elaboration.user._id]" class="d-flex">
                                    <span class="mx-025 my-auto">{{ elaboration.user.name | titlecase }}</span>
                                    <img [src]="elaboration.user.img | img: 'users'" class="card-category bd-bg-round m-auto" [alt]="elaboration.user.name + ' img'" onerror="this.onerror=null;this.src='/assets/img/intern/no-img.svg'">
                                </a>
                            </div>
                        </div>

                        <div *ngIf="elaboration.observations">
                            <div class="d-flex">
                                <p class="enf mr-05"><b>observaciones:</b></p>
                                <button (click)="obs( i )" type="button" class="btn-add card-category">
                                    <span class="m-auto">
                                        <span [id]="'plus'+i">+</span>
                                        <span class="d-none" [id]="'minus'+i">-</span>
                                    </span>
                                </button>
                            </div>
                            <p class="d-none" [id]="'check'+i" [innerHtml]="elaboration.observations | ingFind:allIngredients"></p>
                        </div>
                        <app-elaborations-table [content]="elaboration" [idx]="i" [portions]="portions"></app-elaborations-table>
                    </div>
                </div>
            </div>

            <div id="reviews">
                <app-reviews-concat [content]="content" [model]="'Recipe'" [modal]="false" (reviewsCountEmit)="reviewsCount=$event"></app-reviews-concat>
            </div>
        </div>

        <app-recipe-form *ngIf="edit" [content]="content"></app-recipe-form>
    </div>
</div>