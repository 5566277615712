import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../services/translate.service';

@Pipe({
  name: 'Esp'
})
export class EspPipe implements PipeTransform {

 
  constructor (  private translateService: TranslateService  ){}

  transform( option: string ) {
    
    return this.translateService.esp( option );

  }

}
