<div class="sm-logo-box">
    <div class="sm-logo nav-90">
        <div class="sm-lt-box">
            <a [routerLink]="type">
                <img src="../../assets/img/intern/logo.svg" class="logo" alt="FoodiesHome Logo">
                <h1 class="text-logo">FoodiesHome</h1>
                <h1 class="text-logo-sm">FH</h1>
            </a>
        </div>

        <div class="d-flex m-auto mr-0 mb-0">
            <div class="d-flex mr-05 cl-main" id="friendsChange">
                <p (click)="friendsSel(false)" [class]="'animated fast cr-pointer '+(friends?'o-5':'fw-600')" id="friendsChangeWorld">Mundo</p>
                <p class="animated mx-05">|</p>
                <p (click)="friendsSel(true)" [class]="'animated fast cr-pointer '+(!friends?'o-5':'fw-600')" id="friendsChangeFriends">Amigos</p>
            </div>
            <app-search-global></app-search-global>
        </div>
    </div>
</div>

<nav class="navbar">
    <div class="nav-90">
        <a [routerLink]="type" class="main-logo">
            <img src="../../assets/img/intern/logo-w.svg" class="logo" alt="FoodiesHome Logo">
            <h1 class="text-logo">FoodiesHome</h1>
            <h1 class="text-logo-sm">FH</h1>
        </a>

        <div class="m-auto mr-0 md-d">
            <div class="d-flex mr-1" id="friendsChangeTop">
                <p (click)="friendsSel(false)" [class]="'animated fast m-auto cr-pointer '+(friends?'o-5':'fw-600')" id="friendsChangeTopWorld">Mundo</p>
                <p class="animated m-auto mx-05">|</p>
                <p (click)="friendsSel(true)" [class]="'animated fast m-auto cr-pointer '+(!friends?'o-5':'fw-600')" id="friendsChangeTopFriends">Amigos</p>
            </div>
        </div>

        <div class="active wait" id="mainMenu">
            <div (click)="typeSel('restaurants')" class="central left" [class.opt-l]="menu" id="mainRestaurants">
                <img src="assets/img/intern/icons/menus/restaurants.svg" alt="main" class="icon m-auto">
            </div>
            <div (click)="openModal('new');menu=false;profile=true;" class="central up" [class.opt-c]="menu">
                <img src="assets/img/intern/icons/menus/add.svg" alt="main" class="icon m-auto">
            </div>
            <div (click)="typeSel('recipes')" class="central right" [class.opt-r]="menu" id="mainRecipes">
                <img src="assets/img/intern/icons/menus/recipes.svg" alt="main" class="icon m-auto">
            </div>
        </div>

        <div id="nav-menu">
            <a (click)="typeSel('restaurants', $event)" routerLink="restaurants" routerLinkActive="active" class="m-auto md-d" [class.o-10]="profile && type==='restaurants'">
                <span>Restaurantes</span>
            </a>
            <a (click)="typeSel('recipes', $event)" routerLink="recipes" routerLinkActive="active" class="m-auto md-d" [class.o-10]="profile && type==='recipes'">
                <span>Recetas</span>
            </a>

            <div class="ml-md-1">
                <app-search-global></app-search-global>
            </div>

            <a (click)="profile=false" [routerLink]="type" routerLinkActive="active" class="m-auto sm-d">
                <img [src]="'assets/img/intern/icons/menus/'+type+'-w.svg'" [alt]="type" class="icon xmd m-auto" [class.nav-change]="anim">
            </a>

            <div class="central-shadow"></div>
            <div (click)="menu=!menu;anim=false;" class="central" id="mainCentral">
                <img src="assets/img/intern/icons/menus/menu.svg" alt="main" class="m-auto">
            </div>

            <a (click)="profile=true" routerLink="profile" routerLinkActive="active" class="m-auto o-10" [class.d-none]="!authService.user || !authService.guard">
                <div>
                    <img [src]="user?.img | img: 'users'" alt="profile" class="profile-img m-auto" onerror="this.onerror=null;this.src='/assets/img/intern/no-img.svg'">
                    <img src="assets/img/intern/icons/menus/profile-w.svg" alt="profile" class="icon xmd m-auto">
                </div>
            </a>
            <a (click)="openModal('login')" class="m-auto" [class.d-none]="authService.user && authService.guard">
                <div>
                    <span>
                        <button  type="button" class="btn-white">Login</button>
                    </span>
                    <img src="assets/img/intern/icons/menus/profile-login.svg" alt="profile login" class="icon xmd m-auto">
                </div>
            </a>
        </div>
    </div>
</nav>

<div class="back-navbar main"></div>