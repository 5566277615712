import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'level'
})
export class LevelPipe implements PipeTransform {

  transform( points: number | undefined ) {
    
    let level;

    if ( !points ) {
      level = 'Hierro';
    }  else if ( points >= 100000 ) {
      level = 'Foodies Master';
    } else if ( points >= 50000 ) {
      level = 'Diamante';
    } else if ( points >= 10000 ) {
      level = 'Platino';
    } else if ( points >= 5000 ) {
      level = 'Oro';
    } else if ( points >= 1000 ) {
      level = 'Plata';
    } else if ( points >= 100 ) {
      level = 'Bronce';
    } else {
      level = 'Hierro';
    }

    return level;

  }

}
