import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VariablesService {

  public categories: string[] = ['starters', 'main', 'desserts', 'garnish', 'drinks', 'sauces'];
  public fullSubcategories: any[] = ['none', 'legumes', 'vegetables', 'mushrooms', 'soups','rices', 'pastas', 'meats', 'fishes', 'eggs', 'fruits', 'desserts', 'bakes', 'juices', 'cocktails', 'infusions'];
  public conditions: string[] = ['gluten-free', 'vegan', 'no-meat', 'no-milk', 'no-alcohol'];
  public mesures: string[] = ['al gusto (ag)', 'gramos (gr)', 'kilogramos (kg)', 'miligramos (mg)', 'libras (lb)', 
  'onzas (oz)', 'litros (l)', 'centilitros (cl)', 'mililitros (ml)', 'galon (gal)', 'cuarto (qt)', 
  'pinta (pt)', 'taza (tz)', 'cucharada (cs)', 'cucharadita (cc)', 'pizca (pz)', 'unidades (uni)',
  'piezas (pi)', 'rebanadas (rb)', 'vaso (vs)', 'chorrito (ch)', 'poco (pc)', 'gotas (gt)',
  'porciento (%)', 'partes (prt)', 'puñado (pñ)'];
  public difficulties: string[] = ['low', 'medium', 'high'];
  public subcategories: any[] = ['none', 'legumes', 'vegetables', 'mushrooms', 'soups','rices', 'pastas', 'meats', 'fishes', 'eggs', 'fruits', 'desserts', 'bakes'];
  public drinkSubcategories: any[] = ['juices', 'cocktails', 'infusions'];
  public tools: any;
  public foodFamilies: string[] = ['unknown', 'milk', 'nuts', 'fish', 'blue-fish', 'white-fish', 'seafood', 'crustacean', 'mollusk', 'echinoderm', 'meat', 'red-meat', 'white-meat', 'sausages', 'egg', 'tuber', 'legume', 'vegetable', 'mushroom', 'fruit', 'cereal', 'pasta', 'flour', 'sugar',  'fat', 'oil', 'butter', 'spice', 'seed', 'dressing', 'alcohol', 'processed', 'drink', 'infusion', 'water', 'condiment'];
  public coins: any;
  public countries: any;
  // public address: any;
  public towns: string[];
  public working: boolean = false;
public restCategories: string[] = ['spanish', 'italian', 'french', 'greek', 'german', 'english', 'chinese', 'japanese', 'thai', 'indian', 'mexican', 'peruvian', 'arabic','north american', 'latin american', 'european', 'african', 'asian', 'oceania', 'american', 'fusion', 'mediterranean', 'seasonal', 'market', 'bake-pies-cakes', 'vegetarian', 'cafeteria', 'teahouse', 'varied', 'product', 'fast-food', 'food-truck', 'vanguard', 'others', 'galician', 'asturian', 'basque', 'castilian', 'andalusian', 'levantine', 'healthy', 'traditional']
  public specialities: string[] = ['meets', 'fishes-s', 'seafoods', 'vegan', 'rices-s', 'stews', 'desserts', 'cocktails', 'alcohols', 'beers', 'wines', 'bushmeat', 'roast', 'grill']
  public restaurantTypes: string[] = ['lunch-dinner', 'breakfast-snack', 'brunch', 'snack-food', 'drinks-cocktails'];
  public commodities: string[] = ['menu', 'parking', 'takeaway', 'valet', 'terrace'];
  // public commodities: string[] = ['menu', 'parking', 'takeaway', 'valet', 'nobooking', 'terrace'];
  public ocassions: string[] = ['lunch-dinner', 'breakfast-snack', 'brunch', 'snack-food', 'drinks-cocktails', 'little-time', 'big-events', 'special-ocassions', 'romantic', 'business', 'family', 'friends']
  public verbs: any = /^(agreg|ahum|alin|amas|anad|as|apag|aplic|apliqu|aument|baj|bat|calent|canaliz|carameliz|casc|coc|cuez|cubr|col|cuel|colg|cuelg|coloc|coloqu|condiment|control|cort|dej|descongel|desmenuz|desmold|despeg|dobl|dor|ech|empan|enfri|engras|escurr|espolvore|estir|evit|exprim|extend|extiend|fre|fri|freg|forr|gratin|guis|golpe|hac|hag|herv|hierv|horne|humedec|humedezc|incorpor|infusion|introduc|indroduzc|lav|liber|lig|limpi|llev|macer|manten|mantien|med|mid|mezcl|mol|mont|mov|muev|pas|pel|pes|pic|pinch|pon|pus|prepar|prob|prueb|rall|reban|reboz|reduc|reduzc|rehog|remov|remuev|repart|repos|reserv|retir|revolv|revuelv|romp|salpiment|salte|sazon|serv|sirv|sofre|sofri|sub|tamiz|tamic|templ|termin|toc|toqu|tost|tuest|transfer|transfier|tritur|troce|potenci|precalent|precalient|quit|unt|vert|viert|volc|vuelc|volte)(ar|er|ir|o|a|e|i|ad|ed|id|amo|emo|imo|ai|ei|an|en|ale|ala|alo|elo|ele|ela|irle|arle|erle|irla|arla|erla|irlo|arlo|erlo||are|dre|ara|dra|ire|aremo|dremo|areis|drei|aran|dran|aba|ia|abamo|iamo|abai|iai|aban|ian|ado|ada|ido|ida|aria|dria|ariamo|driamo|ariai|driai|arian|drian|aste|iste|io|ga|go|aron|gamo|istei|gan|ieron|astei|aremo|arei|iera|aren|ieren|ara|aramo|ieramo|arai|aran|ieran|ase|iese|asemo|iesemo|ieremo|asei|ierei|asen|iesen|iere|ando|iendo)?(s)?$/i

  constructor(  private http: HttpClient  ) { }
  
  getTools(): string[] | any {
    return this.http.get( '../../assets/data/tools.json' ).pipe(
      map( (resp: any) => {
        this.tools = resp;
      } ),
      catchError( error => {
        return of(false)
      } )
    );
  }
  
  categorySelect( option: string ) {
    const idx = this.tools.tools.indexOf( option );
    return this.tools.categories[ idx ];
  }

  getCoins(): any[] | any {
    return this.http.get( '../../assets/data/coins.json' ).pipe(
      map( (resp: any) => {
        this.coins = resp;
      } ),
      catchError( error => {
        return of(false)
      } )
    );
  }

  getCountries(): any[] | any {
    return this.http.get( '../../assets/data/countries_esp.json' ).pipe(
      map( (resp: any) => {
        this.countries = resp;
      } ),
      catchError( error => {
        return of(false)
      } )
    );
  }

  getTowns(): any[] | any {
    this.working = true;
    return this.http.get( '../../assets/data/towns.json' ).pipe(
      map( (resp: any) => {
        this.working = false;
        return this.towns = resp.towns;
      } ),
      catchError( error => {
        return of(false)
      } )
    );
  }

  // getAddress(): any[] | any {
  //   this.working = true;
  //   return this.http.get( '../../assets/data/address_esp.json' ).pipe(
  //     map( (resp: any) => {
  //       this.working = false;
  //       return this.address = resp;
  //     } ),
  //     catchError( error => {
  //       return of(false)
  //     } )
  //   );
  // }

}
