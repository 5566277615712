import { Component, OnInit, HostListener, EventEmitter, Output } from '@angular/core';
import { SearchService } from '../../../services/backend/search.service';
import { Router } from '@angular/router';
import { of } from 'rxjs';

@Component({
  selector: 'app-search-global',
  templateUrl: './search-global.component.html',
  styles: [
  ]
})
export class SearchGlobalComponent implements OnInit {
  
  private from: number = 0;
  private limit: number = 10;
  public search: boolean = false;
  public searchResults: any  = {
    users: [],
    recipes: [],
    restaurants: [],
  }
  public loading: boolean = false;
  public empty: boolean = false;
  public type: 'users' | 'recipes' | 'restaurants' = 'users';
  private searchTerm: string;
  private maxScroll: number = 0;
  private timeOutPress: any;

  constructor(  private searchService: SearchService,
                private router: Router  ) { }

  ngOnInit(): void {
  }

  searchGlobal( term: string, push: boolean = false ) {

    this.loading = true;
    clearTimeout( this.timeOutPress );
    let time = 400;
    
    if ( push ) {
      this.from += this.limit;
      time = 0;
    } else {
      this.searchResults = { users: [], recipes: [], restaurants: [], };
      this.maxScroll = 0;
      this.searchTerm = term;
      this.from = 0;
    };

    this.timeOutPress = setTimeout( () => {
 
      this.searchService.searchAll( this.from, this.limit, term ).subscribe( resp => {
        if ( resp.ok ) {
          this.empty = false;
          if ( resp?.users?.length <= 0 && resp?.recipes?.length <= 0 &&  resp?.restaurants?.length <= 0 ) this.empty = true;
          for ( let type of ['users', 'recipes', 'restaurants'] ) {
            if ( resp[ type ]?.length > 0 ) {
              this.searchResults[ type ] = [ ...this.searchResults[ type ], ...resp[ type ] ];
            }
          }
        }
        this.loading = false;
      })

    }, time )

  }

  redirect( id: string, type: string ) {
    this.search = false;
    this.router.navigate( ['/reload'] );
    setTimeout( () => {
      this.router.navigate( [ (type==='users' ? '/profile/user' : '/' + type.slice( 0, type.length - 1 )) + '/' + id ] );
    }, 100 );
  }

  scrollSearcher( searchResult: any ) {

    if ( !this.loading && this.searchTerm ) {
      const pos = searchResult.scrollTop;
      const max = searchResult.scrollHeight || 0;
      
      if ( (pos + (searchResult.clientHeight * 1.5)) >= max && pos > this.maxScroll ) {
        this.maxScroll = pos;
        this.searchGlobal( this.searchTerm, true );
      }
    }
    
  }

  @HostListener( 'document: click', ['$event'] )
  clickOut( event: { target: any; } ) {

    if ( this.search ) {
      
      const searchElem: any = document.querySelector( '.navbar .search-global-input' );
      const icon: any = document.querySelector( '.navbar .search-global img' );
      const searchElemSm: any = document.querySelector( '.search-global-input' );
      const iconSm: any = document.querySelector( '.search-global img' );
      
      if ( !searchElem.contains( event.target ) && !icon.contains( event.target ) && !searchElemSm.contains( event.target ) && !iconSm.contains( event.target ) ) {
        return this.search = false;
      }
      return this.search = true;
  
    }

  }
  
}
