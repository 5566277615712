import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/backend/auth.service';
import { tap } from 'rxjs/operators';
import { ModalService } from '../services/modal.service';
import { UserService } from '../services/backend/user.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(  private userService: UserService,
                private authService: AuthService,
                private modalService: ModalService,
                private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
      
    return this.authService.validateToken()
        .pipe(
          tap( isAuth =>  {
            if ( this.userService?.role !== 'ADMIN_ROLE' ) {
                this.router.navigateByUrl('/recipes');
            }
          })
        );

  }

}
