import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LevenshteinService } from '../../../services/levenshtein.service';
import { TranslateService } from '../../../services/translate.service';
import { ModalService } from '../../../services/modal.service';
import { HelperService } from '../../../services/helper.service';
import { VariablesService } from '../../../services/variables.service';

@Component({
  selector: 'app-search-icon-selector',
  templateUrl: './search-icon-selector.component.html',
  styles: [
  ]
})
export class SearchIconSelectorComponent implements OnInit {

  @Input() options: any[] = [];
  @Input() optionsSelected: string[] = [];
  @Input() type: string;
  @Output() optionsEmit: EventEmitter< string[] > = new EventEmitter();
  
  public searchOptions: string[] = [];
  public lastTextSearch: string;

  constructor(  private levenshteinService: LevenshteinService,
                private translateService: TranslateService,
                private modalService: ModalService,
                private helperService: HelperService,
                private vs: VariablesService    ) { }

  ngOnInit(): void {
  }

  search( text: string ) {
    
    this.lastTextSearch = text;
    if ( !text || text.length <= 0 ) {
      return this.searchOptions = [];
    }
    
    let searchOptions: any[] = [];
    
    for ( let i = 0; i < 3; i++ ) {
      
      searchOptions = searchOptions.concat( 
        this.options.filter(
          option => {
            
                      let must;

                      switch ( i ) {
                        case 0:
                          must = this.translateService.esp( option ).includes( text );
                          break;
                        case 1:
                          must = option.includes( text );
                          break;
                        case 2:
                            must = this.levenshteinService.searchSplit( option, text ) || 
                                    this.levenshteinService.searchSplit( this.translateService.esp( option ), text ); //Could be changed to catch the levenshtein value                      
                          break;
                      }

                      return !this.optionsSelected.includes( option ) && !searchOptions.includes( option ) && must
                    }
        )
      );

      if ( searchOptions.length > 4 ) {
        return this.searchOptions = searchOptions;
      }

    }
  
    this.searchOptions = searchOptions;

  }

  checkOpt( option: string ) {
    return this.selection( this.optionsSelected, option );
  }

  selection( selection: any, option: string ) {
    
    this.helperService.deletingChange( true );

    const include = selection.indexOf( option );
    if ( include >= 0 ) {
      selection.splice( include, 1 );
    } else {

      if ( this.modalService.showModal && this.modalService.type === 'ingredients' ) {
        selection = [ option ];
      } else {
        selection.push( option );
      }

    }
    
    this.optionsEmit.emit( selection );
    this.search( this.lastTextSearch );

  }

  ingredientsPreview() {
    const ingredients = this.options.filter( option => !this.optionsSelected.includes( option ) );  
    return ingredients;
  }

  categorySelect( option: string ){
    if ( this.type === 'tools' ) {
      return this.vs.categorySelect( option );
    }
    return option;
  }

  selectedRes( event: any ) {
    this.optionsSelected = event;
    this.search( this.lastTextSearch );
  }

}
