import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  
  public _showModal: boolean = false;
  public _ok: boolean = false;
  public _type: 'login' | 'register' | 'image' | 'crop' | 'tools' | 'ingredients' | 'close' | 'question' | 'new' | 'coins' | 'exist' | 'forgotten' | 'report' | 'share' | 'q-share' | 'share-follow';
  public _status: 'none' |'ok' | 'error' | 'question' | 'warning' | 'no-log' | 'loading' | 'info' = 'none';
  public _modalMsg: string = '';
  public _modalTitle: string = '';
  public _modalButtons: string[] = ['cancelar', 'ok'];
  public _img: string;
  public _imgs: string[];
  public _imgsIdx: number;
  public _imgType: 'users' | 'recipes' | 'restaurants';
  public _imgName: string;
  public _imgDate: string;
  public _imgEdit: boolean = false;
  public _imgEditTemp: boolean = false;
  public _imgEditId: string;
  public _editing: boolean = false;
  public _id: string;
  public _model: string;
  public _showAdviseBanner: boolean = false;
  public _advType: string;
  public _saveAdvise: boolean;
  public timeOut: any;
  public auxTimeOut: any;

  constructor() { }

  get showModal() {
    return this._showModal;
  }

  get showAdviseBanner() {
    return this._showAdviseBanner;
  }

  get advType() {
    return this._advType;
  }

  get saveAdvise() {
    return this._saveAdvise;
  }

  get ok() {
    return this._ok;
  }

  get type() {
    return this._type;
  }

  get status() {
    return { 
      status: this._status,
      msg: this._modalMsg
    };
  }

  get opt() {
    return { 
      title: this._modalTitle,
      msg: this._modalMsg,
      buttons: this._modalButtons
    };
  }

  get img() {
    return {
      img: this._img,
      imgs: this._imgs,
      imgsIdx: this._imgsIdx,
      imgType: this._imgType,
      imgName: this._imgName,
      imgDate: this._imgDate
    }
  }

  get imgType() {
    return this._imgType;
  }

  get imgEdit() {
    return { imgEdit: this._imgEdit, imgEditId: this._imgEditId }
  }

  get id() {
    return this._id;
  }

  get model() {
    return this._model;
  }

  get editing() {
    return this._editing;
  }
  
  openModal( type: 'login' | 'register' | 'image' | 'crop' | 'tools' | 'ingredients' | 'close' | 'question' | 'new' | 'coins' | 'exist' | 'forgotten' | 'report' | 'share' | 'q-share' | 'share-follow' ) {
    this._type = type;
    this._showModal = true;
    
    if ( type === 'image' && this._imgEditTemp && !this._editing || type === 'crop' && this._imgEditTemp && !this._editing ) {
      this._imgEdit = true;
    } else {
      this._imgEdit = false;
    }
  }
  
  closeModal() {
    this._status = 'none';
    this._showModal = false;
    this._modalTitle = '';
    this._modalMsg = '';
    this._modalButtons = ['cancelar', 'ok'];  
    this._imgEdit = false;
  }

  openAdviseBanner( type: string, save: boolean = true ) {
    if ( this._showAdviseBanner ) {
      clearTimeout( this.timeOut );
      clearTimeout( this.auxTimeOut );
      this._showAdviseBanner = false;
      this.auxTimeOut = setTimeout( () => this._showAdviseBanner = true );
    } else {
      this._showAdviseBanner = true;
    }
    
    this._advType = type;
    this.timeOut = setTimeout(() => {
      this._showAdviseBanner = false;
    }, 2000 );
  }

  setStatus( status: 'none' | 'ok' | 'error' | 'question' | 'warning' | 'no-log' | 'loading' | 'info', statusMsg: string = '' ) {
    this._status = status;
    this._modalMsg = statusMsg;
    this.openModal( 'close' );
  }
  
  setImg( img: string, type: 'users' | 'recipes' | 'restaurants', date: string , name?: string ) {
    this._img = img;
    this._imgs = [];
    this.imgComp( type, date, name );
  }

  setImgs( imgs: string[], type: 'users' | 'recipes' | 'restaurants', date: string, idx: number = 0, name?: string ) {
    this._imgs = imgs;
    this._imgsIdx = idx;
    this._img = '';
    this.imgComp( type, date, name );
  }

  setOk( ok: boolean ) {
    this._ok = ok;
  }

  setReport( id: string, model: string ) {
    this._id = id;
    this._model = model;
  }

  imgComp( type: 'users' | 'recipes' | 'restaurants', date: string, name?: string ) {
    this._imgType = type;
    this._imgName = name || type;
    this._imgDate = date;
  }

  setImgEdit( edit: boolean, id: string = '' ) {
    this._imgEditTemp = edit;
    this._imgEditId = id;
  }

  setEditing( edit: boolean ) {
    this._editing = edit;
  }

  setOpt( title: string, msg: string, buttons: string[] = ['cancelar', 'ok'] ) {
    this._modalTitle = title;
    this._modalMsg = msg;
    this._modalButtons = buttons;
  }

}
