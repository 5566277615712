import { Component } from '@angular/core';
import { AuthService } from 'app/services/backend/auth.service';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styles: [
  ]
})
export class PagesComponent {

  constructor(  public  modalService: ModalService,
                // public authService: AuthService,
                  ) {}
  
}
