<div (scroll)="onScroll($event)" class="replies">
    <div *ngFor="let reply of replies">
        <app-reply [reply]="reply"></app-reply>
    </div>
    <div *ngIf="replies.length > 0" class="px-1 py-2 o-5">
        <p>Ya no hay más respuestas</p>
    </div>
    <img *ngIf="loading" src="assets/img/intern/icons/status/loading.svg" alt="loading" class="icon spin text-center my-2">
</div>
<app-loading *ngIf="loading && replies?replies.length<=0:false"></app-loading>
<div *ngIf="replies.length === 0" class="wait">
    <p class="o-5">Se el primero en comentar</p>
</div>