<div class="search-global">
    <img (click)="search=!search" src="assets/img/intern/icons/menus/search.svg" alt="recipes" class="icon m-auto cr-pointer" [class.o-10]="search">
</div>
<!-- <div class="search-global-input grow-down animated fast" [class.d-none]="!search" [class.bg-grey]="searchInput.value.length>0"> -->
<div class="search-global-input bg-grey" [class.d-none]="!search">
    <div class="w-md-60 m-auto pst-relative">
        <input (keyup)="searchGlobal(searchInput.value)" type="search" placeholder="Busca amigos, restaurantes o recetas..." #searchInput>
        <img (click)="searchGlobal(searchInput.value)" src="assets/img/intern/icons/menus/search.svg" alt="recipes" class="icon sm m-auto cr-pointer search-icon">
    </div>
    <!-- <div class="results m-auto bg-grey" [class.d-none]="searchInput.value.length<=0" [class.d-block]="searchInput.value.length>0"> -->
    <div class="results m-auto bg-grey d-block">
        <div id="profile-menu" class="profile-menu mb-0">
            <div (click)="type='users'" class="animated" [class.category-selected]="type==='users'">
                <img src="assets/img/intern/icons/menus/profile.svg" alt="users" class="icon">
            </div>
            <div (click)="type='restaurants'" class="animated" [class.category-selected]="type==='restaurants'">
                <img src="assets/img/intern/icons/menus/restaurants.svg" alt="restaurants" class="icon">
            </div>
            <div (click)="type='recipes'" class="animated" [class.category-selected]="type==='recipes'">
                <img src="assets/img/intern/icons/menus/recipes.svg" alt="recipes" class="icon">
            </div>
        </div>
        <div *ngIf="(searchResults[type]?.length > 0)" (scroll)="scrollSearcher(searchResult)" class="scroll mt-0 row scroll-show" #searchResult>
            <div *ngFor="let result of (searchResults[ type ] || [])" (click)="redirect(result.id, type)" class="d-flex p-1 cr-pointer">
                <img [src]="result.img | img: type" [alt]="type+' img'" class="icon lg bd-bg-round mr-05">
                <div class="d-block m-auto ml-0 text-left">
                    <p class="lh-1">{{result.name}}</p>
                    <p class="lh-1 o-5 first-lc"><small>{{ (type==='recipes'?result.user.alias:(type==='restaurants'?result.address.town:result.alias)) | titlecase }}</small></p>
                </div>
            </div>
            <div *ngIf="loading" class="p-1">
                <img src="assets/img/intern/icons/status/loading.svg" alt="loading" class="icon spin m-auto">
            </div>
        </div>
        <div *ngIf="(searchResults[type]?.length <= 0) && loading" class="p-1">
            <img src="assets/img/intern/icons/status/loading.svg" alt="loading" class="icon spin m-auto">
        </div>
        <div *ngIf="(searchResults[type]?.length <= 0) && !loading" class="p-1">
            <p class="o-5">{{ searchInput.value.length > 0?'No se encuentran resultados...':'Escribe para iniciar la búsqueda...'}}</p>
        </div>
    </div>
</div>