import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesComponent } from './pages/pages.component';
import { RestaurantsComponent } from './restaurants/restaurants.component';
import { RecipesComponent } from './recipes/recipes.component';
import { MarketPlaceComponent } from './market-place/market-place.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { PagesRoutingModule } from './pages-routing.module';
import { ComponentsModule } from '../components/components.module';
import { RecipeComponent } from './recipe/recipe.component';
import { ProfileComponent } from './profile/profile.component';
import { SettingsComponent } from './settings/settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewRecipeComponent } from './new-recipe/new-recipe.component';
import { PipesModule } from '../pipes/pipes.module';
import { ReloadComponent } from './reload/reload.component';
import { FollowingComponent } from './following/following.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NewReviewComponent } from './new-review/new-review.component';
import { RestaurantComponent } from './restaurant/restaurant.component';
import { IngredientsAdminComponent } from './ingredients-admin/ingredients-admin.component';
import { TermsComponent } from './terms/terms.component';
import { RestaurantsAdminComponent } from './restaurants-admin/restaurants-admin.component';
import { ShoppingListComponent } from './shopping-list/shopping-list.component';



@NgModule({
  declarations: [
    PagesComponent,
    RestaurantsComponent,
    RecipesComponent,
    MarketPlaceComponent,
    RecipeComponent,
    ProfileComponent,
    SettingsComponent,
    NewRecipeComponent,
    ReloadComponent,
    FollowingComponent,
    NotificationsComponent,
    NewReviewComponent,
    RestaurantComponent,
    IngredientsAdminComponent,
    TermsComponent,
    RestaurantsAdminComponent,
    ShoppingListComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    SharedModule,
    ComponentsModule,
    PipesModule
  ],
  exports: [
    PagesComponent,
    RestaurantsComponent,
    RecipesComponent,
    MarketPlaceComponent,
    ProfileComponent
  ]
})
export class PagesModule { }
