import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages/pages.component';
import { RecipesComponent } from './recipes/recipes.component';
import { RestaurantsComponent } from './restaurants/restaurants.component';
import { MarketPlaceComponent } from './market-place/market-place.component';
import { RecipeComponent } from './recipe/recipe.component';
import { ProfileComponent } from './profile/profile.component';
import { SettingsComponent } from './settings/settings.component';
import { NewRecipeComponent } from './new-recipe/new-recipe.component';
import { AuthGuard } from '../guards/auth.guard';
import { ReloadComponent } from './reload/reload.component';
import { FollowingComponent } from './following/following.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NewReviewComponent } from './new-review/new-review.component';
import { RestaurantComponent } from './restaurant/restaurant.component';
import { IngredientsAdminComponent } from './ingredients-admin/ingredients-admin.component';
import { TermsComponent } from './terms/terms.component';
import { AdminGuard } from '../guards/admin.guard';
import { RestaurantsAdminComponent } from './restaurants-admin/restaurants-admin.component';
import { environment } from 'environments/environment';
import { ShoppingListComponent } from './shopping-list/shopping-list.component';

const base_url = environment.base_url;


const routes: Routes = [

  {
      path: '',
      data: { seo: { title: 'FoodiesHome' } },
      component: PagesComponent,
      children: [
          { path: '', redirectTo: 'restaurants', pathMatch: 'full' },
          { path: 'recipes', data: { seo: { title: 'Recetas - FoodiesHome', metaTags: [
            { name: 'description', content: 'Encuentra las mejores recetas con las valoraciones de tus contactos de confianza.' },
            { property: 'og:locale', content: 'es_ES'},
            { property: 'og:type', content: 'web-app' },
            { property: 'og:title', content: 'FoodiesHome | Recetas' },
            { proprety: 'og:description', content: 'Encuentra las mejores recetas con las valoraciones de tus contactos de confianza.' },
            { property: 'og:image', content: 'https://storage.googleapis.com/foodieshome-uploads/logo-sm.png' },
            { property: 'og:url', content: base_url + 'recipes' },
            { name: "twitter:card", content: "summary_large_image" },
          ] } }, component: RecipesComponent },
          { 
            path: 'recipe/:id',
            component: RecipeComponent 
          },
          { path: 'restaurants', data: { seo: { title: 'Restaurantes - FoodiesHome', metaTags: [
            { name: 'description', content: 'Encuentra los mejores restaurantes con las valoraciones de tus contactos de confianza.' },
            { property: 'og:locale', content: 'es_ES'},
            { property: 'og:type', content: 'web-app' },
            { property: 'og:title', content: 'FoodiesHome | Restaurantes' },
            { proprety: 'og:description', content: 'Encuentra los mejores restaurantes con las valoraciones de tus contactos de confianza.' },
            { property: 'og:image', content: 'https://storage.googleapis.com/foodieshome-uploads/logo-sm.png' },
            { property: 'og:url', content: base_url + 'restaurants' },
            { name: "twitter:card", content: "summary_large_image" },
          ] } }, component: RestaurantsComponent },
          { 
            path: 'restaurant/:id',
            component: RestaurantComponent 
          },
          { path: 'market-place', component: MarketPlaceComponent },
          { path: 'terms', data: { seo: { title: 'Términos - FoodiesHome' } }, component: TermsComponent },
          {
            path: 'admin',
            data: { seo: { title: 'Admin - FoodiesHome' } },
            canActivate: [ AdminGuard ],
            children: [
              { path: 'ingredients', component: IngredientsAdminComponent },
              { path: 'restaurants', component: RestaurantsAdminComponent },
              { path: '**', redirectTo: 'ingredients', pathMatch: 'full' },
            ]
          },
          { 
            path: 'profile',
            data: { seo: { title: 'Perfil - FoodiesHome' } },
            children: [
              { path: '', component: ProfileComponent },
              { path: 'user/:id', component: ProfileComponent },
              { path: 'settings', data: { seo: { title: 'Ajustes - FoodiesHome' } }, canActivate: [ AuthGuard ], component: SettingsComponent },
              { path: 'new-recipe', data: { seo: { title: 'Nueva receta - FoodiesHome' } }, canActivate: [ AuthGuard ], component: NewRecipeComponent },
              { path: 'new-review', data: { seo: { title: 'Nueva reseña - FoodiesHome' } }, canActivate: [ AuthGuard ], component: NewReviewComponent },
              { path: 'following', data: { seo: { title: 'Siguiendo - FoodiesHome' } }, canActivate: [ AuthGuard ], component: FollowingComponent },
              { path: 'following/:name/:id', data: { seo: { title: 'Siguiendo - FoodiesHome' } }, canActivate: [ AuthGuard ], component: FollowingComponent },
              { path: 'notifications', data: { seo: { title: 'Notificaciones - FoodiesHome' } }, canActivate: [ AuthGuard ], component: NotificationsComponent },
              { path: 'shopping-list', data: { seo: { title: 'Lista de la Compra - FoodiesHome' } }, canActivate: [ AuthGuard ], component: ShoppingListComponent },
              { path: '**', redirectTo: '', pathMatch: 'full' },
            ]
          },
          { path: 'reload', component: ReloadComponent },
          { path: '**', redirectTo: 'restaurants', pathMatch: 'full' },
      ]
  },
  { path: '**', redirectTo: 'restaurants', pathMatch: 'full' },

];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class PagesRoutingModule {}
