import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styles: [
  ]
})
export class NewComponent implements OnInit {

  constructor(  private modalService: ModalService  ) { }

  ngOnInit(): void {
  }

  close() {
    this.modalService.closeModal()
  }
}
