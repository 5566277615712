<div class="elab-carousel mt-1">
    <p id="elaborations" class="lh-1">Elaboraciones Secundarias<br><small class="o-5">salsas, masas, guarniciones...</small></p>
    <div (click)="scrollable()" class="carousel-box">

        <div (scroll)="carouselScroll()" class="carousel" id="elabCarousel">
            <div class="btn-card-box">
                <button (click)="openElaboration(-1)" type="button" class="btn-card-add"><span class="m-auto">+</span></button>
            </div>

            <div *ngFor="let elaboration of elaborations, let i=index" class="card-m d-flex">
                <button (click)="posChange(i, -1)" type="button" class="btn-car-ch left" [class.d-none]="elabMove(i, -1)">
                        <img src="/assets/img/intern/icons/menus/arrow.svg" alt="arrow left">
                    </button>
                <div (click)="openElaboration(i)" class="card-add">
                    <img [src]="(elabSelect(i)?lastImg:(elaboration.img | img:'recipes'))" [alt]="elaboration.name+' image'">
                    <p class="elab-title">{{elaboration.name | autoFit:i:'.elab-title':'.card-add':4:16}}</p>
                    <img (click)="quitElaboration(i)" src="/assets/img/intern/icons/menus/delete.svg" alt="delete" class="icon sm">
                </div>
                <button (click)="posChange(i, 1)" type="button" class="btn-car-ch right" [class.d-none]="elabMove(i, 1)">
                    <img src="/assets/img/intern/icons/menus/arrow.svg" alt="arrow right">
                </button>
            </div>

            <div *ngIf="elaborations.length<=0" class="d-flex">
                <div class="m-auto">
                    <p>¡No hay contenido!<br><small class="o-5">Añada elaboraciones secundarias...</small></p>
                </div>
            </div>
        </div>

        <div (click)="scrolling(-1)" class="carousel-s left" [class.d-none]="!scrollBtn.left">
            <img src="/assets/img/intern/icons/menus/arrow-car.svg" alt="arrow left">
        </div>
        <div (click)="scrolling(1)" class="carousel-s right" [class.d-none]="!scrollable() || !scrollBtn.right">
            <img src="/assets/img/intern/icons/menus/arrow-car.svg" alt="arrow right">
        </div>

    </div>
</div>

<div *ngIf="newElaboration" class="grow-down" [class.d-block]="newElaboration" [class.d-none]="!newElaboration" [class.exit-up]="exitElaboration">
    <app-elaboration-form [elaboration]="elaborations[elaborationIndex]" [portions]="portions" (recipeFormEmit)="addElaboration($event);exit();" (elaborationsEmit)="exit();"></app-elaboration-form>
</div>