import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MoodScoreComponent } from '../mood-score/mood-score.component';

@Component({
  selector: 'app-plate-score',
  templateUrl: './plate-score.component.html',
  styles: [
  ]
})
export class PlateScoreComponent implements OnInit {

  @ViewChild( MoodScoreComponent ) moodScoreComponent: MoodScoreComponent;

  @Input() name: string;
  @Input() score: number;
  @Input() error: boolean;
  @Input() del: boolean = false;
  @Output() plateEmitter: EventEmitter< { name: string, score: number } > = new EventEmitter();
  @Output() delEmitter: EventEmitter< boolean > = new EventEmitter();

  public form: FormGroup;
  public showAdv: boolean = false;

  constructor(  private fb: FormBuilder  ) { }

  ngOnInit(): void {    
    this.form = this.fb.group({
      name: [ this.name || '', Validators.required],
      score: [ this.score || '', Validators.required]
    })
  }

  scoreChange( score: number ) {
    this.form.controls['score'].setValue( score );    
    if ( !this.del ) {
      this.emitter();
    }   
  }

  emitter() {
    const FC = this.form.controls;
    if ( !this.del ) {
      return this.plateEmitter.emit( { name: FC['name'].value, score: FC['score'].value } );
    }
    if ( FC['name'].value.length > 0 ) {
      this.name = FC['name'].value;
    } else {
      FC['name'].setValue( this.name );
      return this.advise();
    }
    if ( FC['score'].value > 0 ) {
      this['score'] = FC['score'].value;
    } else {
      FC['score'].setValue( this.score );
      this.moodScoreComponent.score = this.score;
      return this.advise();
    }
    if ( this.name.length > 0 && this.score > 0 ) {
      return this.plateEmitter.emit( { name: FC['name'].value, score: FC['score'].value } );
    }
  }

  plateDel() {
    this.delEmitter.emit( true );
  }

  advise() {
    
    if ( !this.showAdv ) {
      this.showAdv = true;
      setTimeout( () => {
        this.showAdv = false;
      }, 2000 )
    }

  }
}
