<div class="animated">
    <div *ngIf="ms.img?.imgs && ms.img.imgs.length>0">
        <!-- <div *ngIf="ms.img?.imgs && ms.img.imgs.length>0" class="d-flex"> -->
        <!-- <button (click)="idxChange(-1)" type="button" class="btn-car-ch left" [class.unactive]="idx<=0">
            <img src="/assets/img/intern/icons/menus/arrow.svg" alt="arrow right">
        </button> -->

        <div>
            <div class="modal-img bf">
                <img [src]="ms.img.imgs[idx] | img: ms.img.imgType" alt="profile img" class="img-content">
            </div>

            <!-- <p>{{ (idx+1) + '/' + ms.img.imgs.length }}</p> -->
        </div>
        <div class="d-flex mt-025">
            <button (click)="idxChange(-1)" type="button" class="btn-car-ch left" [class.unactive]="idx<=0">
                <img src="/assets/img/intern/icons/menus/arrow-car.svg" alt="arrow right">
            </button>


            <p>{{ (idx+1) + '/' + ms.img.imgs.length }}</p>

            <button (click)="idxChange(1)" *ngIf="ms.img.imgs" type="button" class="btn-car-ch right" [class.unactive]="idx>=(ms.img.imgs.length-1)">
                <img src="/assets/img/intern/icons/menus/arrow-car.svg" alt="arrow right">
            </button>
        </div>
    </div>

    <div *ngIf="(!ms.img?.imgs && ms.img?.img) || (ms.img.imgs.length<=0 && ms.img?.img)" class="modal-img bf">
        <div *ngIf="!ms.imgEdit.imgEdit">
            <img [src]="ms.img.img | img: ms.img.imgType" alt="profile img" class="img-content">
        </div>
        <div *ngIf="ms.imgEdit.imgEdit">
            <app-img-select [img]="ms.img.img" [type]="ms.img.imgType"></app-img-select>
        </div>
    </div>

    <div class="img-subtitle">
        <p class="date">{{ ms.img.imgDate | date:'dd/MM/yyyy' || 'Recuerdos' }}</p>
        <p class="m-auto modal-img-txt">{{ ms.img.imgName | titlecase | autoFit:0:'.modal-img-txt':'.modal-img':2:0:true:56 }}</p>
    </div>
</div>