import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardComponent } from './card/card.component';
import { GridComponent } from './grid/grid.component';
import { SearchComponent } from './search-folder/search/search.component';
import { LoginComponent } from './modals/login/login.component';
import { RegisterComponent } from './modals/register/register.component';
import { ModalComponent } from './modals/modal/modal.component';
import { AuthComponent } from './modals/auth/auth.component';
import { ImageComponent } from './modals/image/image.component';
import { BtnScrollTopComponent } from './btn-scroll-top/btn-scroll-top.component';
import { PillsComponent } from './pills/pills.component';
import { ImgSelectComponent } from './img-select/img-select.component';
import { OptSelectComponent } from './modals/opt-select/opt-select.component';
import { PipesModule } from '../pipes/pipes.module';
import { IconSelectorComponent } from './icon-selector/icon-selector.component';
import { SearchIconSelectorComponent } from './search-folder/search-icon-selector/search-icon-selector.component';
import { IconSelectedComponent } from './icon-selected/icon-selected.component';
import { RecipeFiltersComponent } from './filters/recipe-filters/recipe-filters.component';
import { StatusComponent } from './modals/status/status.component';
import { AdderContainerComponent } from './elaborations/adder-container/adder-container.component';
import { CropperComponent } from './cropper/cropper.component';
import { QuestionComponent } from './modals/question/question.component';
import { ElaborationsTableComponent } from './elaborations-table/elaborations-table.component';
import { RecipeFormComponent } from './recipe-form/recipe-form.component';
import { LoadingComponent } from './loading/loading.component';
import { ReplyComponent } from './reply-folder/reply/reply.component';
import { ReplyFormComponent } from './reply-folder/reply-form/reply-form.component';
import { RepliesComponent } from './reply-folder/replies/replies.component';
import { DropdownMenuComponent } from './dropdown-menu/dropdown-menu.component';
import { PageGridComponent } from './page-grid/page-grid.component';
import { FollowBtnComponent } from './follow-btn/follow-btn.component';
import { RecipeReviewFormComponent } from './reviews-folder/recipe-review-form/recipe-review-form.component';
import { NewComponent } from './modals/new/new.component';
import { RestaurantReviewFormComponent } from './reviews-folder/restaurant-review-form/restaurant-review-form.component';
import { ForkScoreComponent } from './reviews-folder/score/fork-score/fork-score.component';
import { MoodScoreComponent } from './reviews-folder/score/mood-score/mood-score.component';
import { CoinsComponent } from './modals/coins/coins.component';
import { ImgCarouselComponent } from './img-carousel/img-carousel.component';
import { ButtonGroupComponent } from './button-group/button-group.component';
import { PlateScoreComponent } from './reviews-folder/score/plate-score/plate-score.component';
import { RestaurantFiltersComponent } from './filters/restaurant-filters/restaurant-filters.component';
import { SearchGlobalComponent } from './search-folder/search-global/search-global.component';
import { ElabCarouselComponent } from './elab-carousel/elab-carousel.component';
import { ExistComponent } from './modals/exist/exist.component';
import { RestaurantFormComponent } from './restaurant-form/restaurant-form.component';
import { ForgottenComponent } from './modals/forgotten/forgotten.component';
import { ReportComponent } from './modals/report/report.component';
import { ElaborationFormComponent } from './elaborations/elaboration-form/elaboration-form.component';
import { ShareComponent } from './modals/share/share.component';
import { ListComponent } from './list/list.component';
import { ReviewsConcatComponent } from './reviews-folder/reviews-concat/reviews-concat.component';
import { ReviewsListComponent } from './reviews-folder/reviews-list/reviews-list.component';
import { ReviewContentComponent } from './reviews-folder/review-content/review-content.component';
import { ReviewElementComponent } from './reviews-folder/review-element/review-element.component';
import { AdviseBannerComponent } from './advise-banner/advise-banner.component';
import { BtnBackComponent } from './btn-back/btn-back.component';
import { ToDoListComponent } from './to-do-list/to-do-list.component';
import { ShareFollowComponent } from './modals/share-follow/share-follow.component';

@NgModule({
  declarations: [
    CardComponent,
    GridComponent,
    SearchComponent,
    LoginComponent,
    RegisterComponent,
    ModalComponent,
    AuthComponent,
    ImageComponent,
    BtnScrollTopComponent,
    ElaborationFormComponent,
    AdderContainerComponent,
    PillsComponent,
    ImgSelectComponent,
    OptSelectComponent,
    IconSelectorComponent,
    SearchIconSelectorComponent,
    IconSelectedComponent,
    RecipeFiltersComponent,
    StatusComponent,
    CropperComponent,
    QuestionComponent,
    ElaborationsTableComponent,
    RecipeFormComponent,
    LoadingComponent,
    RecipeReviewFormComponent,
    ReplyComponent,
    ReplyFormComponent,
    RepliesComponent,
    DropdownMenuComponent,
    PageGridComponent,
    FollowBtnComponent,
    NewComponent,
    RestaurantReviewFormComponent,
    ForkScoreComponent,
    MoodScoreComponent,
    CoinsComponent,
    ImgCarouselComponent,
    ButtonGroupComponent,
    PlateScoreComponent,
    RestaurantFiltersComponent,
    SearchGlobalComponent,
    ElabCarouselComponent,
    ExistComponent,
    RestaurantFormComponent,
    ForgottenComponent,
    ReportComponent,
    ShareComponent,
    ListComponent,
    ReviewsConcatComponent,
    ReviewsListComponent,
    ReviewContentComponent,
    ReviewElementComponent,
    AdviseBannerComponent,
    BtnBackComponent,
    ToDoListComponent,
    ShareFollowComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule
  ],
  exports: [
    GridComponent,
    ModalComponent,
    RegisterComponent,
    ElaborationFormComponent,
    PillsComponent,
    ImgSelectComponent,
    ElaborationsTableComponent,
    RecipeFormComponent,
    LoadingComponent,
    RecipeReviewFormComponent,
    DropdownMenuComponent,
    PageGridComponent,
    FollowBtnComponent,
    RestaurantReviewFormComponent,
    SearchGlobalComponent,
    RestaurantFormComponent,
    ReviewsConcatComponent,
    AdviseBannerComponent,
    BtnBackComponent,
    ToDoListComponent
  ]
})
export class ComponentsModule { }
