import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../models/user.model';
import { AuthService } from '../../services/backend/auth.service';
import { Router } from '@angular/router';
import { UserService } from '../../services/backend/user.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styles: [
  ]
})
export class NotificationsComponent implements OnInit {

  public user: User;
  public empty: boolean = true;

  constructor(  private authService: AuthService,
                private userService: UserService  ) {
                  this.user = authService.user;
                }

  ngOnInit(): void {    
    if ( this.user?.notifications && this.user.notifications.length >= 0) {
      this.empty = false;
      if ( this.authService.alert ) {
        this.userService.updateUsersNotifications( this.user.notifications ).subscribe( resp => {
          if ( resp.ok ) {
            this.authService.validateToken().subscribe();
            this.authService.alert = false;
          }
        });
      }
    }
  }
  
  action( key: number ) {
    
    switch( key ) {
      case 0:
        return 'ha comenzado a seguirte'
      case 1:
        return `ha añadido a favoritos tu publicación`
      case 2:
        return `ha dejado una reseña en tu publicación`
      case 3:
        return `ha comentado tu reseña en`
    }

  }

}
