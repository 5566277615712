import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styles: [
  ]
})
export class ImageComponent implements OnInit {

  public img: string | string[];
  public idx: number = 0;
  
  constructor(  public ms: ModalService  ) { }

  ngOnInit(): void {
    this.idx = this.ms.img.imgsIdx;
    const modal:any = document.getElementsByClassName('modal-box');
    modal[0].style.width = "fit-content"
    const modalBody:any = document.getElementsByClassName('modal-body');
    modalBody[0].style.overflow = "visible"
  }

  idxChange( dir: number ) {
    this.idx += dir;
    if ( this.idx < 0 ) {
      this.idx = 0 
    } else if ( this.idx > ((this.ms.img?.imgs?.length  - 1) | 0) ) {
      this.idx = ((this.ms.img?.imgs?.length - 1) | 0);
    }
  }

  
  private touchStartX: number;
  private touchStartY: number;
  private pages: string[] = [ 'recipes', 'restaurants', 'profile' ];

  @HostListener( 'document: touchstart', ['$event'] )
  touchStart( event: TouchEvent ) {
    this.touchStartX = event.touches[0].pageX;
    this.touchStartY = event.touches[0].pageY;   
  }

  @HostListener( 'document: touchend', ['$event'] )
  touchEnd( event: TouchEvent ) {
    
    const difY = this.touchStartY - event.changedTouches[0].pageY;
    if ( Math.abs( difY ) < 25 ) {
      const difX = this.touchStartX - event.changedTouches[0].pageX;
      if ( difX > 50 ) {
        this.idxChange( 1 )
      } else if ( difX < -50 ) {
        this.idxChange( -1 )
      }
    }

  }

}
