import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthService } from './auth.service';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class IngredientsService {

  private headers: object;

  constructor(  private http: HttpClient,
                private authService: AuthService  ) {
                  this.headers = authService.headers;
                }

  getIngredients() {

    return this.http.get( `${ base_url }/ingredients/`, this.headers )
    .pipe(
      tap( (resp: any) => {
        return resp;
      }),
      catchError( error => {
        console.log(error);
        return of(false)
      } )
    );

  }

  autocomplete( term: string ) {

    return this.http.get( `${ base_url }/ingredients/autocomplete/${ term }` )
    .pipe(
      tap( (resp: any) => {
        return resp;
      }),
      catchError( error => {
        console.log(error);
        return of(false)
      } )
    );
  
  }

  createIngredient( ingredient: any ) {
    
    return this.http.post( `${ base_url }/ingredients`, ingredient, this.headers )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  console.log(error);
                  return of(false)
                } )
              );

  }

  updateIngredient( ingredient: object, id: string | undefined  ) {
    
    return this.http.put( `${ base_url }/ingredients/${ id }`, ingredient, this.headers )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  console.log(error);
                  return of(false)
                } )
              );

  }

  deleteIngredient( id: string | undefined ) {
    
    return this.http.delete( `${ base_url }/ingredients/${ id }`, this.headers )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  console.log(error);
                  return of(false)
                } )
              );

  }

}
