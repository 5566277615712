import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styles: [
  ]
})
export class AuthComponent implements OnInit {

  constructor(  public modalService: ModalService  ) { }

  ngOnInit(): void {
  }

}
