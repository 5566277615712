<div class="restaurant-review" [class.mb-5]="!inRestaurant" [class.in-restaurant]="inRestaurant">
    <form (keydown.enter)="enter($event)" (ngSubmit)="saveReview()" [formGroup]="newRestaurantReviewForm" [class.mb-3]="inRestaurant && gFval('qualityprice') && gFval('recommended')" id="restaurantReviewForm">
        <div class="restaurant-review-body">

            <div *ngIf="!inRestaurant">
                <p>Restaurante*<br><small class="o-5">Comprueba que es el correcto en el mapa inferior</small></p>
                <div class="row g-31-2 no-space mb-1">
                    <div class="col">
                        <input (click)="searchOpen=true" (keyup)="searchTerm=name.value;search();geoSearch();" formControlName="name" type="text" placeholder="*Nombre del restaurante..." [class.error]="formSubmitted && !formValid && !buttonText()" id="search-restaurant" autocomplete="off"
                            #name>
                        <div class="elaboration-search-box" [class.d-none]="(searchContents?searchContents.length<=0:true) || !searchOpen">
                            <div (scroll)="scrollSearcher( searchResult )" class="elaboration-search" id="search-result" #searchResult>
                                <div (click)="externRestaurant(content)" *ngFor="let content of searchContents" class="d-flex pst-relative">
                                    <img [src]="content.img | img: 'restaurants'" [alt]="content.name" class="bd-rds-20">
                                    <div class="m-auto text-left lh-1">
                                        <p class="m-0">{{ content.name }}</p>
                                        <p class="elaboration-number mt-025"><small>{{ content.address.town + ', ' + (content.address.addressplus?(content.address.addressplus+ ', '):'')  + content.address.country }}</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img *ngIf="locked" src="../../assets/img/intern/icons/menus/lock.svg" class="lock" alt="lock">
                    </div>
                    <div class="col mxr-md-05">
                        <input (keyup)="search();geoSearch();townsFilter(town.value)" formControlName="town" type="text" placeholder="Localidad..." [class.error]="formSubmitted && !formValid && !buttonText()" list="townList" #town autocomplete="off">
                        <datalist id="townList">
                            <option *ngFor="let town of townsFil" [value]="town">{{ town }}</option>
                        </datalist>
                        <img *ngIf="locked" src="../../assets/img/intern/icons/menus/lock.svg" class="lock" alt="lock">
                    </div>
                    <div class="col w-20">
                        <select (change)="search();geoSearch()" formControlName="country" placeholder="*País o territorio...">
                            <option *ngFor="let country of countries" [value]="country.iso">{{ country.iso | iso: 'name' | titlecase }}</option>
                        </select>
                        <img *ngIf="locked" src="../../assets/img/intern/icons/menus/lock.svg" class="lock mr-05" alt="lock">
                    </div>
                    <div class="col w-100">
                        <p class="m-0 o-5">¿No es suficiente?</p>
                        <div class="pst-relative">
                            <input (keyup)="geoSearch()" formControlName="addressplus" type="text" placeholder="Calle, código postal, centro de ocio...">
                            <img *ngIf="locked" src="../../assets/img/intern/icons/menus/lock.svg" class="lock" alt="lock">
                        </div>
                    </div>
                    <div *ngIf="locked" class="col-12">
                        <p class="m-0 o-5">¿No es el emplazamiento buscado?</p>
                        <button (click)="enableForm()" type="button" class="btn-red-ol">Cambiar campos</button>
                    </div>

                </div>
                <div class="radar-box w-md-60 m-auto">
                    <div class="radar" [class.search]="showMap">
                        <iframe src="https://www.google.com/maps/embed/v1/place?key=AIzaSyApxDl_UlYmLAPCm27f4ahKC2bJC155Wp8&q=españa&zoom=4" width="100%" height="600" frameborder="0" class="map" allowfullscreen id="map">
                                </iframe>
                        <div class="intern-box" [class.d-none]="showMap">
                            <div class="cross"></div>
                            <div class="spin-box" [class.spin-radar]="radar">
                                <div class="line"></div>
                                <p class="wait" [class.d-none]="radar">Completa los campos superiores para comenzar la búsqueda</p>
                            </div>
                            <p class="wait" [class.d-none]="(!incomplete)">
                                Falta {{ (name.value.length
                                <=0)? 'el nombre': 'la localidad'}}...</p>
                                    <p class="wait" [class.d-none]="showMap || (name.value.length<=0) || (town.value.length<=0)">Buscando...</p>
                        </div>
                    </div>
                </div>
            </div>

            <p class="mt-1" id="reviewForm">¿Recomiendas este sitio?</p>
            <div class="mt-1">
                <app-mood-score [score]="recommended" [moods]="['bad', 'normal', 'good']" [buttons]="['no', 'sin más', 'sí']" (scoreEmitter)="fval('recommended', $event);initScroll();"></app-mood-score>
                <!-- <app-button-group [active]="recommended" [buttons]="['no', 'sin más', 'si']" [mandatory]="true" (emitter)="fval('recommended', $event);initScroll();"></app-button-group> -->
            </div>

            <p>¿Relación calidad precio?</p>
            <!-- ['menos', 'buena', 'excelente'] -->
            <app-mood-score [score]="gFval('qualityprice')" [buttons]="['mala', 'mejorable', 'buena', 'excelente']" (scoreEmitter)="fval('qualityprice', $event)"></app-mood-score>

            <p>¿Atención?</p>
            <app-mood-score [score]="gFval('atention')" [buttons]="['mala', 'justita', 'amable', 'impecable']" (scoreEmitter)="fval('atention', $event)"></app-mood-score>

            <p>¿Decoración?</p>
            <app-mood-score [score]="gFval('decoration')" [buttons]="['mala', 'normal', 'cuidada', 'refinada']" (scoreEmitter)="fval('decoration', $event)"></app-mood-score>
            <p *ngIf="formSubmitted && !formValid" class="error-text">Complete los campos superiores para guardar la reseña</p>

            <p class="lh-1 mb-0">Precio por comensal
                <br><small class="o-5">Aproximado</small>
            </p>
            <div class="w-md-60 d-flex m-auto js-center">
                <input formControlName="price" type="number" placeholder="Precio..." class="bd-r-rd-none fs-l h-60px w-140px text-center">
                <button (click)="coinsSelect()" type="button" class="btn-input">{{ helperService.coin }}</button>
            </div>

            <p class="text-left bd-b rd-05">Opcional</p>
            <p *ngIf="!gFval('qualityprice') || !gFval('recommended') || !gFval('atention') || !gFval('decoration') || !gFval('price')" class="o-5 mt-05 text-left"><small>Completa primero los campos esenciales</small></p>

            <div *ngIf="!inRestaurant || (gFval('qualityprice') && gFval('recommended') && gFval('atention') && gFval('decoration') && gFval('price'))" class="mb-1">

                <p>¿Es fácil aparcar?</p>
                <app-mood-score [score]="gFval('parkingscore')" [moods]="['bad', 'good']" [buttons]="['no', 'sí']" (scoreEmitter)="fval('parkingscore', $event);initScroll();"></app-mood-score>
                <!-- <app-button-group [active]="gFval('parkingscore')" (emitter)="fval('parkingscore', $event)"></app-button-group> -->

                <p>¿Es necesaria la Reserva?</p>
                <app-button-group [active]="gFval('bookingscore')" [buttons]="['no', 'día anterior', 'varios días']" (emitter)="fval('bookingscore', $event)"></app-button-group>

                <p>¿Tamaño de la ración?</p>
                <app-button-group [active]="gFval('foodsize')" [buttons]="['pequeña', 'moderada', 'grande']" (emitter)="fval('foodsize', $event)"></app-button-group>

                <p>Ocasiones recomendadas</p>
                <app-icon-selector [options]="ocassions" [type]="'ocassions'" [oldOptSelected]="ocassionsSel" [grid]="'643'" (selectedEmit)="ocassionsSel=$event;fval('ocassions', $event);"></app-icon-selector>

                <p>Reseña de platos</p>
                <app-plate-score [error]="!plateValid" [score]="0" (plateEmitter)="fval('plate', $event.name);fval('platescore', $event.score)"></app-plate-score>
                <p *ngIf="!plateValid" [class.error-text]="!plateValid">Escriba el nombre del plato y valórelo para añadirlo</p>
                <div class="d-flex js-center my-1">
                    <hr class="hr-decor">
                    <button (click)="plateAdd()" type="button" class="btn-add"><span class="m-auto">+</span></button>
                    <hr class="hr-decor">
                </div>
                <div *ngFor="let plate of plateArr, let i=index" class="pst-relative">
                    <app-plate-score [name]="plate.name" [score]="plate.score" [del]="true" (plateEmitter)="plateArr[i]=$event" (delEmitter)="plateDel(i)"></app-plate-score>
                </div>

                <p>Imágenes</p>
                <app-img-select [multiple]="true" (imgEmit)="addImg($event)"></app-img-select>
                <div class="mt-1">
                    <app-img-carousel [imagesTemp]="imgArr" [images]="imgArrToUp" [name]="'img'" (imagesEmitter)="imgCar($event)"></app-img-carousel>
                </div>

                <p>Observaciones</p>
                <textarea formControlName="observations" placeholder="Añadir observaciones..." cols="30" rows="10" id="restaurantReviewObs"></textarea>

                <p>Etiquetas</p>
                <app-pills [pills]="tags" (pillsEmit)="tags=$event"></app-pills>

            </div>
        </div>

        <div class="bd-t review-sc">
            <p *ngIf="formSubmitted && !formValid && !buttonText()" class="error-text mt-0">Campos erróneos, compruebe el formulario</p>
            <div class="d-flex mt-1">
                <button type="submit" class="btn-red m-auto" [disabled]="!buttonText()">Guardar {{ buttonText() || '' }}</button>
            </div>
        </div>

    </form>
</div>