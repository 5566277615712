import { Component, OnInit, HostListener } from '@angular/core';
import { HelperService } from '../../services/helper.service';
import { ModalService } from '../../services/modal.service';
import { UploadService } from '../../services/backend/upload.service';
import { AuthService } from '../../services/backend/auth.service';

@Component({
    selector: 'app-cropper',
    templateUrl: './cropper.component.html',
    styles: [
    ]
})
export class CropperComponent implements OnInit {
    
    public tempImg: any;
    public preview: any;

    public cropComponent: any;
    public container: any;
    public crop_img: any;
    public image_target: any;
    public image_show: any;
    public event_state: any = {};
    public keyZoomValue: any = 4.0;
    public MINWIDTH: number = 100;
    public MINHEIGHT: number = 100;
    public CROPWIDTH: number = 200;
    public CROPHEIGHT: number = 200;
    public cropLeft: number = 0;
    public cropTop: number = 0;
    public cropperWidth: number = 0;
    public cropperHeight: number = 0;
    public resize_canvas: any = null;
    public lastHypo: any = 0;
    public doCrop: boolean = true;
    public imgLeft: number = 0;
    public imgTop: number = 0;
    public maxW: number = 0;
    public maxH: number = 0;
    public SAVEWIDTH: number;
    public SAVEHEIGHT: number;
    public imgInitWidth: number;
    public imgInitHeight: number;
    public imgInitLeft: number;
    public imgInitTop: number;
    public ratio: number;
    public zoomSum: number = 1;
    public isMoving: boolean;

    constructor(    private helperService: HelperService,
                    private uploadService: UploadService,
                    private ms: ModalService  ) { }

    // Modificación sobre https://codepen.io/qertis/pen/RNPXee
    ngOnInit() {

        const interval = setInterval( () => {
            if ( !this.tempImg ) {
                if ( this.helperService.imgType === 'elaborations' ) {
                    this.tempImg = this.helperService.tempImgElab;
                } else {
                    this.tempImg = this.helperService.tempImg;
                }
            }
            if ( this.tempImg ) {
                clearInterval( interval );
                let i = 0;
                const subInterval = setInterval( () => {
                    this.preview = document.getElementById( 'preview' );
                    if ( this.preview ) {
                        clearInterval( subInterval );
                        this.cropInit();
                    } else if ( i > 200 ) {
                        clearInterval( subInterval );
                        this.ms.setStatus( 'error', 'Error al cargar la imagen' );
                    } else {
                        i++;
                    }
                }, 100 );
            }
        }, 100 );
        
    }

    cropInit() {

        this.image_target = document.querySelector('.crop-image');
        var profile_img = 1;
        if ( window.location.href.includes('profile') ) {
            profile_img = 2;
        }

        this.SAVEWIDTH = 800 / profile_img;
        this.SAVEHEIGHT = 800 / profile_img;
        this.ratio = this.SAVEWIDTH / this.CROPWIDTH;

        if (this.image_target.complete) {
            this.init();
        } else {
            this.image_target.onload = function () {
                this.init();
            };
        }

    }

    init() {

        this.image_target.dataset.isCrop = 'true';
        this.image_target.classList.add('crop-blur');
        this.image_target.draggable = false;

        this.crop_img = new Image();
        this.crop_img.crossOrigin = this.image_target.crossOrigin;  
        this.crop_img.src = this.image_target.src;
        this.crop_img.draggable = false;

        this.resize_canvas = document.createElement('canvas');
        
        this.cropComponent = document.getElementsByClassName('crop-component')[0];
        this.container = document.getElementsByClassName('overlay')[0];

        this.cropComponent.appendChild(this.container);

        let wraper = this.image_target.parentNode;
        wraper.appendChild(this.cropComponent);

        this.cropComponent.appendChild(this.crop_img);
        this.cropComponent.appendChild(this.image_target);
        this.container.appendChild(this.crop_img);

        this.maxW = this.cropComponent.clientWidth;
        this.maxH = this.cropComponent.clientHeight;

        this.CROPWIDTH = this.maxW * 0.75;
        this.CROPHEIGHT = this.maxH * 0.75;


        this.container.style.width = this.CROPWIDTH + 'px';
        this.container.style.height = this.CROPHEIGHT + 'px';
        this.container.style.marginLeft = - this.CROPWIDTH/2 + 'px';
        this.container.style.marginTop = - this.CROPHEIGHT/2 + 'px';

        const imgW = this.image_target.clientWidth;
        const imgH = this.image_target.clientHeight;
        if ( imgW >= imgH ) {
            this.image_target.style.width = this.maxW + 'px';
            this.crop_img.style.width = this.maxW + 'px';
        } else {
            this.image_target.style.height = this.maxH + 'px';
            this.crop_img.style.height = this.maxH + 'px';
        }
        
        this.image_target.style.position = 'absolute';
        this.imgLeft = (this.maxW - this.image_target.clientWidth) / 2;
        this.imgTop = (this.maxH - this.image_target.clientHeight) / 2;
        
        this.image_target.style.left = this.imgLeft + 'px';
        this.image_target.style.top = this.imgTop + 'px';
        
        this.imgInitWidth = this.crop_img.clientWidth;
        this.imgInitHeight = this.crop_img.clientHeight;

        this.crop_img.style.left = (this.CROPWIDTH - this.imgInitWidth)/2 + 'px';
        this.crop_img.style.top = (this.CROPHEIGHT - this.imgInitHeight)/2 + 'px';
        this.imgInitLeft = this.crop_img.offsetLeft;
        this.imgInitTop = this.crop_img.offsetTop;
        
        this.reset();

    }

    startMoving( e: any ) {
        e.preventDefault();
        e.stopPropagation();

        this.isMoving = true;
        this.saveEventState(e);
    }

    moving( e: any ) {

        if ( !this.isMoving ) return;
        if ( !this.cropComponent.contains( e.target ) ) return this.endMoving();
        
        if ( e.targetTouches?.length === 2 ) {
            let hypo = Math.hypot((e.targetTouches[0].pageX - e.targetTouches[1].pageX),
            (e.targetTouches[0].pageY - e.targetTouches[1].pageY));
            
            this.imgZoom( ((hypo > this.lastHypo) ? 1 : -1), true )
            this.lastHypo = hypo;
            return;
        }
        
        let curuntTouch: any = {}
        curuntTouch.x = e.pageX || e.touches && e.touches[0].pageX;
        curuntTouch.y = e.pageY || e.touches && e.touches[0].pageY;

        const left = curuntTouch.x - (this.event_state.mouse_x - this.event_state.container_left);
        const top = curuntTouch.y - (this.event_state.mouse_y - this.event_state.container_top);

        this.updateImg( left, top );

    }

    endMoving() {
        if ( !this.isMoving ) return;
        this.isMoving = false;
        this.openCropCanvasImg();
    }

    // Save the initial event details and container state
    saveEventState( e: any ) {
        this.event_state.container_left = this.crop_img.offsetLeft;
        this.event_state.container_top = this.crop_img.offsetTop;

        this.event_state.mouse_x = (e.clientX || e.pageX || e.touches && e.touches[0].clientX) + window.scrollX;
        this.event_state.mouse_y = (e.clientY || e.pageY || e.touches && e.touches[0].clientY) + window.scrollY;
    }

    resizing( e: any ) {
        e.preventDefault();
        this.imgZoom(e.deltaY > 0 ? 1 : -1);
    }

    imgZoom( zoom: any, touchZoom?: boolean ) {

        this.zoomSum += zoom * 0.05;
        
        if ( this.zoomSum < 0.25 ) return this.zoomSum = 0.25;

        const  newWidth = Math.floor( this.container.clientWidth + zoom )
        this.ratio = this.SAVEWIDTH / newWidth;

        const w = this.imgInitWidth * this.zoomSum;
        const h = this.imgInitHeight * this.zoomSum;
        
        const left = this.crop_img.offsetLeft - this.imgInitWidth * zoom * 0.025;
        const top = this.crop_img.offsetTop - this.imgInitHeight * zoom * 0.025;

        this.updateImgSize( w, h );
        this.updateImg( left, top );
        
        if ( !touchZoom ) this.openCropCanvasImg();

    }

    updateImgSize( width: any, height: any ) {
        this.crop_img.style.width = width + 'px';
        this.crop_img.style.height = height + 'px';
        this.image_target.style.width = width + 'px';
        this.image_target.style.height = height + 'px';
    }

    updateImg( left: any, top: any ) {
        this.cropLeft = left * this.ratio;
        this.cropTop = top * this.ratio;

        this.crop_img.style.top = top + 'px';
        this.crop_img.style.left = left + 'px';
        const dif = this.maxW * 0.25;
        this.image_target.style.top = top + dif/2 + 'px';
        this.image_target.style.left = left + dif/2 + 'px';
    }
    
    // rotate() {
    //     this.crop_img.style.transform = 'rotate(45deg)';
    //     this.image_target.style.transform = 'rotate(45deg)';
    // }

    reset() {
        this.cropComponent.classList.add('zoom');
        this.ratio = this.SAVEWIDTH / this.CROPWIDTH;
        this.zoomSum = 1;

        this.updateImg( this.imgInitLeft, this.imgInitTop );
        this.updateImgSize( this.imgInitWidth, this.imgInitHeight );

        this.openCropCanvasImg();
    }

    openCropCanvasImg() {
        
        this.crop();
                
        try {
            const base64Img = this.resize_canvas.toDataURL('image/jpeg', 0.75);
            const croppedImg = this.resize_canvas.toBlob( (blob: Blob) => {
                return blob;
            }, 'image/jpeg', 0.75 );      
            const preview = document.getElementById('preview');
            preview?.setAttribute('src', base64Img);
        } catch( error: any ) {
            console.log(error);
        }

    }
    
    crop() {         

        this.cropperWidth = this.crop_img.width * this.ratio;
        this.cropperHeight = this.crop_img.height * this.ratio;
        
        this.resize_canvas.width = this.SAVEWIDTH;
        this.resize_canvas.height = this.SAVEHEIGHT;
        
        var ctx = this.resize_canvas.getContext('2d');
        ctx.fillStyle = '#fff';  /// set white fill style
        ctx.fillRect(0, 0, this.resize_canvas.width, this.resize_canvas.height);
        ctx.drawImage( this.crop_img,
            this.cropLeft, this.cropTop,
            this.cropperWidth, this.cropperHeight );

    }

    close() {

        if ( this.preview.getAttribute('src') && this.ms.showModal && this.ms.type === 'crop' ) {
            const img = this.preview.getAttribute('src');
            if ( this.helperService.imgType === 'elaborations' ) {
                this.helperService.setImgElab( img );
            } else {
                this.helperService.setImg( img );
            }
        }

        if ( this.ms.imgEdit.imgEdit ) {
            this.updateService();
            return this.ms.openModal( 'image' );
        }
        this.ms.closeModal();

    }

    updateService() {

        this.uploadService.updateImg( this.base64ToFile( this.helperService.tempImg ), this.ms.img.imgType, this.ms.imgEdit.imgEditId )
            .then( resp => {
            if ( resp.ok ) {
                this.ms.closeModal();
                return location.reload();
            }
            this.ms.setStatus( 'error', resp.msg );
            }).catch( error => {
            console.log(error);
            this.ms.setStatus( 'error', error.error.msg );
            });
    
    }

    base64ToFile( base64Image: string ): Blob {
        const split = base64Image.split( ',' );
        const type = split[0].replace( 'data:', '' ).replace( ';base64', '' );
        const byteString = atob( split[1] );
        const ab = new ArrayBuffer( byteString.length );
        const ia = new Uint8Array( ab );
        for ( let i = 0; i < byteString.length; i += 1 ) {
            ia[i] = byteString.charCodeAt( i );
        }
        return new Blob( [ab], {type} );
    }

    @HostListener( 'document: keypress', ['$event'] )
    keyPress( event: { target: any; } ) {
        this.keyHandler( event );
    }
    
    keyHandler( e: any ) {
        e.preventDefault();

        switch ( String.fromCharCode( e.charCode ) ) {
            case '+' :
                this.imgZoom( this.keyZoomValue );
                break;
            case '-' :
                this.imgZoom( -this.keyZoomValue );
                break;
        }
    }

}
