<hr *ngIf="(reviewsCount>0 && !modal) || (!owner && !reviewed && !modal)" class="mt-2">

<div *ngIf="(!owner && !reviewed) || (admin && !reviewed)" class="mb-1 pst-relative">
    <div class="m-auto text-center">
        <p class="mb-1">{{model==='Recipe'?'¿Lo has cocinado?':'¿Lo has visitado?'}}<br><b>¡Deja una reseña!</b></p>
        <button (click)="addReview=true" class="btn-red">Añadir Reseña</button>
    </div>
</div>

<div *ngIf="addReview" class="edit-review">
    <div class="modal">
        <div class="modal-box mxh-95vh">
            <img (click)="addReview=false;" src="assets/img/intern/icons/menus/close.svg" alt="close" class="modal-close">
            <div class="modal-title bd-b">
                <p class="title">Crear Reseña</p>
            </div>
            <div class="m-auto">
                <app-restaurant-review-form *ngIf="model==='Restaurant'" [restaurant]="content" [inRestaurant]="true" (reviewEmit)="reloadReviews($event)" (editEmit)="addReview=$event;"></app-restaurant-review-form>
                <app-recipe-review-form *ngIf="model==='Recipe'" [id]="id" [model]="model" (reviewEmit)="reloadReviews($event);addReview=false;"></app-recipe-review-form>
            </div>
        </div>
    </div>
</div>

<!-- (scoreEmit)="scoreChange($event);" -->
<app-reviews-list [content]="content" [id]="id" [model]="model==='Restaurant'?'RestaurantReview':'RecipeReview'" (reviewsCount)="reviewsCount=$event;reviewsCountEmit.emit($event);" (userReviewEmit)="reviewed=$event;"></app-reviews-list>