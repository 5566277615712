import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../../../services/modal.service';
import { HelperService } from '../../../services/helper.service';
import { ValidationService } from '../../../services/validation.service';
import { VariablesService } from '../../../services/variables.service';
import { filter } from 'rxjs/operators';
import { TextFinderService } from '../../../services/text-finder.service';
import { IngredientsService } from '../../../services/backend/ingredients.service';

@Component({
  selector: 'app-adder-container',
  templateUrl: './adder-container.component.html',
  styles: [
  ]
})
export class AdderContainerComponent implements OnInit {

  @Input() invalid: any = {};
  @Input() content: any = {};
  @Output() contentEmitter: EventEmitter< any > = new EventEmitter()

  public formSubmitted: any = {
    ingredients: false,
    steps: false
  };
  public contentForm: any = {
    ingredients: {},
    steps: {}
  };
  public contentArr: any = {
    ingredients: [],
    steps: []
  };
  public validFields: any[] = ['ingredient', 'quantity', 'units'];
  public foodFamily: string[] = ['unknown'];
  public mesures: string[];
  private ingToFind: string[] = [];
  public ingredientsAutocomplete: any[];
  public autocompleteIdx: number = -1;
  public autocompleteShow: boolean = false;

  constructor(  private fb: FormBuilder,
                private modalService: ModalService,
                public helperService: HelperService,
                private validationService: ValidationService,
                private textFinderService: TextFinderService,
                private ingredientsService: IngredientsService,
                private vs: VariablesService  ) {
                  this.mesures = vs.mesures;
                }

  ngOnInit(): void {
    
    if ( this.content ) {
      this.contentArr = this.content;      
    }

    this.contentForm.ingredients = this.fb.group({
        ingredient: [ '',  Validators.required ],
        quantity: [ '',  [Validators.required, Validators.min( 0.001 )] ],
        units: [ this.mesures[1], Validators.required ],
        foodFamily: [ this.foodFamily, Validators.required ]
      }, {
        validators: [ this.validationService.insideArr( 'units', this.mesures ) ]
      });
    
    this.contentForm.steps = this.fb.group({
        step: [ '',  Validators.required ],
        // time: [ '' ],
      });

  }

  ngDoCheck() {
    
    if ( !this.modalService.showModal && this.helperService.helperChanges ) {
      this.foodFamily = this.helperService.foodFamily;
      this.contentForm.ingredients.controls['foodFamily'].setValue(this.foodFamily);
      this.helperService.noChanges();
    }

  }

  add( type: string ) {
      
      const CF = this.contentForm;
      const CFT = CF[type];
      this.formSubmitted[type] = true;
      
      if ( this.mesures.indexOf( CFT.controls['units']?.value ) === 0 ) {
        CFT.controls['quantity']?.setValue( 1 );
      }
      
      if ( CFT?.valid ) {
        if ( type === 'ingredients' ) {
          const repeatIng = this.contentArr[type].filter( (cont: any) => {
            return cont.ingredient === CFT.controls['ingredient']?.value;
          } );
          if ( repeatIng.length > 0 ) {
            this.modalService.setStatus( 'warning', '¡El ingrediente introducido está repetido!<br>Si ha sido un error puede eliminarlo de la lista inferior' )
          }
        }
        
        this.contentForm[type].controls['units']?.setValue( this.mesures.indexOf( CFT.controls['units']?.value ) );
        this.contentArr[type].push( CFT.value );        
        this.contentForm[type].reset({
          units: this.mesures[ CF.ingredients.get('units')?.value ],
          foodFamily: ['unknown']
        });
        // localStorage.setItem( type, this.contentArr[type].toString() );
        this.formSubmitted[type] = false;
      
        this.contentEmitter.emit( this.contentArr );
      }

  }
  
  remove( type: string, i: number ) {
    this.contentArr[type].splice(i, 1);
    this.contentEmitter.emit( this.contentArr );
  }

  invalidField( form: FormGroup, type: string, field: string ) {
    return this.validationService.invalidField( form, field, this.formSubmitted[type] );
  }

  change( type: string, i: number ) {
    
    if ( type === 'ingredients' ) {
      for ( let field of this.validFields ) {
        this.changeValidation( type, field , i );    
      }
    } else {
      const time: any = document.getElementById( 'time' + i );
      if ( time ) {
        this.contentArr[type][i].time = time.value;
      }
      this.changeValidation( type, 'step', i );
    }
    

    this.contentEmitter.emit( this.contentArr );

  }

  changeValidation( type: string, item: 'step' | 'ingredient' | 'quantity' | 'units', i: number ) {
    
    // No required fields Empty
    const field: any = document.getElementById( item + i );
    const id: string = type + 'ShowAdvise';
    
    
    if ( !field ) { 
      return;
    }
    
    if ( field.value.length > 0 && item === 'quantity' && field.value >= 0.001 && field.value.indexOf('-') <= 0 ) {
       
      this.contentArr[type][i][item] = field.value;

    } else if( field.value.length <= 0 || ( item === 'quantity' && field.value < 0.001 ) || ( item === 'quantity' && field.value.indexOf('-') >= 0 ) ) {
      
      this.advise( id + i );
      if ( !field.classList.contains( 'error' ) ) {
        field.classList.add( 'error' );
        setTimeout( () => {
          field.classList.remove( 'error' );
        }, 2000 )
      }

      field.value = this.contentArr[type][i][item];

    } else if ( item === 'units' ) {
      
      this.contentArr[type][i][item] = this.mesures.indexOf( field.value );
      if ( this.contentArr[type][i][item] === ( this.mesures.length - 1 ) ) {
        this.contentArr[type][i].quantity = 1;
      }

    } else {

      this.contentArr[type][i][item] = field.value;

    }    

  }

  advise( id: string ) {

    const advise = document.getElementById( id );
    if ( advise && advise.style.opacity !== '1' ) {
      advise.style.opacity = '1';
      advise.style.visibility = 'visible'
      setTimeout( () => {
        advise.style.opacity = '0';
        advise.style.visibility = 'hidden'
      }, 2000 )
    }

  }
  
  openModal( type: 'login' | 'register' | 'image' | 'tools' | 'ingredients' ) {
    this.helperService.setOptionSelection( { tools: [], ingredients: [ this.foodFamily ] } );
    this.modalService.openModal( type );
  }

  negative( id: string, i: any = '' ) {
    const negative: any = document.getElementById( id + i );
    return negative?.value !== '' && negative?.value < 0.001;
  }

  posChange( idx: number, type: 'ingredients' | 'steps', dir: number ) {
    
    let copy;
    let arr;
    const changer = idx + dir;
  
    if ( type === 'ingredients' ) {
      arr = this.contentArr.ingredients;
      if ( changer < 0 || changer > arr.length ) {
        return;
      }
      copy = arr[ idx ];
      arr.splice( idx, 1 );
      arr.splice( changer, 0, copy );
    } else if ( type === 'steps' ) {
      arr = this.contentArr.steps;
      if ( changer < 0 || changer > arr.length ) {
        return;
      }
      copy = arr[ idx ];
      arr.splice( idx, 1 );
      arr.splice( changer, 0, copy );
    }

    
    const lastSel: any = document.querySelectorAll('.selected')[0];
    if ( lastSel ) {
      lastSel.classList.remove('selected');
    }
    const changeSel: any = document.querySelectorAll('.changed')[0];
    if ( changeSel ) {
      changeSel.classList.remove('changed');
    }
    
    const newSel: any = document.querySelectorAll('.item.'+type)[idx];
    const newChangeSel: any = document.querySelectorAll('.item.'+type)[idx + dir];
    
    if ( newSel && newChangeSel ) {
      newSel.classList.add('selected');
      newChangeSel.classList.add('changed');
      setTimeout( () => {
        newSel.classList.remove('selected');
        newChangeSel.classList.remove('changed');
      }, 500 );
    }
    
  }
  
}
