<div class="card">
    <div class="d-flex">
        <img [src]="content.img | img: 'users'" [alt]="content.name + ' img'" class="icon lg bd-bg-round" onerror="this.onerror=null;this.src='/assets/img/intern/no-img.svg'">
        <div class="my-auto ml-05">
            <p>{{ content.name }}</p>
            <p class="o-5"><small>{{ '@' + content.alias }}</small></p>
        </div>
        <p (click)="collapsed=!collapsed;getReviews();" class="enf cr-pointer m-auto mr-0">Ver restaurantes</p>
    </div>
    <div [class.d-none]="collapsed">
        <div *ngFor="let review of reviews" class="my-1">
            <div class="d-flex">
                <!-- <p class="m-auto mr-0"><small>{{ review.updatedAt | date: 'dd/MM/yyyy' }}</small></p> -->
                <p class="d-flex mb-025">
                    <img src="/assets/img/intern/icons/menus/geo.svg" class="icon xsm geo ml-0" alt="geo img">
                    <small class="lh-1 m-auto">{{ review.restaurant.address.town | titlecase }}</small>
                </p>
                <p class="cl-main m-auto mr-0">{{ ((distance(review.restaurant.address?.coord?.coordinates) > 1) ? (distance(review.restaurant.address?.coord?.coordinates) + ' km') : ((distance(review.restaurant.address?.coord?.coordinates) * 100) + 'm')) }}</p>
            </div>
            <div class="d-flex">
                <img [src]="review.restaurant.img | img: 'restaurants'" [alt]="review.restaurant.name + ' img'" class="icon lg bd-rds-20 bd-red">
                <div class="my-auto ml-05 lh-1">
                    <p>{{ review.restaurant.name }}</p>
                    <p><small class="o-5">{{ (review.restaurant.category | toStr:'restCategories') | Esp | titlecase }}</small></p>
                    <p><small *ngIf="review.restaurant.specialities.length>0" class="in-category mb-025"><span *ngFor="let speciality of review.restaurant.specialities, let i=index"><span *ngIf="i>0"> {{ (i===(review.restaurant.specialities.length-1))?'y':',' }} </span>{{ speciality | toStr: 'specialities' | Esp | titlecase}}</span></small></p>
                </div>
                <div class="m-auto mr-0 lh-1">
                    <p [class]="'cl-'+recommendationColor[review.recommended - 1]">{{ recommendation[review.recommended - 1] | uppercase }}</p>
                    <div *ngIf="review?.qualityprice" class="col text-center m-0">
                        <p class="lh-1 o-5"><small>Calidad Precio: </small></p>
                        <img [src]="'/assets/img/intern/icons/menus/'+mood[(review.qualityprice || 1) - 1]+'.svg'" [alt]="mood[review.qualityprice || 0]" class="icon sm my-auto">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>