import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Recipe } from '../../models/recipe.model';
import { RecipesService } from '../../services/backend/recipes.service';
import { RecipeReviewsService } from '../../services/backend/recipe-reviews.service';
import { ModalService } from '../../services/modal.service';
import { SearchComponent } from '../search-folder/search/search.component';
import { CacheService } from '../../services/cache.service';
import { RestaurantReviewsService } from '../../services/backend/restaurant-reviews.service';
import { RestaurantsService } from '../../services/backend/restaurants.service';
import { HelperService } from '../../services/helper.service';
import { UserService } from '../../services/backend/user.service';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styles: [
  ]
})
export class GridComponent implements OnInit {

  @ViewChild( SearchComponent) searchComponent: SearchComponent;

  @Input() id: string;
  @Input() type: 'recipes' | 'restaurants';
  @Input() contentType: '' | 'reviews' | 'favs' | 'later' | 'lists' = '';
  @Input() friends: boolean = false;
  @Input() mainProfile: boolean = false;

  public contents: Recipe[] | any = [];
  public fullContents: Recipe[] | any = [];
  public searchContent: Recipe[] | any = [];
  public scrollDown: boolean = true;
  public loading: boolean = false;
  public searchingLoad: boolean = false;
  public searching: boolean = false;
  public double: boolean = false;
  public service: RecipesService | RecipeReviewsService | RestaurantsService | RestaurantReviewsService;
  public from: number = 0;
  public limit: number = 12;
  public lastMax: number = 0;
  public lastPos: number = 0;
  public pos: number = 0;
  public empty: boolean = false;
  public more: boolean = true;
  private grid: any;
  private cacheType: string;
  private location: any;
  public changing: boolean = false;
  private cache: any;

  constructor(  private modalService: ModalService,
                private recipesService: RecipesService,
                private restaurantsService: RestaurantsService,
                private helperService: HelperService,
                private userService: UserService,
                private cs: CacheService,
                private recipeReviewsService: RecipeReviewsService,
                private restaurantReviewsService: RestaurantReviewsService  ) { }

  async ngOnInit() {

    this.loading = true;
    this.double = (this.contentType === 'reviews' ? true : false);
    
    this.grid = document.getElementById('grid');

    const gridHeight = window.innerHeight - 197,
          windowWidth = window.innerWidth;
          // rowsPreload = 1.25;
    let cardHeight = 0,
        columns = 0,
        imgHeight = 0;

    const auxType = (this.contentType==='favs' || this.contentType==='later' || this.contentType==='lists') ? '' : this.contentType;
    
    switch( auxType ) {
      case '':
        if ( windowWidth > 992 ) {          
          cardHeight = (Math.min(windowWidth * 0.85, 1000) / 4 - 32) * 5/7 + 180;
          columns = 4;
        } else if ( windowWidth > 768 ) {
          cardHeight = (windowWidth * 0.9 / 3 - 32) * 5/7 + 180;
          columns = 3;
        } else if ( windowWidth > 576 ) {
          cardHeight = (windowWidth * 0.95 / 2 - 32) * 5/7 + 180;
          columns = 2;
        } else {
          cardHeight = (windowWidth * 1 / 1 - 32) * 5/7 + 180;
          columns = 1;
        }
        if ( this.type === 'restaurants') {
          this.service = this.restaurantsService;
        } else {
          this.service = this.recipesService;
        }
        break;
      case 'reviews':
          cardHeight = 206.5;
          columns = 1;
        if ( this.type === 'restaurants') {
          this.service = this.restaurantReviewsService;
        } else {
          this.service = this.recipeReviewsService;
        }
        break;
      default:
        this.modalService.setStatus('error', 'Tipo no válido, recargue la página o contacte con FoodiesHome si el proble persiste');
        break;
    }
    // * rowsPreload
    this.limit = Math.max( Math.floor( Math.floor( (gridHeight / cardHeight) + 1  ) * columns), 2 * columns );

    //GeoLocation
    this.cacheType =  this.type + this.contentType + (this.id?'ById':'') + (this.friends?'Friends':'');
    this.cache = this.cs.getCacheContent( this.cacheType );
    if ( (new Date( this.cache?.date ).getTime() <= (new Date().getTime() - (60000 * 30))) ) this.helperService.setLocAccepted(false);
    
    if ( !this.helperService.locAccepted ) {
      navigator.geolocation.getCurrentPosition( pos => {
        this.location = pos.coords || 'none';
        if ( !this.helperService.locAccepted ) this.reset();
        this.helperService.setLocAccepted(true, pos.coords.latitude, pos.coords.longitude);
        return this.loadCacheContent();
      }, error => {
        this.location = 'none';
        this.helperService.setLocAccepted(false);
        return this.loadCacheContent();
      });
      setTimeout( () => {
        if ( !this.location ) this.loadCacheContent();
      }, 1000 );
    } else if ( this.helperService.locAccepted ) {
      return this.loadCacheContent();
    }


  }

  loadCacheContent() {
    
    if ( !this.mainProfile && window.location.pathname.includes( 'profile' ) ) return this.loadContent();
    const cache = this.cache;
    
    if ( cache?.array?.length > 0 && (new Date( cache?.date ).getTime() > (new Date().getTime() - (60000 * 30))) ) {

      this.fullContents = cache.array;
      this.contents = this.fullContents;
      this.from = cache.from;
      this.lastMax = cache.max;
      this.lastPos = cache.position;
      this.empty = cache.empty;
      
      this.loading = false;
      const interval = setInterval( () => {
        if ( this.grid.scrollTop >= ( cache.position - 50 ) ) {
          clearInterval( interval );
        } else {
          this.grid.scroll( 0, cache.position );
        }
      })
      
      this.changing = false;

    } else {
      
      if ( cache?.array?.length > 0 ) {
        this.cs.cacheReset( this.cacheType );
        this.reset();
      }

      this.loadContent();

    }

  }

  loadContent() {

    this.loading = true;

    const loc = (!this.location || this.location === 'none')?false:[ this.location?.latitude, this.location?.longitude ];

    if ( this.id ) {
      if ( this.contentType === 'favs' ) {
        if ( this.service === this.recipesService || this.service === this.restaurantsService ) {
          this.service.getFavs( this.id, this.from, this.limit, loc ).subscribe( resp => this.contentFill( resp ) );
          return;
        }
      } else if ( this.contentType === 'later' ) {
        this.userService.getLater( this.type, this.from, this.limit ).subscribe( resp => this.contentFill( resp ) );
        return;
      // } else if ( this.contentType === 'lists' ) {
      //   if ( this.service === this.recipesService || this.service === this.restaurantsService ) {
      //     this.service.getLists( this.from, this.limit, this.friends, loc ).subscribe( resp => this.contentFill( resp ) );
      //     return;
      //   }
      }

      this.service.getFilterId( this.id, this.from, this.limit, loc ).subscribe( resp => this.contentFill( resp ) );
      return;
    }

    // if ( this.contentType === 'lists' ) {
    //   if ( this.service === this.recipesService || this.service === this.restaurantsService ) {
    //     this.service.getLists( this.from, this.limit, this.friends, loc ).subscribe( resp => this.contentFill( resp ) );
    //     return;
    //   }
    // }
    this.service.get( this.from, this.limit, this.friends, loc ).subscribe( resp => this.contentFill( resp ) );

  }

  contentFill( resp: any ) {
    
    const auxType = (this.contentType==='reviews' ? 'reviews' : (this.contentType==='lists' ? 'users' : this.type));
  
    if ( resp.ok && resp[ auxType ]?.length > 0 ) {
      this.fullContents.push( ...resp[ auxType ] );
      this.contents = this.fullContents;
      this.from += this.limit;      
    }
    this.empty = (this.contents.length <= 0);    
    this.loading = false;

    if ( this.mainProfile || !window.location.pathname.includes( 'profile' ) ) this.cs.setCacheContent( this.fullContents, this.grid.scrollTop, this.from, this.lastMax, this.cacheType, this.empty );
    
    this.changing = false;

  }

  search( event: any ) {
    
    this.searchingLoad = false;
    this.searching = true;
    
    if ( event.ok ) {
      this.more = event?.more;
      if (  (event?.contents?.length > 0) ){
        return this.contents = event.contents;
        // if ( event?.more ) return this.contents = event.contents;
        // return;
      }
    }
    if ( event.ok || this.searchComponent.searchTerm?.length > 0 ) {
      this.empty = true;
      return this.contents = [];
    }
    this.searching = false;
    this.contents = this.fullContents;    
    this.loading = false;
    this.empty = false;

  }

  contentsCheck() {
    if ( this.contents?.length > 0 || this.contents && !this.loading) return true;
    return false;
  }

  onScroll( event: any ) {
    
    let pos = 0;
    if ( this.grid ) {
      pos = this.grid.scrollTop;
      if ( (event.target.scrollTop - this.pos) > 0 && window.scrollY === 0 ) window.scrollTo({top:250, behavior: 'smooth'});
      this.pos = event.target.scrollTop;
    }

    const max = this.grid?.scrollHeight || 0;
    
    if ( (pos + (window.innerHeight * 1.5)) >= max && !this.loading && max > this.lastMax && !this.empty ) {
      this.lastMax = max;
            
      if ( this.searching ) {
        this.searchComponent.search( true );     
      } else {
        this.loadContent();
      }
    }

    if ( pos > ( this.lastPos + 75 ) || pos < ( this.lastPos - 75 ) || pos <= 0) {
      this.lastPos = pos;
      if ( !this.changing ) if ( this.mainProfile || !window.location.pathname.includes( 'profile' ) ) this.cs.setCacheContent( this.fullContents, this.grid.scrollTop, this.from, this.lastMax, this.cacheType, this.empty );
    }
    
  }

  reset() {
    this.fullContents = [];
    this.contents = [];
    this.from = 0;
    this.lastMax = 0;
    this.lastPos = 0;
    this.empty = false;
  }

  @HostListener( 'document: keydown', ['$event'] )
  keyDown( event: any ) {
    if ( event.key === 'ArrowDown' ) {
      this.grid?.scroll( { top: (this.grid.scrollTop + 20) } );
    } else if (event.key === "ArrowUp" && this.grid.scrollTop > 0 ) {
      event.preventDefault();
      this.grid?.scroll( { top: (this.grid.scrollTop - 20) } );
    }
  }

}
