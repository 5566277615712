<div class="modal-md px-1">
    <form (ngSubmit)="report()" [formGroup]="contactForm" class="w-100">
        <p>Contenido</p>
        <button (click)="reason='r1';resetInput();" type="button" class="btn-red-ol my-025 w-100" [class.bg-aux]="reason==='r1'">Sexual, Violento, Ilegal...</button>
        <button (click)="reason='r2';resetInput();" type="button" class="btn-red-ol my-025 w-100" [class.bg-aux]="reason==='r2'">Spam, Publicidad, Fraude...</button>
        <button (click)="reason='r3';resetInput();" type="button" class="btn-red-ol my-025 w-100" [class.bg-aux]="reason==='r3'">Infracción de Propiedad Intelectual</button>
        <button (click)="reason='r4';resetInput();" type="button" class="btn-red-ol my-025 w-100" [class.bg-aux]="reason==='r4'">Ya no existe</button>
        <button (click)="reason='r5';resetInput();" type="button" class="btn-red-ol my-025 w-100" [class.bg-aux]="reason==='r5'">Error</button>
        <p class="lh-1 mt-1">¿Otros motivos?<br><small class="o-5">Escribelos aquí abajo</small></p>
        <div class="lh-1">
            <textarea (keyup)="reason=reportText.value.slice(0,150);resetInput(reportText.value.slice(0,150));" type="text" formControlName="reason" placeholder="otras razones..." [class.error]="contactForm.invalid" #reportText></textarea>
            <small [class.error-text]="contactForm.invalid">{{ reportText.value.length }}/150</small>
            <p *ngIf="contactForm.invalid" class="error-text">Has excedido el número máximo de caracteres</p>
        </div>
        <button type="submit" class="btn-red">Reportar</button>
    </form>
</div>