import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Reply } from '../../models/reply.model';

const base_url = environment.base_url;


@Injectable({
  providedIn: 'root'
})
export class ReplyService {

  private headers: object;

  constructor(  private http: HttpClient,
    private authService: AuthService  ) {
      this.headers = authService.headers;
    }

  
  getRepliesFilterById( id: string | undefined, from: number, limit: number ) {

    return this.http.get( `${ base_url }/reply/${ id }?from=${from}&limit=${limit}` )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  return of(false)
                } )
              );

  }

  createReply( reply: Reply ) {
    
    return this.http.post( `${ base_url }/reply`, reply, this.headers )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  return of(false)
                } )
              );

  }

  updateReply( id: string | undefined, reply: Reply ) {
    
    return this.http.put( `${ base_url }/reply/${ id }`, reply, this.headers )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  return of(false)
                } )
              );

  }

  delete( id: string | undefined ) {
    
    return this.http.delete( `${ base_url }/reply/${ id }`, this.headers )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  return of(false)
                } )
              );

  }

}
