<img [src]="'assets/img/intern/icons/status/warning.svg'" alt="warning" class="icon">
<p class="subtitle">Existen restaurantes con nombres similares:</p>
<p class="o-5"><small>Comprueba si alguno es el que buscas o pulse "Ok" para continuar sin cambios</small></p>
<div class="container modal-exist w-fc m-auto mt-1">
    <div (click)="contentClick(i)" *ngFor="let content of search, let i=index" class="d-flex pst-relative cr-pointer">
        <img [src]="content.img | img: 'restaurants'" [alt]="content.name" class="icon lg m-auto ml-0 mr-05 bd-rds-20">
        <div class="text-left m-auto ml-0">
            <p class="m-0 subtitle">{{ content.name }}</p>
            <p class="elaboration-number"><small>{{ content.address.town + ', ' + (content.address.addressplus?(content.address.addressplus+ ', '):'')  + content.address.country }}</small></p>
        </div>
    </div>
</div>

<button (click)="ok(false)" class="btn-red sm mt-1 mr-05">Cancelar</button>
<button (click)="ok(true)" class="btn-red sm mt-1 ml-05">Ok</button>