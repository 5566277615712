<div class="adder">
    <div class="container" [class.error]="invalid.ingredients">

        <label><small>Ingredientes*</small></label>

        <form (ngSubmit)="add('ingredients')" [formGroup]="contentForm.ingredients" id="ingredient">
            <div class="row g-21">
                <div class="col w-md-40">
                    <div class="d-flex">
                        <input type="number" formControlName="quantity" placeholder="*Cantidad..." class="mr-05" [class.error]="invalidField(contentForm.ingredients, 'ingredients',  'quantity') || negative('quantity')" id="quantity">
                        <select type="text" formControlName="units" placeholder="*Unidad de medida" class="mr-md-05" [class.error]="invalidField(contentForm.ingredients, 'ingredients',  'units')">
                            <option *ngFor="let mesure of mesures" [value]="mesure">{{ mesure }}</option>
                        </select>
                    </div>
                </div>
                <div class="col w-md-60">
                    <input type="text" formControlName="ingredient" placeholder="*Añadir ingrediente..." [class.error]="invalidField(contentForm.ingredients, 'ingredients',  'ingredient')" #ingredientText>
                </div>
                <div class="w-100">
                    <p *ngIf="invalidField(contentForm.ingredients, 'ingredients',  'ingredient') || invalidField(contentForm.ingredients, 'ingredients',  'quantity')" class="error-text">No puede haber campos vacios o erroneos</p>
                    <p *ngIf="invalidField(contentForm.ingredients, 'ingredients',  'units')" class="error-text">Debe introducir una unidad de medida válida</p>
                    <p *ngIf="negative('quantity')" class="error-text">La cantidad no puede tener un valor inferior a 0.001</p>
                </div>
            </div>

            <div class="d-flex js-center">
                <hr class="hr-decor">
                <button type="submit" class="btn-add"><span class="m-auto">+</span></button>
                <hr class="hr-decor">
            </div>
        </form>

        <div *ngFor="let ingredient of contentArr.ingredients, let i = index" class="item ingredients">
            <button *ngIf="i>0" (click)="posChange(i, 'ingredients', -1)" type="button" class="btn-ch">
            <img src="/assets/img/intern/icons/menus/arrow.svg" alt="arrow Up">
        </button>
            <div class="d-flex" [class.mt-05]="i===0" [class.pt-05]="i===0">
                <div class="item-added">
                    <input (change)="change('ingredients', i)" type="text" [placeholder]="'*Ingrediente '+(i+1)+'...'" [value]="contentArr.ingredients[i].ingredient" class="w-60" [id]="'ingredient'+i">
                    <input (change)="change('ingredients', i)" type="number" placeholder="*Cantidad..." [value]="contentArr.ingredients[i].quantity" class="w-20 mx-05" [class.error]="negative('quantity', i)" [id]="'quantity'+i">
                    <select (change)="change('ingredients', i)" type="text" placeholder="*Unidades..." class="w-20" [id]="'units'+i">
                    <option *ngFor="let mesure of mesures" [value]="mesure" [selected]="mesure === mesures[contentArr.ingredients[i].units]">{{ mesure }}</option>
                </select>
                    <div>
                        <p [id]="'ingredientsShowAdvise'+i" class="advise a-l lg">No puede haber campos vacíos o erroneos</p>
                    </div>
                </div>
                <button (click)="remove('ingredients', i)" type="button" class="btn-rm"><span class="m-auto">-</span></button>
            </div>
            <button *ngIf="i<(contentArr.ingredients.length-1)" (click)="posChange(i, 'ingredients', 1)" type="button" class="btn-ch down">
            <img src="/assets/img/intern/icons/menus/arrow.svg" alt="arrow Down">
        </button>
        </div>
    </div>
    <p *ngIf="invalid.ingredients" class="error-text">Campo obligatorio</p>

    <div class="container" [class.error]="invalid.steps">
        <label class="mt-3"><small>Pasos*</small></label>

        <form (ngSubmit)="add('steps')" [formGroup]="contentForm.steps">
            <textarea type="text" formControlName="step" placeholder="*Añadir paso..." [class.error]="invalidField(contentForm.steps, 'steps',  'step')"></textarea>

            <p *ngIf="invalidField(contentForm.steps, 'steps',  'step')" class="error-text">El campo paso no puede estar vacio</p>

            <div class="d-flex js-center">
                <hr class="hr-decor">
                <button type="submit" class="btn-add"><span class="m-auto">+</span></button>
                <hr class="hr-decor">
            </div>
        </form>

        <div *ngFor="let step of contentArr.steps, let i = index" class="item steps">
            <button *ngIf="i>0" (click)="posChange(i, 'steps', -1)" type="button" class="btn-ch">
            <img src="/assets/img/intern/icons/menus/arrow.svg" alt="arrow up">
        </button>
            <div class="d-flex" [class.mt-1]="i===0">
                <div class="item-added">
                    <input (change)="change('steps', i)" type="text" [placeholder]="'Paso '+(i+1)" [value]="step.step" class="mr-05" [id]="'step'+i">

                    <p [id]="'stepsShowAdvise'+i" class="advise a-l lg">
                        No puede dejar este campos vacío o erroneo
                    </p>
                </div>
                <button (click)="remove('steps',i)" type="button" class="btn-rm"><span class="m-auto">-</span></button>
            </div>
            <button *ngIf="i<(contentArr.steps.length-1)" (click)="posChange(i, 'steps', 1)" type="button" class="btn-ch down">
            <img src="/assets/img/intern/icons/menus/arrow.svg" alt="arrow down">
        </button>
        </div>
    </div>
    <p *ngIf="invalid.steps" class="error-text">Campo obligatorio</p>
</div>