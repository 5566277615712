<div *ngIf="optionsSelected.length>0" class="bd-b grow-down pb-025">
    <label><small>Seleccionados</small></label>
    <div class="flex-box">
        <div (click)="checkOpt(optionSel)" *ngFor="let optionSel of optionsSelected, let i=index" class="cat-select">
            <div [class]="'btn-ingredient black bg-'+iconSel(i, optionSel)">
                <img [src]=" 'assets/img/intern/icons/'+type+ '/'+iconSel(i, optionSel)+ '.svg' " [alt]="iconSel(i, optionSel) " class="icon ">
            </div>
            <p>{{ optionSel | Esp | titlecase }}</p>
        </div>
    </div>
</div>