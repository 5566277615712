import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ModalService } from '../../../services/modal.service';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styles: [
  ]
})
export class ModalComponent implements OnInit {

  public title: string;
  public titleShow: boolean = true;
  public openClick: boolean = true;

  constructor(  public modalService: ModalService  ) { }

  ngOnInit(): void {

    if ( !this.modalService.type ) return;
    const type = this.modalService.type

    switch ( type ) {
      case 'register':
        this.title = 'registrarse';
        break;
      case 'image':
        this.titleShow = false;
        this.title = 'foto';
        break;
      case 'ingredients':
        this.title = 'ingredientes';
        break;
      case 'tools':
        this.title = 'utensilios';
        break;
      case 'question':
        this.title = 'confirmación';
        break;
      case 'close':
        this.title = 'estado';
        break;
      case 'new':
        this.title = 'nuevo/a';
        break;
      case 'coins':
        this.title = 'moneda';
        break;
      case 'exist':
        this.title = 'elemento existente';
        break;
      case 'forgotten':
        this.title = 'recuperar contraseña';
        break;
      case 'report':
        this.title = 'reportar';
        break;
      case 'share':
        this.title = 'compartir';
        break;
      case 'q-share':
        this.modalService.setOpt( '¿Desea compartir la publicación?', 'Haga click en ok para compartirla con tus contactos o cancela para volver al perfil' );
        this.title = 'compartir';
        break;
      case 'crop':
        this.titleShow = false;
        this.title = 'recortar';
        break;
      case 'share-follow':
        // this.titleShow = false;
        this.title = 'compartir';
        break;
      default:
        this.title = type;
        break;
    }

  }

  closeModal() {
  
    setTimeout( () => {
      this.modalService.closeModal();
    });

  }

  @HostListener( 'document: keydown', ['$event'] )
  keydown( event: any ) {
    if ( this.modalService.showModal ) {
      if ( event.key === 'Escape' ) {
        this.modalService.closeModal();
      }
    }
  }

  @HostListener( 'document: click', ['$event'] )
  clickOut( event: { target: any; } ) {

    if ( this.modalService.type === 'crop' && this.modalService.showModal && !this.openClick ) {
      const modal: any = document.getElementById( "modal-box" );
      if ( modal?.contains( event.target ) ) {
        return;
      }
      return this.modalService.closeModal();
    }
    this.openClick = false;

  }

}
