<div class="pst-relative double mb-05" [class.extend]="extend" [id]="'revObs'+reviewContent.id">
    <p class="mx-05 mb-025" [class.o-5]="!reviewContent?.observations" [innerText]="reviewContent?.observations || 'No hay comentarios añadidos'"></p>

    <div *ngIf="bigReview" class="d-flex js-center big-review" [class.pst-static]="extend">
        <hr class="hr-decor">
        <button (click)="extendEmitter()" type="button" class="btn-add"><span [class.d-none]="extend">+</span><span [class.d-none]="!extend">-</span></button>
        <hr class="hr-decor">
    </div>
</div>

<div *ngIf="type==='restaurants'" class="review-score">
    <div class="row g-3 mt-025 mb-05">
        <!-- <div class="col text-center m-0">
            <p class="lh-1 o-5"><small>Price: </small></p>
            <p class="subtitle">{{ reviewContent?.price?.price }}<small>{{ reviewContent?.price?.coin === 'EUR'?'€':reviewContent?.price?.coin }}</small></p>
        </div> -->
        <div *ngIf="reviewContent?.qualityprice" class="col text-center m-0">
            <p class="lh-1 o-5"><small>Calidad/Precio: </small></p>
            <img [src]="'/assets/img/intern/icons/menus/'+mood[(reviewContent.qualityprice || 1) - 1]+'.svg'" [alt]="mood[(reviewContent.qualityprice || 1) - 1]" class="icon my-auto">
        </div>
        <div *ngIf="reviewContent?.atention" class="col text-center m-0">
            <p class="lh-1 o-5"><small>Servicio: </small></p>
            <img [src]="'/assets/img/intern/icons/menus/'+mood[(reviewContent.atention || 1) - 1]+'.svg'" [alt]="mood[(reviewContent.atention || 1) - 1]" class="icon my-auto">
        </div>
        <div *ngIf="reviewContent?.decoration" class="col text-center m-0">
            <p class="lh-1 o-5"><small>Estética: </small></p>
            <img [src]="'/assets/img/intern/icons/menus/'+mood[(reviewContent.decoration || 1) - 1]+'.svg'" [alt]="mood[(reviewContent.decoration || 1) - 1]" class="icon my-auto">
        </div>
    </div>

    <div *ngIf="reviewContent.images && reviewContent.images.length>0" class="card-car d-flex of-auto">
        <!-- <div *ngIf="reviewContent.images && reviewContent.images[0]!=='no-img'" class="card-car d-flex of-auto"> -->
        <div *ngFor="let img of reviewContent.images, let i=index" class="p-05 m-auto">
            <img (click)="openModal('image');setImgs(reviewContent.images, i)" [src]="img | img:type" class="card-car-img cr-pointer bd-rd bd-rds-10">
        </div>
    </div>
</div>