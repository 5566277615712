<div class="p-search" #filter>
    <div class="search-box">
        <input (keyup)="searchTerm=searchStr.value;search();" type="search" [placeholder]="'Buscar... ' + (type==='restaurants'?'Ej: Restaurante, Ciudad':'Ej: Ensalada, Verduras')" class="search" #searchStr>
        <img (click)="collapsed=!collapsed;townsVS();" src="assets/img/intern/icons/menus/filter.svg" alt="filters" class="filter">

        <div class="dropdown grow-down" [class.d-block]="!collapsed" id="filter">
            <div *ngIf="type==='recipes'">
                <app-recipe-filters [contentType]="contentType" (filtersEmit)="filterGrid($event)" (collapsedEmit)="collapsed=$event"></app-recipe-filters>
            </div>
            <div *ngIf="type==='restaurants'">
                <app-restaurant-filters [contentType]="contentType" (filtersEmit)="filterGrid($event)" (collapsedEmit)="collapsed=$event"></app-restaurant-filters>
            </div>
        </div>

    </div>
</div>
<div class="back-search-box"></div>
<p>{{ (searchStr.value.includes(',') && type==='restaurants' && (filters?.town?.length
    <=0 )) ? 'No encontramos el municipio' : '' }}</p>