import { Component, Output, EventEmitter, Input } from '@angular/core';


@Component({
  selector: 'app-mood-score',
  templateUrl: './mood-score.component.html',
  styles: [
  ]
})
export class MoodScoreComponent {

  @Input() score: number;
  @Input() scoreArr: number[] = [];
  @Input() buttons: string[];
  @Input() moods: string[] = ['angry', 'neutral', 'happy', 'excellent'];
  @Input() multi: boolean = false;
  @Output() scoreEmitter: EventEmitter< number > = new EventEmitter();

  constructor() { }

  scoreChange( num: number ) {
    if ( this.multi ) {
      const idx = this.scoreArr.indexOf( num );
      if ( idx >= 0 ) return this.scoreArr.splice( idx, 1 );
      this.scoreArr.push( num );
    } else {
      this.score = num;
      // this.score = (this.score===num?0:num); //Deselect an option
      this.scoreEmitter.emit( this.score );
    }
  }
}
