<div class="modal animated" id="modal">
    <div class="modal-box" [class.img]="modalService.type==='image'" id="modal-box">
        <img (click)="closeModal()" src="assets/img/intern/icons/menus/close.svg" alt="close" class="modal-close">
        <div *ngIf="titleShow" class="modal-title">
            <p class="title">{{ title | titlecase }}</p>
        </div>
        <div class="modal-body">
            <div *ngIf="modalService.status.status==='none'" class="modal-if">
                <app-auth *ngIf="modalService.type==='login'||modalService.type==='register'"></app-auth>
                <app-image *ngIf="modalService.type==='image'"></app-image>
                <app-cropper *ngIf="modalService.type==='crop'"></app-cropper>
                <app-opt-select *ngIf="['ingredients', 'tools'].includes(modalService.type)" (closeEmit)="$event?closeModal():false"></app-opt-select>
                <app-question *ngIf="modalService.type==='question'" (closeEmit)="$event?closeModal():false"></app-question>
                <app-question *ngIf="modalService.type==='q-share'" [share]="true" (closeEmit)="$event?closeModal():false"></app-question>
                <app-new *ngIf="modalService.type==='new'" (closeEmit)="$event?closeModal():false"></app-new>
                <app-coins *ngIf="modalService.type==='coins'" (closeEmit)="$event?closeModal():false"></app-coins>
                <app-exist *ngIf="modalService.type==='exist'" (closeEmit)="$event?closeModal():false"></app-exist>
                <app-forgotten *ngIf="modalService.type==='forgotten'" (closeEmit)="$event?closeModal():false"></app-forgotten>
                <app-report *ngIf="modalService.type==='report'" (closeEmit)="$event?closeModal():false"></app-report>
                <app-share *ngIf="modalService.type==='share'" (closeEmit)="$event?closeModal():false"></app-share>
                <app-share-follow *ngIf="modalService.type==='share-follow'" (closeEmit)="$event?closeModal():false"></app-share-follow>
            </div>
            <div *ngIf="modalService.status.status!=='none'" class="modal-if">
                <app-status></app-status>
            </div>
        </div>
    </div>
</div>