<div class="d-flex bd-b rd-05 p-025">
    <p class="enf m-auto ml-0 lh-1 py-05"><b>Reseñas: {{ count }}</b></p>
    <div *ngIf="guard" class="d-flex">
        <p class="m-auto cl-main mr-05">{{ friends?'Amigos':'Mundo' }}</p>
        <div class="m-auto mr-0 cr-pointer">
            <img [src]="'/assets/img/intern/icons/menus/'+(friends?'profile':'world')+'.svg'" [alt]="friends?'friends':'world'" class="icon md m-auto">
        </div>
    </div>
</div>
<div (scroll)="onScroll($event)" class="reviews">
    <div *ngIf="userReview?(userReview.length>0):false" class="review owner" [id]="'review'+(userReview[0].id || userReview[0]._id)">
        <!-- (scoreEmit)="scoreEmit.emit($event)" -->
        <app-review-element [content]="content" [model]="model" [review]="userReview[0]" [owner]="true"></app-review-element>
    </div>
    <div *ngFor="let review of reviews" class="review" [id]="'review'+(review.id || review._id)">
        <!-- (scoreEmit)="scoreEmit.emit($event)" -->
        <app-review-element [content]="content" [model]="model" [review]="review"></app-review-element>
    </div>
    <div *ngIf="loading || !loading && reviews.length>3" class="text-center my-2 ">
        <img *ngIf="loading" src="assets/img/intern/icons/status/loading.svg" alt="loading" class="icon spin">
        <p *ngIf="!loading && reviews.length>3" class="wait o-5">No hay más reseñas</p>
    </div>

    <div class="h-120px d-flex bd-t">
        <p class="o-5 my-auto ml-05">No hay {{ (!loading && reviews.length >0 || !loading && userReview.length>0)? 'más': '' }} reseñas...<br><b>{{ (!loading && reviews.length >0 || !loading && userReview.length>0)? '': '¡Se el primero en escribir una!' }}</b></p>
    </div>
</div>
<app-loading *ngIf="loading && reviews?reviews.length<=0:false"></app-loading>