import { Pipe, PipeTransform } from '@angular/core';
import { VariablesService } from '../services/variables.service';

@Pipe({
  name: 'iso'
})
export class IsoPipe implements PipeTransform {

  private countries: any[] = [];

  constructor (  private vs: VariablesService  ){
    this.countries = vs.countries;
  }

  transform( country: string, option: string = 'name' ) {
    
    const iso = this.countries.filter( ctry => {      
      return ctry.iso === country || ctry.name === country;
    } )
    
    return iso[0][option];

  }

}
