<div class="d-flex">

    <div class="reply-w">
        <div class="reply" [class.w-100]="edit">
            <p class="c-bk-05"><small>{{ owner?'Tú':reply.user?.alias }}</small></p>

            <div *ngIf="!edit" class="d-flex">
                <p class="reply-text" [class.long]='longText'>{{ (longText&&!extend)?(reply.observations | slice:0:40)+'...':reply.observations }}</p>
            </div>

            <div *ngIf="edit">
                <app-reply-form [oldReply]="reply" (replyEmit)="replyUpdate($event)"></app-reply-form>
            </div>

            <div *ngIf="longText" class="d-flex js-center big-review bg-aux-025" [class.pst-static]="extend">
                <hr class="hr-decor">
                <button (click)="extend=!extend" type="button" class="btn-add"><span [class.d-none]="extend">+</span><span [class.d-none]="!extend">-</span></button>
                <hr class="hr-decor">
            </div>

            <p class="text-right c-bk-05"><small>{{ reply.createdAt | date:'HH:mm' }}</small></p>
        </div>
    </div>

    <div>
        <div class="d-flex h-fc mt-1 ml-05 h-fc">
            <a [routerLink]="['/profile/user/'+(reply.user?.id || reply.user?._id || reply.user)]">
                <img [src]="reply.user?.img | img: 'users'" [alt]="reply.user?.name" class="icon md bd-bg-round" onerror="this.onerror=null;this.src='/assets/img/intern/no-img.svg'">
            </a>

            <app-dropdown-menu [name]="'replyMenu'" [content]="reply" [model]="'Reply'" (editEmit)="edit=$event"></app-dropdown-menu>
        </div>

        <p class="text-center c-bk-05"><small>{{ reply.createdAt | date:'dd/MM/yy' }}</small></p>
    </div>

</div>