import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Reply } from '../../../models/reply.model';
import { ReplyService } from '../../../services/backend/reply.service';
import { ModalService } from '../../../services/modal.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ValidationService } from '../../../services/validation.service';

@Component({
  selector: 'app-reply-form',
  templateUrl: './reply-form.component.html',
  styles: [
  ]
})
export class ReplyFormComponent implements OnInit {

  @Input() ref: string;
  @Input() oldReply: Reply;
  @Input() model: string;
  @Output() replyEmit: EventEmitter< any > = new EventEmitter();
  
  public replyForm: FormGroup;
  private formSubmitted: boolean = false;

  constructor(  public fb: FormBuilder,
                private replyService: ReplyService,
                private modalService: ModalService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private validationService: ValidationService  ) { }

  ngOnInit(): void {
    this.formInit();
  }

  formInit() {

    if ( this.oldReply ) {

      this.replyForm = this.fb.group({
        observations: [ this.oldReply.observations, Validators.required ],
        ref:  [ this.oldReply.ref, Validators.required ],
      });

    } else {

      this.replyForm = this.fb.group({
        observations: [ '', Validators.required ],
        ref:  [ this.ref, Validators.required ],
        pathModel: [ this.model, Validators.required ]
      });

    }

  }

  createReply() {

    this.formSubmitted = true;
    
    if ( this.replyForm.valid ) {
      if ( this.oldReply ) {

        this.replyService.updateReply( this.oldReply.id, this.replyForm.value ).subscribe( resp => {
          this.adder( resp );
        });

      } else {

        this.replyService.createReply( this.replyForm.value ).subscribe( resp => {
          this.adder( resp );
        });

      }
    }

  }

  adder( resp: any ) {
    if ( resp.ok ) {
      this.replyEmit.emit( { form: this.replyForm.value, edit: false } );
      
      this.formSubmitted = false;
      this.formInit();
      const scrollElem = document.getElementsByClassName('replies')[0];
      scrollElem?.scroll( { top: 0, behavior: 'smooth' } );
    } else {
      this.modalService.setStatus( resp.nolog ? 'no-log' : 'error', resp.msg );
    }
  }

  invalidField( field: string ) {
    return this.validationService.invalidField( this.replyForm, field, this.formSubmitted );
  }

}
