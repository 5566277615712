<form class="text-center scroll-show p-05 filters">

    <div *ngIf="contentType==='reviews'">
        <p>Recomendado</p>
        <div class="mt-1">
            <app-mood-score [scoreArr]="recommended || []" [moods]="['bad', 'normal', 'good']" [buttons]="['no', 'sin más', 'sí']" [multi]="true" (scoreEmitter)="pushRecom($event)"></app-mood-score>
        </div>
        <hr>
    </div>

    <p>Localidad</p>
    <input (change)="townsSel=[townF.value]" (keyup)="townsFilter(townF.value)" type="text" placeholder="Localidad..." class="mr-md-05" list="townList" id="townF" #townF>
    <datalist id="townList">
        <option *ngFor="let town of townsFil" [value]="town">{{ town }}</option>
    </datalist>

    <hr>
    <p>Tipo de cocina</p>
    <select (change)="categoryChange(catSel.value)" type="text" placeholder="Tipo de cocina..." class="mr-md-05" id="catSel" #catSel>
        <option value="todas">Todas</option>
        <option *ngFor="let category of categories" [value]="category">{{ category | Esp | titlecase }}</option>
    </select>
    <div class="flex-box js-left">
        <div *ngFor="let category of categoriesSel, let i=index" class="pill">
            {{ category | Esp }}
            <div (click)="categoryChange('', i, true)" class="d-flex"></div>
        </div>
    </div>

    <hr>
    <p>Precio máximo</p>
    <input (change)="max=maxPrice.value" type="number" placeholder="Precio..." #maxPrice>

    <hr>
    <p>Comodidades</p>
    <app-icon-selector [options]="commodities" [type]="'commodities'" [oldOptSelected]="commoditiesSel" [grid]="'63'" (selectedEmit)="commoditiesSel=$event" #categoriesIcons></app-icon-selector>

    <hr>
    <p>Horario de comida</p>
    <app-icon-selector [options]="restaurantTypes" [type]="'ocassions'" [oldOptSelected]="restaurantTypesSel" [grid]="'643'" (selectedEmit)="restaurantTypesSel=$event;" #restaurantTypesIcons></app-icon-selector>

    <div *ngIf="contentType==='reviews'">
        <hr>
        <p>Ocasiones recomendadas</p>
        <app-icon-selector [options]="ocassions" [type]="'ocassions'" [oldOptSelected]="ocassionsSel" [grid]="'643'" (selectedEmit)="ocassionsSel=$event;" #ocassionsIcons></app-icon-selector>
    </div>

</form>
<div class="align-center pt-05 bd-t">
    <button type="button" (click)="reset()" class="btn-red sm">Reset</button>
    <button type="button" (click)="filterChange()" class="btn-red sm">Ok</button>
</div>