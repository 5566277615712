import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Reply } from '../../../models/reply.model';
import { ReplyService } from '../../../services/backend/reply.service';
import { AuthService } from '../../../services/backend/auth.service';
import { User } from '../../../models/user.model';

@Component({
  selector: 'app-replies',
  templateUrl: './replies.component.html',
  styles: [
  ]
})
export class RepliesComponent implements OnInit {

  @Input() ref: string;
  @Input() replies: Reply[] = [];
  @Output() repliesCount: EventEmitter< number > = new EventEmitter();

  public from: number = 0;
  private limit: number = 5;
  public loading: boolean = false;
  public maxPos: number = 0;

  private user: User;
  public count: number = 0;

  constructor(  private replyService: ReplyService,
                private authService: AuthService  ) { 
                  this.user = authService.user;
                }

  ngOnInit(): void {
    this.getReplies();
  }

  getReplies() {
    this.loading = true;
    
    this.replyService.getRepliesFilterById( this.ref, this.from, this.limit ).subscribe( resp => {
      if ( resp.ok && resp.replies.length > 0) {
        this.replies = [ ...this.replies, ...resp.replies ];
        this.count = resp.total;
        this.repliesCount.emit( this.count );
      }
      this.from += this.limit;
      this.loading = false;
    });
  }

  onScroll( event: any ) {

    const element = event.target;
    let pos = 0;
    if (element) {
      pos = element.scrollTop;
    }
    const max = element.scrollHeight || 0;
    
    if ( (pos + 300) >= max && !this.loading && pos > this.maxPos  ) {
      this.maxPos = pos;
      this.getReplies();
    }
    
  }

}
