<form (ngSubmit)="login()" [formGroup]="loginForm">

    <input type="text" formControlName="access" placeholder="Usuario o Email" name="form-input" [class.error]="invalidField('access')">
    <input type="password" formControlName="password" placeholder="Contraseña" name="form-input" [class.error]="invalidField('password')">
    <p *ngIf="!valid" class="error-text">Nombre, email o contraseña incorrectos</p>
    <div class="check-label mb-05">
        <label (click)="checkOpt()" class="label" [class.checked]="loginForm.controls['remember'].value"></label>
        <label>Recordarme</label>
    </div>
    <button type="submit" class="btn-red">Login</button>
    <p class="m-auto">¿Olvidaste la contraseña? <a (click)="modalService._type='forgotten'" class="alt">Recuperar</a></p>

    <hr>

    <div id="buttonDiv"></div>

</form>