import { Component, OnInit } from '@angular/core';
import { User } from 'app/models/user.model';
import { UserService } from '../../../services/backend/user.service';
import { AuthService } from '../../../services/backend/auth.service';
import { HelperService } from '../../../services/helper.service';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'app-share-follow',
  templateUrl: './share-follow.component.html',
  styles: [
  ]
})
export class ShareFollowComponent implements OnInit {

  public following: User[] = [];
  public permissions: any[] = [];
  private uid: string;

  constructor(  private userService: UserService,
                private authService: AuthService,
                private modalService: ModalService,
                private helperService: HelperService  ) { 
                  this.uid = authService.uid;
                }

  ngOnInit(): void {
    this.permissions = this.helperService.array;
    this.userService.getFollow( this.uid ).subscribe( resp => { 
      if ( resp.ok ) this.following = resp.follows;
    } );
  }

  shareWith( user: User ) {
    this.modalService.setOk( true );
    const id =  user._id || user.id;
    const aux = this.permissions.indexOf( id );

    if ( aux < 0 ) {
      this.permissions.push( id )
    } else {      
      this.permissions.splice( aux, 1 );
    }

    this.helperService.setArray( this.permissions );
  }

  close() {
    this.modalService.closeModal();
  }

}
