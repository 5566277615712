import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/backend/auth.service';
import { User } from '../../models/user.model';
import { UserService } from '../../services/backend/user.service';
import { ModalService } from '../../services/modal.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-following',
  templateUrl: './following.component.html',
  styles: [
  ]
})
export class FollowingComponent implements OnInit {

  public follows: User[];
  public name: string;
  public main: boolean = true;
  public advise: boolean = false;
  public adviseTime: any;

  constructor(  private authService: AuthService,
                private userService: UserService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private modalService: ModalService  ) {
              }

  ngOnInit(): void {
    this.navbar( true );
    this.name = (this.activatedRoute.snapshot.params.name || this.authService.user.name);
    let id = (this.activatedRoute.snapshot.params.id || this.authService.user.id);
    this.userService.getFollow( id ).subscribe( resp => {
      this.follows = resp.follows;   
    } );
    if ( this.activatedRoute.snapshot.params.name ) this.main = false;
  }

  ngOnDestroy() {
    this.navbar( false );
  }

  openModal( type: 'login' | 'register' | 'image' | 'crop' | 'tools' | 'ingredients' ) {
    this.modalService.openModal( type );
  }

  setImg( follow: User ) {
    this.modalService.setImg( follow.img, 'users', follow.updatedAt || '', follow.name );
  }

  navbar( add: boolean = true ) {
    const navbar = document.querySelector( '.sm-logo-box' );
    const backNavbar = document.querySelector( '.back-navbar.main' );
    if ( navbar && backNavbar ) {
      if ( add ) {
        return navbar.classList.add( 'd-none' );
      }
      navbar.classList.remove( 'd-none' );
    }
  }

  showAdvise() {
    this.advise = true;
    clearTimeout( this.adviseTime );
    this.adviseTime = setTimeout( () => {
      const banner: any = document.getElementById( 'followBanner' );
      banner.classList.add( 'fade-out' )
      setTimeout( () => {
        this.advise = false;
      }, 200 ); 
    }, 2000 );
  }

}
