import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { RestaurantReview } from '../../../models/restaurant-review.model';
import { RecipeReview } from '../../../models/recipe-review.model';
import { ModalService } from '../../../services/modal.service';
import { Recipe } from '../../../models/recipe.model';
import { Restaurant } from '../../../models/restaurant.model';

@Component({
  selector: 'app-review-content',
  templateUrl: './review-content.component.html',
  styles: [
  ]
})
export class ReviewContentComponent implements OnInit {

  @Input() type: 'restaurants' | 'recipes' = 'restaurants';
  @Input() contentName: string;
  @Input() reviewContent: RestaurantReview | RecipeReview | any;
  @Output() extendEmit: EventEmitter< boolean > = new EventEmitter();

  public extend: boolean = false;
  public bigReview: boolean = false;
  public working: boolean = false;
  // public mood: string[] = ['less', 'happy', 'excellent'];
  public mood = ['angry', 'neutral', 'happy', 'excellent']

  constructor(  private modalService: ModalService  ) { }

  ngOnInit(): void {

    let h = 57,
        i = 0,
        bigger: any;
    
    const interval = setInterval( () => {
      bigger = document.getElementById( 'revObs' + this.reviewContent.id );
      if ( bigger ) {
        clearInterval( interval );
        if ( bigger.scrollHeight > h ) {
          this.bigReview = true;
        }
      } else if ( i > 200 ) {
        clearInterval( interval );
        this.bigReview = true;
      } else {
        i++;
      }
    }, 10);

  }

  openModal( type: 'login' | 'register' | 'image' | 'crop' | 'tools' | 'ingredients' ) {
    this.modalService.openModal( type );
  }
  
  setImgs( imgs: any, idx: number = 0 ) {
    if ( imgs.length > 1 ) return this.modalService.setImgs( imgs, 'restaurants', this.reviewContent.updatedAt.toString() || '', idx, this.contentName );
    this.modalService.setImg( imgs[0], 'restaurants', this.reviewContent.updatedAt.toString() || '', this.contentName );
  }

  extendEmitter() {
    this.working = true;
    this.extend = !this.extend;
    this.extendEmit.emit( this.extend );
    setTimeout( () => {
      this.working = false;
    }, 100 );
  }

  @HostListener( 'document: click', ['$event'] )
  clickOut( event: { target: any; } ) {

    if ( this.extend && !this.working ) {
      const container: any = document.getElementById( 'card' + this.reviewContent.id );
      const modal: any = document.getElementById( "modal" );
      if ( container?.contains( event.target ) || modal?.contains( event.target ) ) {
        return;
      }
      this.extend = false;
      this.extendEmit.emit( false );
    }

  }

}
