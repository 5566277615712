import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LoginForm } from '../interfaces/login-form.interface';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  
  invalidField( form: FormGroup, field: string, formSubmitted: boolean ): boolean {

    if ( form.get(field)?.invalid && formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  invalidPassword( form: FormGroup, formSubmitted: boolean ) {

    const pass1 = form.get('password')?.value;
    const pass2 = form.get('passwordCheck')?.value;

    if ( (pass1 !== pass2) && formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  
  itemFormat( form: FormGroup, item: any ) {

    const registerForm = form.controls;
    let finalValue = registerForm[ item ].value.trim();

    if ( item === 'identificationCode' || item === 'reference' ) {
      finalValue = finalValue.toUpperCase()
    } else {
      finalValue = finalValue.toLowerCase()
    }
    
    registerForm[ item ].setValue( finalValue );
    
  }

  passwordMatch( pass1Name: string, pass2Name: string ) {

    return ( formGroup: FormGroup ) => {

      const pass1Control = formGroup.get( pass1Name );
      const pass2Control = formGroup.get( pass2Name );
    
      if ( pass1Control?.value === pass2Control?.value ) {
        pass2Control?.setErrors(null);
      } else {
        pass2Control?.setErrors( { doesntMatch: true } );
      }

    }

  }

  insideArr( item: string, arr: string[] ) {
    
    return ( formGroup: FormGroup ) => {
      const str = formGroup.get( item );
      return arr.indexOf( str?.value ) >= 0;
    }
    
  }

}
