<div *ngIf="tempImg">
    <div (wheel)="resizing($event)" (touchstart)="startMoving($event)" (touchmove)="moving($event)" (touchend)="endMoving()" (mousedown)="startMoving($event)" (mousemove)="moving($event)" (mouseup)="endMoving()" class="modal-img-box">
        <div class="modal-img">
            <div class="center">
                <div class="crop-component">
                    <div class="overlay img-round-container">
                    </div>
                </div>
                <img crossorigin="Anonymous" [src]="tempImg" class="crop-image" alt="crop img" />
            </div>
            <div class="btn-zoom">
                <button (click)="imgZoom(5)" (touchstart)="imgZoom(5)" type="button" class="btn-add lh-1 mb-05">+</button>
                <button (click)="imgZoom(-5)" (touchstart)="imgZoom(-5)" type="button" class="btn-add lh-1">-</button>
                <!-- <button (click)="rotate()" (touchstart)="rotate()" class="btn-add">R</button> -->
            </div>
        </div>
    </div>

    <img id="preview" class="d-none" alt="crop img">

    <div>
        <button (click)="reset()" class="btn-red sm mt-1 mr-05" id="btn-no-crop">Reset</button>
        <button (click)="openCropCanvasImg();close();" class="btn-red sm mt-1 ml-05" id="btn-crop">Aceptar</button>
    </div>
</div>