import { Component, OnInit, Output, EventEmitter, Input, HostListener, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { VariablesService } from '../../services/variables.service';
import { Restaurant } from '../../models/restaurant.model';
import { ImgSelectComponent } from '../img-select/img-select.component';
import { HelperService } from '../../services/helper.service';
import { ModalService } from '../../services/modal.service';
import { UploadService } from '../../services/backend/upload.service';
import { SearchService } from '../../services/backend/search.service';
import { RestaurantsService } from '../../services/backend/restaurants.service';
import { CacheService } from '../../services/cache.service';
import { Router } from '@angular/router';
import { ValidationService } from '../../services/validation.service';
import { TypeChangeService } from '../../services/type-change.service';
import { AuthService } from '../../services/backend/auth.service';

@Component({
  selector: 'app-restaurant-form',
  templateUrl: './restaurant-form.component.html',
  styles: [
  ]
})
export class RestaurantFormComponent implements OnInit {


  @ViewChild( ImgSelectComponent ) imgSelectComponent: ImgSelectComponent;

  @Input() id: string;
  @Input() inRestaurant: boolean = false;
  @Input() admin: boolean = false;
  @Input() restaurant: Restaurant | undefined;
  @Output() formEmitter: EventEmitter< any > = new EventEmitter();
  @Output() editEmit: EventEmitter< boolean > = new EventEmitter();

  public restaurantForm: FormGroup;
  public categories: string[];
  public categoriesSelect: number[] = [];
  public specialities: string[];
  public specialitiesSelect: number[] = [];
  private updateRestaurant: boolean = false;
  public restaurantTypes: string[];
  public restaurantTypesSelect: number[] = [];

  public formValid: boolean = true;
  public formSubmitted: boolean = false;
  public plateValid: boolean = true;
  public plateArr: any[] = [];
  public img: File | Blob;
  public more: boolean = false;

  public coins: any[];
  public countries: any[];
  public shadow: boolean = true;
  public newImg: boolean = false;

  public timeOut: any;
  public timeOutShow: any;
  public showMap: boolean = false;
  public radar: boolean = false;
  public incomplete: boolean = false;
  public down: boolean = false;

  public searchTerm: string;
  public searchContents: Restaurant[] = [];
  public searchOpen: boolean = false;
  private timeOutSearch: any;
  public locked: boolean = false;
  public loading: boolean = false;
  public empty: boolean = false;
  public from: number = 0;
  public limit: number = 5;
  public tags: string[] = [];

  public towns: string[];
  public townsFil: string[] = [];

  private role: string = 'NONE_ROLE';

  
  constructor(  private fb: FormBuilder,
                public helperService: HelperService,
                private vs: VariablesService,
                private modalService: ModalService,
                private uploadService: UploadService,
                private searchService: SearchService,
                private restaurantsService: RestaurantsService,
                private validationService: ValidationService,
                private authService: AuthService,
                public TCS: TypeChangeService,
                private cs: CacheService,
                private router: Router  ) {
                  this.coins = this.vs.coins;
                  this.countries = this.vs.countries;
                  this.categories = this.vs.restCategories;
                  this.specialities = this.vs.specialities;
                  this.restaurantTypes = this.vs.restaurantTypes;
                  this.categories = [ 'none', ...this.categories ];
                  this.specialities = [ 'none', ...this.specialities ];
                  this.restaurantTypes = [ 'none', ...this.restaurantTypes ];
                  this.role = authService?.user?.role || 'NONE_ROLE';
                }


  ngOnInit(): void {
    
    this.townsVS();
    // this.addressVS();
    this.modalService.setImgEdit( false, '' );

    const RT = this.restaurant;

    this.categoriesSelect = RT?.category || [];
    this.specialitiesSelect = RT?.specialities || [];
    this.restaurantTypesSelect = RT?.restaurantType || [];
    this.tags = RT?.tags || [];
    
    this.restaurantForm = this.fb.group({
      name: [ RT?.name || '', [ Validators.required ] ],
      town: [ RT?.address.town || '', [ Validators.required ] ],
      country: [ RT?.address.country || this.countries[63].iso, [ Validators.required ] ],
      addressplus: [ RT?.address?.addressplus || '' ],
      latitude: [ RT?.address?.coord?.coordinates[0] || '', Validators.pattern( '^[-+]?([1-8]?[0-9](\\.[0-9]+)?|90(\\.0+)?)$' ) ],
      longitude: [ RT?.address?.coord?.coordinates[1] || '', Validators.pattern( '^[-+]?(180(\\.0+)?|((1[0-7][0-9])|([1-9]?[0-9]))(\\.[0-9]+)?)$' )],
      coin: [ RT?.price?.coin || this.helperService.coin || 'EUR' ],
      category: [  -1 ],
      specialities: [ -1 ],
      phone: [ RT?.contact?.phone || ''],
      web: [ RT?.contact?.web || '', Validators.pattern('^(https:\/\/|http:\/\/)?(www.)?[a-z0-9._%+-]+\.[a-z]{2,4}([\/a-zA-Z0-9#-]+)?$')],
      contactbooking: [ RT?.contact?.booking || ''],
      contactmenu: [ RT?.contact?.menu || ''],
      // contactdrinks: [ RT?.contact?.drinks || ''],
      // email: [ RT?.contact?.email || '', Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$') ],
      menu: [ RT?.commodities?.menu || false ],
      parking: [ RT?.commodities?.parking || false],
      terrace: [ RT?.commodities?.terrace || false],
      // nobooking: [ RT?.commodities?.nobooking || false],
      takeaway: [ RT?.commodities?.takeaway || false],
      valet: [ RT?.commodities?.valet || false],
      restaurantType: [ -1 ],
      img: [ RT?.img || 'no-img' ],
      observations: [ RT?.observations || '' ],
      tags: [ RT?.tags || [] ]
    });

    if ( RT ) this.updateRestaurant = true;

    if ( !this.restaurant && this.id ) {
      this.restaurantsService.getRestaurantsById( this.id ).subscribe( resp => {
        this.showMap = true;
        this.externRecipe( resp.restaurant );
      } );
    } else if ( this.restaurant ) {
      this.showMap = true;
    }

  }

  ngOnDestroy() {
    if ( this.role === 'ADMIN_ROLE' && this.id ) this.modalService.setImgEdit( true, this.id );
  }

  emitter() {
    this.formEmitter.emit( this.restaurantForm.value );
  }

  addImg( img: any ) {
    this.img = img;
    this.newImg = true;
  }

  coinsSelect() {
    this.modalService.openModal( 'coins' );
  }

  fval( ctrl: string, val: any ) {
    this.restaurantForm.controls[ctrl].setValue( val );
  }

  gFval( ctrl: string ) {
    return this.restaurantForm.controls[ctrl]?.value;
  }

  saveReview() {

    this.search();
    if ( this.searchContents?.length > 0 && !this.restaurant ) {

      this.helperService.setArray( this.searchContents );
      this.modalService.openModal( 'exist' );

      const interval = setInterval( () => {
        if ( !this.modalService.showModal ) {
          clearInterval( interval );
          if (  !this.modalService.ok ) return;
          if ( this.helperService.selection ) {
            this.externRecipe( this.helperService.selection );
            this.helperService.setSelection();
            window.scroll( { top: 0 });
            return;
          }
          this.saveChecked();
        }
      }, 100 )

    } else {
      this.saveChecked();
    }
    
  }

  async saveChecked() {

    this.formSubmitted = true;
    this.fval( 'coin', this.helperService.coin );

    this.modalService.setStatus( 'loading' );

    if ( this.newImg ) {
      this.img = await this.imgUpload();
      this.fval( 'img', this.img );
      this.newImg = false;
    }

    this.sendForm();

  }

  async sendForm() {

    const NRRF = this.restaurantForm;

    if ( this.gFval( 'latitude' ) ||  this.gFval( 'longitude' ) ) {      
      if ( !this.gFval( 'latitude' ) || !this.gFval( 'longitude' ) ) return;
    }

    if ( !NRRF.valid ) return

    let NRRFO = this.restaurantForm.value;
    this.formValid = true;
    
    for ( let cat of ['name', 'town', 'country', 'addressplus', 'category', 'subcategory', 'specialities', 'restaurantType','coin', 'menu', 'parking', 'nobooking', 'takeaway','valet', 'phone', 'web', 'mail'] ) {
      delete NRRFO[cat];
    }

    if ( !this.restaurant ) {
      this.restaurant = {
        name: this.gFval( 'name' ).toLowerCase(),
        address: {
          town: this.gFval( 'town' ).toLowerCase(),
          country: this.gFval( 'country' )
        }
      }
    } else {
      if ( this.gFval( 'name' ) ) this.restaurant.name = this.gFval( 'name' ).toLowerCase();
      if ( this.gFval( 'town' ) ) this.restaurant.address.town = this.gFval( 'town' ).toLowerCase();
      if ( this.gFval( 'country' ) ) this.restaurant.address.country = this.isoCountry( this.gFval( 'country' ) ).iso;
    }

    if ( this.gFval( 'addressplus' ) ) this.restaurant.address.addressplus = this.gFval( 'addressplus' ).toLowerCase();
    if ( this.gFval( 'latitude' ) && this.gFval( 'longitude' ) ) {
      this.restaurant.address.coord = {
        type: "Point",
        coordinates : [ 
          this.gFval( 'latitude' ),
          this.gFval( 'longitude' )
        ]
      }
    }
    
    if ( this.categoriesSelect?.length > 0 ) this.restaurant.category = this.categoriesSelect;
    if ( this.specialitiesSelect?.length > 0 ) this.restaurant.specialities = this.specialitiesSelect;
    this.restaurant.restaurantType = this.restaurantTypesSelect?.length > 0 ? this.restaurantTypesSelect : [0];
    
    this.restaurant.tags = this.tags;

    if ( this.gFval( 'coin' ) ) this.restaurant.price = { price: this.restaurant.price?.price || 0, coin: this.gFval( 'coin' ) };
    
    if ( this.gFval( 'phone' ) || this.gFval( 'web' ) || this.gFval( 'email' ) ) {
      this.restaurant.contact = {};
      
      if ( this.gFval( 'phone' ) ) this.restaurant.contact.phone = this.gFval( 'phone' ).replace(/\\s/g, '');
      if ( this.gFval( 'web' ) ) this.restaurant.contact.web = this.gFval( 'web' ).replace(/\\s/g, '');
      if ( this.gFval( 'contactbooking' ) ) this.restaurant.contact.booking = this.gFval( 'contactbooking' ).replace(/\\s/g, '');
      if ( this.gFval( 'contactmenu' ) ) this.restaurant.contact.menu = this.gFval( 'contactmenu' ).replace(/\\s/g, '');
      // if ( this.gFval( 'contactdrinks' ) ) this.restaurant.contact.drinks = this.gFval( 'contactdrinks' ).toLowerCase();
      // if ( this.gFval( 'email' ) ) this.restaurant.contact.email = this.gFval( 'email' ).toLowerCase();
    }

    if ( this.gFval( 'menu' ) || this.gFval( 'takeaway' ) || this.gFval( 'terrace' ) || this.gFval( 'parking' ) || this.gFval( 'valet' ) ) {
      this.restaurant.commodities = {};
      if ( this.gFval( 'menu' ) ) this.restaurant.commodities.menu = this.gFval( 'menu' );
      if ( this.gFval( 'takeaway' ) ) this.restaurant.commodities.takeaway = this.gFval( 'takeaway' );
      if ( this.gFval( 'terrace' ) ) this.restaurant.commodities.terrace = this.gFval( 'terrace' );
      // if ( this.gFval( 'nobooking' ) ) this.restaurant.commodities.nobooking = this.gFval( 'nobooking' );
      if ( this.gFval( 'parking' ) ) this.restaurant.commodities.parking = this.gFval( 'parking' );
      if ( this.gFval( 'valet' ) ) this.restaurant.commodities.valet = this.gFval( 'valet' );
    }

    if ( this.gFval( 'img' ) ) this.restaurant.img = this.gFval( 'img' );

    if ( !this.updateRestaurant ) {
      this.restaurantsService.createRestaurant( this.restaurant ).subscribe( async resp => {
        NRRFO.restaurant = resp.restaurant.id;
        this.modalService.setStatus( resp.ok ? 'ok' : 'error', resp.msg || 'Guardado con éxito' );
      });
    } else {
      this.restaurantsService.updateRestaurant( this.restaurant, this.restaurant.id ).subscribe( async resp => {
        NRRFO.restaurant = resp.restaurant.id;
        this.modalService.setStatus( resp.ok ? 'ok' : 'error', resp.msg || 'Actualizado con éxito' );
      });
    }

    this.cs.cacheReset( 'restaurants', true );
    
    this.formEmitter.emit( {form: NRRFO, edit: false } );
    
    if ( this.admin ) {
      this.editEmit.emit( false );
    } else {
      this.reset();
    }

  }

  async imgUpload() {

    let upImg;
    if ( !this.restaurant ) {          
      upImg = await this.uploadService.uploadImg( this.img, 'restaurants' );
    } else {
      upImg = await this.uploadService.updateImg( this.img, 'restaurants', this.id );
    }

    return upImg.fileName;

  }

  geoSearch() {
    
    if ( this.inRestaurant ) return;
    if ( this.restaurant ) this.restaurant = undefined;
    this.showMap = false;
    this.radar = true;
    clearTimeout( this.timeOut );
    clearTimeout( this.timeOutShow );

    const name = this.gFval( 'name' ),
          town = this.gFval( 'town' ),
          addressplus = this.gFval( 'addressplus' ),
          country = this.gFval( 'country' );
    const term = `${ name }, ${ town }, ${ country }, ${ addressplus }`;

    if ( name.length > 0 && town.length > 0 ) {
      this.incomplete = false;
      this.timeOut = setTimeout ( () => {
        document.getElementById( 'map' )?.setAttribute( 'src', 'https://www.google.com/maps/embed/v1/place?key=AIzaSyApxDl_UlYmLAPCm27f4ahKC2bJC155Wp8&q='+term+'&zoom=15');
        this.timeOutShow = setTimeout( () => {
          this.showMap = true;
          this.radar = false;
        }, 1000 );
      }, 500 );
    } else if ( name.length <= 0 && town.length <= 0 ) {
      this.radar = false;
      this.incomplete = false;
    } else {
      this.incomplete = true;
    }

  }

  advise( id: string ) {

    const advise = document.getElementById( id );
    if ( advise && advise.style.opacity !== '1' ) {
      advise.style.opacity = '1';
      advise.style.visibility = 'visible'
      setTimeout( () => {
        advise.style.opacity = '0';
        advise.style.visibility = 'hidden'
      }, 2000 )
    }

  }

  pillsChange(  type: string, idx: any = '', del: boolean = false ) {
    let val: number = this.gFval( type ),
        selector: any;
    switch ( type ) {
      case 'category':
        selector = this.categoriesSelect;        
        break;
      case 'specialities':
        selector = this.specialitiesSelect;
        break;
      case 'restaurantType':
        selector = this.restaurantTypesSelect;
        break;
    }
    
    if ( !selector.includes( val ) && val >= 0 ) return selector.push( val );
    if ( del ) return selector.splice( idx, 1 );
    
  }

  // Restaurant Searcher

  search( push: boolean = false) {
    
    if ( this.inRestaurant ) return;
    this.searchOpen = true;
    this.loading = true;
    clearTimeout( this.timeOut );
    clearTimeout( this.timeOutSearch );

    const time = 500;

    this.timeOutSearch = setTimeout( () => {
 
      if ( !push ) {
        this.from = 0;
      };
      if ( this.searchTerm?.length >= 1 ) {
        return this.searchServ( push );
      }
  
      this.from = 0;
      this.searchContents = [];

    }, time )

  }

  searchServ( push: boolean ) {

    return this.searchService.search( this.from, this.limit, this.searchTerm, undefined, { town: this.gFval( 'town' ), country: this.isoCountry( this.gFval( 'country' ) ).iso },'restaurants' ).subscribe( resp => {

      if ( resp.ok && resp.searchResults?.length > 0 ) {

        if ( push ) {
          this.searchContents.push( ...resp.searchResults );
        } else {
          this.searchContents = resp.searchResults;
        }
        this.empty = false;
        this.from += this.limit;

      } else {
        if ( !push ) this.searchContents = [];
        this.empty = true;
      }

      this.loading = false;

    } );

  }

  scrollSearcher( searchResult: any ) {

    if ( !this.empty && !this.loading ) {
      const pos = searchResult.scrollTop;
      const max = searchResult.scrollHeight || 0;

      if ( (pos + (searchResult.clientHeight * 1.5)) >= max ) {
          this.search( true );
      }
    }
    
  }

  externRecipe( content: Restaurant ) {
    
    this.searchOpen = false;
    const NRRF = this.restaurantForm.controls;
    this.locked = true;
    
    this.fval( 'name', content.name );
    this.fval( 'town', content.address.town );
    this.fval( 'country', this.isoCountry( content.address.country ).name );
    this.fval( 'addressplus', content.address.addressplus );
    NRRF['name'].disable();
    NRRF['town'].disable();
    NRRF['country'].disable();
    NRRF['addressplus'].disable();
    this.geoSearch();
    this.restaurant = content;
    this.ngOnInit();
    // this.showMap = true;
    // this.radar = false;
    // this.incomplete = false;

  }

  enableForm() {
    const NRRF = this.restaurantForm.controls;
    NRRF['name'].enable();
    NRRF['town'].enable();
    NRRF['country'].enable();
    NRRF['addressplus'].enable();
    this.locked = false;
  }

  @HostListener( 'document: click', ['$event'] )
  clickOut( event: { target: any; } ) {
    if ( this.searchOpen ) {
      const searchElaboration: any = document.getElementById( 'search-restaurant' );
      const searchResult: any = document.getElementById( 'search-result' );
      if ( searchElaboration.contains( event.target ) || searchResult.contains( event.target ) ) {
        return;
      }
      this.searchOpen = false;
    }
  }

  // Aux
  scroll( event: any, scroll: boolean = true ) {

    const reviews = document.getElementsByClassName('reviews');
    const scrollIn = ( (reviews?.length > 0) ? reviews[0] : window );
    const initY = ( (reviews?.length > 0) ? reviews[0].scrollTop : window.scrollY );

    const targetTop: number = event.target.getBoundingClientRect().top;
    if ( targetTop < 100 || !scroll ) return;
    const scrollY = ( initY + (targetTop - 150) );
    setTimeout( () => {
      scrollIn.scrollTo({ top: scrollY, behavior: 'smooth' });
    }, 100 );
    
  }

  reset() {
    this.restaurant = undefined;
    this.showMap = false;
    this.radar = false;
    this.incomplete = false;
    this.down = false;
    this.formSubmitted = false;
    this.more = false;
    this.locked = false;
    this.formValid = false;
    this.enableForm();
    this.ngOnInit();
    this.editEmit.emit( false );
    //Revisar en un futuro, no me convence
    this.modalService.setEditing( false );
    if ( this.inRestaurant && !this.admin ) {
      this.router.navigateByUrl( '/reload' );
      setTimeout( () => {
        this.router.navigateByUrl( '/restaurant/' + this.id );
      }, 100 );
    }
  }

  isoCountry( country: string ) {
    const iso = this.countries.filter( ctry => {      
      return ctry.iso === country || ctry.name === country;
    } )
    
    return iso[0] || { name: '', iso: '' };
  }
  
  invalidField( field: string ) {
    return this.validationService.invalidField( this.restaurantForm, field, this.formSubmitted );
  }

  enter( event: any ) {
    const obs:any = document.getElementById( 'restaurantReviewObs' );
    if ( !obs?.contains( event.target )) event.preventDefault();
  }

  townsFilter( text: string ) {
    if ( text.length > 0 ) return this.townsFil = this.towns.filter( town => town.includes( text ) );
    return this.townsFil = [];
  }

  townsVS() {

    if (!this.vs.towns && !this.vs.working) {
      this.vs.getTowns().subscribe( (resp: any) => {
        this.towns = resp;
      } );
    } else {
      this.towns = this.vs.towns;
    }
    
  }

  // addressVS() {

  //   if (!this.vs.address && !this.vs.working) {
  //     this.vs.towns = [];
  //     this.vs.getAddress().subscribe( (resp: any) => {
  //       for ( let address of resp ) this.vs.towns.push( address.town );
  //       this.towns = this.vs.towns;
  //     } );
  //   } else {
  //     this.towns = this.vs.towns;
  //   }

  // }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    const pos = ( document.documentElement.scrollTop || document.body.scrollTop ) + window.innerHeight;
    const max = ( document.documentElement.scrollHeight || document.body.scrollHeight );

    if ( pos >= (max - 10) ) {
      this.shadow = false;
      return this.down = true;
    }
    this.shadow = true;
    this.down = false;
  }

}
