<app-loading *ngIf="!user"></app-loading>
<div *ngIf="user" class="main-page">
    <div class="profile-nav">
        <div class="d-flex">
            <div *ngIf="!mainProfile" class="my-auto">
                <app-btn-back [abs]="false"></app-btn-back>
            </div>
            <p class="title ws-nowrap"><b>{{ user.name }}</b></p>

            <div *ngIf="mainProfile" class="menu d-flex">
                <a (click)="new()">
                    <img src="assets/img/intern/icons/menus/add.svg" alt="add" class="icon">
                </a>
                <a routerLink="/profile/notifications">
                    <div [class.alert]="authService.alert" class="pst-relative">
                        <img src="assets/img/intern/icons/menus/notifications.svg" alt="notifications" class="icon">
                    </div>
                </a>
                <img (click)="collapsed=!collapsed" src="assets/img/intern/icons/menus/settings.svg" alt="options" class="icon">
            </div>

            <div *ngIf="!mainProfile" id="follow-menu">
                <div class="menu text-right">
                    <app-follow-btn [id]="user.id"></app-follow-btn>
                </div>
                <app-dropdown-menu [name]="'userMenu'" [content]="user" [model]="'User'"></app-dropdown-menu>
            </div>
        </div>

        <div class="profile page-title">
            <div class="dropdown grow-down text-center" [class.d-block]="!collapsed" #settings>
                <ul>
                    <a routerLink="/profile/settings">
                        <li>Ajustes</li>
                    </a>
                    <a routerLink="/profile/following">
                        <li>Siguiendo</li>
                    </a>
                    <a routerLink="/profile/shopping-list">
                        <li>Lista de la Compra</li>
                    </a>
                    <a *ngIf="user?.role === 'ADMIN_ROLE'">
                        <li routerLink="/admin/ingredients" class="bd-t">Administración</li>
                    </a>
                    <div class="cr-pointer">
                        <li (click)="logOut()" class="bd-t">Log Out</li>
                    </div>
                </ul>
            </div>
        </div>
    </div>

    <div class="title-box p-05 pt-1">
        <div class="d-flex">
            <div (click)="openModal('image');setImg(user.img);" class="profile-img-box img-round-container">
                <img [src]="user.img | img: 'users'" [alt]="user.name + ' img'" class="img-content" onerror="this.onerror=null;this.src='/assets/img/intern/no-img.svg'">
            </div>
            <div class="profile-info-box">
                <div class="profile-info">
                    <div>
                        <p><small>Nivel</small></p>
                        <p><b>{{ user.points | level }}</b></p>
                    </div>
                    <div (click)="info()" class="cr-pointer">
                        <p><small>Seguidores</small></p>
                        <p><b>{{ user.follow?.followerscount || 0 }}</b></p>
                    </div>
                    <div (click)="navigate()" class="cr-pointer">
                        <p><small>Siguiendo</small></p>
                        <p><b>{{ user.follow?.users?.array?.length || 0 }}</b></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="profile-description">
            <p>{{ user.description }}</p>
        </div>
    </div>

    <div class="row g-2 text-center cl-main">
        <div [class]="'col profile-type '+(type==='restaurants'?'selected':'')">
            <p (click)="catChange('restaurants', contentType)">Restaurantes</p>
        </div>
        <div [class]="'col profile-type '+(type==='recipes'?'selected':'')">
            <p (click)="catChange('recipes', contentType)">Recetas</p>
        </div>
    </div>

    <div id="profile-menu" class="profile-menu">
        <div (click)="catChange(type, 'reviews')" class="category-subselector" [class.category-selected]="contentType==='reviews'">
            <img src="assets/img/intern/icons/menus/reviews.svg" alt="reviews" class="icon" [class.subcategory-selected]="contentType==='reviews'">
        </div>
        <div (click)="catChange(type, '')" class="animated" [class.category-selected]="contentType===''">
            <img [src]="'assets/img/intern/icons/menus/'+type+'.svg'" alt="recipes" class="icon">
        </div>
        <div (click)="catChange(type, 'favs')" class="category-subselector odr-2" [class.category-selected]="contentType==='favs'">
            <img src="assets/img/intern/icons/menus/heart-p.svg" alt="favs" class="icon" [class.subcategory-selected]="contentType==='favs'">
        </div>
        <div *ngIf="mainProfile" (click)="catChange(type, 'later')" class="category-subselector odr-2" [class.category-selected]="contentType==='later'">
            <img src="assets/img/intern/icons/menus/later-r.svg" alt="favs" class="icon" [class.subcategory-selected]="contentType==='later'">
        </div>
    </div>

    <div class="profile-grid">
        <app-grid [id]="user.id" [type]="type" [contentType]="contentType" [mainProfile]="mainProfile"></app-grid>
    </div>
</div>