<app-loading *ngIf="!follows"></app-loading>
<div *ngIf="follows" class="main-page following">
    <div class="profile-nav">
        <div class="page-title h-fc p-0">
            <div class="my-auto">
                <app-btn-back [abs]="false"></app-btn-back>
            </div>
            <p class="title mr-025"><b>{{ name }}</b></p>
            <img *ngIf="!main" (click)="showAdvise()" src="assets/img/intern/icons/status/info.svg" alt="info" class="icon sm m-auto mr-025">
        </div>
    </div>

    <div *ngIf="advise && !main" class="advise-banner fade-in fast" id="followBanner">
        <p>No aparecerás en el listado independientemente de si es tú seguidor</p>
    </div>

    <div class="of-auto mxh-100-116px bd-t mt-1">
        <table class="w-100 m-auto follow">
            <tr *ngFor="let follow of follows, let i=index">
                <td class="d-flex">
                    <img (click)="openModal('image');setImg(follow)" [src]="follow.img | img: 'users'" [alt]="follow.name+' Imagen de perfil'" class="bd-bg-round" onerror="this.onerror=null;this.src='/assets/img/intern/no-img.svg'">
                </td>
                <td class="lh-1">
                    <a [routerLink]="'/profile/user/'+follow.id" class="c-bk-05">
                        <p><b>{{ follow.name }}</b></p>
                        <small>{{ follow.alias }}</small>
                    </a>
                </td>
                <td class="text-center">
                    <app-follow-btn [id]="follow.id"></app-follow-btn>
                </td>
            </tr>
        </table>
    </div>
    <div class="h-50px"></div>
</div>