<form (keydown.enter)="enter($event)" (ngSubmit)="saveReview()" [formGroup]="restaurantForm" class="mb-5">

    <div>
        <p>Restaurante*<br><small class="o-5" [class.d-none]="!inRestaurant">Comprueba que es el correcto en el mapa inferior</small></p>
        <div class="row g-31-2 no-space mb-1">
            <div class="col">
                <input (click)="searchOpen=true" (keyup)="searchTerm=name.value;search();geoSearch();" formControlName="name" type="text" placeholder="*Nombre del restaurante..." [class.error]="formSubmitted && !formValid" id="search-restaurant" autocomplete="off" #name>
                <div class="elaboration-search-box" [class.d-none]="(searchContents?searchContents.length<=0:true) || !searchOpen">
                    <div (scroll)="scrollSearcher( searchResult )" class="elaboration-search" id="search-result" #searchResult>
                        <div (click)="externRecipe(content)" *ngFor="let content of searchContents" class="d-flex pst-relative">
                            <img [src]="content.img | img: 'restaurants'" [alt]="content.name" class="bd-rds-20">
                            <div class="m-auto text-left">
                                <p class="m-0">{{ content.name }}</p>
                                <p class="elaboration-number"><small>{{ content.address.town + ', ' + (content.address.addressplus?(content.address.addressplus+ ', '):'')  + content.address.country }}</small></p>
                            </div>
                        </div>
                    </div>
                </div>
                <img *ngIf="locked" src="../../assets/img/intern/icons/menus/lock.svg" class="lock" alt="lock">
            </div>
            <div class="col mxr-md-05">
                <input (keyup)="search();geoSearch();townsFilter(townF.value)" formControlName="town" type="text" placeholder="Localidad..." class="mr-md-05" [class.d-none]="gFval('country')!=='ESP'" list="townList" #townF>
                <datalist id="townList">
                    <option *ngFor="let town of townsFil" [value]="town">{{ town }}</option>
                </datalist>
                <!-- <select (change)="search();geoSearch();" formControlName="town" type="text" placeholder="Ciudad..." class="mr-md-05" [class.d-none]="gFval('country')!=='ESP'">
                    <option *ngIf="towns.length<=0" value="loading">Cargando</option>
                    <option *ngFor="let town of towns" [value]="town">{{ town }}</option>
                </select> -->
                <input (keyup)="search();geoSearch()" formControlName="town" type="text" placeholder="*Localidad..." [class.error]="formSubmitted && !formValid" [class.d-none]="gFval('country')==='ESP'">
                <img *ngIf="locked" src="../../assets/img/intern/icons/menus/lock.svg" class="lock" alt="lock">
            </div>
            <div class="col w-20">
                <select (change)="search();geoSearch()" formControlName="country" placeholder="*País o territorio..." #country>
                    <option *ngFor="let country of countries" [value]="country.iso">{{ country.iso | iso: 'name' | titlecase }}</option>
                </select>
                <img *ngIf="locked" src="../../assets/img/intern/icons/menus/lock.svg" class="lock mr-05" alt="lock">
            </div>
            <div class="col">
                <p class="m-0 o-5">¿No es suficiente?</p>
                <div class="pst-relative">
                    <input (keyup)="geoSearch()" formControlName="addressplus" type="text" placeholder="Calle, código postal, centro de ocio...">
                    <img *ngIf="locked" src="../../assets/img/intern/icons/menus/lock.svg" class="lock" alt="lock">
                </div>
            </div>
            <div *ngIf="locked" class="col-12 text-center">
                <p class="m-0 o-5">¿No es el emplazamiento buscado?</p>
                <button (click)="enableForm()" type="button" class="btn-red-ol mt-05">Cambiar campos</button>
            </div>
        </div>

        <div *ngIf="!inRestaurant" class="radar-box w-md-60 m-auto">
            <div class="radar text-center" [class.search]="showMap">
                <iframe src="https://www.google.com/maps/embed/v1/place?key=AIzaSyApxDl_UlYmLAPCm27f4ahKC2bJC155Wp8&q=españa&zoom=4" width="100%" height="600" frameborder="0" class="map" allowfullscreen id="map">
                        </iframe>
                <div class="intern-box" [class.d-none]="showMap">
                    <div class="cross"></div>
                    <div class="spin-box" [class.spin-radar]="radar">
                        <div class="line"></div>
                        <p class="wait" [class.d-none]="radar">Completa los campos superiores para comenzar la búsqueda</p>
                    </div>
                    <p class="wait" [class.d-none]="(!incomplete)">
                        Falta {{ (name.value.length
                        <=0)? 'el nombre': 'la localidad'}}...</p>
                            <p class="wait" [class.d-none]="showMap || (name.value.length<=0) || (gFval('town').value.length<=0)">Buscando...</p>
                </div>
            </div>
        </div>

    </div>

    <div class="mt-1">
        <label>Coordenadas:</label>
        <div class="row">
            <div class="col">
                <p class="m-0 o-5">Latitud:</p>
                <div class="pst-relative">
                    <input formControlName="latitude" type="number" placeholder="Latitud..." [class.error]="invalidField('latitude') || (!gFval('longitude') && formSubmitted)">
                </div>
            </div>
            <div class="col">
                <p class="m-0 o-5">Longitud:</p>
                <div class="pst-relative">
                    <input formControlName="longitude" type="number" placeholder="Longitud..." [class.error]="invalidField('longitude') || (!gFval('latitude') && formSubmitted)">
                </div>
            </div>
        </div>
        <p *ngIf="!gFval('latitude') && formSubmitted || !gFval('longitude') && formSubmitted" class="error-text">Es necesario completar ambas coordenadas</p>
        <p *ngIf="invalidField('latitude') || invalidField('longitude')" class="error-text">Formato erroneo de coordenadas:<small><br>Latitud: -90.000 / +90.000<br>Longitud: -180.000 / +180.000</small></p>
    </div>

    <div class="mt-1">
        <label>General:</label>
        <div class="row g-42">
            <div class="col">
                <label class="o-5">Moneda usada:</label>
                <select type="text" formControlName="coin" placeholder="Monedas..." class="mr-05">
                    <option *ngFor="let coin of coins" [value]="coin.iso">{{ coin.iso }}</option>
                </select>
            </div>
            <div class="col">
                <label class="o-5">Tipo de cocina:</label>
                <select (change)="pillsChange('category')" type="text" formControlName="category" placeholder="Categorías..." class="mr-05">
                    <option *ngFor="let category of categories, let i=index" [value]="i-1">{{ category | Esp | titlecase }}</option>
                </select>
                <div class="flex-box js-left">
                    <div *ngFor="let category of categoriesSelect, let i=index" class="pill">
                        {{ category | toStr: 'restCategories' | Esp }}
                        <div (click)="pillsChange('category', i, true)" class="d-flex"></div>
                    </div>
                </div>
            </div>
            <div class="col">
                <label class="o-5">Especialidades:</label>
                <select (change)="pillsChange('specialities')" type="text" formControlName="specialities" placeholder="Especialidades..." class="mr-05">
                    <option *ngFor="let speciality of specialities, let i=index" [value]="i-1">{{ speciality | Esp | titlecase }}</option>
                </select>
                <div class="flex-box js-left">
                    <div *ngFor="let specitily of specialitiesSelect, let i=index" class="pill">
                        {{ specitily | toStr: 'specialities' | Esp }}
                        <div (click)="pillsChange('specialities', i, true)" class="d-flex"></div>
                    </div>
                </div>
            </div>
            <div>
                <label class="o-5">Tipo de restaurante:</label>
                <select (change)="pillsChange('restaurantType')" type="text" formControlName="restaurantType" placeholder="Especialidades..." class="mr-05">
                    <option *ngFor="let type of restaurantTypes, let i=index" [value]="i-1">{{ type | Esp | titlecase }}</option>
                </select>
                <div class="flex-box js-left">
                    <div *ngFor="let type of restaurantTypesSelect, let i=index" class="pill">
                        {{ type | toStr: 'restaurantTypes' | Esp }}
                        <div (click)="pillsChange('restaurantType', i, true)" class="d-flex"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-1">
        <label>Contacto:</label>
        <div class="row g-42">
            <div class="col">
                <label class="o-5">Phone:</label>
                <input type="tel" formControlName="phone" placeholder="Teléfono..." class="mr-05">
            </div>
            <div class="col">
                <label class="o-5">Web:</label>
                <input type="text" formControlName="web" placeholder="Web..." class="mr-05">
            </div>
            <div class="col">
                <label class="o-5">Reserva:</label>
                <input type="tel" formControlName="contactbooking" placeholder="Url Reserva..." class="mr-05">
            </div>
            <div class="col">
                <label class="o-5">Carta:</label>
                <input type="text" formControlName="contactmenu" placeholder="Url Menu..." class="mr-05">
            </div>
            <!-- <div class="col">
                <label class="o-5">Carta de Vinos:</label>
                <input type="email" formControlName="contactdrinks" placeholder="Url Bebidas..." class="mr-05">
            </div>
            <div class="col">
                <label class="o-5">Email:</label>
                <input type="email" formControlName="email" placeholder="Email..." class="mr-05">
            </div> -->
        </div>
    </div>

    <div class="mt-1">
        <label>Comodidades:</label>
        <div class="d-flex flex-wrap js-center text-center">
            <div class="m-auto">
                <label class="o-5 m-auto">Menu:</label>
                <div class="px-05">
                    <select formControlName="menu">
                        <option value="true">Sí</option>
                        <option value="false">No</option>
                    </select>
                </div>
            </div>
            <div class="m-auto">
                <label class="o-5 m-auto">Parking:</label>
                <div class="px-05">
                    <select formControlName="parking">
                        <option value="true">Sí</option>
                        <option value="false">No</option>
                    </select>
                </div>
            </div>
            <div class="m-auto">
                <label class="o-5 m-auto">Terraza:</label>
                <div class="px-05">
                    <select formControlName="terrace">
                        <option value="true">Sí</option>
                        <option value="false">No</option>
                    </select>
                </div>
            </div>
            <!-- <div class="m-auto">
                <label class="o-5 m-auto">Sin Reserva:</label>
                <div class="px-05">
                    <select formControlName="nobooking">
                        <option value="true">Sí</option>
                        <option value="false">No</option>
                    </select>
                </div>
            </div> -->
            <div class="m-auto">
                <label class="o-5 m-auto">Take Away:</label>
                <div class="px-05">
                    <select formControlName="takeaway">
                        <option value="true">Sí</option>
                        <option value="false">No</option>
                    </select>
                </div>
            </div>
            <div class="m-auto">
                <label class="o-5 m-auto">Aparcacoches:</label>
                <div class="px-05">
                    <select formControlName="valet">
                        <option value="true">Sí</option>
                        <option value="false">No</option>
                    </select>
                </div>
            </div>
        </div>
    </div>

    <label class="mt-3">Etiquetas</label>
    <app-pills [pills]="tags" (pillsEmit)="tags=$event"></app-pills>

    <div class="mt-1">
        <label>Imagen:</label>
        <div class="mt-1">
            <app-img-select [img]="restaurant?.img || 'no-img'" [type]="'restaurants'" (imgEmit)="addImg($event)"></app-img-select>
        </div>
    </div>

    <p *ngIf="formSubmitted && !formValid || !gFval('latitude') && formSubmitted || !gFval('longitude') && formSubmitted" class="error-text">Formulario Erroneo: Revise los campos en marcados en rojo</p>
    <div class="text-center mt-2">
        <button type="submit" class="btn-red">Guardar Restaurante</button>
    </div>
</form>