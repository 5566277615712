import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { Restaurant } from '../../models/restaurant.model';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { HelperService } from '../helper.service';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class RestaurantsService {

  private headers: object;

  constructor(  private http: HttpClient,
                private hs: HelperService,
                private authService: AuthService  ) {
                  this.headers = authService.headers;
                }

  get( from: number, limit: number, friends: boolean = false ) {

    let location;
    if ( this.hs?.coords?.lat ) location = [this.hs.coords.lat, this.hs.coords.lon];
    
    return this.http.get( `${ base_url }/restaurants?from=${ from }&limit=${ limit }&location=${ location || undefined }&uid=${ this.authService.uid }${ friends?(`&friends=${ this.authService.user.follow?.users?.array || '' }`):'' }` )
    .pipe(
      tap( (resp: any) => {
        return resp;
      }),
      catchError( error => {
        // this.modalService.setStatus( 'error', error.error.msg );
        console.log(error);
        return of(false)
      } )
    );

  }

  getFilterId( id: string, from: number, limit: number ) {
    
    let location;
    if ( this.hs?.coords?.lat ) location = [this.hs.coords.lat, this.hs.coords.lon];

    return this.http.get( `${ base_url }/restaurants/filter/${ id }?from=${ from }&limit=${ limit }&location=${ location || undefined }` )
    .pipe(
      tap( (resp: any) => {        
        return resp;
      }),
      catchError( error => {
        return of(false)
      } )
    );

  }

  getRestaurantsById( id: string ) {

    return this.http.get( `${ base_url }/restaurants/restaurant/${ id }`, this.headers )
    .pipe(
      tap( (resp: any) => {
        return resp;
      }),
      catchError( error => {
        // this.modalService.setStatus( 'error', error.error.msg );
        console.log(error);
        return of(false)
      } )
    );

  }

  getFavs( id: string, from: number, limit: number ) {
    
    let location;
    if ( this.hs?.coords?.lat ) location = [this.hs.coords.lat, this.hs.coords.lon];

    return this.http.get( `${ base_url }/restaurants/favs/${ id }?from=${ from }&limit=${ limit }&location=${ location || undefined }`, this.headers )
    .pipe(
      tap( (resp: any) => {
        return resp;
      }),
      catchError( error => {
        return of(false)
      } )
    );

  }

  getLists( from: number, limit: number, friends: boolean = false ) {
    
    let location;
    if ( this.hs?.coords?.lat ) location = [this.hs.coords.lat, this.hs.coords.lon];

    return this.http.get( `${ base_url }/restaurants/users/lists?from=${ from }&limit=${ limit }&location=${ location || undefined }&uid=${ this.authService.uid }${ friends?(`&friends=${ this.authService.user.follow?.users?.array || '' }`):'' }` )
    .pipe(
      tap( (resp: any) => {
        return resp;
      }),
      catchError( error => {
        // this.modalService.setStatus( 'error', error.error.msg );
        console.log(error);
        return of(false)
      } )
    );

  }

  createRestaurant( restaurant: Restaurant, pos: number | null = 0 ) {
    
    return this.http.post( `${ base_url }/restaurants?position=${ pos }`, restaurant, this.headers )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  // this.modalService.setStatus( 'error', error.error.msg );
                  console.log(error);
                  return of(false)
                } )
              );

  }

  updateRestaurant( restaurant: Restaurant, id: string | undefined, pos: number | null = null  ) {
    
    return this.http.put( `${ base_url }/restaurants/${ id }?position=${ pos }`, restaurant, this.headers )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  console.log(error);
                  return of(false)
                } )
              );

  }

  addFavs( id: string ) {

    const body = { user: this.authService.uid, date: new Date() };
    return this.http.put( `${ base_url }/restaurants/favs/${ id }`, body, this.headers )
              .pipe(
                tap( (resp: any) => {                  
                  return resp;
                }),
                catchError( error => {
                  console.log(error);
                  return of(false)
                } )
              );

  }

  //Change Id
  delete( id: string | undefined ) {
    
    return this.http.put( `${ base_url }/restaurants/change/${ id }`, {}, this.headers )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  console.log(error);
                  return of(false)
                } )
              );

  }

}
