<div class="modal-auth">
    <div *ngIf="modalService.type==='login'" class="mt-1">
        <app-login *ngIf="modalService.type==='login'"></app-login>
        <div class="modal-question">
            <p class="m-auto">¿No dispones de usuario? <a (click)="modalService._type='register'" class="alt">Registrarse</a></p>
        </div>
    </div>
    <div *ngIf="modalService.type==='register'" class="mt-1">
        <app-register></app-register>
        <div class="modal-question">
            <p class="m-auto">¿Ya dispones de usuario? <a (click)="modalService._type='login'" class="alt">Login</a></p>
        </div>
    </div>
</div>