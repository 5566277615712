import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TypeChangeService } from '../../../services/type-change.service';
import { VariablesService } from '../../../services/variables.service';
import { ForkScoreComponent } from '../../reviews-folder/score/fork-score/fork-score.component';
import { filter } from 'rxjs/operators';
import { TranslateService } from '../../../services/translate.service';
import { LevenshteinService } from '../../../services/levenshtein.service';

@Component({
  selector: 'app-recipe-filters',
  templateUrl: './recipe-filters.component.html',
  styles: [
  ]
})
export class RecipeFiltersComponent implements OnInit {

  @ViewChild( 'conditionsIcons' ) public conditionsIcons: ElementRef | any;
  @ViewChild( 'categoriesIcons' ) public categoriesIcons: ElementRef | any;
  @ViewChild( 'subcategoriesIcons' ) public subcategoriesIcons: ElementRef | any;
  @ViewChild( 'drinkSubcategoriesIcons' ) public drinkSubcategoriesIcons: ElementRef | any;
  @ViewChild( 'ingredientsIcons' ) public ingredientsIcons: ElementRef | any;
  @ViewChild( ForkScoreComponent ) public forkScoreComponent: ForkScoreComponent;
  
  @Input() collapsed: boolean;
  @Input() contentType:  '' | 'reviews' | 'favs' | 'later' | 'lists' = '';
  @Output() filtersEmit: EventEmitter< { filters: any, termFilters: any } > = new EventEmitter();
  @Output() collapsedEmit: EventEmitter< boolean > = new EventEmitter();

  public recommended: number[] = [];
  // public optForm: FormGroup;
  public timePos: number = 5;
  public timeArray: string[] = ['15', '30', '60', '90', '120', 'infinito']
  public timeNumArray: number[] = [15, 30, 60, 90, 120, 999999]

  public categories: string[];
  public fullSubcategories: any[];
  public conditions: string[];
  public subcategories: any[];
  public drinkSubcategories: any[];
  public foodFamilies: any[];
  public score: number | undefined;

  public conditionsSel: string[] = [];
  public categoriesSel: string[] = [];
  public subcategoriesSel: string[] = [];
  public drinkSubcategoriesSel: string[] = [];
  public ingredientsSel: string[] = [];

  public conditionsSelTemp: string[] = [];
  public categoriesSelTemp: string[] = [];
  public subcategoriesSelTemp: string[] = [];
  public drinkSubcategoriesSelTemp: string[] = [];


  constructor(  private fb: FormBuilder,
                private tcs: TypeChangeService,
                private vs: VariablesService,
                private translateService: TranslateService,
                private levenshteinService: LevenshteinService  ) {
                  this.categories = vs.categories;
                  this.fullSubcategories = vs.fullSubcategories;
                  this.conditions = vs.conditions;
                  this.subcategories = vs.subcategories.slice(1,vs.subcategories.length);
                  this.drinkSubcategories = vs.drinkSubcategories;
                  this.foodFamilies = vs.foodFamilies.slice(1, vs.foodFamilies.length);
                }

  ngOnInit(): void {
    // this.optForm = this.fb.group({
    //   timebar: [''],
    //   time: [''],
    // });

  }

  timeSel( val: number ) {

    this.timePos += val;
    if ( this.timePos > 5 ) {
      this.timePos = 5;
    } else if ( this.timePos < 0 ) {
      this.timePos = 0;
    }

  }

  filterChange() {

    const subcat = [ ...this.subcategoriesSel, ...this.drinkSubcategoriesSel ];
    const subcatTemp = [ ...this.subcategoriesSelTemp, ...this.drinkSubcategoriesSelTemp ];
    
    this.filtersEmit.emit({ filters: {
      recommended: this.recommended,
      time: this.timeNumArray[this.timePos],
      categories: this.tcs.strToNumArr(this.categoriesSel, this.categories),
      subcategories: this.tcs.strToNumArr(subcat, this.fullSubcategories),
      conditions: this.tcs.strToNumArr(this.conditionsSel, this.conditions),
      ingredientsFamilies: this.ingredientsSel,
      score: this.score,
    }, termFilters: {
      categories: this.tcs.strToNumArr(this.categoriesSelTemp, this.categories),
      subcategories: this.tcs.strToNumArr(subcatTemp, this.fullSubcategories),
      conditions: this.tcs.strToNumArr(this.conditionsSelTemp, this.conditions),
    } });
    this.collapsedEmit.emit( true );

  }

  reset() {
    this.timePos = 5;
    this.categoriesSel = [];
    this.subcategoriesSel = [];
    this.drinkSubcategoriesSel = [];
    this.conditionsSel = [];
    this.ingredientsSel = [];
    // this.score = undefined;
    
    this.conditionsIcons.optionsSelected = [];
    this.categoriesIcons.optionsSelected = [];
    this.subcategoriesIcons.optionsSelected = [];
    this.drinkSubcategoriesIcons.optionsSelected = [];
    this.ingredientsIcons.optionsSelected = [];
    // this.forkScoreComponent.scoreSel = 4;
    // this.forkScoreComponent.scoreCheck = false;
  }

  // resetScore() {
    // this.score = undefined;
    // this.forkScoreComponent.scoreSel = 4;
    // this.forkScoreComponent.scoreCheck = false;
  // }

  filterSearch( searchTerm: string ) {

    let searchOptions: any[] = [],
        filterSearchOptions: any[] = [],
        finalSearchOptions: any[] = [],
        i: number = 0;

    this.resetTemp();
    
    for ( let term of searchTerm.split( ' ' ) ) {

      i = 0;

      if ( !['el','él','ella','ellas','ellos','la','lá','lo','las','los','le','les','en','de','un','con','para', 'a', 'y', 'o', 'al', 'es', 'ya', 'sin', 'si', 'no'].includes( term ) ) {
        for ( let options of [ this.categories, this.subcategories, this.drinkSubcategories, this.conditions.slice(0,2) ] ) {
          
          for ( let j = 0; j < 3; j++ ) {
          //Hacer un componente o servicio con este algoritmo
            filterSearchOptions = searchOptions.concat(
              options.filter(
                option => {
                  return this.textMatcher( option, term, j, searchOptions );
                }
              )
            );
              
          }
          
          for ( let filter of filterSearchOptions ) {
            if ( !finalSearchOptions.includes( filter ) ) finalSearchOptions.push( filter );
          }
          
          if ( i === 0 ) {
            this.categoriesSelTemp = [ ...this.categoriesSelTemp, ...filterSearchOptions ];
          } else if ( i === 1 ) {
            this.subcategoriesSelTemp = [ ...this.subcategoriesSelTemp, ...filterSearchOptions ];
          } else if ( i === 2 ) {
            this.drinkSubcategoriesSelTemp = [ ...this.drinkSubcategoriesSelTemp, ...filterSearchOptions ];
          } 
          else if ( i === 3 ) {
            this.conditionsSelTemp = [ ...this.conditionsSelTemp, ...filterSearchOptions ];
          }
            
          i++;

        }
      }

    }

    this.filterChange();

  }

  pushRecom( event: number ) {
    const idx = this.recommended.indexOf( event );
    if ( idx >= 0 ) return this.recommended.splice( idx, 1 );
    this.recommended.push( event );
  }
  
  textMatcher( option: string, term: string, j: number, searchOptions: string[] ) {
    
    let must;

    const regTerm = new RegExp( term.toLowerCase()
    .normalize('NFC')
    .normalize('NFKC')
    .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\S\s])/g, '')
    .replace(/a/g, '[a,á,à,ä]+')
    .replace(/e/g, '[e,é,ë]+')
    .replace(/i/g, '[i,í,ï,y]+')
    .replace(/o/g, '[o,ó,ö,ò]+')
    .replace(/u/g, '[u,ü,ú,ù]+')
    .replace(/n/g, '[n,ñ]+')
    .replace(/b|v/g, '[b,v]+')
    .replace(/h/g, '([h,y]+)?')
    .replace(/c|ç|z/g, '[c,ç,z]+')
    .replace(/y|ll/g, '[i,y,j,ll,h]+')
    .replace(/\,\[i,y,j,ll,h\]\+\]/g, ',y]')
    .replace(/f|ph/g, '[f,ph]+')
    .replace(/g|j|x/g, '[g,j,y,x]+')
    .replace(/\,\[g,j,y,x\]\+\,/g, ',j,')
    .replace(/(s|x|(\[c,ç,z]\+\(\[h,y\]\+\)\?))/g, '[x,ch,s,z]+')
    .replace(/\,\[x,ch,s,z\]\+/g, ',x')
    .replace(/(\[e,é,ë\]\+\[x,ch,s,z\]\+$|\[e,é,ë\]\+\[x,ch,s,z\]\+ |\[x,ch,s,z\]\+$|\[x,ch,s,z\]\+ )/g, '([e,é,ë]|s|es)?')
    .replace(/(k|q\[u,ü,ú,ù]\+|q)/g, '[q,qu,k]+'), 'i');

    switch ( j ) {
      case 0:
        must = (this.translateService.esp( option ).includes( term ) || regTerm.test( this.translateService.esp( option ) ));
        break;
      case 1:
        must = (option.includes( term ) || regTerm.test( option ));
        break;
      case 2:
        must = this.levenshteinService.searchSplit( option, term, 1 ) || 
                this.levenshteinService.searchSplit( this.translateService.esp( option ), term, 1 ); //Could be changed to catch the levenshtein value                      
        break;
    }

    return !searchOptions.includes( option ) && must

  }

  resetTemp() {
    this.categoriesSelTemp = [];
    this.subcategoriesSelTemp = [];
    this.drinkSubcategoriesSelTemp = [];
    this.conditionsSelTemp = [];
  }

  // timeChange() {

    // let value = this.optForm.controls['timebar'].value;
    // this.optForm.controls['time'].setValue( Math.floor( 1.2 * value ) );    

  // }

  // radioCheck( time: string ) {

    // this.optForm.controls[time].setValue(true);

  // }


}
