import { Component, Input, OnInit, HostListener, EventEmitter, ViewChild, Output } from '@angular/core';
import { Recipe } from '../../models/recipe.model';
import { ModalService } from '../../services/modal.service';
import { ValidationService } from '../../services/validation.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-elab-carousel',
  templateUrl: './elab-carousel.component.html',
  styles: [
  ]
})
export class ElabCarouselComponent implements OnInit {

  @ViewChild( 'elabCarousel' ) public carousel: any;
  @Input() elaborations: Recipe[] = [];
  @Input() portions: number = 0;

  public newElaboration: boolean = false;
  public exitElaboration: boolean = false;
  public elaborationIndex : number = -1;
  public lastImg: any;

  public scrollBtn: any = {
    left: false,
    right: true
  };
  private slide: boolean = false;
  private scrollCheck: any;

  constructor(  private modalService: ModalService,
                public helperService: HelperService,
                private validationService: ValidationService  ) { }

  ngOnInit(): void {
  }

  openElaboration( i: number, scroll: boolean = true ) {

    this.newElaboration = false;
    this.elaborationIndex = i;
    this.lastImg = undefined;
    
    setTimeout( () => {
      this.newElaboration = true;
      if ( scroll ) {
        setTimeout( () => {
          this.scrollToView();
        })
      }
    })

  }

  scrollToView() {
    document.getElementById('elaborations')?.scrollIntoView({behavior: "smooth"});
  }
  
  elabMove( i: number, dir: number ) {
    return (dir < 0) ? (i <= 0) : (i >= ( this.elaborations.length - 1 ));
  }

  addElaboration( elaboration: Recipe ) {
    
    const id = elaboration._id || elaboration.id;
    for ( let elab of this.elaborations ) {
      if ( id && ((elab._id || elab.id) === id && elaboration.foreign ) ) {
        this.modalService.setStatus( 'warning', `¡Elaboración repetida!<br> <i>${ elaboration.name }</i>, no será añadida` );
        return;
      }
    }

    this.lastImg = this.helperService.tempImgElab;

    if ( this.elaborationIndex >= 0 ) return this.elaborations[ this.elaborationIndex ] = elaboration;

    this.elaborations.push(elaboration);

  }

  quitElaboration( idx: number ) {

    this.modalService.openModal( 'question' );
    const interval = setInterval( () => {
      if ( !this.modalService.showModal ) {
        clearInterval(interval);
        if ( this.modalService.ok ) {
          this.elaborations.splice( idx, 1 );
        }
      }
    }, 100);

  }

  exit() {

    this.exitElaboration = true;
    setTimeout( () => {
      this.newElaboration = false
      this.exitElaboration = false;
    }, 250 );
    
  }

  elabSelect( i: number ) {
    if ( !this.lastImg ) return false;
    if ( this.elaborationIndex < 0 ) {
      return this.elaborationIndex === this.elaborations.length;
    } else {
      return this.elaborationIndex === i;
    }
  }

  posChange( idx: number, dir: number ) {
    
    this.newElaboration = false;
    let copy;
    let arr;
    const changer = idx + dir;
  
    arr = this.elaborations;
  
    if ( changer < 0 || changer > arr.length ) {
      return;
    }
    copy = arr[ idx ];
    arr.splice( idx, 1 );
    arr.splice( changer, 0, copy );
    
    const lastSel: any = document.getElementsByClassName('selected')[0];
    if ( lastSel ) {
      lastSel.classList.remove('selected');
    }
    const changeSel: any = document.getElementsByClassName('changed')[0];
    if ( changeSel ) {
      changeSel.classList.remove('changed');
    }
    
    const newSel: any = document.getElementsByClassName('card-m')[idx];
    const newChangeSel: any = document.getElementsByClassName('card-m')[idx + dir];
    if ( newSel && newChangeSel ) {
      newSel.classList.add('selected');
      newChangeSel.classList.add('changed');
      setTimeout( () => {
        newSel.classList.remove('selected');
        newChangeSel.classList.remove('changed');
      }, 500 );
    }

  }

  @HostListener('document:mousedown', ['$event'])
  onMouseDown( event: MouseEvent) {
    
    if ( !this.carousel ) {
      this.carousel = document.getElementById('elabCarousel'); 
    }
    if ( this.carousel?.contains( event.target ) ) {
      event.preventDefault();
      this.slide = true;
      this.carousel.style.cursor = 'grabbing';
      this.carousel.classList.add( 'grab' );
    }

  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp( event: MouseEvent) {
    
    if ( !this.carousel ) {
      this.carousel = document.getElementById('elabCarousel'); 
    }
    if ( this.slide ) {
      this.carousel.eventPre
      this.slide = false;
      this.carousel.style.cursor = 'grab';
      this.carousel.classList.remove( 'grab' );
      this.carouselScroll();
    }

  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove( event: MouseEvent) {

    if ( this.carousel?.contains( event.target ) && this.slide ) {
      event.preventDefault();
      let scrollX: any = this.carousel.scrollLeft - event.movementX;;
      
      this.carousel.scroll( { left: scrollX } );
    }

  }

  carouselScroll() {
    if ( !this.carousel ) {
      this.carousel = document.getElementById('elabCarousel'); 
    }
    
    clearTimeout( this.scrollCheck );
    this.scrollCheck = setTimeout( () => {
      this.scrollBtnDisp( this.carousel.scrollLeft );
    }, 100 );
  }

  scrollBtnDisp( scrollX: number ) {
    
    this.scrollBtn = { 
      left: ( scrollX > 0 ), 
      right: ( (scrollX + 1) < (this.carousel.scrollWidth - this.carousel.clientWidth) )
    }
    this.carousel.scroll( { left: scrollX, behavior: "smooth" } )

  }

  scrolling( dir: number ) {
    
    if ( !this.carousel ) {
      this.carousel = document.getElementById('elabCarousel'); 
    }
    const scrollWidth: any = this.carousel.scrollWidth;
    let scrollX: any = this.carousel.scrollLeft;
    scrollX = scrollX + ( Math.floor( this.carousel.clientWidth - 120 ) * dir );
    
    if ( scrollX <= 50 ) scrollX = 0;
    if ( scrollX >= (scrollWidth - this.carousel.clientWidth) ) scrollX = scrollWidth;

    this.scrollBtnDisp( scrollX );

  }

  scrollable() {
    if ( !this.carousel ) {
      this.carousel = document.getElementById('elabCarousel'); 
    }
    return this.carousel.scrollWidth > this.carousel.clientWidth;
  }

}
