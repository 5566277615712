<div class="main-page px-05">

    <div class="title-box">
        <div class="page-title p-0">
            <div class="my-auto">
                <app-btn-back [abs]="false"></app-btn-back>
            </div>
            <h2 class="title">Ajustes</h2>
        </div>
    </div>

    <div class="mt-1">
        <app-img-select [img]="user.img" (imgEmit)="imgToUpdate=$event"></app-img-select>
        <div class="align-center mt-2">
            <button (click)="updateImg()" type="button" class="btn-red">Cambiar imagen</button>
        </div>
    </div>

    <p class="subtitle mt-2">Opciones de usuario</p>
    <div class="mt-1 mb-2">
        <form (ngSubmit)="updateFields()" [formGroup]="settingsForm">
            <label for="name">Nombre</label>
            <input type="text" formControlName="name" placeholder="Nombre" [class.error]="invalidField('name', settingsForm, settingsSubmitted)">
            <label for="alias">Alias</label>
            <input type="text" formControlName="alias" placeholder="Alias" [class.error]="invalidField('alias', settingsForm, settingsSubmitted)">
            <label for="email">Email</label>
            <input type="email" formControlName="email" placeholder="Email" [class.error]="invalidField('email', settingsForm, settingsSubmitted)">
            <label for="description">Descripción</label>
            <div class="pst-relative">
                <input type="text" maxlength="max" formControlName="description" placeholder="Descripción" [class.error]="invalidField('description', settingsForm, settingsSubmitted)" #description>
                <p>{{ description.value.length }}<small>/100</small></p>
            </div>
            <div class="align-center mt-2">
                <button type="submit" class="btn-red">Cambiar opciones</button>
            </div>
        </form>
    </div>

    <p class="subtitle mt-2">Cambio de contraseña</p>
    <div class="mt-1 mb-5">
        <form (ngSubmit)="updatePassword()" [formGroup]="passwordForm">
            <label for="email">Contraseña Actual</label>
            <input type="password" formControlName="password" placeholder="Contraseña actual" [class.error]="invalidField('password', passwordForm, passwordSubmitted)">
            <label for="passwordNew">Nueva Contraseña</label>
            <input type="password" formControlName="passwordNew" placeholder="Nueva contraseña" [class.error]="invalidField('passwordNew', passwordForm, passwordSubmitted) || invalidPassword()">
            <input type="password" formControlName="passwordCheck" placeholder="Repetir contraseña" [class.error]="invalidField('passwordCheck', passwordForm, passwordSubmitted) || invalidPassword()">
            <div class="align-center mt-2">
                <button type="submit" class="btn-red">Cambiar contraseña</button>
            </div>
        </form>
    </div>

</div>