import { Pipe, PipeTransform, HostListener } from '@angular/core';

@Pipe({
  name: 'autoFit'
})
export class AutoFitPipe implements PipeTransform {

  transform( name: string, idx: number, textRef: string, widthRef: string, maxLines: number = 3, margin: number = 0, maxSizes: boolean = true, max?: number ) {
    
    const title: any = document.querySelectorAll( textRef ),
          card: any = document.querySelectorAll( widthRef ),
          w = (card[idx]?.clientWidth - margin) || 0,
          nameArr = name.split( ' ' ),
          totalLength = this.sLen( name );

    let size;
    let xl, md, sm;
    if ( max ) {
      xl = max; md = (max - 4); sm = Math.max(max - 14, 14);
    } else if ( window.innerWidth < 576 ) {
      xl = 28; md = 24; sm = 18;
    } else {
      xl = 32; md = 24; sm = 18;
    }

    if ( (nameArr.length > 1 && (totalLength * md / 2) > w) || (nameArr.length > 1 &&  ( (w / totalLength) * 2 ) < sm) ) {
      
      let lines,
          maxLine = 0,
          concat = '';

      if ( nameArr.length > 2 && (totalLength * sm / 2) > (w * 2)) {
        lines = maxLines;
      } else {
        lines = 2;
      }
      const lineLength = totalLength / lines;

      for ( let word of nameArr ) {
        concat = concat + word + ' ';
        
        if ( this.sLen( concat ) > lineLength && maxLine > this.sLen( concat ) || this.sLen( concat ) > lineLength && maxLine === 0) {
          maxLine = concat.length;
          if ( (this.sLen( concat ) - 1) > lineLength ) maxLine--;
          concat = word + ' ';
        }
        if ( (this.sLen( word ) + 1) > lineLength && maxLine > (this.sLen( word ) + 1) ) {
          maxLine = this.sLen( word ) + 1;
        }
      }

      size = ( (w / maxLine) * 2 );
      if ( maxSizes ) {
        let maxSize = (lines>2) ? sm : md;
        size = (size>maxSize)  ? maxSize : size;
      }

    } else {
      size = ( (w / totalLength) * 2 );
      if ( maxSizes ) {
        size = (size > xl) ? xl : size;
      }
    }
    
    if ( size < 12 ) size = 12;

    title[idx].style.fontSize = size + 'px';
    
    return name;

  }

  sLen( str: string ) {
    let len = 0;
    const charArr = 'mMwW'
    const smCharArr = '.,:;- '
    
    for ( let char of str ) {
      if ( charArr.indexOf(char) >= 0 ) {
        len += 1.7;
      } else if ( char.toUpperCase() === char && smCharArr.indexOf(char) < 0) {
        len += 1.2;
      } else if ( smCharArr.indexOf(char) >= 0 ) {
        len += 0.6;
      } else {
        len++;
      }
    }
    return len;
  }
  
}
