import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../services/backend/auth.service';
import { Router } from '@angular/router';
import { ModalService } from '../../../services/modal.service';
import { ValidationService } from '../../../services/validation.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styles: [
  ]
})
export class RegisterComponent {

  public formSubmitted: boolean = false;
  public registerForm: FormGroup = this.fb.group({
    name: ['',  Validators.required],
    alias: ['',  Validators.required],
    email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')] ],
    password: ['',  [Validators.required, Validators.minLength(2)] ],
    passwordCheck: ['',  [Validators.required, Validators.minLength(2)] ],
    terms: [ false, [ Validators.required, Validators.requiredTrue ] ],
  }, {
    validators: [ this.validationService.passwordMatch('password', 'passwordCheck')]
  });
  public existing: string;


  constructor(  private fb: FormBuilder,
                private authService: AuthService,
                private router: Router,
                private modalService: ModalService,
                private validationService: ValidationService  ) { }

  register() {

    this.existing = '';
    this.formSubmitted = true; 
    
    this.format();
    
    if ( this.registerForm.invalid ) {
      return;
    }
    
    this.authService.createUser( this.registerForm.value )
        .subscribe( resp => {
          if ( resp.ok ) {
            return this.modalService.closeModal();
          }
          this.existing = resp.msg;
        }, (err) => {
          this.modalService.setStatus( 'error', err.error.msg );
        });

  }

  checkOpt() {

    const oldValue = this.registerForm.controls['terms'].value;
    this.registerForm.controls['terms'].setValue(!oldValue);

  }

  invalidField( field: string ): boolean {
    return this.validationService.invalidField( this.registerForm, field, this.formSubmitted);
  }

  invalidPassword() {
    return this.validationService.invalidPassword( this.registerForm, this.formSubmitted );
  }

  acceptTerm() {
    return !this.registerForm.get('terms')?.value && this.formSubmitted;
  }

  format() {
    
    for ( let item of ['name', 'alias', 'email'] ) {
      this.validationService.itemFormat( this.registerForm, item );
    }

  }

}
