import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hourMin'
})
export class HourMinPipe implements PipeTransform {

  transform(value: number) {
    let h = Math.floor(value / 60);
    let min = Math.floor(value % 60);
    return (h > 0 ? (h + ' h ') : '') + (min > 0 ? (min + ' min ') : '');
  }

}
