import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styles: [
  ]
})
export class QuestionComponent implements OnInit {

  @Input() share: boolean = false;

  constructor(  public modalService: ModalService  ) { }

  ngOnInit(): void {
  }
  
  ok( ok: boolean ) {
    this.modalService.setOk(ok);
    this.modalService.closeModal();
  }

}
