import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';

const base_url = environment.base_url;

@Pipe({
  name: 'img'
})
export class ImgPipe implements PipeTransform {

  transform( img: string | undefined | null, type: 'users' | 'recipes' | 'restaurants' | 'reviews' | 'none' ): string {

    if ( type === 'none' ) type = 'users';

    if ( !img || img === 'no-img' ) {
        return `assets/img/intern/no-img.svg`;
    } else if ( img.includes('https') || img.includes('data:image/jpeg;base64') ) {
        return img;
    } else if ( img ) {
        return `${ base_url }/upload/${ type }/${ img }`;
    } else {
        return `assets/img/intern/no-img.svg`;
    }

  }

}
