import { Component, OnInit, Input } from '@angular/core';
import { Recipe } from '../../models/recipe.model';
import { RecipeReview } from '../../models/recipe-review.model';
import { User } from '../../models/user.model';
import { HelperService } from '../../services/helper.service';
import { RestaurantReviewsService } from '../../services/backend/restaurant-reviews.service';
import { RestaurantReview } from '../../models/restaurant-review.model';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styles: [
  ]
})
export class ListComponent implements OnInit {
  
  @Input() content: User | any;
  @Input() type: 'recipes' | 'restaurants';

  public collapsed: boolean = true;
  public recommendation: string[] = ['no recomendado', 'no está mal...', 'recomendado'];
  public recommendationColor: string[] = ['error', 'warning', 'ok'];
  public mood: string[] = ['angry', 'neutral', 'happy', 'excellent'];
  private coords: any;
  public reviews: RestaurantReview[] = [];

  constructor(  private hs: HelperService,
                private restaurantReviewsService: RestaurantReviewsService  ) {
    this.coords = hs.coords; 
  }

  ngOnInit(): void {
  }

  getReviews() {
    this.restaurantReviewsService.getFilterId( (this.content.id || this.content._id), 0, 10 ).subscribe( resp => {
      this.reviews = resp.reviews;
    })
  }
  
  distance( coord: any ) {
    const rEarth = 6371;
    const rad = Math.PI / 180;
    const difLat = (coord[0] -  this.coords.lat) * rad;
    const difLon = (coord[1] -  this.coords.lon) * rad;

    const a = Math.pow( Math.sin(difLat/2), 2 ) +
    Math.cos(this.coords.lat * rad) *
    Math.cos(this.coords.lon * rad) *
    Math.pow( Math.sin(difLon/2) , 2 );
    
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt( 1 - a )) * rEarth;
    
    return Math.round( c * 10 ) / 10;
  }

}
