import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { AuthService } from '../../services/backend/auth.service';
import { User } from '../../models/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/backend/user.service';
import { GridComponent } from '../../components/grid/grid.component';
import { CacheService } from '../../services/cache.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styles: [
  ]
})
export class ProfileComponent implements OnInit {
  
  @ViewChild( 'settings' ) public settings: ElementRef;
  @ViewChild( GridComponent ) private gridComponent: GridComponent;

  public user: User;
  public mainUser: User;

  public collapsed: boolean = true;
  public collapsedTemp: boolean = true;
  public type: 'recipes' | 'restaurants' = 'restaurants';
  public contentType: '' | 'reviews' | 'favs' | 'later' = 'reviews';
  public reviewsType: 'recipes' | 'restaurants' = 'restaurants';
  public favsType: 'recipes' | 'restaurants' = 'restaurants';
  public recipesCount: number = 0;
  public reviewsCount: number = 0;
  public statsReady: boolean;
  public mainProfile: boolean = false;

  private touchStartX: number;
  private touchStartY: number;

  constructor(  public authService: AuthService,
                public modalService: ModalService,
                private activatedRoute: ActivatedRoute,
                private userService: UserService,
                private hs: HelperService,
                private router: Router,
                private cs: CacheService  ) {
                  this.mainUser = authService.user;
                }
                      
  ngOnInit(): void {
    
    this.navbar()
    let { id } = this.activatedRoute.snapshot.params;
    const cache = this.cs.getCacheOptions( 'profile' );
    
    if ( id ) {

      if ( id === this.mainUser?.id ) {
        this.router.navigateByUrl('/profile');
        this.mainProfile = true;
        this.type = this.hs.type;
        this.contentType = cache.contentType || '';
        this.reviewsType = cache.reviewsType || 'restaurants';
        this.favsType = cache.favsType || 'restaurants';

        if ( this.mainUser?.notifications &&  this.mainUser.notifications.length > 0 ) {
          this.authService.alert = !this.mainUser.notifications[0]?.read;
        } else {
          this.authService.alert = false;
        }
        return;
      }
      this.userService.getUser( id ).subscribe( resp => {
        if ( resp.ok ) {
          this.user = resp.user;
          this.hs.setShare( resp.user.img, resp.user.name, 'users' );
        }
      });

    } else if ( this.mainUser ) {

      this.user = this.mainUser;
      id = this.mainUser.id;
      this.mainProfile = true;
      this.type = this.hs.type;
      this.contentType = cache.contentType || 'reviews';
      this.reviewsType = cache.reviewsType || 'restaurants';
      this.favsType = cache.favsType || 'restaurants';
      if ( this.mainUser?.notifications &&  this.mainUser.notifications.length > 0 ) {
        this.authService.alert = !this.mainUser.notifications[0]?.read;
      } else {
        this.authService.alert = false;
      }

    } else {
      this.router.navigateByUrl( '/'+this.hs.type );
      setTimeout( () => {
        this.modalService.openModal( 'login' );
      }, 100 );
      return;
    }
    
    if ( this.mainProfile ) this.modalService.setImgEdit( this.mainProfile, id );
    let max = 0;

  }

  ngOnDestroy() {
    this.navbar( false );
    if ( this.mainProfile ) this.modalService.setImgEdit( false, '' );
  }

  catChange( type: 'recipes' | 'restaurants', contentType: '' | 'reviews' | 'favs' | 'later' ) {

    this.type = type;
    this.contentType = contentType;
    this.gridComponent.type = type;
    this.gridComponent.contentType = contentType;
    this.gridComponent.contents = [];
    this.gridComponent.fullContents = [];
    this.gridComponent.searchContent = [];
    this.gridComponent.from = 0;
    this.gridComponent.lastMax = 0;
    this.gridComponent.empty = false;
    this.gridComponent.changing = true;
    this.gridComponent.ngOnInit();
    if ( this.mainProfile ) this.cs.setCacheOptions( 'profile', { type: type, contentType: contentType, reviewsType: this.reviewsType, favsType: this.favsType } );
    
  }

  new() {
    this.modalService.openModal( 'new' );
  }

  navigate() {
    if ( !this.mainUser ) return this.modalService.setStatus( 'no-log', 'Necesitas iniciar sesión para acceder a esta funcionalidad' );
    if ( this.mainProfile ) return this.router.navigateByUrl( "/profile/following" );
    this.router.navigateByUrl( "/profile/following/" + this.user.name + '/' + this.user.id );
  }

  info() {
    this.modalService.setStatus( 'info', 'En FoodiesHome buscamos que comas bien.<br><b>Sigue criterios, no amigos.</b>' )
  }

  openModal( type: 'login' | 'register' | 'image' | 'crop' | 'tools' | 'ingredients' ) {
    this.modalService.openModal( type );
  }

  setImg( img: string ) {
    this.modalService.setImg( img, 'users', this.user.updatedAt || '', this.user.name );
  }

  logOut() {
    this.authService.logout();
  }
  
  navbar( add: boolean = true ) {
    const navbar = document.querySelector( '.sm-logo-box' );
    const backNavbar = document.querySelector( '.back-navbar.main' );
    if ( navbar && backNavbar ) {
      if ( add ) {
        return navbar.classList.add( 'd-none' );
      }
      navbar.classList.remove( 'd-none' );
    }
  }

  @HostListener( 'document: click', ['$event'] )
  clickOut( event: { target: any; } ) {

    if ( !this.collapsedTemp ) {
      if ( !this. collapsed ) {
        if ( this.settings.nativeElement.contains( event.target ) ) {
          return this.collapsedTemp = false;
        }
        this.collapsedTemp = true;
        return  this.collapsed = true;
      }
    }

    this.collapsedTemp = this.collapsed;

  }

  @HostListener( 'document: click', ['$event'] )
  clickFriends( event: { target: any; } ) {
    const mainRestaurants: any = document.getElementById( 'mainRestaurants' );
    const mainRecipes: any = document.getElementById( 'mainRecipes' );
    if ( mainRestaurants.contains( event.target ) || mainRecipes.contains( event.target )  ) {
      this.catChange( this.hs.type, this.contentType);
      return;
    }
  }

  @HostListener( 'document: touchstart', ['$event'] )
  touchStart( event: TouchEvent ) {
    this.touchStartX = event.touches[0].pageX;
    this.touchStartY = event.touches[0].pageY;   
  }

  @HostListener( 'document: touchend', ['$event'] )
  touchEnd( event: TouchEvent ) {

    const changeTypes: any[] = [ 'reviews', '', 'favs', 'later' ];
    const idx = changeTypes.indexOf( this.contentType || '' );

    if ( idx >= 0 ) {
      const difY = this.touchStartY - event.changedTouches[0].pageY;
      if ( Math.abs( difY ) < 25 ) {
        const difX = this.touchStartX - event.changedTouches[0].pageX;
        if ( difX > 50 ) {
          this.contentType = changeTypes[ idx === (changeTypes.length - 1) ? 0 : (idx + 1) ];
        } else if ( difX < -50 ) {
          this.contentType = changeTypes[ idx === 0 ? (changeTypes.length - 1) : (idx - 1) ];
        }
        if ( difX > 50 || difX < -50 ) this.catChange( this.type, this.contentType );
      }
    }

  }

}
