import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../services/backend/auth.service';
import { ModalService } from '../../../services/modal.service';
import { ReportsService } from '../../../services/backend/reports.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styles: [
  ]
})
export class ReportComponent implements OnInit {

  public contactForm: FormGroup;
  public formSubmitted: boolean = false;
  public reason: string;

  constructor(  private authService: AuthService,
                private router: Router,
                private reportsService: ReportsService,
                private fb: FormBuilder,
                public modalService: ModalService  ) { }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      reason: [ '' , [Validators.maxLength( 150 )] ]
    });
  }


  report() {
    
    this.formSubmitted = true;
    if ( this.contactForm.invalid) {
      return;
    }

    this.modalService.openModal( 'question' );
    const interval = setInterval( () => {
      
      if ( !this.modalService.showModal ) {

        clearInterval(interval);
        if ( this.modalService.ok ) {
          const report = {
            ref: this.modalService.id || 'no-id',
            pathModel: this.modalService.model,
            reason: this.reason
          }
          
          this.reportsService.createReport( report ).subscribe( resp => {
            this.reload( resp, resp.deleted );
          });

        }

      }

    }, 100);

  }

  reload( resp: any, reload: boolean = true ) {

    this.modalService.setStatus( resp.ok ? 'ok' : (resp.nolog ? 'no-log' : 'error'), resp.msg );
    
    if ( resp.ok && reload ) {
      this.router.navigate( [ '/reload' ] )
      let route: string;
      if ( this.modalService.model === 'Recipe') {
        route = 'recipes';
      } else {
        route = ( (this.modalService.model!=='User')?'recipe/':'profile/user/' ) + this.modalService.id;
      }
      setTimeout( () => { 
        this.router.navigate( [ `/${ route }` ] ); 
      }, 100);
    }

  }

  resetInput( reason: string = '' ) {
    this.contactForm.controls[ 'reason' ].setValue( reason );
  }

}
