<div class="main-page">
    <div class="page-title">
        <p class="title">Añadir ingrediente</p>
        <a routerLink="/admin/restaurants" class="btn-red-ol sm m-auto mr-0">Restaurantes</a>
    </div>
    <div class="d-flex my-1">
        <input type="text" placeholder="*Ingrediente" class="mr-05" id="ingredient">
        <select (change)="familyChange()" type="text" placeholder="*Unidad de medida" class="mr-05" id="foodFamily">
            <option *ngFor="let family of foodFamilies" [value]="family">{{ family }}</option>
        </select>
        <button (click)="create()" type="button" class="btn-red sm">Ok</button>
    </div>
    <div class="page-title">
        <p class="title lh-1">Ingredientes <br> <small> sin familia: {{ total }}</small></p>
        <button (click)="get()" class="btn-red-ol sm m-auto mr-0">Refresh</button>
    </div>
    <table class="w-100 mb-3">
        <tr>
            <th>Ingrediente</th>
            <th>Familia</th>
        </tr>
        <tr *ngFor="let ingredient of ingredients, let i=index">
            <td class="pr-05 va-t">
                <input (keyup.enter)="update(ingredient.id, i)" type="text" [value]="ingredient.ingredient" [id]="'ingredient'+i">
            </td>

            <td class="pr-05 d-flex">
                <select (change)="familyChange(i)" type="text" placeholder="*Unidad de medida" class="mr-05" [id]="'foodFamily'+i">
                    <option *ngFor="let family of foodFamilies" [value]="family" [selected]="family===ingredient.foodFamily">{{ family }}</option>
                </select>
                <button (click)="update(ingredient.id, i)" type="button" class="btn-red-ol sm mr-05">Ok</button>
                <button (click)="delete(ingredient.id, i)" type="button" class="btn-red sm">Delete</button>
            </td>

            <div class="flex-box js-left">
                <div *ngFor="let family of ingredient.foodFamily, let idx=index" class="pill">
                    {{ family }}
                    <div (click)="familyChange(i, true, idx)" class="d-flex"></div>
                </div>
            </div>
        </tr>
        <tr *ngIf="loading">
            <app-loading></app-loading>
        </tr>
    </table>
</div>