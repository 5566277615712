import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn-scroll-top',
  templateUrl: './btn-scroll-top.component.html',
  styles: [
  ]
})
export class BtnScrollTopComponent implements OnInit {

  public gridElement: any;
  public active: boolean = false;
  public showAdvise: boolean = false;
  public firstClick: boolean = false;

  constructor() { }

  ngOnInit(): void {

    this.gridElement = document.getElementsByClassName( 'grid' );

  }
  
  scrollTop() {
    
    this.firstClick = true;
    const btn = document.querySelector( '.btn-scroll-top' );
    btn?.classList.add( 'up-down' );

    if ( this.gridElement && this.active ) {
      this.gridElement.item(0).scrollTo( { top: 0, behavior: "smooth" } );
      this.firstClick = false;
    }

    //Double Click and Advise Activation
    this.active = true;
    setTimeout( () => {
      this.active = false;
      btn?.classList.remove( 'up-down' );
      this.advise();
    }, 300 );

  }

  advise() {

    const advise = document.getElementById( 'up-btn-advise' );
    
    if ( this.firstClick && advise && advise.style.opacity !== '1' ) {
      advise.style.opacity = '1';
      advise.style.visibility = 'visible';
      setTimeout( () => {
        advise.style.opacity = '0';
        advise.style.visibility = 'hidden';
      }, 2000 )
    }

  }

}
