<div class="main-page mb-5">
    <h2 class="mt-2">Términos y condiciones</h2>
    <h3 class="mt-1" id="advise">AVISO LEGAL</h3>
    <p>En cumplimiento con el deber de información recogido en artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico (LSSICE), el propietario de la web , le informa de lo siguiente:</p>
    <ul>
        <li>Denominación social: Datazen Big DataSL</li>
        <li>NIF: B42917039</li>
        <li>Domicilio: Dublin, 1 of. 1C (Europolis) 28232 Las Rozas de Madrid</li>
    </ul>
    <p>Con los límites establecidos en la ley, Datazen Big Data, SL no asume ninguna responsabilidad derivada de la falta de veracidad, integridad, actualización y precisión de los datos o informaciones que contienen su páginas web.</p>
    <p>Los contenidos e información no vinculan a Datazen Big Data, SL ni constituyen opiniones, consejos o asesoramiento legal de ningún tipo pues se trata meramente de un servicio ofrecido con carácter informativo y divulgativo.</p>
    <p>Las páginas de Internet de Datazen Big Data, SL pueden contener enlaces (links) a otras páginas de terceras partes que Datazen Big Data, SL no puede controlar. Por lo tanto, Datazen Big Data, SL no puede asumir responsabilidades por el contenido que
        pueda aparecer en páginas de terceros.</p>
    <p>Los textos, imágenes, sonidos, animaciones, software y el resto de contenidos incluidos en este website son propiedad exclusiva de Datazen Big Data, SL o sus licenciantes. Cualquier acto de transmisión, distribución, cesión, reproducción, almacenamiento
        o comunicación pública total o parcial, deberá contar con el consentimiento expreso de Datazen Big Data, SL.</p>
    <p>Asimismo, para acceder a algunos de los servicios que Datazen Big Data, SL ofrece a través del sitio web, deberá proporcionar algunos datos de carácter personal. En cumplimiento de lo establecido en el Reglamento (UE) 2016/679 del Parlamento Europeo
        y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos le informamos que, mediante la cumplimentación de los presentes
        formularios, sus datos personales quedarán incorporados y serán tratados en los ficheros de Datazen Big Data, SL con el fin de poderle prestar y ofrecer nuestros servicios así como para informarle de las mejoras del sitio Web.</p>
    <p>Le informamos también de que tendrá la posibilidad en todo momento de ejercer los derechos de acceso, rectificación, cancelación, oposición, limitación y portabilidad de sus datos de carácter personal, de manera gratuita mediante email a: info@datazen.es</p>

    <h3 class="mt-1" id="data">POLÍTICA DE PROTECCIÓN DE DATOS</h3>
    <p>De acuerdo con lo establecido por la normativa de Protección de Datos de Carácter Personal, le informamos que está facilitando sus datos de carácter personal al Responsable de Tratamiento: DATAZEN BIG DATA, S.L (en adelante LA ENTIDAD), con dirección
        en C/Dublín, 1 oficina 1C de LAS ROZAS DE MADRID y correo electrónico <a class="alt" target="_blank" href="mailto:info@datazen.es">info@datazen.es</a></p>
    <h4>1. Finalidad del tratamiento</h4>
    <p>Los datos recopilados serán únicamente los detallados en el formulario de contacto, así como aquellos que usted mismo nos facilite en el apartado “Mensaje”. La ENTIDAD podrá tratar sus datos de carácter personal de acuerdo con las siguientes finalidades:</p>
    <ul>
        <li>Gestionar su navegación a través del Sitio Web.</li>
        <li>Gestionar el servicio de Atención al Usuario, para dar respuesta a las dudas, quejas, comentarios o inquietudes que pueda tener relativas a la información incluida en el Sitio Web, así como cualesquiera otras consultas que pueda tener.</li>
        <li>Tratar tus datos con fines comerciales, siempre que haya consentido el pertinente tratamiento de datos personales. Para darnos consentimiento para enviarle información sobre nuestros servicios, tendrá que marcar la casilla “Sí, deseo recibir información
            comercial”. De lo contrario, no podremos utilizar sus datos personales con este fin.</li>
        <li>Gestionar el cumplimiento, mantenimiento, desarrollo y control de cualesquiera relaciones que la ENTIDAD mantenga con usted.</li>
    </ul>
    <p>El hecho de no facilitar cierta información señalada como obligatoria, puede conllevar que no sea posible gestionar su petición a través del formulario de contacto.</p>
    <p>En el caso de que nos aporte Datos Personales de terceros, usted se responsabiliza de haber informado y haber obtenido el consentimiento de estos para ser aportados con las finalidades indicadas en los apartados correspondientes de la presente Política
        de Privacidad y Cookies.</p>
    <h4>2. Legitimación del tratamiento de sus datos de carácter personal</h4>
    <p>La legitimación se basa en el consentimiento que usted nos otorga al clicar el botón “ACEPTO LA POLÍTICA DE PROTECCIÓN DE DATOS”.</p>
    <h4>Destinatarios de cesiones</h4>
    <p>No cederemos sus datos a terceros, salvo para dar cumplimiento a una obligación legal.</p>
    <h4>4. Derechos</h4>
    <p>Por último, le informamos de sus derechos en materia de protección de datos.</p>
    <ul>
        <li>Derecho a solicitar el acceso a sus datos personales.</li>
        <li>Derecho a solicitar su rectificación o supresión (derecho al olvido).</li>
        <li>Derecho a solicitar la limitación de su tratamiento, y a oponerse al tratamiento.</li>
        <li>Derecho a la portabilidad de los datos.</li>
        <li>Derecho a la retirada de consentimiento.</li>
    </ul>
    <p>Para ejercer sus derechos no tiene más que realizar una solicitud por escrito y acompañarla de una copia de su DNI.</p>
    <p>DATAZEN BIG DATA, S.L dispone de formularios adecuados al respecto. No tiene más que solicitarlos o bien presentar su propio escrito en <a class="alt" target="_blank" href="mailto:info@datazen.es">info@datazen.es</a>.</p>
    <h4>5. Medidas de seguridad</h4>
    <p>DATAZEN BIG DATA, S.L tiene implantadas las medidas técnicas y organizativas necesarias para garantizar la seguridad de sus datos de carácter personal y evitar su alteración, la pérdida y el tratamiento y/o el acceso no autorizado, teniendo en cuenta
        el estado de la tecnología, la naturaleza de los datos almacenados y los riesgos provenientes de la acción humana o del medio físico y natural a que están expuestas.</p>

    <h3 class="mt-1" id="cookies">POLÍTICA DE COOKIES</h3>
    <p>Datazen Big Data, SL informa acerca del uso de las cookies en su página web: <a class="alt" target="_blank" href="https://foodieshome.herokuapp.com/">www.foodieshome.herokuapp.com</a></p>
    <p>Las cookies son archivos que se pueden descargar en su equipo a través de las páginas web. Son herramientas que tienen un papel esencial para la prestación de numerosos servicios de la sociedad de la información. Entre otros, permiten a una página
        web almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información obtenida, se pueden utilizar para reconocer al usuario y mejorar el servicio ofrecido.</p>
    <h4>Tipos de cookies</h4>
    <p>Según quien sea la entidad que gestione el dominio desde donde se envían las cookies y trate los datos que se obtengan se pueden distinguir dos tipos:</p>
    <ul>
        <li>Cookies propias: Aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario.</li>
        <li>Cookies de terceros: Aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies.</li>
    </ul>
    <p>En el caso de que las cookies sean instaladas desde un equipo o dominio gestionado por el propio editor pero la información que se recoja mediante éstas sea gestionada por un tercero, no pueden ser consideradas como cookies propias.</p>
    <p>Existe también una segunda clasificación según el plazo de tiempo que permanecen almacenadas en el navegador del cliente, pudiendo tratarse de:</p>
    <ul>
        <li>Cookies de sesión: Diseñadas para recabar y almacenar datos mientras el usuario accede a una página web. Se suelen emplear para almacenar información que solo interesa conservar para la prestación del servicio solicitado por el usuario en una
            sola ocasión (p.e. una lista de productos adquiridos).</li>
        <li>Cookies persistentes: Los datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios años.</li>
    </ul>
    <p>Por último, existe otra clasificación con seis tipos de cookies según la finalidad para la que se traten los datos obtenidos:</p>
    <ul>
        <li>Cookies técnicas: Aquellas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación
            de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripción o participación en un evento, utilizar
            elementos de seguridad durante la navegación, almacenar contenidos para la difusión de vídeos o sonido o compartir contenidos a través de redes sociales.</li>
        <li>Cookies de personalización: Permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario como por ejemplo serian el idioma, el tipo de navegador
            a través del cual accede al servicio, la configuración regional desde donde accede al servicio, etc.</li>
        <li>Cookies de análisis: Permiten al responsable de las mismas, el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas. La información recogida mediante este tipo de cookies se utiliza en la medición
            de la actividad de los sitios web, aplicación o plataforma y para la elaboración de perfiles de navegación de los usuarios de dichos sitios, aplicaciones y plataformas, con el fin de introducir mejoras en función del análisis de los datos
            de uso que hacen los usuarios del servicio.</li>
        <li>Cookies publicitarias: Permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios.</li>
        <li>Cookies de publicidad comportamental: Almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad
            en función del mismo.</li>
        <li>Cookies de redes sociales externas: Se utilizan para que los visitantes puedan interactuar con el contenido de diferentes plataformas sociales (facebook, youtube, twitter, linkedIn, etc..) y que se generen únicamente para los usuarios de dichas
            redes sociales. Las condiciones de utilización de estas cookies y la información recopilada se regula por la política de privacidad de la plataforma social correspondiente.</li>
    </ul>
    <h4>Desactivación y eliminación de cookies</h4>
    <p>Tienes la opción de permitir, bloquear o eliminar las cookies instaladas en tu equipo mediante la configuración de las opciones del navegador instalado en su equipo. Al desactivar cookies, algunos de los servicios disponibles podrían dejar de estar
        operativos. La forma de deshabilitar las cookies es diferente para cada navegador, pero normalmente puede hacerse desde el menú Herramientas u Opciones. También puede consultarse el menú de Ayuda del navegador dónde puedes encontrar instrucciones.
        El usuario podrá en cualquier momento elegir qué cookies quiere que funcionen en este sitio web.</p>
    <p>Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador:</p>
    <ul>
        <li>Microsoft Internet Explorer o Microsoft Edge: <a class="alt" target="_blank" href="http://windows.microsoft.com/es-es/windows-vista/Block-or-allow-cookies">http://windows.microsoft.com/es-es/windows-vista/Block-or-allow-cookies</a></li>
        <li>Mozilla Firefox:<a class="alt" target="_blank" href="http://support.mozilla.org/es/kb/impedir-que-los-sitios-web-guarden-sus-preferencia">http://support.mozilla.org/es/kb/impedir-que-los-sitios-web-guarden-sus-preferencia</a></li>
        <li>Chrome: <a class="alt" target="_blank" href="https://support.google.com/accounts/answer/61416?hl=es">https://support.google.com/accounts/answer/61416?hl=es</a></li>
        <li>Safari: <a class="alt" target="_blank" href="http://safari.helpmax.net/es/privacidad-y-seguridad/como-gestionar-las-cookies/">http://safari.helpmax.net/es/privacidad-y-seguridad/como-gestionar-las-cookies/</a></li>
        <li>Opera: <a class="alt" target="_blank" href="http://help.opera.com/Linux/10.60/es-ES/cookies.html">http://help.opera.com/Linux/10.60/es-ES/cookies.html</a></li>
    </ul>
    <p>Además, también puede gestionar el almacén de cookies en su navegador a través de herramientas como las siguientes:</p>
    <ul>
        <li>Ghostery: <a class="alt" target="_blank" href="https://www.ghostery.com">www.ghostery.com</a></li>
        <li>Your Online Choices: <a class="alt" target="_blank" href="https://www.youronlinechoices.com/es/">www.youronlinechoices.com/es/</a></li>
    </ul>
    <p>Cookies utilizadas en <a class="alt" target="_blank" href="https://foodieshome.herokuapp.com/">www.foodieshome.herokuapp.com</a></p>
    <p>A continuación se identifican las cookies que están siendo utilizadas en este portal así como su tipología y función:</p>
    <ul>
        <li>_utma</li>
        <li>_ga</li>
    </ul>
    <p>Duración: 2 años</p>
    <p>Descripción: Sirve para distinguir a los usuarios. Generada por Google Analytics. Google almacena la información recogida por las cookies en servidores ubicados en Estados Unidos, cumpliendo con la legislación Europea en cuanto a protección de datos
        personales y se compromete a no compartirla con terceros, excepto cuando la ley le obligue a ello o sea necesario para el funcionamiento del sistema. Google no asocia su dirección IP con ninguna otra información que tenga. Si desea obtener más
        información acerca de las cookies usadas por Google Analytics, por favor acceda a esta dirección: <a class="alt" target="_blank" href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es&csw=1">https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es&csw=1</a></p>
    <p>Tipo: Tercero</p>
    <p>Finalidad: Analítica</p>
    <h4>Aceptación de la Política de cookies</h4>
    <p><a class="alt" target="_blank" href="https://foodieshome.herokuapp.com/">www.foodieshome.herokuapp.com</a>, asume que usted acepta el uso de cookies. No obstante, muestra información sobre su Política de cookies en la parte inferior o superior de
        cualquier página del portal con cada inicio de sesión con el objeto de que usted sea consciente.</p>
    <p>Ante esta información es posible llevar a cabo las siguientes acciones:</p>
    <ul>
        <li>Aceptar cookies: No se volverá a visualizar este aviso al acceder a cualquier página del portal durante la presente sesión.</li>
        <li>Cerrar: Se oculta el aviso en la presente página.</li>
        <li>Modificar su configuración: Podrá obtener más información sobre qué son las cookies, conocer la Política de cookies de: <a class="alt" target="_blank" href="https://foodieshome.herokuapp.com/">www.foodieshome.herokuapp.com</a> y modificar la configuración
            de su navegador.</li>
    </ul>
</div>