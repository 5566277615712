import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/backend/auth.service';
import { ModalService } from './services/modal.service';
import { VariablesService } from './services/variables.service';
import { CacheService } from './services/cache.service';
import { HelperService } from './services/helper.service';
import { LoginForm } from './interfaces/login-form.interface';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { SeoServiceService } from './services/seo-service.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'p20201003';
  // public ok: boolean;

  constructor(  public authService: AuthService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private seoService: SeoServiceService,
                private vs: VariablesService,
                private cs: CacheService,
                private hs: HelperService,
                private update: SwUpdate,
                private  modalService: ModalService    ) { 
                  this.authService.validateToken().subscribe( resp => {
                    if ( resp ) {
                      const friends = localStorage.getItem( 'friends' );
                      this.hs.setFriends( (friends === '1') );
                    }
                    // this.ok = true;
                  });
                  this.vs.getTools().subscribe();
                  this.vs.getCoins().subscribe();
                  this.vs.getCountries().subscribe();
                  // this.vs.getAddress().subscribe();
                }
  
  ngOnInit() {

    this.updateClient();   
    this.cs.cacheClear();
    
    //Metatags change
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(e => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe(data => {
      let seoData = data['seo'];
      if ( seoData ){
        if ( seoData['title'] ) this.seoService.updateTitle( seoData['title'] );
        if ( seoData['metaTags'] ) this.seoService.updateMetaTags( seoData['metaTags'] );
      }
    });

    let iter = 0;
    const interval = setInterval( () => {
      if ( this.authService.init ) {
        clearInterval( interval );
        if ( localStorage.getItem('token') && !this.authService.guard ) {
          this.modalService.setStatus('no-log', 'Vuelve a iniciar sesión, la anterior ha caducado');
          localStorage.removeItem('token');
        }
      } else if ( iter > 100 ) {
        clearInterval( interval );
        this.modalService.setStatus('warning', 'Hemos identificado posibles problemas de red, quizás el funcionamiento sea algo lento.');
        this.modalService.setStatus('no-log', 'Vuelve a iniciar sesión, la anterior ha caducado');
        localStorage.removeItem('token');
      } else {
        iter++;
      }
    }, 100 )

    const type = localStorage.getItem( 'type' );
    if ( type === 'restaurants' || type === 'recipes' ) this.hs.setType( type );

  }
 
  updateClient() {
    if ( !this.update.isEnabled ) return console.log( 'Last Version Running' );
    this.update.versionUpdates.subscribe( event => {
      if ( event.type === 'VERSION_READY' ) {
        const ok = confirm('Existe una nueva versión, ¿Desea actualizar?');
        if ( ok ) this.update.activateUpdate().then( () => window.location.reload() );
      }
    } );
  }
  
}