import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Report } from '../../models/report.model';
import { ModalService } from '../modal.service';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private headers: object;

  constructor(  private http: HttpClient,
                private modalService: ModalService,
                private authService: AuthService  ) {
      this.headers = authService.headers;
    }

  
  getReports( id: string | undefined ) {

    return this.http.get( `${ base_url }/report` )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  return of(false)
                } )
              );

  }

  getReportsFilter( id: string | undefined ) {

    return this.http.get( `${ base_url }/report/${ id }` )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  return of(false)
                } )
              );

  }

  createReport( report: Report ) {
    
    return this.http.post( `${ base_url }/report`, report, this.headers )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  return of(false)
                } )
              );

  }

  updateReport( id: string | undefined, report: Report ) {
    
    return this.http.put( `${ base_url }/report/${ id }`, report, this.headers )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  return of(false)
                } )
              );

  }

  reportReport( id: string | undefined ) {
    
    return this.http.put( `${ base_url }/report/report/${ id }`,{}, this.headers )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  return of(false)
                } )
              );

  }

  deleteReport( id: string | undefined ) {
    
    return this.http.delete( `${ base_url }/report/${ id }`, this.headers )
              .pipe(
                tap( (resp: any) => {
                  return resp;
                }),
                catchError( error => {
                  return of(false)
                } )
              );

  }

}
