import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../services/backend/auth.service';
import { ModalService } from '../../../services/modal.service';
import { ValidationService } from '../../../services/validation.service';
import { Router } from '@angular/router';


declare const google: any;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [
  ]
})
export class LoginComponent implements OnInit {

  public loginForm = this.fb.group({
    access: ['', Validators.required],
    password: ['', Validators.required],
    remember: [false],
  });
  
  private formSubmitted: boolean = false;
  public valid: boolean = true;

  public auth2: any;

  constructor(  private fb: FormBuilder,
                private authService: AuthService,
                public modalService: ModalService,
                private router: Router,
                private validationService: ValidationService,  ) { }

  ngOnInit(): void {
    if ( localStorage.getItem( 'access' ) ) {
      this.loginForm.controls['access'].setValue( localStorage.getItem( 'access' ) );
      this.loginForm.controls['remember'].setValue( true );
    }
    this.renderGoogle();
  }

  checkOpt() {
    
    const oldValue = this.loginForm.controls['remember'].value;
    this.loginForm.controls['remember'].setValue(!oldValue);

  }

  login() {

    this.formSubmitted = true;

    this.authService.login( this.loginForm.value ).subscribe(
      resp => {

        if ( resp.ok ) {
          if ( this.loginForm.get('remember')?.value ){
            localStorage.setItem('access', this.loginForm.get('access')?.value );
          } else {
            localStorage.removeItem('access');
          }
  
          this.formSubmitted = false;
          this.valid = true;
          this.modalService.closeModal()
        } else {
          this.valid = false;
        }

      }
    );

  }

  invalidField( field: string ): boolean {
    return this.validationService.invalidField( this.loginForm, field, this.formSubmitted);
  }

  renderGoogle() {
    
    google.accounts.id.initialize({
        client_id: "581126864935-4pp8isgfng30vt0v164vimunkl5qepbt.apps.googleusercontent.com",
        callback: ( response: any ) => {
          this.authService.loginGoogle( response ).subscribe();
        }
    });
    google.accounts.id.renderButton(
        document.getElementById("buttonDiv"), {
            theme: "outline",
            size: "large"
        } // customization attributes
    );
    google.accounts.id.prompt(); // also display the One Tap dialog

  }

}
