<app-loading *ngIf="loading"></app-loading>
<div *ngIf="!loading && content" class="card" [class.double-card]="double" [class.extend]="extend" [id]="'card'+cardId">

    <div class="card-head" [class.mb-05]="double">
        <div class="d-flex">
            <div *ngIf="type!=='restaurants' || (type==='restaurants' && contentType==='reviews')" class="d-flex w-100-33px">
                <a [routerLink]="'/profile/user/'+(user._id || user.id)" class="card-user">
                    <img [src]="user.img | img: 'users'" class="icon bd-bg-round mr-025" [alt]="user.name + ' img'" onerror="this.onerror=null;this.src='/assets/img/intern/no-img.svg'">
                    <!-- <p class="cl-main m-auto ml-0 mr-74px of-auto">{{ user.name | titlecase}}</p> -->
                    <p class="cl-main my-auto ml-0 mr-05 of-auto">{{ user.alias | lowercase }}</p>
                </a>
                <p *ngIf="double" class="m-auto mr-0"><small>{{ reviewContent.updatedAt | date: 'dd/MM/yyyy' }}</small></p>
            </div>
            <div *ngIf="type==='restaurants' && contentType!=='reviews'" class="card-loc">
                <a [href]="'https://www.google.es/maps/place?q=' + (content.name + ', ' + (content.address.addressplus ? (content.address.addressplus + ', ') : '') + content.address.town + ', ' + content.address.country)" target="_blank">
                    <p class="d-flex m-auto ml-0">
                        <img src="/assets/img/intern/icons/menus/geo.svg" class="icon xsm geo ml-0 mb-025" alt="geo img">
                        <span *ngIf="dist && content?.dist?.calculated" class="cl-main m-auto ml-0 fs-smd">{{ ((content?.dist?.calculated > 1000) ? (((content?.dist?.calculated / 1000) | number:'1.1-1') + ' km') : (((content?.dist?.calculated) | number:'1.1-1') + 'm')) }}</span>
                        <!-- <span *ngIf="dist" class="cl-main m-auto ml-0 fs-smd">{{ ((dist > 1) ? (dist + ' km') : ((dist * 100) + 'm')) }}</span> -->
                        <span *ngIf="!dist || !content?.dist?.calculated" class="cl-main m-auto ml-0 fs-smd">- Km</span>
                    </p>
                    <p class="ml-025 fs-s">{{ content.address.town | titlecase }}</p>
                </a>
            </div>
            <div *ngIf="!double" (click)="pannelChange()" class="card-score cr-pointer" [id]="'card-score'+cardId">
                <img src="/assets/img/intern/icons/menus/score.svg" alt="score" class="my-auto">
                <p>{{ ((avgScore) | number:'1.1-1') || '-' }}</p>
            </div>
        </div>

        <div *ngIf="!double" class="card-title flex-col">
            <a [routerLink]="'/'+type.slice(0,-1)+'/'+id" class="d-flex flex-col h-100">
                <p [class]="'lh-08 my-auto card-title-text idx-'+idx">{{ (content.name | titlecase) | autoFit:0:'.card-title-text.idx-'+idx:'.card .card-body':3 }}</p>
                <p *ngIf="type==='restaurants'" class="mt-0 mb-025 w-100">
                    <small class="d-flex">
                        <span class="o-5 m-auto ml-0">{{ (content.category!==24?'Cocina ':'') + (((content.category[0] | toStr:'restCategories' | Esp)  || '-') | titlecase) }}</span>
                        <span class="m-auto mr-0 cl-main-075">{{ (content.price?.price * priceAux) >= 60 ? '€€€+' : (content.price?.price * priceAux) >= 35 ? '€€€' : (content.price?.price * priceAux) >= 15 ? '€€' : '€'}}</span>
                    </small>
                </p>
            </a>

            <div *ngIf="pannel" class="card-category-pannel text-center fade-in" [id]="'pannel'+cardId">
                <div *ngIf="pannelScr" class="m-auto fade-in slow">
                    <p class="fs-xs cl-main mb-025">Puntuación:</p>
                    <div *ngIf="type==='restaurants'" class="row g-2">
                        <div *ngIf="content?.score?.recommended" class="col">
                            <p class="fs-xs o-75">Recomendado</p>
                            <img [src]="'assets/img/intern/icons/menus/'+moodStatus[content.score.recommended - 1]+'.svg'" alt="recommended" [class]="'icon md bd-rd-circle active bg-'+mood[content.score.recommended - 1]" [title]="category | Esp">
                        </div>
                        <div *ngIf="content?.score?.qualityprice" class="col">
                            <p class="fs-xs o-75">Calidad/Precio</p>
                            <img [src]="'assets/img/intern/icons/menus/'+mood[content.score.qualityprice - 1]+'.svg'" alt="qualityprice" [class]="'icon md bd-rd-circle active bg-'+mood[content.score.qualityprice - 1]" [title]="category | Esp">
                        </div>
                        <div *ngIf="content?.score?.atention" class="col">
                            <p class="fs-xs o-75">Atención</p>
                            <img [src]="'assets/img/intern/icons/menus/'+mood[content.score.atention - 1]+'.svg'" alt="atention" [class]="'icon md bd-rd-circle active bg-'+mood[content.score.atention - 1]" [title]="category | Esp">
                        </div>
                        <div *ngIf="content?.score?.decoration" class="col">
                            <p class="fs-xs o-75">Decoración</p>
                            <img [src]="'assets/img/intern/icons/menus/'+mood[content.score.decoration - 1]+'.svg'" alt="decoration" [class]="'icon md bd-rd-circle active bg-'+mood[content.score.decoration - 1]" [title]="category | Esp">
                        </div>
                    </div>
                    <div *ngIf="type==='recipes' && content?.score?.main" class="m-025">
                        <p class="fs-xs o-75">Principal</p>
                        <img [src]="'assets/img/intern/icons/menus/'+mood[content.score.main - 1]+'.svg'" alt="score" [class]="'icon sm bd-rd-circle active bg-'+mood[content.score.main - 1]" [title]="category | Esp">
                    </div>
                    <div *ngIf="!content?.score?.main && !content?.score?.recommended && !content?.score?.qualityprice && !content?.score?.atention && !content?.score?.decoration" class="col m-025">
                        <p class="o-5">No disponible</p>
                    </div>

                </div>
                <div *ngIf="type==='recipes' && !pannelScr" class="m-auto fade-in slow">
                    <div class="m-auto mb-0">
                        <p class="fs-xs cl-main mb-025">Categoría:</p>
                        <div class="d-flex js-center">
                            <div *ngIf="content.category!==2" class="m-025">
                                <img [src]="'assets/img/intern/icons/categories/'+category+'.svg'" [alt]="category" [class]="'icon sm card-category bd-rd-circle bg-'+category" [title]="category | Esp">
                                <p class="fs-xs o-75">{{ category | Esp | titlecase }}</p>
                            </div>
                            <div *ngIf="content.subcategory>0" class="m-025">
                                <img [src]="'assets/img/intern/icons/subcategories/'+subcategory+'.svg'" [alt]="subcategory" [class]="'icon sm card-category bd-rd-circle bg-'+subcategory" [title]="subcategory | Esp">
                                <p class="fs-xs o-75">{{ subcategory | Esp | titlecase }}</p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="condLength>0" class="m-auto mt-0">
                        <p class="fs-xs cl-main mb-025">Condiciones:</p>
                        <div class="d-flex js-center">
                            <div *ngFor="let condition of conditionsSel" class="m-025">
                                <img [src]="'assets/img/intern/icons/conditions/'+condition+'.svg'" [alt]="condition" class="icon sm bd-rd-circle bg-grey-o75" [title]="conditions">
                                <p class="fs-xs o-75">{{ condition | Esp | titlecase }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div *ngIf="type==='recipes'" [class]="'card-time px-'+(double?'0 my-auto mb-0':'05')" [class.double-bottom]="double"> -->
            <div *ngIf="type==='recipes'" class="card-time" [class.double-bottom]="double">
                <p><small class="cl-main">{{ content.time | hourMin }}</small></p>

                <div class="card-category-box" [class.double-category]="double">
                    <img (click)="pannelChange(false)" src="assets/img/intern/icons/status/info.svg" alt="info" class="icon xsm cr-pointer" title="info" [id]="'pannelBtn'+cardId">
                </div>
            </div>
        </div>
        <div *ngIf="double" class="d-flex mt-05">
            <a [routerLink]="'/'+type.slice(0,-1)+'/'+id">
                <div class="">
                    <img [src]="content.img | img:type" [alt]="'Imagen '+content.name" class="bd-rds-10 icon lg">
                </div>
            </a>
            <div class="card-title ml-05 col">
                <div class="m-auto ml-0">
                    <a [routerLink]="'/'+type.slice(0,-1)+'/'+id">
                        <p [class]="'of-auto card-title-text idx-'+idx">{{ (content.name | titlecase) | autoFit:0:'.card-title-text.idx-'+idx:'.card-title':2:8:true:24 }}</p>
                        <p *ngIf="type==='restaurants'" class="o-5"><small>{{ (content.category!==24?'Cocina ':'') + (content.category[0] | toStr:'restCategories' | Esp | titlecase) }}</small></p>
                        <p *ngIf="type==='recipes'" class="o-5"><small>{{ ( (content.subcategory?(content.subcategory | toStr:'subcategory'):(content.category | toStr:'category')) | Esp | titlecase) }}</small></p>
                    </a>
                    <p [class]="'mt-025 cl-'+recommendationColor[(reviewContent.recommended || 0) - 1]">{{ recommendation[(reviewContent.recommended || 0) - 1] | uppercase }}</p>
                </div>
            </div>
            <p *ngIf="type==='restaurants' && contentType==='reviews'" class="title price">{{ reviewContent?.price?.price }}<small>{{ reviewContent?.price?.coin === 'EUR'?'€':reviewContent?.price?.coin }}</small></p>
        </div>
    </div>

    <div class="card-body w-100" [class.h-fc]="!double">
        <div class="w-100">
            <a [class.d-none]='double' [routerLink]="'/'+type.slice(0,-1)+'/'+id">
                <div class="card-img">
                    <img [src]="(content.img | img:type)" [alt]="'Imagen '+content.name" class="img-content">
                </div>
            </a>

            <div *ngIf='double && reviewContent'>
                <app-review-content [type]="type" [contentName]="content.name" [reviewContent]="reviewContent" (extendEmit)="extend=$event"></app-review-content>
            </div>

        </div>
    </div>

    <div *ngIf="!double" class="card-user-review d-flex">
        <p *ngIf="friendReview" class="m-auto ml-05 mb-0 lh-06 fs-s cr-pointer">
            <a [routerLink]="'/profile/user/'+(friendReview._id || friendReview.id)">
                <img [src]="friendReview.img | img: 'users'" class="icon xxsm bd-bg-round mr-025" [alt]="friendReview.alias + ' img'" onerror="this.onerror=null;this.src='/assets/img/intern/no-img.svg'">
                <b class="o-5">{{ friendReview.alias | slice:0:11 | lowercase }}{{ (friendReview.alias.length>11?'...':'') }}</b>
                <span class="o-5"> ha{{ ownReview?'s':'' }} reseñado</span>
            </a>
        </p>
    </div>

    <div class="fav-box" [class.double-fav-box]="double">
        <div (click)="reviews=!reviews" class="pst-relative my-auto mb-0 cr-pointer" [id]="'revBtn'+cardId">
            <img src="/assets/img/intern/icons/menus/replies-l.svg" alt="reviews" class="p-025 pb-0 icon md cr-pointer">
            <p class="count-reviews o-5 fs-xs">{{ countReviews?(countReviews>99?'99+':countReviews):0 }}</p>
        </div>
        <img (click)="addLater()" [src]="'/assets/img/intern/icons/menus/later'+(isLater?'-fill':'')+'.svg'" alt="later" class="later icon md p-01 pb-0">
        <img (click)="addFavs()" [src]="'/assets/img/intern/icons/menus/heart'+(isFav?'-fill':'')+'.svg'" alt="heart" class="icon md p-015 pb-0 cr-pointer mx-0 my-auto mb-0">
    </div>

    <div class="modal fade-in" [class.d-none]="!reviews">
        <div *ngIf="contentType!=='reviews'" class="modal-box" [id]="'modal-box'+cardId">
            <img (click)="reviews=false" src="assets/img/intern/icons/menus/close.svg" alt="close" class="modal-close">
            <div class="modal-of">
                <app-reviews-concat *ngIf="reviews" [content]="content" [model]="type==='restaurants'?'Restaurant':'Recipe'"></app-reviews-concat>
            </div>
        </div>
        <div *ngIf="contentType==='reviews'" class="modal-box" [id]="'modal-box-reply'+cardId">
            <img (click)="reviews=false" src="assets/img/intern/icons/menus/close.svg" alt="close" class="modal-close">
            <div class="modal-of">
                <p>{{ reviewContent?.observations ? (reviewContent.observations.length > 150 ? ((reviewContent.observations | slice:0:150) + '...') : reviewContent.observations) : 'No hay comentarios añadidos' }}</p>
                <app-reply-form [ref]="reviewContent.id || reviewContent._id" [model]="type==='restaurants'?'RestaurantReview':'RecipeReview'" (replyEmit)="repliesReload()"></app-reply-form>
                <app-replies [ref]="reviewContent.id || reviewContent._id" (repliesCount)="countReviews=$event"></app-replies>
            </div>
        </div>
    </div>

</div>