<div *ngIf="content" class="elaboration-table mt-1 mb-2 row g-21">
    <div class="col w-md-30 p-0 mr-05 text-center scroll-show">
        <img *ngIf="!noImg" (click)="openModal('image');setImg(content.img)" [src]="content.img | img:'recipes'" [alt]="content.name" class="in-img mb-1">
        <table class="bd-r w-100 p-05 bg-aux-025">
            <tr>
                <th></th>
                <th>Ingrediente</th>
                <th>Cantidad</th>
            </tr>
            <tr *ngFor="let ingredient of content.ingredients, let i=index" [id]="'ing'+(content.id||content._id)+i">
                <td><img (click)="checked(i)" src="/assets/img/intern/icons/menus/check.svg" alt="check" class="icon xsm o-25 animated fast cr-pointer"></td>
                <td class="pr-05 ing">
                    <div class="d-flex pst-relative">
                        <span class="mr-05">{{ ingredient.ingredient }}</span>
                    </div>
                </td>
                <td>
                    <div class="d-flex pst-relative">
                        <span class="mr-05 lh-1">{{ ingredient.units === 0 ? '' : ((ingredient.quantity * portions) | numOpt) }} <small>{{ (ingredient.units | toStr:'units') | Units:(ingredient.units === 0?'bg':'sm') }}</small></span>
                        <div class="pst-relative m-auto mr-0">
                            <img (click)="advise(ingredient)" src="/assets/img/intern/icons/status/info.svg" alt="info" class="icon xsm cr-pointer">
                        </div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
    <div class="col m-auto mr-0 mt-05 scroll-show">
        <div *ngFor="let step of content.steps; let i=index" class="mb-2 animated fast step" [id]="'step'+(content.id||content._id)+i">
            <p>
                <img (click)="checked(i, 'step')" src="/assets/img/intern/icons/menus/check.svg" alt="check" class="icon xsm o-25 animated fast cr-pointer mr-025">
                <b (click)="checked(i, 'step')" class="enf cr-pointer">Paso {{ i + 1 }}: </b>
                <span [innerHtml]="step.step | ingFind:ingArr()"></span>
            </p>
        </div>
    </div>
    <span *ngIf="ingShow" class="modal-box ing fade-in-out">
        <p class="o-5">Familia:</p>
        <div *ngFor="let family of ing.foodFamily" class="d-flex js-center">
            <img  [src]="'/assets/img/intern/icons/ingredients/'+family+'.svg'" [alt]="family" class="icon xsm mr-025">
            <p>{{ family | Esp }}</p>
        </div>
        <p class="o-5">Unidades:</p>
        <p>{{ (ing.units | toStr:'units') | Units:'bg' }}</p>
    </span>
</div>