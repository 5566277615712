import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../services/backend/auth.service';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'app-forgotten',
  templateUrl: './forgotten.component.html',
  styles: [
  ]
})
export class ForgottenComponent implements OnInit {

  public contactForm: FormGroup;
  public formSubmitted: boolean = false;

  constructor(  private authService: AuthService,
                private router: Router,
                private fb: FormBuilder,
                public modalService: ModalService  ) { }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      email: [ '' , [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')] ]
    });
  }


  forgotten() {

    this.authService.forgotten( this.contactForm.value )
      .subscribe( resp => {

        this.router.navigateByUrl('/');
        this.modalService.setStatus( "ok", "La nueva contraseña llegará a tu correo electrónico" )
        this.modalService.openModal( "close" );

      }, (err) => {

        this.modalService.setStatus( "error",  err.error.msg )
        this.modalService.openModal( "close" );

      });
      
  }
  
}
