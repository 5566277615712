import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button-group',
  templateUrl: './button-group.component.html',
  styles: [
  ]
})
export class ButtonGroupComponent implements OnInit {

  @Input() active: any;
  @Input() buttons: string[] = ['no', 'sí'];
  @Input() mandatory: boolean = false;
  @Output() emitter: EventEmitter< any > = new EventEmitter();

  public activeOpt: number;

  constructor() { }

  ngOnInit(): void {
    if ( typeof this.active === 'number' ) {
      this.activeOpt = this.active;
    } else {
      this.activeOpt = ((this.active === true) ? 1 : ((this.active === false) ? 2 : 0));
    }
  }

  emit( val: any ) {
    this.activeOpt = ( ((this.activeOpt === val) && !this.mandatory) ? '' : val );
    this.emitter.emit( this.activeOpt );
  }

}
