<app-search-icon-selector [options]="options[type]" [optionsSelected]="optionsSelection[type]" [type]="type" (optionsEmit)="optionsSelection[type]=$event"></app-search-icon-selector>

<div *ngIf="type==='tools' ">
    <div class="mt-05">
        <p><small>¿No encuentras el utensilio?</small></p>
        <a (click)="more=!more" class="alt">{{ more?'Esconder -':'Añadir nuevos +' }}</a>
    </div>
    <div *ngIf="more" class="mt-05 grow-down">
        <label>Otros Utensilios</label>
        <app-pills (pillsEmit)="optForm.controls['others'].setValue($event)" [placesHolder]="'utensilios'"></app-pills>
    </div>
</div>
<button (click)="saveOpt()" class="btn-red sm mt-05">Ok</button>