import { Component, OnInit } from '@angular/core';
import { Restaurant } from '../../../models/restaurant.model';
import { HelperService } from '../../../services/helper.service';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'app-exist',
  templateUrl: './exist.component.html',
  styles: [
  ]
})
export class ExistComponent implements OnInit {

  public search: Restaurant[] = [];

  constructor(  private helperService: HelperService,
                private modalService: ModalService  ) { }

  ngOnInit(): void {
    this.search = this.helperService.array;
  }

  contentClick( idx: number ) {
    this.helperService.setSelection( this.search[idx] );
    this.modalService.setOk(true);
    this.modalService.closeModal();
  }

  ok( ok: boolean ) {
    this.modalService.setOk(ok);
    this.modalService.closeModal();
  }
  
}
