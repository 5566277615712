import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { HelperService } from '../../services/helper.service';
import { VariablesService } from '../../services/variables.service';

@Component({
  selector: 'app-icon-selected',
  templateUrl: './icon-selected.component.html',
  styles: [
  ]
})
export class IconSelectedComponent implements OnInit {

  @Input() optionsSelected: string[] = [];
  @Input() type: string;
  @Output() selectedEmit: EventEmitter< any > = new EventEmitter();
  
  constructor(  private helperService: HelperService,
                private vs: VariablesService  ) { }

  ngOnInit(): void {
  }

  checkOpt( option: string ) {

    this.helperService.deletingChange( true );
    return this.selection( this.optionsSelected, option );
    
  }

  selection( selection: any, option: string ) {
    
    const include = selection.indexOf( option );
    if ( include >= 0 ) {
      selection.splice( include, 1 );
    } else {
      selection.push( option );
    }
    
    this.selectedEmit.emit( this.optionsSelected );

  }


  iconSel( idx: number, option: string ) {
    
    if ( this.type === 'tools' ) {
      return this.vs.categorySelect( option );
    }
    return option;

  }

}
