import { Pipe, PipeTransform } from '@angular/core';
import { VariablesService } from '../services/variables.service';

@Pipe({
  name: 'toStr'
})
export class ToStrPipe implements PipeTransform {

  public categories: string[];
  public subcategories: any[];
  public conditions: string[];
  public mesures: string[];
  public difficulties: string[];
  public tools: string[];
  public restCategories: string[];
  public restSubcategories: string[];
  public specialities: string[];
  public restaurantTypes: string[];
  

  constructor (  private vs: VariablesService  ) {
    this.categories = vs.categories;
    this.subcategories = vs.fullSubcategories;
    this.conditions = vs.conditions;
    this.mesures = vs.mesures;
    this.difficulties = vs.difficulties;
    this.tools = vs.tools.tools;
    this.restCategories = vs.restCategories;
    this.specialities = vs.specialities;
    this.restaurantTypes = this.vs.restaurantTypes;
  }

  transform( index: number, option: string  ) {
    switch ( option ) {
      case'category':
        return this.categories[ index ];
      case'subcategory':
        return this.subcategories[ index ];
      case'conditions':
        return this.conditions[ index ];
      case'units':
        return this.mesures[ index ];
      case'difficulty':
        return this.difficulties[ index ];
      case 'tools':
        return this.tools[ index ];
      case 'restCategories':
        return this.restCategories[ index ];
      case 'specialities':
        return this.specialities[ index ];
      case 'restaurantTypes':
        return this.restaurantTypes[ index ];
    }
  }

}
