<form class="text-center scroll-show p-05 filters">

    <div *ngIf="contentType==='reviews'">
        <p>Recomendado</p>
        <div class="mt-1">
            <app-mood-score [scoreArr]="recommended || []" [moods]="['bad', 'normal', 'good']" [buttons]="['no', 'sin más', 'sí']" [multi]="true" (scoreEmitter)="pushRecom($event)"></app-mood-score>
        </div>
        <hr>
    </div>

    <p>Tiempo de elaboración:</p>
    <div class="py-05">
        <div class="time-stairs-box">
            <button (click)="timeSel(-1)" type="button" class="btn-rm mb-0">-</button>

            <div class="time-stairs">
                <button (click)="timePos=i" *ngFor="let time of timeArray, let i = index" type="button" [class]="'time-stair-'+time" [class.time-selected]="timePos>=i"></button>
            </div>

            <button (click)="timeSel(1)" type="button" class="btn-add mb-0">+</button>
        </div>
        <p>{{ timePos === 5 ? 'Sin límite' : ('hasta '+timeArray[timePos]+' min') }}</p>
    </div>

    <hr>
    <p>Condiciones</p>
    <app-icon-selector [options]="conditions" [type]="'conditions'" [oldOptSelected]="conditionsSel" [grid]="'63'" (selectedEmit)="conditionsSel=$event" #conditionsIcons></app-icon-selector>

    <hr>
    <p>Categorías</p>
    <app-icon-selector [options]="categories" [type]="'categories'" [oldOptSelected]="categoriesSel" [grid]="'63'" (selectedEmit)="categoriesSel=$event" #categoriesIcons></app-icon-selector>

    <hr>
    <p>Tipo de alimentos</p>
    <app-icon-selector [options]="subcategories" [type]="'subcategories'" [oldOptSelected]="subcategoriesSel" [grid]="'643'" (selectedEmit)="subcategoriesSel=$event" #subcategoriesIcons></app-icon-selector>

    <hr>
    <p>Tipo de bebida</p>
    <app-icon-selector [options]="drinkSubcategories" [type]="'subcategories'" [oldOptSelected]="drinkSubcategoriesSel" [grid]="'643'" (selectedEmit)="drinkSubcategoriesSel=$event" #drinkSubcategoriesIcons></app-icon-selector>

    <hr>
    <p>Alergias</p>
    <app-search-icon-selector [options]="foodFamilies" [type]="'ingredients'" [optionsSelected]="ingredientsSel" #ingredientsIcons></app-search-icon-selector>

</form>
<div class="align-center pt-05 bd-t">
    <button type="button" (click)="reset()" class="btn-red sm">Reset</button>
    <button type="button" (click)="filterChange()" class="btn-red sm">Ok</button>
</div>