<div *ngIf="following.length > 0" class="down-btn">
    <div *ngFor="let user of following">
        <!-- (click)="shareWith(user)" -->
        <div class="d-flex p-1 cr-pointer">
            <img [src]="user.img | img:'users'" alt="User Img" class="icon bd-bg-round mr-05">
            <p class="m-auto ml-0">{{ user.name }}</p>
            <button (click)="shareWith(user)" class="sm light btn-red-ol ml-1" [class.btn-red-ol]="!permissions.includes(user.id)" [class.btn-red]="permissions.includes(user.id)">
                {{ permissions.includes(user.id)?'Compartido':'Compartir' }}
            </button>
        </div>
    </div>
</div>
<div *ngIf="following.length <= 0">
    <p>Todavía no sigues a nadie</p>
</div>
<button (click)="close()" class="btn-red mt-1">Close</button>