import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { Recipe } from '../../models/recipe.model';
import { TypeChangeService } from '../../services/type-change.service';
import { RecipesService } from '../../services/backend/recipes.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/backend/auth.service';
import { ModalService } from '../../services/modal.service';
import { VariablesService } from '../../services/variables.service';
import { RecipeReview } from '../../models/recipe-review.model';
import { RecipeReviewsService } from '../../services/backend/recipe-reviews.service';
import { User } from '../../models/user.model';
import { CacheService } from '../../services/cache.service';
import { UserService } from '../../services/backend/user.service';
import { HelperService } from '../../services/helper.service';
import { environment } from 'environments/environment';
import { SeoServiceService } from '../../services/seo-service.service';

const base_url = environment.base_url;


@Component({
  selector: 'app-recipe',
  templateUrl: './recipe.component.html',
  styles: [
  ]
})
export class RecipeComponent implements OnInit {

  public content: Recipe | any;
  public img: string;

  public category: string;
  public subcategory: string;
  public conditionsSel: string[] = [];
  public toolsSel: string[] = [];

  public categories: string[];
  public subcategories: any[];
  public conditions: string[];
  public tools: any = [];
  
  public owner: boolean = false;
  public edit: boolean = false;
  public addReview: boolean = false;
  public loading: boolean = false;
  public reviewed: boolean = true;
  
  public uid: string;
  private mainUser: User;
  public isFav: boolean = false;
  public isLater: boolean = false;
  public condLength: number = 0;
  public toolsLength: number = 0;
  public allIngredientsFamilies: string[] = [];
  public allIngredients: string[] = [];
  public avgScore: number | undefined;
  public avgFriendsScore: any;
  public model: string = 'Recipe';
  public reviewsCount: number = 0;
  private id: string;

  public recommendation: string[] = ['no recomendado', 'no está mal...', 'recomendado'];
  public recommendationColor: string[] = ['error', 'warning', 'ok'];

  public portions: number = 1;

  constructor(  private recipesService: RecipesService,
                private typeChangeService: TypeChangeService,
                private recipeReviewsService: RecipeReviewsService,
                private activatedRoute: ActivatedRoute,
                private authService: AuthService,
                private userService: UserService,
                private modalService: ModalService,
                private location: Location,
                private seoService: SeoServiceService,
                private cs: CacheService,
                private hs: HelperService,
                private vs: VariablesService ) {
                  this.categories = vs.categories;
                  this.subcategories = vs.subcategories;
                  this.conditions = vs.conditions;
                  this.mainUser = authService.user;
                }

  async ngOnInit() {

    this.loading = true;
    this.tools= this.vs.tools.tools;
    
    const { id } = this.activatedRoute.snapshot.params;
    this.id = id;

    this.recipesService.getRecipesById( id ).subscribe(  resp => {
      this.content = resp.recipe;
      this.allIngredientsFamilies = resp.recipe?.ingredientsFamilies || [];
      this.portions = this.content?.portions;
      this.avgScore = this.scoreAlg( resp.recipe.score );
      // this.hs.setShare( resp.recipe.img, resp.recipe.name, 'recipes' );
      const meta = [
        { name: 'description', content: 'Únete a la comunidad de los Foodies. Encuentra las mejores recetas con las valoraciones de tus contactos de confianza.' },
        { property: 'og:locale', content: 'es_ES'},
        { property: 'og:type', content: 'web-app' },
        { property: 'og:title', content: 'FoodiesHome: ' + resp.recipe.name },
        { proprety: 'og:description', content: 'Únete a la comunidad de los Foodies. Encuentra las mejores recetas con las valoraciones de tus contactos de confianza.' },
        { property: 'og:image', content: 'https://storage.googleapis.com/foodieshome-uploads/uploads/recipes/' + resp.recipe.img + '.jpeg' },
        { property: 'og:url', content: base_url + 'recipe/'+ id },
        { name: "twitter:card", content: "summary_large_image" },
      ];
      this.seoService.updateMetaTags( meta );
      this.seoService.updateTitle( 'FoodiesHome | ' + resp.recipe.name );
    } );

    this.uid = this.authService.uid;
    

    const friendsArr = this.mainUser?.follow?.users?.array || [];
    if ( this.mainUser && friendsArr.length > 0 ) {
      this.recipeReviewsService.getRecipeReviewsAvgFriends( id, friendsArr ).subscribe( resp => { 
        if (resp.ok ) this.avgFriendsScore = this.scoreAlg( resp.avg ); 
      } );
    }

    let iter = 0;
    
    const interval = setInterval( () => {

      if ( this.content ) {

        clearInterval(interval);
        
        if ( this.uid === (this.content.user._id || this.content.user.id) || this.mainUser?.role === 'ADMIN_ROLE' ) {
          this.owner = true;
          this.modalService.setImgEdit( true, (this.content.id || this.content._id) );
        }

        this.fav();
        this.later();

        if ( this.content.conditions ) {
          this.conditionsSel = this.typeChangeService.numToStrArr( this.content.conditions, this.conditions );
          this.condLength = this.content.conditions.length;
        }
        
        this.allIngredients = [];
        if ( this.content?.ingredients?.length > 0 ) {
          for ( let contIng of this.content.ingredients ) {
            this.allIngredients.push( contIng.ingredient );
          }
        }

        if ( this.content?.elaborations && (this.content?.elaborations?.length > 0) ) {
          let toolsAdder = [];
          this.model = 'Recipe';
          
          for ( let elaboration of (this.content?.elaborations || []) ) {
            for ( let tool of (elaboration?.tools || []) ) {
              if ( toolsAdder.indexOf( tool ) < 0 ) {
                toolsAdder.push( tool );
              }
            }
            
            for ( let family of (elaboration?.ingredientsFamilies || []) ) {
              if ( !this.allIngredientsFamilies.includes( family ) ) this.allIngredientsFamilies.push( family );
            }
            
            // let i = 0;
            // if (elaboration?.ingredients && elaboration?.ingredients.length > 0) {
            //   for (let ing of elaboration.ingredients) {
            //     this.allIngredients.push( ing.ingredient );
            //     elaboration.ingredients[i].quantity = Math.round((ing.quantity) * 100 ) / 100;
            //     // elaboration.ingredients[i].quantity = Math.round((ing.quantity * this.content.portions) * 100 ) / 100;
            //     i++;
            //   }
            // }
          }
          
          this.toolsLength = toolsAdder.length;
          this.toolsSel = this.typeChangeService.numToStrArr( toolsAdder, this.tools );
          
        }
        
        // let j = 0;
        // if (this.content.ingredients && this.content.ingredients.length > 0) {
        //   for (let ing of this.content.ingredients) {
        //     this.content.ingredients[j].quantity = Math.round((ing.quantity) * 100) / 100;
        //     // this.content.ingredients[j].quantity = Math.round((ing.quantity * this.content.portions) * 100) / 100;
        //     j++;
        //   }
        // }
        
        if ( this.content.tools ) {
          this.toolsLength = this.content.tools.length;
          this.toolsSel = this.typeChangeService.numToStrArr( this.content.tools, this.tools );
        }
          
        this.loading = false;
        
        this.activatedRoute.fragment.subscribe( (fragment: any) => {
          setTimeout(()=> {
            document.getElementById(fragment || '')?.scrollIntoView( { behavior: 'smooth' } );
            this.location.replaceState( this.location.path() );
          }, 200)
        });

      } else if ( iter > 20 ) {

        clearInterval(interval);
        this.loading = false;
        this.modalService.setStatus( 'error', 'Error en el proceso, compruebe su conexión e intentelo de nuevo' );

      } else {
        iter++;
      }

    }, 100);

  }

  ngOnDestroy() {
    if ( this.content.user._id === this.uid ) this.modalService.setImgEdit( false, '' );
  }
  
  scoreAlg( score: any ) {
    if ( !score?.recommended || !score?.main) return;
    return ( (score.recommended - 1) * 0.6 * 3/2 + (score.main - 1) * 0.4 ) * 10/3;
  }

  addFavs() {
    
    if ( !this.isFav && this.uid ) this.modalService.openAdviseBanner( 'en favoritos' );

    const id = this.content.id;
    this.recipesService.addFavs( id?id:'' ).subscribe( resp => {
      if ( resp.ok) {
        if ( this.isFav && this.mainUser?.favs?.recipes?.array && (this.mainUser?.favs?.recipes?.array.indexOf( id ) >= 0) ) {
          this.mainUser?.favs.recipes?.array.splice( this.mainUser?.favs.recipes?.array.indexOf( id ), 1)
        } else if( this.mainUser?.favs?.recipes?.array ) {
          this.mainUser?.favs.recipes.array?.push( id );
        } else if ( this.mainUser ) {
          this.mainUser = { ...this.mainUser, favs: { recipes: { array:  [ id ] } } };
        }
        this.cs.cacheReset( 'recipesfavsById' );
        this.authService.user = { ...this.authService.user, favs: { recipes: { array: ( this.mainUser?.favs?.recipes?.array  || [] ) } } };
        return this.isFav = !this.isFav;
      }
      this.modalService.setStatus( resp.nolog ? 'no-log' : 'error', resp.msg );
    });

  }
  
  fav() {
    if ( this.mainUser?.favs?.recipes?.array ) {
      const fav = this.mainUser?.favs?.recipes?.array.indexOf( this.content.id ) >= 0;
      this.isFav = fav;
    }
  }

  addLater() {

    if ( !this.isLater && this.uid ) this.modalService.openAdviseBanner( 'para más tarde' );

    this.userService.addLater( this.id, 'recipes' ).subscribe( resp => {
      if ( resp.ok) {
        let laterArr: any = false,
            laterPos: any = -1
        if ( this.mainUser?.later && this.mainUser?.later?.recipes ) {
          laterPos = this.mainUser?.later?.recipes?.indexOf( this.id );
          laterPos = (laterPos===0) ? 0 : (laterPos || -1);
          laterArr = this.mainUser?.later?.recipes || false;
        }
        if ( this.isLater && this.mainUser.later && laterArr && (laterPos >= 0) ) {
          this.mainUser.later.recipes?.splice( laterPos, 1);
        } else if( this.mainUser.later  && laterArr ) {
          this.mainUser.later.recipes?.push( this.id );
        } else if ( this.mainUser ) {
          this.mainUser = { ...this.mainUser, later: { recipes: [ this.id ] } };
        }
        this.cs.cacheReset( 'recipeslaterById' );
        this.authService.user = { ...this.authService.user, later: { recipes: ( laterArr  || [] ) } };
        return this.isLater = !this.isLater;
      }
      this.modalService.setStatus( resp.nolog ? 'no-log' : 'error', resp.msg );
    });
  }

  later() {
    const userLaterType: any = this.mainUser?.later?.recipes || false;
    if ( userLaterType ){
      let laterIdx = userLaterType.indexOf( this.id );
      this.isLater = (laterIdx >= 0);
    }
  }

  openModal( type: 'login' | 'register' | 'image' | 'crop' | 'tools' | 'ingredients' ) {
    this.modalService.openModal( type );
  }

  setImg( img: string ) {
    this.modalService.setImg( img, 'recipes', this.content.updatedAt || '', this.content.name );
  }

  obs( i: any ) {
    
    const check = document.getElementById( 'check' + i );
    const plus = document.getElementById( 'plus' + i );
    const minus = document.getElementById( 'minus' + i );
    if ( check?.classList.contains( 'd-none' ) ) {
      check?.classList.remove( 'd-none' );
      plus?.classList.add( 'd-none' );
      minus?.classList.remove( 'd-none' );
    } else {
      check?.classList.add( 'd-none' );
      plus?.classList.remove( 'd-none' );
      minus?.classList.add( 'd-none' );
    }
    
  }

  categorySelect( option: string ){
    return this.vs.categorySelect( option );
  }

  // Aux
  scroll( scroll: boolean ) {

    if ( scroll ) {
      const head = document.getElementsByClassName( 'in-head' )[0];
      const scrollY = head.clientHeight || 300;
      
      setTimeout( () => {
        window.scrollTo({ top: scrollY, behavior: 'smooth' });
      }, 100 );
    }

  }

  portionsChange( val: any, sum: number = 0 ) {
    const portion = parseInt( val );
    this.portions = ((sum < 0) && (portion === 0)) ? 0 : (portion + sum);
  }
  
  scrollRev() {
    document.getElementById('reviews')?.scrollIntoView({ behavior: 'smooth' });
  }

}
