<div [class.container]="!main" class="elaborations-form">
    <form *ngIf="!foreign" (change)="recipeCache();" (keydown.enter)="enter($event)" (ngSubmit)="saveRecipe()" [formGroup]="newRecipeForm">

        <div *ngIf="!elaboration && !main || foreign && !main" class="w-md-60 m-auto mt-2 mb-1">

            <p class="text-center">¿Elaboraciones de otros usuarios?</p>
            <input (click)="searchOpen=true" (keyup)="searchTerm=term.value;search();" type="search" placeholder="Buscar elaboraciones..." id="search-elaboration" autocomplete="false" #term>

            <div class="elaboration-search-box" [class.d-none]="(searchContents?searchContents.length<=0:true) || !searchOpen">
                <div (scroll)="scroll( searchResult )" class="elaboration-search" id="search-result" #searchResult>
                    <div (click)="externRecipe(content)" *ngFor="let content of searchContents" class="d-flex pst-relative">
                        <img [src]="content.img | img: 'recipes'" [alt]="content.name" class="bd-rds-20">
                        <div class="m-auto">
                            <p class="m-0">{{ content.name }}</p>
                            <div class="in-category d-flex js-md-center">
                                <p class="my-auto"><small class="my-auto">by</small></p>
                                <span class="mx-025 my-auto"> {{ content.user.name | titlecase }}</span>
                                <img [src]="content.user.img | img: 'users'" alt="profile img" class="icon sm w-fc bd-bg-round" onerror="this.onerror=null;this.src='/assets/img/intern/no-img.svg'">
                            </div>
                            <p class="elaboration-number"><small>Elaboraciones: {{content.elaborations?(content.elaborations.length>0?content.elaborations.length:1):1}}</small></p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <p for="title">General*</p>
        <div class="row g-21 mt-1">
            <div class="col w-fc">
                <app-img-select [img]="elaboration?elaboration.img:'no-img'" [type]="main?'recipes':'elaborations'" [error]="invalidField('img')" (imgEmit)="img=$event;newImg=true;recipeCache();"></app-img-select>
                <p *ngIf="invalidField('img')" class="error-text">Debe contener imagen</p>
            </div>
            <div class="col mt-mobile-1">
                <div>
                    <label for="name"><small>Nombre*</small></label>
                    <input type="text" formControlName="name" placeholder="*Nombre de la elaboración..." [class.error]="invalidField('name')">
                    <p *ngIf="invalidField('name')" class="error-text">Revisar</p>
                </div>
                <div class="row g-2 col-m-0">
                    <div class="col pr-025">
                        <label for="category"><small>Categoría*</small></label>
                        <select (change)="auxSubcategories=(category.value==='drinks'?drinkSubcategories:subcategories)" formControlName="category" placeholder="*Categoría..." [class.error]="invalidField('category')" #category>
                            <option *ngFor="let category of categories" [value]="category" #categoryText>{{ category | Esp | titlecase }}</option>
                        </select>
                        <p *ngIf="invalidField('category')" class="error-text">Revisar</p>
                    </div>
                    <div class="col pl-025">
                        <label for="subcategory"><small>Familia*</small></label>
                        <select formControlName="subcategory" [class.error]="invalidField('subcategory')">
                            <option *ngFor="let subcategory of auxSubcategories" [value]="subcategory">{{ subcategory | Esp | titlecase }}</option>
                        </select>
                        <p *ngIf="invalidField('subcategory')" class="error-text">Revisar</p>
                    </div>
                    <!-- Second line -->
                    <div class="col pr-025">
                        <label for="portions"><small>Raciones*</small></label>
                        <input type="number" formControlName="portions" placeholder="*Número de raciones..." [class.error]="invalidField('portions')" id="portionsRecipe">
                        <p *ngIf="invalidField('portions')" class="error-text">Revisar</p>
                    </div>
                    <div class="col pl-025">
                        <label for="time"><small>Tiempo*</small></label>
                        <div class="pst-relative">
                            <input type="number" formControlName="time" placeholder="*Tiempo..." [class.error]="invalidField('time')">
                            <p (click)="advise('showAdvise2')" class="units-label" title="Minutos">min</p>
                            <p id="showAdvise2" class="advise">
                                Tiempo en minutos
                            </p>
                            <p *ngIf="invalidField('time')" class="error-text">Revisar</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-1">
            <p>Dificultad*</p>
            <div class="mt-1 mb-2 text-center">
                <app-button-group [active]="(difficulties.indexOf(newRecipeForm.controls['difficulty'].value) + 1)" [buttons]="['baja', 'media', 'alta']" (emitter)="setDifficulty($event)"></app-button-group>
            </div>
            <p *ngIf="invalidField('difficulty')" class="error-text">Campo obligatorio</p>
        </div>

        <p>Elaboración Principal*</p>
        <app-adder-container [invalid]="contentValidation()" [content]="contentSend()" (contentEmitter)="content($event)"></app-adder-container>

        <div *ngIf="main">
            <app-elab-carousel [elaborations]="elaborations" [portions]="newRecipeForm.controls['portions'].value"></app-elab-carousel>
        </div>

        <!-- <div class="mt-1">
            <label for="conditions"><small>Condiciones especiales</small></label>
            <app-icon-selector [options]="conditions" [type]="'conditions'" [grid]="'63'" [oldOptSelected]="selectedConditions" (selectedEmit)="this.selectedConditions=$event" name="conditions"></app-icon-selector>
        </div> -->

        <div class="mt-1">
            <p>Utensilios</p>
            <div class="d-flex mt-1">
                <div *ngFor="let tool of toolsSelected" class="cat-select">
                    <div [class]="'btn-ingredient bg-'+categorySelect(tool)">
                        <img (click)="openModal('tools')" [src]="'assets/img/intern/icons/tools/'+categorySelect(tool)+'.svg'" [alt]="categorySelect(tool)" class="icon" [title]="categorySelect(tool) | Esp | titlecase">
                    </div>
                    <p>{{ tool | Esp | titlecase }}</p>
                </div>
                <button (click)="openModal('tools')" type="button" class="btn-add ml-05 mt-0"><span class="m-auto">+</span></button>
                <p *ngIf="toolsSelected.length<=0" class="o-5 m-auto ml-05 pl-05 bd-l">¡No hay utensilios!</p>
            </div>
        </div>

        <p class="mt-1 o-5 text-center">¿Añadir más información?</p>
        <div class="d-flex js-center">
            <hr class="hr-decor">
            <button (click)="more=!more" type="button" class="btn-add"><span class="m-auto" [class.d-none]="more">+</span><span class="m-auto" [class.d-none]="!more">-</span></button>
            <hr class="hr-decor">
        </div>

        <div class="mt-1 grow-down" [class.d-none]="!more">
            <label class="mt-3">Etiquetas</label>
            <app-pills [pills]="tags" (pillsEmit)="tags=$event"></app-pills>

            <label class="mt-3">Observaciones</label>
            <textarea placeholder="Añadir observaciones..." cols="30" rows="10" formControlName="observations" [class.error]="invalidField('observations')"></textarea>

            <label class="mt-3">Enlace de video</label>
            <input type="url" formControlName="link" placeholder="Url Youtube..." [class.error]="invalidField('link')">
            <p *ngIf="invalidField( 'link')" class="error-text">Solo admite enlaces a <a href="https://www.youtube.com/" class="error-text">youtube.com</a></p>
        </div>

        <p *ngIf="newRecipeForm.invalid && formSubmitted" class="error-text">Error de formulario, comprueba los campos señalados en rojo</p>

        <div [class]="'align-center mt-2 mb-'+(main?5:1)">
            <button type="submit" [class]="'btn-red'+(main?'':'-ol')">Guardar {{ main?'Receta':'Elaboración'}}</button>
        </div>
    </form>

    <div *ngIf="foreign" class="foreign">

        <div class="row g-21 mb-1">
            <div class="col mb-md-1">
                <div class="row g-2">
                    <div class="col d-flex">
                        <img (click)="openModal('image');setImg(elaboration.img)" [src]="elaboration.img | img:'recipes'" [alt]="content.name" class="in-img my-auto mw-100 h-auto">
                    </div>
                    <div class="col">
                        <div class="my-auto ml-1">
                            <p><b class="enf">Tiempo: </b><span class="o-5">{{ elaboration.time }}</span></p>
                            <p><b class="enf">Dificultad: </b><span class="o-5">{{ elaboration.difficulty | toStr:'difficulty' | Esp }}</span></p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="ml-1">
                    <div *ngIf="elaboration.conditions?(elaboration.conditions.length>0):false" class="row g-63 mb-1">
                        <div *ngFor="let condition of elaboration.conditions" class="col search-opt">
                            <img [src]="'assets/img/intern/icons/conditions/'+(condition | toStr:'conditions')+'.svg'" [alt]="condition | toStr:'conditions'" [title]="condition | toStr:'conditions' | Esp" class="icon m-auto">
                            <p>{{ condition | toStr:'conditions' | Esp }}</p>
                        </div>
                    </div>
                    <div *ngIf="elaboration.tools?(elaboration.tools.length>0):false">
                        <p class="need-text"><small>Utensilios Necesarios:</small></p>
                        <div class="d-flex js-center">
                            <div *ngFor="let tool of elaboration.tools" class="cat-select m-05 w-fc">
                                <div [class]="'text-center cr-unset btn-ingredient bg-'+categorySelect(tool | toStr:'tools')">
                                    <img [src]="'assets/img/intern/icons/tools/'+categorySelect(tool | toStr:'tools')+'.svg'" [alt]="tool | toStr:'tools'" class="icon m-auto">
                                </div>
                                <p>{{ tool | toStr:'tools' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="elaboration.observations" class="mb-1">
            <div class="d-flex">
                <p class="enf mr-05"><b>observaciones:</b></p>
                <button (click)="showObs=!showObs" type="button" class="btn-add card-category"><span class="m-auto">
                    <span id="plusMain">+</span>
                    <span class="d-none" id="minusMain">-</span></span>
                </button>
            </div>
            <p [class.d-none]="!showObs">{{ elaboration.observations }}</p>
        </div>

        <app-elaborations-table [content]="elaboration" [noImg]="true" [portions]="newRecipeForm.controls['portions'].value"></app-elaborations-table>

    </div>

    <img *ngIf="!main" (click)="elaborationsEmit.emit(false)" src="assets/img/intern/icons/menus/close.svg" alt="close" class="modal-close">
</div>