<div class="title-box d-flex">
    <div class="my-auto">
        <app-btn-back [abs]="false"></app-btn-back>
    </div>

    <div class="profile-menu mb-0 pr-2">
        <div (click)="myListBool=true;listChange(myList);" class="animated" [class.category-selected]="myListBool">
            <p class="m-auto"><b>Mi Lista</b></p>
        </div>
        <div (click)="myListBool=false;toShop=[];toShopChecked=[]" class="animated" [class.category-selected]="!myListBool">
            <p class="m-auto"><b>Compartidas</b></p>
        </div>
    </div>
</div>

<div *ngIf="!myListBool && (toShop.length <= 0 || toShopChecked.length <= 0)">
    <div (click)="listChange(sharedList)" *ngFor="let sharedList of sharedLists">
        <div class="d-flex p-1 cr-pointer">
            <img [src]="sharedList.user?.img | img:'users'" alt="User Img" class="icon bd-bg-round mr-05">
            <p class="m-auto ml-0">{{ sharedList.user?.name }}</p>
        </div>
    </div>
</div>

<div *ngIf="myListBool || toShop.length > 0 || toShopChecked.length > 0" class="px-1" id="todolist">
    <div class="d-flex js-spcbtw title-box">
        <p *ngIf="!myListBool" class="fs-l m-auto ml-0"><b>Lista de {{list.user?.name}}</b></p>
        <button *ngIf="myListBool" (click)="share()" class="btn-none cl-main">Compartir</button>

        <div class="m-auto mr-0">
            <button *ngIf="edit && undo.length > 0" (click)="rmUndo()" class="btn-none cl-main">Deshacer</button>
            <button (click)="edit=!edit" class="btn-none cl-main ml-1">{{edit?'Guardar':'Editar'}}</button>
        </div>
    </div>

    <div class="d-flex">
        <input (keydown.enter)="addItem(todo)" type="text" placeholder="Añadir Ingredientes" #todo>
        <button (click)="addItem(todo)" class="btn-add ml-05">+</button>
    </div>

    <hr class="hr-decor mx-auto mt-1 mb-2">
    <div *ngIf="toShop.length > 0  || toShopChecked.length > 0" class="w-70-75 m-auto scroll-show">
        <ul *ngFor="let itemlist of [toShop, toShopChecked]; let lIdx=index" [class.checked]="lIdx === 1">
            <p *ngIf="lIdx === 0 && toShop.length <= 0" class="text-center o-5">Lista Vacía</p>
            <hr *ngIf="lIdx === 1 && toShopChecked.length > 0" class="hr-decor mx-auto my-2">
            <li *ngFor="let item of itemlist; let i=index" class="item" [id]="lIdx+'item'+i">
                <!-- <li *ngFor="let item of toShop; let i=index" class="item" [id]="'item'+i" [class.pt-1]="i===0" [class.pb-1]="i===(toShop.length-1)"> -->
                <button *ngIf="i>0 && edit" (click)="posChange(i, lIdx, -1)" type="button" class="btn-ch">
                    <img src="/assets/img/intern/icons/menus/arrow.svg" alt="arrow Up">
                </button>
                <div class="d-flex js-spcbtw" [class.mt-0]="i===0" [class.my-1]="!edit">
                    <!-- <div class="d-flex js-spcbtw"  [class.my-1]="!edit"> -->
                    <div class="d-flex">
                        <img (click)="checked(i, lIdx)" src="/assets/img/intern/icons/menus/check.svg" alt="check" class="icon xsm o-25 animated fast cr-pointer my-auto mr-1">
                        <span *ngIf="!edit" [innerHtml]="item" class="item"></span>
                        <input *ngIf="edit" (change)="changeItem(i, lIdx)" type="text" [value]="item" class="item" [id]="lIdx+'itemVal'+i">
                    </div>
                    <button *ngIf="edit" (click)="rmItem(i, lIdx)" class="btn-add ml-05">-</button>
                </div>
                <button *ngIf="(i<(toShop.length-1) || i<(toShopChecked.length-1)) && edit" (click)="posChange(i, lIdx, 1)" type="button" class="btn-ch down">
                    <img src="/assets/img/intern/icons/menus/arrow.svg" alt="arrow Down">
                </button>
            </li>
        </ul>
    </div>

</div>