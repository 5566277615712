<form [formGroup]="form">
    <div class="row g-21">
        <div class="col w-fc m-auto ml-0 p-0 btn-m-sm">
            <input (change)="emitter()" formControlName="name" type="text" placeholder="Plato..." [class.error]="error">
        </div>
        <div class="col w-fc d-flex flex-00 m-auto">
            <app-mood-score [score]="score" [moods]="['bad', 'normal', 'good']" [buttons]="['no pedir', 'sin más', 'pedir']" (scoreEmitter)="scoreChange($event)"></app-mood-score>
            <button *ngIf="del" (click)="plateDel()" type="button" class="btn-rm ml-1"><span class="m-auto">-</span></button>
        </div>
    </div>
</form>

<p *ngIf="del" class="advise a-l lg mt-05" [ngStyle]="{'opacity': showAdv?1:0, 'visibility': showAdv?'visible':'hidden'}">
    No puede dejar este campo vacíos o erroneos
</p>