import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'Units'
})
export class UnitsPipe implements PipeTransform {

  transform( units: string, type: string ) {
    let unitsArr: any = units.replace( ')', '');
    unitsArr = unitsArr.split( '(' );
    if ( type === 'sm' ) {
      return unitsArr[1];
    } else {
      return unitsArr[0];
    }
  }

}
