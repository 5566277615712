import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { User } from '../../models/user.model';
import { Location } from '@angular/common';
import { Restaurant } from '../../models/restaurant.model';
import { RestaurantsService } from '../../services/backend/restaurants.service';
import { TypeChangeService } from '../../services/type-change.service';
import { RestaurantReviewsService } from '../../services/backend/restaurant-reviews.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/backend/auth.service';
import { ModalService } from '../../services/modal.service';
import { CacheService } from '../../services/cache.service';
import { VariablesService } from '../../services/variables.service';
import { RestaurantReview } from '../../models/restaurant-review.model';
import { UserService } from '../../services/backend/user.service';
import { HelperService } from '../../services/helper.service';
import { SeoServiceService } from '../../services/seo-service.service';
import { environment } from 'environments/environment';

const base_url = environment.base_url;


@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.component.html',
  styles: [
  ]
})
export class RestaurantComponent implements OnInit {

  public content: Restaurant | any;
  public img: string;

  public coins: any[];
  public countries: any[];
  public commodities: string[];
  public contentcommodities: string[] = [];
  
  public owner: boolean = false;
  public edit: boolean = false;
  public addReview: boolean = false;
  public loading: boolean = false;
  public mapLoading: boolean = false;
  public mapLoadingHide: boolean = false;
  public reviewed: boolean = true;

  public uid: string;
  private mainUser: User;
  public isFav: boolean = false;
  public isLater: boolean = false;
  public avgScore: number | undefined;
  public avgFriendsScore: any;
  public model: string = 'Restaurant';
  public reviewsCount: number = 0;
  public mapHide: boolean = true;
  // public mood: string[] = ['less', 'happy', 'excellent'];
  public mood: string[] = ['angry', 'neutral', 'happy', 'excellent'];
  public moodStatus = ['bad', 'normal', 'good']
  public size: string[] = ['pequeñas', 'moderadas', 'grandes'];
  public bookingTime: string[] = ['No necesaria', 'Día anterior', 'Vários días'];
  public contact: string;
  public contactType: string;
  public plates: any[] = [];
  public priceAux: number = 1;
  private id: string;

  public recommendation: string[] = ['no recomendado', 'no está mal...', 'recomendado'];
  public recommendationColor: string[] = ['error', 'warning', 'ok'];


  constructor(  private restaurantsService: RestaurantsService,
                private typeChangeService: TypeChangeService,
                private restaurantReviewsService: RestaurantReviewsService,
                private activatedRoute: ActivatedRoute,
                private authService: AuthService,
                private userService: UserService,
                private modalService: ModalService,
                private router: Router,
                private route: ActivatedRoute,
                private location: Location,
                private seoService: SeoServiceService,
                private cs: CacheService,
                private hs: HelperService,
                private vs: VariablesService ) {
                  this.countries = vs.countries;
                  this.coins = vs.coins;
                  this.commodities = vs.commodities;
                  this.mainUser = authService.user;
                }

  ngOnInit(): void {

    this.loading = true;
    this.mapLoading = true;

    const { id } = this.activatedRoute.snapshot.params;
    this.id = id;

    this.restaurantsService.getRestaurantsById( id ).subscribe(  resp => {
      this.content = resp.restaurant;
      if ( resp.restaurant?.restaurantType[0] === 1 ) this.priceAux = 3;
      for ( let plate of resp.plates) this.plates = [ ...this.plates, ...plate.plates ];      
      for ( let commodity of this.commodities ) {
        if ( this.content.commodities && this.content.commodities[commodity] ) {
          this.contentcommodities.push( commodity );
        }
      }
      
      this.avgScore = this.scoreAlg( resp.restaurant.score );
      if ( this.content.score) for ( let score of ['recommended', 'qualityprice', 'atention', 'decoration', 'foodsize', 'bookingscore', 'parkingscore'] ) this.content.score[score] = (this.rounder(score) + 1);
      // this.hs.setShare( resp.restaurant.img, resp.restaurant.name, 'restaurants' );
      const meta = [
              { name: 'description', content: 'Únete a la comunidad de los Foodies. Encuentra los mejores restaurantes con las valoraciones de tus contactos de confianza.' },
              { property: 'og:locale', content: 'es_ES'},
              { property: 'og:type', content: 'web-app' },
              { property: 'og:title', content: 'FoodiesHome: ' + resp.restaurant.name },
              { proprety: 'og:description', content: 'Únete a la comunidad de los Foodies. Encuentra los mejores restaurantes con las valoraciones de tus contactos de confianza.' },
              { property: 'og:image', content: 'https://storage.googleapis.com/foodieshome-uploads/uploads/restaurants/' + resp.restaurant.img },
              { property: 'og:url', content: base_url + 'restaurant/'+ id },
              { name: "twitter:card", content: "summary_large_image" },
            ];
      this.seoService.updateMetaTags( meta );
      this.seoService.updateTitle( 'FoodiesHome | ' + resp.restaurant.name );
    } );

    this.uid = this.authService.uid;

    const friendsArr = this.mainUser?.follow?.users?.array || [];
    if ( this.mainUser && friendsArr.length > 0 ) {
      this.restaurantReviewsService.getRestaurantReviewsAvgFriends( id, friendsArr ).subscribe( resp => {
        if (resp.ok ) this.avgFriendsScore = this.scoreAlg( resp.avg );
      } );
    }
    
    let iter = 0;
    
    const interval = setInterval( () => {

      if ( this.content ) {

        clearInterval(interval);
        if ( (this.content.user?._id || this.content.user?.id) === this.uid ) {
          this.owner = true;
        }

        if ( this.mainUser?.role === 'ADMIN_ROLE' ) this.modalService.setImgEdit( true, (this.content.id || this.content._id) );
        this.fav();
        this.later();
        
        this.loading = false;
        
        this.route.fragment.subscribe( fragment => {
          setTimeout(()=> {
            document.getElementById(fragment || '')?.scrollIntoView( { behavior: 'smooth' } );
            this.location.replaceState( this.location.path() );
          }, 200)
        });
        
        let map,
            j: number;
        const mapInterval = setInterval( () => {
          map = document.getElementById( 'mapLoc' );
          if ( map ) {
            clearInterval( mapInterval );
            map.setAttribute( 'src', 'https://www.google.com/maps/embed/v1/place?key=AIzaSyApxDl_UlYmLAPCm27f4ahKC2bJC155Wp8&q='+this.content.name+','+(this.content.address.addressplus?this.content.address.addressplus+',':'')+this.content.address.town+','+this.content.address.country+'&zoom=15');    
            this.mapHide = false;
            setTimeout( () => {
              this.mapLoading = false;
              setTimeout( () => {
                this.mapLoadingHide = true;
              }, 500);
            }, 1000);
          } else if ( j > 20 ) {
            clearInterval( mapInterval );
            this.modalService.setStatus( 'warning', 'No hemos podido cargar la ubicación' );
          } else {
            j++;
          }
        }, 100 )

      } else if ( iter > 20 ) {

        clearInterval(interval);
        this.loading = false;
        this.modalService.setStatus( 'error', 'Error en el proceso, compruebe su conexión e intentelo de nuevo' );

      } else {
        iter++;
      }

    }, 100);

  }

  ngOnDestroy() {
    if ( this.mainUser?.role === 'ADMIN_ROLE' ) this.modalService.setImgEdit( false, '' );
  }

  addFavs() {
    
    if ( !this.isFav && this.uid ) this.modalService.openAdviseBanner( 'en favoritos' );

    const id = this.content.id;
    this.restaurantsService.addFavs( id?id:'' ).subscribe( resp => {
      if ( resp.ok) {
        if ( this.isFav && this.mainUser?.favs?.restaurants?.array && (this.mainUser?.favs?.restaurants?.array.indexOf( id ) >= 0) ) {
          this.mainUser?.favs.restaurants?.array.splice( this.mainUser?.favs.restaurants?.array.indexOf( id ), 1)
        } else if( this.mainUser?.favs?.restaurants?.array ) {
          this.mainUser?.favs.restaurants.array?.push( id );
        } else if ( this.mainUser ) {
          this.mainUser = { ...this.mainUser, favs: { restaurants: { array:  [ id ] } } };
        }
        this.cs.cacheReset( 'restaurantsfavsById' );
        this.authService.user = { ...this.authService.user, favs: { restaurants: { array: ( this.mainUser?.favs?.restaurants?.array  || [] ) } } };
        return this.isFav = !this.isFav;
      }
      this.modalService.setStatus( resp.nolog ? 'no-log' : 'error', resp.msg );
    });

  }
  
  fav() {
    if ( this.mainUser?.favs?.restaurants?.array ) {
      const fav = this.mainUser?.favs?.restaurants?.array.indexOf( this.content.id ) >= 0;
      this.isFav = fav;
    }
  }

  addLater() {

    if ( !this.isLater && this.uid ) this.modalService.openAdviseBanner( 'para más tarde' );

    this.userService.addLater( this.id, 'restaurants' ).subscribe( resp => {
      if ( resp.ok) {
        let laterArr: any = false,
            laterPos: any = -1
        if ( this.mainUser?.later && this.mainUser?.later?.restaurants ) {
          laterPos = this.mainUser?.later?.restaurants?.indexOf( this.id );
          laterPos = (laterPos===0) ? 0 : (laterPos || -1);
          laterArr = this.mainUser?.later?.restaurants || false;
        }
        if ( this.isLater && this.mainUser.later && laterArr && (laterPos >= 0) ) {
          this.mainUser.later.restaurants?.splice( laterPos, 1);
        } else if( this.mainUser.later  && laterArr ) {
          this.mainUser.later.restaurants?.push( this.id );
        } else if ( this.mainUser ) {
          this.mainUser = { ...this.mainUser, later: { restaurants: [ this.id ] } };
        }
        this.cs.cacheReset( 'restaurantslaterById' );
        this.authService.user = { ...this.authService.user, later: { restaurants: ( laterArr  || [] ) } };
        return this.isLater = !this.isLater;
      }
      this.modalService.setStatus( resp.nolog ? 'no-log' : 'error', resp.msg );
    });

  }

  later() {
    const userLaterType: any = this.mainUser?.later?.restaurants || false;
    if ( userLaterType ){
      let laterIdx = userLaterType.indexOf( this.id );
      this.isLater = (laterIdx >= 0);
    }
  }

  openModal( type: 'login' | 'register' | 'image' | 'crop' | 'tools' | 'ingredients' ) {
    this.modalService.openModal( type );
  }

  setImg( img: string ) {
    this.modalService.setImg( img, 'restaurants', this.content.updatedAt || '', this.content.name );
  }

  obs( i: any ) {
    
    const check = document.getElementById( 'check' + i );
    const plus = document.getElementById( 'plus' + i );
    const minus = document.getElementById( 'minus' + i );
    if ( check?.classList.contains( 'd-none' ) ) {
      check?.classList.remove( 'd-none' );
      plus?.classList.add( 'd-none' );
      minus?.classList.remove( 'd-none' );
    } else {
      check?.classList.add( 'd-none' );
      plus?.classList.remove( 'd-none' );
      minus?.classList.add( 'd-none' );
    }
    
  }

  categorySelect( option: string ){
    return this.vs.categorySelect( option );
  }

  format( str: any ) {
    
    if ( !str ) return;
    let format = str;
    format = format.replace( 'https://', '');
    format = format.replace( 'http://', '');
    format = format.replace(/\/$/g, '');
    return format = format.replace( 'www.', '');

  }

  rounder( type: string ) {
    return Math.max( Math.floor(this.content.score[type] - 0.5), 0 );
  }

  scoreAlg( score: any ) {
    if ( !score?.recommended || !score?.qualityprice || !score?.atention ||  !score?.decoration ) return;
    return ( (score.recommended - 1) * 0.4 * 3/2 + (score.qualityprice - 1) * 0.3 + (score.atention - 1) * 0.15 + (score.decoration - 1) * 0.15 ) * 10/3;
  }

  advise() {

    const advise = document.getElementById( 'price-advise' );
    
    if ( advise && advise.style.opacity !== '1' ) {
      advise.style.opacity = '1';
      advise.style.visibility = 'visible';
      setTimeout( () => {
        advise.style.opacity = '0';
        advise.style.visibility = 'hidden';
      }, 2000 )
    }

  }

  scrollRev() {
    document.getElementById('reviews')?.scrollIntoView({ behavior: 'smooth' });
  }

  contactChange( contact: string, type: string ) {
    this.contact = contact;
    this.contactType = type;
  }
  
}
