<div class="main-page">

    <div class="title-box">
        <div class="page-title">
            <div class="my-auto">
                <app-btn-back [abs]="false"></app-btn-back>
            </div>
            <h2 class="title">Nueva receta</h2>
        </div>
    </div>

    <div class="mt-1 px-05">
        <app-recipe-form></app-recipe-form>
    </div>

</div>