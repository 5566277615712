import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Reply } from '../../../models/reply.model';
import { AuthService } from '../../../services/backend/auth.service';
import { DropdownMenuComponent } from '../../dropdown-menu/dropdown-menu.component';

@Component({
  selector: 'app-reply',
  templateUrl: './reply.component.html',
  styles: [
  ]
})
export class ReplyComponent implements OnInit {

  @ViewChild( DropdownMenuComponent ) dropdownMenuComponent: DropdownMenuComponent;
  @Input() reply: Reply;
  
  public owner: boolean = false;
  public extend: boolean = false;
  public longText: boolean = false;
  public collapsed: boolean = true;
  public edit: boolean = false;
  private uid: string;

  constructor(  private authService: AuthService  ) {
                this.uid = authService.uid;
              }

  ngOnInit(): void {
    this.owner = ((this.reply?.user?._id || this.reply?.user?.id) === this.uid);
    if ( this.reply.observations?.length > 150 ) this.longText = true;
  }

  replyUpdate( event: { form: Reply, edit: boolean } ) {
    this.reply.observations = event.form.observations;
    this.edit = event.edit;
    this.dropdownMenuComponent.edit = event.edit;
  }

}
