import { Component, HostListener, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HelperService } from '../../../services/helper.service';
import { VariablesService } from '../../../services/variables.service';
import { ModalService } from '../../../services/modal.service';
import { ImgSelectComponent } from '../../img-select/img-select.component';
import { UploadService } from '../../../services/backend/upload.service';
import { SearchService } from '../../../services/backend/search.service';
import { RestaurantReviewsService } from '../../../services/backend/restaurant-reviews.service';
import { RestaurantsService } from '../../../services/backend/restaurants.service';
import { RestaurantReview } from '../../../models/restaurant-review.model';
import { PlateScoreComponent } from '../score/plate-score/plate-score.component';
import { Router } from '@angular/router';
import { CacheService } from '../../../services/cache.service';
import { Restaurant } from '../../../models/restaurant.model';
import { TypeChangeService } from '../../../services/type-change.service';
import { UserService } from '../../../services/backend/user.service';
import { User } from '../../../models/user.model';
import { AuthService } from '../../../services/backend/auth.service';

@Component({
  selector: 'app-restaurant-review-form',
  templateUrl: './restaurant-review-form.component.html',
  styles: [
  ]
})
export class RestaurantReviewFormComponent implements OnInit {

  @ViewChild( ImgSelectComponent ) imgSelectComponent: ImgSelectComponent;
  @ViewChild( PlateScoreComponent ) plateScoreComponent: PlateScoreComponent;

  @Input() id: string;
  @Input() inRestaurant: boolean = false;
  @Input() restaurant: Restaurant | undefined;
  @Input() oldReview: RestaurantReview | any;
  @Output() reviewEmit: EventEmitter< any > = new EventEmitter();
  @Output() editEmit: EventEmitter< boolean > = new EventEmitter();

  public newRestaurantReviewForm: FormGroup;
  public formValid: boolean = true;
  public formSubmitted: boolean = false;
  public checked: boolean = false;
  public plateValid: boolean = true;
  public plateArr: any[] = [];
  public img: File | Blob;
  public imgArr: any[] = [];
  public imgArrToUp: any[] = [];
  public imgArrUpFn: any[] = [];
  public firstImg: string;
  public more: boolean = false;

  public coins: any[];
  public countries: any[];
  public shadow: boolean = true;
  public newImg: boolean = false;

  public timeOut: any;
  public timeOutShow: any;
  public showMap: boolean = false;
  public radar: boolean = false;
  public incomplete: boolean = false;
  public down: boolean = false;

  public searchTerm: string;
  public searchContents: Restaurant[] = [];
  public searchOpen: boolean = false;
  private timeOutSearch: any;
  public locked: boolean = false;
  public loading: boolean = false;
  public empty: boolean = false;
  public from: number = 0;
  public limit: number = 5;
  public recommended: number;
  public tags: string[] = [];

  // public address: any[];
  public towns: string[];
  public townsFil: string[] = [];

  public ocassionsSel: string[];
  public ocassions: string[] = [];

  private mainUser: User;
  
  constructor( private fb: FormBuilder,
               public helperService: HelperService,
               private vs: VariablesService,
               private modalService: ModalService,
               private userService: UserService,
               private authService: AuthService,
               private uploadService: UploadService,
               private searchService: SearchService,
               private restaurantsService: RestaurantsService,
               private restaurantReviewsService: RestaurantReviewsService,
               private typeChangeService: TypeChangeService,
               private cs: CacheService,
               private router: Router  ) { 
                 this.ocassions = vs.ocassions;
                 this.mainUser = authService.user;
               }

  ngOnInit(): void {

    // this.addressVS();
    this.townsVS();
    this.coins = this.vs.coins;
    this.countries = this.vs.countries;
    const OR = this.oldReview;
    const RT = this.restaurant;
    
    this.plateArr = OR?.plates || [];
    this.tags = OR?.tags || [];
    //Eliminar tras dejar la base de datos bien
    this.imgArr = OR?.images ? ((OR?.images[0] === 'no-img') ? [] : OR?.images) : [];
    //hasta aquí
    this.recommended = OR?.recommended || 0;
    this.ocassionsSel = this.typeChangeService.numToStrArr( (OR?.ocassions || []), this.ocassions );

    this.newRestaurantReviewForm = this.fb.group({
      name: [ RT?.name || OR?.restaurant?.name || '', Validators.required ],
      town: [ RT?.address.town || OR?.restaurant?.address?.town || '', Validators.required ],
      country: [ RT?.address.country || OR?.restaurant?.address?.country || this.countries[63].iso, Validators.required ],
      addressplus: [ RT?.address.addressplus || OR?.restaurant?.address?.addressplus || '' ],
      recommended: [ OR?.recommended || '', Validators.required  ],
      qualityprice: [ OR?.qualityprice || '', Validators.required ],
      atention: [ OR?.atention || '', Validators.required ],
      decoration: [ OR?.decoration || '', Validators.required ],
      price: [ OR?.price?.price || '', Validators.required  ],
      coin: [ OR?.price?.coin || this.helperService.coin, Validators.required  ],
      images: [ OR?.images || [] ],
      observations: [ OR?.observations || '' ],
      foodsize: [ OR?.foodsize || '' ],
      ocassions: [ this.ocassionsSel || [] ],
      bookingscore: [ OR?.bookingscore || '' ],
      parkingscore: [ OR?.parkingscore || '' ],
      tags: [ OR?.tags || [] ],
      plate: [ '' ],
      platescore: [ '' ],
    });

    if ( !this.restaurant && this.id ) {
      this.restaurantsService.getRestaurantsById( this.id ).subscribe( resp => {
        this.showMap = true;
        this.externRestaurant( resp.restaurant );
      } );
    } else if ( this.restaurant ) {
      this.showMap = true;
    }

  }

  addImg( img: any ) {
    
    this.img = img;
    this.newImg = true;

    // if ( this.imgArr.length <= 5 ) {
      this.imgArr.push( this.helperService.tempImg );
      this.imgArrToUp.push( img );
    // } else {
    //   this.modalService.setStatus( 'warning', 'Solo puedes subir un máximo de 5 imágenes' );
    // }

    this.imgSelectComponent.reset();

  }

  coinsSelect() {
    this.modalService.openModal( 'coins' );
  }

  fval( ctrl: string, val: any ) {
    this.newRestaurantReviewForm.controls[ctrl].setValue( val );
  }

  gFval( ctrl: string ) {
    return this.newRestaurantReviewForm.controls[ctrl].value;
  }

  saveReview() {
    
    if ( this.checked ) return this.saveChecked();

    this.search();
    if ( this.searchContents?.length > 0 && !this.restaurant ) {
      
      this.helperService.setArray( this.searchContents );
      this.modalService.openModal( 'exist' );
      
      const interval = setInterval( () => {
        if ( !this.modalService.showModal ) {
          clearInterval( interval );
          if (  !this.modalService.ok ) return;
          if ( this.helperService.selection ) {
            this.externRestaurant( this.helperService.selection );
            this.helperService.setSelection();
            window.scroll( { top: 0 });
            return;
          }
          this.saveChecked();
        }
      }, 100 )
      
    } else {
      this.saveChecked();
    }
    
  }
  
  async saveChecked() {
    
    this.formSubmitted = true;
    this.checked = true;
    this.fval( 'coin', this.helperService.coin );
    
    if ( this.newImg ) {
      
      this.imgArrUpFn = await this.imgUpload();
      
      let iter = 0;
      const interval = setInterval( () => {
        
        if ( this.imgArrUpFn.length === this.imgArrToUp.length && this.imgArrUpFn.indexOf( 'empty' ) < 0 ) {
          
          this.imgArrToUp = this.imgArrUpFn;
          this.fval( 'images', [ ...this.imgArr.slice(0, (this.imgArr.length - this.imgArrUpFn.length)), ...this.imgArrUpFn ] );
          this.sendForm();
          clearInterval(interval);
          
        } else if ( iter > 20 ) {
  
          this.modalService.setStatus( 'error', 'Error en el proceso, compruebe su conexión e intentelo de nuevo' );
          clearInterval(interval);
  
        } else {
          iter++;
        }
  
      }, 100 );

      this.newImg = false;

    } else {
      this.sendForm();
    }

  }

  async sendForm() {

    this.modalService.setStatus( 'loading' );
    
    const NRRF = this.newRestaurantReviewForm;
    let NRRFO = this.newRestaurantReviewForm.value;
    
    if ( NRRF.valid && this.gFval('qualityprice') && this.gFval('recommended') && this.gFval('atention') && this.gFval('decoration') && this.gFval('price') || this.oldReview && this.gFval('qualityprice') && this.gFval('recommended') && this.gFval('atention') && this.gFval('decoration') && this.gFval('price') ) {

      NRRFO.ocassions = this.typeChangeService.strToNumArr( this.ocassionsSel, this.ocassions );

      this.formValid = true;

      for ( let control of [ 'images', 'observations', 'foodsize', 'bookingscore', 'parkingscore', 'ocassions' ] ) {
        
        if ( !this.oldReview || !this.oldReview[ control ] ) {
          if ( control === 'ocassion' && (NRRF.controls[ control ].value || []).length <= 0 ) {
            delete NRRFO[control];
          } else if ( NRRF.controls[ control ].value === '' || NRRF.controls[ control ].value === 0 ) {
            // if ( control === 'price' ) delete NRRFO['coin'];
            delete NRRFO[control];
          }
        }
        
      }

      NRRFO.price = {
        price: NRRF.controls[ 'price' ].value,
        coin: NRRF.controls[ 'coin' ].value
      }
      
      if ( NRRF.controls['images'].value.length <= 0 ) NRRFO.images = [];
      // if ( NRRF.controls['images'].value.length <= 0 ) NRRFO.images = ['no-img'];
      
      NRRFO.plates = this.plateArr || [];
      
      for ( let cat of ['name', 'town', 'country', 'addressplus', 'plate', 'platescore'] ) {
        delete NRRFO[cat];
      }
      
      if ( !this.restaurant && !this.oldReview ) {

        this.restaurant = {
          name: this.gFval( 'name' ),
          address: {
            town: this.gFval( 'town' ),
            country: this.gFval( 'country' )
          }
        }

        if ( this.firstImg ) this.restaurant.img = this.firstImg;

        if ( this.gFval( 'addressplus' ).length > 0 ) {
          this.restaurant.address = { ...this.restaurant.address, addressplus: this.gFval( 'addressplus' ) }
        }

        await this.restaurantsService.createRestaurant( this.restaurant ).subscribe( async resp => {
          NRRFO.restaurant = resp.restaurant.id;
          await this.restaurantReviewsService.createRestaurantReview( NRRFO ).subscribe( reviewResp => {
            this.reviewOk( reviewResp );
          });
        });

      } else {
        
        NRRFO.restaurant = this.restaurant?.id || this.restaurant?._id|| this.oldReview?.restaurant;
        if ( this.oldReview ) {
          const oldId = this.oldReview.id || this.oldReview._id;
          
          await this.restaurantReviewsService.updateRestaurantReview( oldId, NRRFO ).subscribe( reviewResp => {
            this.reviewOk( reviewResp );
          });
        } else {
          await this.restaurantReviewsService.createRestaurantReview( NRRFO ).subscribe( reviewResp => {
            this.reviewOk( reviewResp );
          });
        }

      }
      
      this.reviewEmit.emit( {form: NRRFO, edit: false } );
      
      this.reset();
      //Revisar en un futuro, no me convence
      this.modalService.setEditing( false );
    }

    //Create or save in future visit restaurant without review
    else if ( !this.oldReview && !this.gFval('qualityprice') && !this.gFval('recommended') && !this.gFval('atention') && !this.gFval('decoration') && !this.gFval('price') ) {
      
      this.modalService.setStatus( 'none' );
      this.modalService.setOpt( '¿Has visitado este restaurante?', 'Reséñalo para tus conocidos', ['Más adelante', 'Reseñar'] );
      this.modalService.openModal( 'question' );
      
      const questionInterval = setInterval( () => {
        if ( !this.modalService.showModal ) {
          clearInterval( questionInterval );
          if ( this.modalService.ok ) {
            const scrollTo = document.getElementById( 'reviewForm' );
            this.scroll( { target: scrollTo } , true );
            //Revisar en un futuro, no me convence
            this.modalService.setEditing( false );
            this.formSubmitted = false;
            return;
          } else {

            if ( !this.restaurant ) {
      
              let restaurant: Restaurant = {
                name: this.gFval( 'name' ),
                address: {
                  town: this.gFval( 'town' ),
                  country: this.gFval( 'country' )
                }
              }
      
              if ( this.firstImg ) restaurant.img = this.firstImg;
      
              if ( this.gFval( 'addressplus' ).length > 0 ) {
                restaurant.address = { ...restaurant.address, addressplus: this.gFval( 'addressplus' ) }
              }
      
              this.restaurantsService.createRestaurant( restaurant ).subscribe( async resp => {
                this.restaurant = resp.restaurant;
              });
      
            }
      
            let i = 0;
            const interval = setInterval( () => {
      
              if ( this.restaurant ) {
                
                const type = 'restaurants',
                      id: any = this.restaurant?.id || this.restaurant?._id;
                
                clearInterval( interval );
                this.modalService.openAdviseBanner( 'para más tarde' );
                
                if ( !this.mainUser?.later?.restaurants?.includes( id ) ) {            
                  this.userService.addLater( id, type ).subscribe( resp => {
                    if ( resp.ok) {
                      let laterArr: any = false,
                          laterPos: any = -1
                      if ( this.mainUser?.later && this.mainUser.later[type] ) {
                        laterPos = this.mainUser?.later[type]?.indexOf( id );
                        laterPos = (laterPos===0) ? 0 : (laterPos || -1);
                        laterArr = this.mainUser.later[type] || false;
                      }
                      if ( this.mainUser.later && laterArr && (laterPos >= 0) ) {
                        this.mainUser.later[type]?.splice( laterPos, 1);
                      } else if( this.mainUser.later  && laterArr ) {
                        this.mainUser.later[type]?.push( id );
                      } else if ( this.mainUser ) {
                        this.mainUser = { ...this.mainUser, later: { [type]: [ id ] } };
                      }
                      this.cs.cacheReset( type + 'laterById' );
                      this.authService.user = { ...this.authService.user, later: { [type]: ( laterArr  || [] ) } };
                      this.reviewOk( resp );
                      const navInterval = setInterval( () => {
                        if ( !this.modalService.showModal ) { 
                          clearInterval( navInterval );
                          this.router.navigateByUrl( '/profile');
                        }
                      }, 100 );
                    } else {
                      this.modalService.setStatus( resp.nolog ? 'no-log' : 'error', resp.msg );
                    }
                  });
                } else {
                  this.modalService.setStatus( 'ok', 'el restaurante ya se encontraba en ver más tarde' );
                }
                //Revisar en un futuro, no me convence
                this.modalService.setEditing( false );
      
              } else if ( i > 200 ) {
                this.modalService.setStatus( 'error', 'se ha producido un error de red. Si persiste contacte con foodieshome@datazen.es' );
                //Revisar en un futuro, no me convence
                this.modalService.setEditing( false );
              } else {
                i++;
              }

            }, 100 );
          }
        }
      }, 100 );

    }

    this.formValid = false;

  }

  reviewOk( reviewResp: any ) {
    this.modalService.setStatus( (reviewResp.ok ? 'ok' : 'error'), (reviewResp.msg ? reviewResp.msg : (reviewResp.ok ? 'Reseña guardada con éxito.<br>Recarga la página si quieres ver el efecto sobre la puntuación global.' : 'No ha sido posible guardar la reseña')) );
    this.cs.cacheReset( 'restaurants', true );
  }

  async imgUpload() {

    let images = new Array(this.imgArrToUp.length).fill( 'empty' );
    
    let type;
    let i = 0;
    for ( let img of this.imgArrToUp ) {
      
      type = typeof img;
      if ( type !== 'string' ) {

        let upImg;
        if ( !this.id ) {
          upImg = await this.uploadService.uploadImg( img, 'restaurants', i );
        } else {
          upImg = await this.uploadService.updateImg( img, 'restaurants', this.id, i );
        }
        
        if ( i === 0 ) {
          if ( !this.restaurant?.img || this.restaurant?.img === 'no-img' || !this.restaurant ) {
            const fImg = await this.uploadService.uploadImg( img, 'restaurants' );
            this.firstImg = fImg.fileName;
          }
        }
        
        images[ upImg.position || 0 ] = upImg.fileName;

      } else {
        images[i] = img;
      }

      i++;

    }

    return images;
  
  }

  geoSearch() {
    
    if ( this.restaurant ) this.restaurant = undefined;
    this.showMap = false;
    this.radar = true;
    clearTimeout( this.timeOut );
    clearTimeout( this.timeOutShow );

    const name = this.gFval( 'name' )?.toLowerCase(),
          town = this.gFval( 'town' )?.toLowerCase(),
          addressplus = this.gFval( 'addressplus' )?.toLowerCase(),
          country = this.gFval( 'country' );
    const term = `${ name }, ${ town }, ${ country }, ${ addressplus }`;

    if ( name.length > 0 && town.length > 0 ) {
      this.incomplete = false;
      this.timeOut = setTimeout ( () => {
        document.getElementById( 'map' )?.setAttribute( 'src', 'https://www.google.com/maps/embed/v1/place?key=AIzaSyApxDl_UlYmLAPCm27f4ahKC2bJC155Wp8&q='+term+'&zoom=15');
        this.timeOutShow = setTimeout( () => {
          this.showMap = true;
          this.radar = false;
        }, 1000 );
      }, 500 );
    } else if ( name.length <= 0 && town.length <= 0 ) {
      this.radar = false;
      this.incomplete = false;
    } else {
      this.incomplete = true;
    }

  }

  plateAdd() {
    
    const NRRF = this.newRestaurantReviewForm;
    
    if ( NRRF.controls['plate'].value?.length > 0 && NRRF.controls['platescore'].value > 0 ) {
      this.plateValid = true;
      this.plateArr.push( { name: NRRF.controls['plate'].value.toLowerCase(), score: NRRF.controls['platescore'].value } );
      this.fval( 'plate', '');
      this.fval( 'platescore', 0);
      document.getElementById( 'plate' )?.focus();
      this.plateScoreComponent.name = '';
      this.plateScoreComponent.score = 0;
      this.plateScoreComponent.moodScoreComponent.score = 0;
      this.plateScoreComponent.form.reset();
      return;
    }
    this.plateValid = false;

  }

  plateChange( idx: number ) {
    const plate: any = document.getElementById( 'plate' + idx );
    if ( plate.value.length > 0 ) {
      return this.plateArr[ idx ].name = plate?.value;
    }
    plate.value = this.plateArr[ idx ].name;
    this.advise( 'plateAdvise' + idx );
  }

  plateDel( idx: number ) {
    this.plateArr.splice( idx, 1 );
  }

  advise( id: string ) {

    const advise = document.getElementById( id );
    if ( advise && advise.style.opacity !== '1' ) {
      advise.style.opacity = '1';
      advise.style.visibility = 'visible'
      setTimeout( () => {
        advise.style.opacity = '0';
        advise.style.visibility = 'hidden'
      }, 2000 )
    }

  }

  // Restaurant Searcher

  search( push: boolean = false) {
    
    this.searchOpen = false;
    this.loading = true;
    clearTimeout( this.timeOut );
    clearTimeout( this.timeOutSearch );

    const time = 500;

    this.timeOutSearch = setTimeout( () => {
 
      this.searchOpen = true;
      if ( !push ) {
        this.from = 0;
      };
      if ( this.searchTerm?.length >= 1 ) {
        return this.searchServ( push );
      }
  
      this.from = 0;
      this.searchContents = [];

    }, time )

  }

  searchServ( push: boolean ) {

    return this.searchService.searchAutComp( this.from, this.limit, this.searchTerm, this.gFval( 'town' ), this.isoCountry( this.gFval( 'country' ) ).iso, 'restaurants' ).subscribe( resp => {
    // return this.searchService.search( this.from, this.limit, this.searchTerm, undefined, { town: this.gFval( 'town' ), country: this.isoCountry( this.gFval( 'country' ) ).iso }, 'restaurants', false, 'restaurants' ).subscribe( resp => {

      if ( resp.ok && resp.searchResults.length > 0 ) {

        if ( push ) {
          this.searchContents.push( ...resp.searchResults );
        } else {
          this.searchContents = resp.searchResults;
        }
        this.empty = false;
        this.from += this.limit;

      } else {
        if ( !push ) this.searchContents = [];
        this.empty = true;
      }

      this.loading = false;

    } );

  }

  scrollSearcher( searchResult: any ) {

    if ( !this.empty && !this.loading ) {
      const pos = searchResult.scrollTop;
      const max = searchResult.scrollHeight || 0;

      if ( (pos + (searchResult.clientHeight * 1.5)) >= max ) {
          this.search( true );
      }
    }
    
  }

  externRestaurant( content: Restaurant ) {
    
    this.searchOpen = false;
    const NRRF = this.newRestaurantReviewForm.controls;
    this.locked = true;
    
    this.fval( 'name', content.name );
    this.fval( 'town', content.address.town );
    this.fval( 'country', this.isoCountry( content.address.country ).iso );
    this.fval( 'addressplus', content.address.addressplus );
    NRRF['name'].disable();
    NRRF['town'].disable();
    NRRF['country'].disable();
    NRRF['addressplus'].disable();
    this.geoSearch();
    this.restaurant = content;
    // this.showMap = true;
    // this.radar = false;
    // this.incomplete = false;

  }

  enableForm() {
    const NRRF = this.newRestaurantReviewForm.controls;
    NRRF['name'].enable();
    NRRF['town'].enable();
    NRRF['country'].enable();
    NRRF['addressplus'].enable();
    this.locked = false;
  }

  townsFilter( text: string ) {
    if ( text.length > 0 ) return this.townsFil = this.towns.filter( town => town.includes( text ) );
    return this.townsFil = [];
  }

  townsVS() {

    if (!this.vs.towns && !this.vs.working) {
      this.vs.getTowns().subscribe( (resp: any) => {
        this.towns = resp;
      } );
    } else {
      this.towns = this.vs.towns;
    }
    
  }

  // addressVS() {

  //   if (!this.vs.address && !this.vs.working) {
  //     // this.vs.towns = [];
  //     this.vs.getAddress().subscribe( (resp: any) => {
  //       // for ( let address of resp ) this.vs.towns.push( address.town );
  //       this.address = resp;
  //     } );
  //   } else {
  //     this.address = this.vs.address;
  //     // this.towns = this.vs.towns;
  //   }
    
  // }

  buttonText() {
    if ( this.newRestaurantReviewForm.valid && this.gFval('qualityprice') && this.gFval('recommended') && this.gFval('atention') && this.gFval('decoration') && this.gFval('price') || this.oldReview && this.gFval('qualityprice') && this.gFval('recommended') && this.gFval('atention') && this.gFval('decoration') && this.gFval('price') ) {
      return 'Reseña';
    } else if ( !this.oldReview && this.gFval('name') && this.gFval('town') && this.gFval('country') && !this.gFval('qualityprice') && !this.gFval('recommended') && !this.gFval('atention') && !this.gFval('decoration') && !this.gFval('price') ) {
      return 'Restaurante';
    }
    return false;
  }

  @HostListener( 'document: click', ['$event'] )
  clickOut( event: { target: any; } ) {
    if ( this.searchOpen ) {
      const searchElaboration: any = document.getElementById( 'search-restaurant' );
      const searchResult: any = document.getElementById( 'search-result' );
      if ( searchElaboration?.contains( event.target ) || searchResult?.contains( event.target ) ) {
        return;
      }
      this.searchOpen = false;
    }
  }

  // Aux
  scroll( event: any, scroll: boolean = true ) {

    const reviews = document.querySelectorAll('.modal .restaurant-review-body');
    
    const scrollIn = ( (reviews.length > 0) ? reviews[0] : window );
    const initY = ( (reviews.length > 0) ? reviews[0].scrollTop : window.scrollY );

    const targetTop: number = event.target.getBoundingClientRect().top;
    if ( targetTop < 100 || !scroll ) return;
    const scrollY = ( initY + (targetTop - 250) );
    setTimeout( () => {
      scrollIn.scrollTo({ top: scrollY, behavior: 'smooth' });
    }, 100 );
    
  }

  onScroll( event: any ) {

    const element = event.target;
    let pos = 0;
    if (element) {
      pos = element.scrollTop;
    }
    const max = element?.scrollHeight || 0;
    
    // element.scrollTo( {top: max, behavior: 'smooth'} );
    
  }

  initScroll() {
    if ( !this.gFval('qualityprice') || !this.gFval('recommended') || !this.gFval('atention') || !this.gFval('decoration') ) return;
    const review = document.querySelector('.modal .restaurant-review-body');
    setTimeout( () => {
      review?.scrollTo( {top: 250, behavior: 'smooth'} );
    }, 100 );
  }

  imgCar( event: any) {
    this.imgArr = event.imagesTemp;
    this.imgArrToUp = event.images;
  }

  reset( exit: boolean = true ) {

    this.restaurant = undefined;
    this.showMap = false;
    this.radar = false;
    this.incomplete = false;
    this.down = false;
    this.formSubmitted = false;
    this.checked = false;
    this.more = false;
    this.locked = false;
    this.plateScoreComponent.form.reset();
    this.plateScoreComponent.name = '';
    this.plateScoreComponent.score = 0;
    this.ngOnInit();

    this.editEmit.emit( false );
    if ( exit ) {
      if ( !this.oldReview ) {
        this.router.navigateByUrl( '/profile' );
      }
    }
    
  }

  isoCountry( country: string ) {
    const iso = this.countries.filter( ctry => {      
      return ctry.iso === country || ctry.name === country;
    } )
    
    return iso[0] || { name: '', iso: '' };
  }

  enter( event: any ) {
    const obs:any = document.getElementById( 'restaurantReviewObs' );
    if ( !obs.contains( event.target )) event.preventDefault();
  }

}
